import { useTheme } from "@mui/material";
import * as React from "react";

const SearchSvg = (props) => {
  const theme = useTheme();
  return (
    <svg
      viewBox="0 0 31 31"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
      {...props}
    >
      <path
        d="M2.246 30.079h-.705a4.904 4.904 0 0 1-1.01-.6 1.846 1.846 0 0 1 .09-2.679c1.845-1.851 3.693-3.7 5.544-5.547.074-.074.144-.151.215-.227-.06-.089-.1-.154-.147-.218a12.98 12.98 0 0 1-2.32-9.53A12.446 12.446 0 0 1 6.99 4.552 12.886 12.886 0 0 1 18.861.135 12.537 12.537 0 0 1 26.2 3.8a12.85 12.85 0 0 1 3.666 6.907c.093.5.154 1 .23 1.5v1.881c-.019.085-.04.169-.056.254-.173.889-.27 1.8-.529 2.664-1.852 6.11-7.91 10.023-14.241 9.199a12.77 12.77 0 0 1-5.89-2.288c-.219-.155-.33-.123-.5.05-1.743 1.731-3.5 3.45-5.241 5.183a3.13 3.13 0 0 1-1.389.929M7.552 12.91c-.002.08-.003.161-.003.241 0 5.152 4.239 9.39 9.39 9.39 5.151 0 9.39-4.238 9.39-9.39 0-5.028-4.039-9.21-9.065-9.384a9.025 9.025 0 0 0-.329-.006c-5.059 0-9.255 4.091-9.383 9.149"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(.001 .025)"
      />
    </svg>
  );
};

export default SearchSvg;
