import { useTheme } from "@mui/material";
import * as React from "react";

const AudioSvg = (props) => {
  const theme = useTheme();
  return (
    <svg
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
      {...props}
    >
      <path
        d="M81.539 0c.635.112 1.281.179 1.9.344a12.548 12.548 0 0 1 9.6 9.782c.83 3.422.124 7.041-1.931 9.9a16.523 16.523 0 0 1-1.838 2.012c-.962 1-1.989 1.94-2.9 2.983a12.837 12.837 0 0 0-2.517 4.79 6.876 6.876 0 0 1-5.575 5.042c-3.661.605-7.202-1.792-8-5.416a8.157 8.157 0 0 1-.146-1.385l-.001-.048c0-.721.592-1.314 1.313-1.316l.053-.001c.741 0 1.354.604 1.365 1.345.046 2.296 1.948 4.16 4.244 4.16a4.254 4.254 0 0 0 4.031-2.914 17.39 17.39 0 0 1 5.1-8.118c.631-.574 1.23-1.182 1.839-1.781a8.697 8.697 0 0 0 2.39-4.874 10.184 10.184 0 0 0-6.035-10.967 9.995 9.995 0 0 0-3.779-.742 10.017 10.017 0 0 0-9.613 7.263 15.204 15.204 0 0 0-.412 2.751 1.4 1.4 0 0 1-1.393 1.39h-.02a1.359 1.359 0 0 1-1.332-1.352l.002-.074c.039-5.924 4.172-11.09 9.943-12.429C78.466.2 79.118.114 79.764 0h1.775Z"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(-63.241)"
      />
      <path
        d="M417.853 9.49c-.027.047-.057.093-.079.142-.22.583-.811.947-1.431.878a1.432 1.432 0 0 1-1.212-1.089c-.255-.857-.5-1.722-.821-2.553a14.44 14.44 0 0 0-2.717-4.338c-.915-1.029-.759-1.893.457-2.529h.683c.271.155.525.338.759.545a17.698 17.698 0 0 1 3.744 6.259c.228.659.413 1.334.618 2l-.001.685Z"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(-382.895 -.001)"
      />
      <path
        d="M199.381 79.818c3.662-.003 6.79 2.744 7.261 6.375a7.16 7.16 0 0 1-.482 3.7 1.374 1.374 0 0 1-1.299.934c-.751 0-1.37-.619-1.37-1.37 0-.201.044-.399.129-.58a4.611 4.611 0 0 0 .337-1.728 4.613 4.613 0 0 0-3.68-4.507 4.747 4.747 0 0 0-2.832.316 1.376 1.376 0 0 1-.601.139c-.752 0-1.37-.618-1.37-1.37 0-.563.347-1.071.871-1.276a6.977 6.977 0 0 1 3.041-.636"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(-182.123 -74.357)"
      />
      <path
        d="M88.656 246.736a1.37 1.37 0 0 1 1.364-1.363c.392 0 .765.169 1.024.463.824.909 1.634 1.83 2.449 2.747.423.475.847.949 1.265 1.428.251.257.392.602.392.961a1.372 1.372 0 0 1-2.434.871c-1.242-1.388-2.486-2.775-3.705-4.183a3.94 3.94 0 0 1-.443-.882l.088-.043"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(-82.51 -228.589)"
      />
      <path
        d="M226.321 167.119a1.715 1.715 0 0 1-1.699-1.706c0-.936.77-1.706 1.706-1.706.936 0 1.706.77 1.706 1.706v.019c0 .926-.761 1.687-1.687 1.687h-.026"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(-209.258 -152.511)"
      />
      <path
        d="M171.441 218.6a1.714 1.714 0 0 1 1.685 1.706c0 .936-.77 1.706-1.706 1.706-.936 0-1.706-.77-1.706-1.706v-.021a1.695 1.695 0 0 1 1.688-1.685h.039"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(-158.106 -203.654)"
      />
      <path
        d="M56.6 326.824a1.715 1.715 0 0 1-1.694-1.706c0-.936.77-1.706 1.706-1.706.936 0 1.706.77 1.706 1.706V325.136c0 .926-.762 1.688-1.688 1.688h-.03"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(-51.152 -301.294)"
      />
      <path
        d="M0 379.984a1.696 1.696 0 0 1 1.688-1.675l.05.001a1.713 1.713 0 0 1 1.674 1.706c0 .936-.77 1.706-1.706 1.706h-.032a1.698 1.698 0 0 1-1.675-1.689l.001-.049"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(0 -352.437)"
      />
    </svg>
  );
};

export default AudioSvg;
