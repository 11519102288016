import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import ImageSkelComponent from "./ImageSkelComponent";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  title: {
    height: theme.typography.h3.fontSize,
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  text: {
    marginBottom: theme.spacing(2),
    height: 300,
  },
  line: {
    height: 2,
    width: "100%",
    border: "none",
    background: theme.palette.background.gray,
  },
}));

const ListItemSkelComponent = () => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <Skeleton className={classes.title} />
      <ImageSkelComponent small />
      <Skeleton className={classes.text} />
      <hr className={classes.line} />
    </section>
  );
};

ListItemSkelComponent.propTypes = {
  name: PropTypes.string,
};
export default React.memo(ListItemSkelComponent);
