import * as React from "react";

const FacebookIconSvg = (props) => (
  <svg
    viewBox="0 0 45 45"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    {...props}
  >
    <path
      d="M-2.3 0v-10.3l-.85-.175a1.748 1.748 0 0 1-.706-.312.75.75 0 0 1-.269-.625v-1.525H-2.3v-.738c-.01-.687.111-1.37.356-2.012a4.262 4.262 0 0 1 1.025-1.538 4.619 4.619 0 0 1 1.631-.987 6.385 6.385 0 0 1 2.188-.35c.293-.002.585.017.875.056.283.041.563.101.838.181l-.075 1.887a.66.66 0 0 1-.1.331.662.662 0 0 1-.231.212 1.164 1.164 0 0 1-.306.119 1.429 1.429 0 0 1-.325.038 3.792 3.792 0 0 0-.913.1 1.47 1.47 0 0 0-.669.356 1.61 1.61 0 0 0-.406.675c-.101.343-.147.7-.138 1.057v.613h3.038v2.65H1.575V0H-2.3Z"
      style={{
        fill: "#2f77eb",
        fillRule: "nonzero",
      }}
      transform="translate(22.5 32.5)"
    />
    <path
      style={{
        fill: "none",
      }}
      d="M0 0h44v44H0z"
      transform="translate(.5 .5)"
    />
    <path
      style={{
        fill: "none",
        stroke: "#2f77eb",
        strokeWidth: 3,
      }}
      d="M1.5 1.5h41v41h-41z"
      transform="translate(.5 .5)"
    />
  </svg>
);

export default FacebookIconSvg;
