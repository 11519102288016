import { useTheme } from "@mui/styles";
import * as React from "react";

const MoreSvg = (props) => {
  const theme = useTheme();
  return (
    <svg
      viewBox="0 0 85 55"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
      {...props}
    >
      <path
        d="M0 213.642c.517-.593 1-1.218 1.557-1.775 3.653-3.667 7.322-7.323 11.066-11.067-.273-.173-.494-.32-.722-.455-1.981-1.175-3.955-2.362-5.949-3.513a2.185 2.185 0 0 1-1.279-2.3 2.213 2.213 0 0 1 1.719-1.831c12.425-4.341 24.848-8.692 37.267-13.053 1.714-.6 3.079.033 3.278 1.586.029.466-.047.933-.221 1.367a18626.62 18626.62 0 0 1-10.2 29.165c-.961 2.745-1.935 5.487-2.881 8.238a2.185 2.185 0 0 1-2.124 1.692 2.183 2.183 0 0 1-1.986-1.283c-1.154-1.993-2.339-3.968-3.512-5.949-.13-.219-.268-.432-.437-.7-.217.2-.406.364-.582.54a29226.93 29226.93 0 0 0-11.136 11.135c-1.267 1.266-2.366 1.256-3.645-.024-2.98-2.979-5.957-5.961-8.932-8.945-.474-.47-.857-1.035-1.281-1.557v-1.271m12.179 7.57a5.45 5.45 0 0 1 .433-.559c3.855-3.867 7.714-7.728 11.575-11.583 1.438-1.437 2.856-1.247 3.9.516l2.582 4.368c.1.166.217.319.368.538L41.356 185l-29.465 10.31c.033.045.07.086.11.125 1.635.973 3.284 1.922 4.9 2.933.456.279.837.664 1.111 1.123a2.071 2.071 0 0 1-.643 2.594c-3.873 3.879-7.749 7.756-11.627 11.631-.191.191-.374.392-.515.54l6.957 6.96"
        style={{
          fill: theme.palette.background.moreIcon,
          fillRule: "nonzero",
        }}
        transform="rotate(45 236.501 118.354)"
      />
      <path
        d="M316.081 0a2.495 2.495 0 0 1 1.594 2.676c-.056 2.609-.017 5.22-.022 7.83a2.003 2.003 0 0 1-1.154 1.878 2.03 2.03 0 0 1-2.144-.13 2.003 2.003 0 0 1-.946-1.718 995.23 995.23 0 0 1 0-8.465A2.321 2.321 0 0 1 314.951 0h1.13Z"
        style={{
          fill: theme.palette.background.moreIcon,
          fillRule: "nonzero",
        }}
        transform="rotate(45 185.085 -283.431)"
      />
      <path
        d="M390.377 249.085a4.75 4.75 0 0 1 1.246.7c1.961 1.9 3.879 3.84 5.794 5.784.44.404.691.975.691 1.572a2.144 2.144 0 0 1-2.134 2.134 2.134 2.134 0 0 1-1.586-.706 718.765 718.765 0 0 1-5.783-5.8 2.08 2.08 0 0 1-.48-2.4 2.195 2.195 0 0 1 2.253-1.292"
        style={{
          fill: theme.palette.background.moreIcon,
          fillRule: "nonzero",
        }}
        transform="rotate(45 475.201 -253.755)"
      />
      <path
        d="M388.079 55.959c.173-.444.412-.86.708-1.233a338.313 338.313 0 0 1 5.839-5.838 2.123 2.123 0 0 1 1.549-.671c1.164 0 2.123.958 2.123 2.123 0 .571-.231 1.119-.639 1.518a412.5 412.5 0 0 1-6 5.979 1.989 1.989 0 0 1-2.291.387 2.213 2.213 0 0 1-1.292-2.265"
        style={{
          fill: theme.palette.background.moreIcon,
          fillRule: "nonzero",
        }}
        transform="rotate(45 267.125 -340.117)"
      />
      <path
        d="M428.282 167.521c1.317 0 2.635-.009 3.952 0a2.103 2.103 0 0 1 2.261 2.086l-.001.067a2.128 2.128 0 0 1-2.265 2.093c-2.705.007-5.41.007-8.115 0-.034.002-.069.002-.103.002a2.135 2.135 0 0 1-2.125-2.125 2.135 2.135 0 0 1 2.228-2.122c1.388-.013 2.776 0 4.163 0"
        style={{
          fill: theme.palette.background.moreIcon,
          fillRule: "nonzero",
        }}
        transform="rotate(45 405.245 -323.947)"
      />
      <path
        d="M197.965 60.985a4.55 4.55 0 0 1-1.195-.675 363.25 363.25 0 0 1-5.888-5.889 2.129 2.129 0 0 1-.013-2.986 2.08 2.08 0 0 1 2.98-.045 375.97 375.97 0 0 1 5.981 6c.64.602.804 1.563.4 2.343a2.217 2.217 0 0 1-2.263 1.255"
        style={{
          fill: theme.palette.background.moreIcon,
          fillRule: "nonzero",
        }}
        transform="rotate(45 184.856 -134.028)"
      />
    </svg>
  );
};
export default MoreSvg;
