import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Container } from "@mui/material";
import BgdImg from "../imgs/bgd.png";

const useStyles = makeStyles((theme) => ({
  cont: {
    position: "relative",
    zIndex: 1,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  box: {
    position: "relative",
    overflow: "hidden",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    background: theme.palette.background.gray,
  },
  img: {
    bottom: 0,
    right: "-50%",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    top: 0,
    position: "absolute",
  },
}));

const GrayContainerComponent = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Container className={classes.cont}>{children}</Container>
      <img src={BgdImg} alt="Background" className={classes.img} />
    </Box>
  );
};

GrayContainerComponent.propTypes = {
  children: PropTypes.any,
};
export default React.memo(GrayContainerComponent);
