import React from "react";
import PropTypes from "prop-types";
import { useGetNewsPostQuery } from "../../services";
import { useParams } from "react-router-dom";
import PostContainerComponent from "../../components/PostContainerComponent";
import { NEWS_ROUTE } from "../../constants/routes";
import { useTranslation } from "react-i18next";

const NewsPostPage = () => {
  const { slug } = useParams();
  const { data, loading } = useGetNewsPostQuery(slug);
  const { t } = useTranslation();

  return (
    <PostContainerComponent
      data={data?.data}
      loading={loading}
      hiddenOtherPosts
      to={NEWS_ROUTE}
      topic={t("news_page.title")}
    />
  );
};

NewsPostPage.propTypes = {
  name: PropTypes.string,
};
export default React.memo(NewsPostPage);
