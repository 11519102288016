import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import { IMAGE_BLOCK } from "../../constants/csses";

const useStyles = makeStyles((theme) => ({
  skeleton: {
    ...IMAGE_BLOCK(theme),
  },
  smallSkeleton: {
    ...IMAGE_BLOCK(theme, 400),
  },
}));

const ImageSkelComponent = ({ small }) => {
  const classes = useStyles();

  return (
    <Skeleton
      variant="rect"
      className={small ? classes.smallSkeleton : classes.skeleton}
    />
  );
};

ImageSkelComponent.propTypes = {
  small: PropTypes.bool,
};
export default React.memo(ImageSkelComponent);
