import React from "react";
import { makeStyles } from "@mui/styles";
import { HashLink as Link } from "react-router-hash-link";
import { classNames } from "../functions";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}));

const LinkComponent = ({
  to,
  className = "",
  children,
  external_link,
  title,
  download,
  target = "_blank",
}) => {
  const classes = useStyles();

  return (
    <>
      {external_link ? (
        <a
          className={classNames([classes.link, className])}
          href={to}
          download={download}
          target={target}
          rel="noreferrer"
          title={title}
        >
          {children}
        </a>
      ) : (
        <Link className={classNames([classes.link, className])} to={to}>
          {children}
        </Link>
      )}
    </>
  );
};

LinkComponent.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  external_link: PropTypes.bool,
  title: PropTypes.string,
  download: PropTypes.bool,
  target: PropTypes.string,
  name: PropTypes.string,
};
export default React.memo(LinkComponent);
