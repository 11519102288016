import { createTheme } from "@mui/material/styles";
import { MOBILE_DEVICE_WIDTH } from "../constants";

export const newTheme = () => {
  const theme = createTheme({
    typography: {
      fontSize: 24,
      fontFamily: '"sofia-pro", "sans-serif"',
      button: {
        fontSize: 22,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 16,
        },
      },
      body1: {
        fontSize: 20,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 16,
        },
      },
      logoTxt: {
        fontSize: 40,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 28,
        },
      },
      body2: {
        fontSize: 22,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 16,
        },
      },
      h6: {},
      h5: {},
      h4: {},
      h3: {
        fontWeight: 500,
        fontSize: 35,
        marginBottom: 16,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 30,
        },
      },
      h2: {
        fontWeight: 500,
        fontSize: 35,
        marginBottom: 16,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 30,
        },
      },
      h1: {
        fontWeight: 500,
        fontSize: 55,
        marginBottom: 16,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 40,
        },
      },
    },
  });
  return theme.typography;
};

const TextBiggestTheme = newTheme();
export default TextBiggestTheme;
