import React, { useCallback } from "react";
import PropTypes from "prop-types";
import lottie from "lottie-web";
import LoaderAnimation from "../animations/loader.json";

const LoaderAnimationComponent = ({ className = "" }) => {
  const loaderCont = useCallback((node) => {
    if (node !== null) {
      lottie.loadAnimation({
        container: node,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: LoaderAnimation,
      });
    }
  }, []);
  return <div className={className} ref={loaderCont}></div>;
};

LoaderAnimationComponent.propTypes = {
  className: PropTypes.string,
};
export default React.memo(LoaderAnimationComponent);
