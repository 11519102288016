import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import LinkComponent from "./LinkComponent";
import MoreSvg from "../svg/MoreSvg";

const useStyles = makeStyles((theme) => ({
  moreNewsBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  moreNews: {
    color: theme.palette.text.primary,
  },
  moreNewsIcon: {
    height: 40,
    marginLeft: theme.spacing(2),
  },
}));

const MoreBtnComponent = ({ to, title }) => {
  const classes = useStyles();

  return (
    <Box className={classes.moreNewsBox}>
      <LinkComponent to={to}>
        <Button className={classes.moreNews}>
          {title}
          <MoreSvg className={classes.moreNewsIcon} />
        </Button>
      </LinkComponent>
    </Box>
  );
};

MoreBtnComponent.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
};
export default React.memo(MoreBtnComponent);
