export const MAIN_ROUTE = "/";
export const INFORMATIONS_ROUTE = "/jak-stworzyc-zastepczy-dom";
export const KNOWLEDGES_ROUTE = "/vademecum-wiedzy";
export const NEWS_ROUTE = "/aktualnosci";
export const STORIES_ROUTE = "/poznaj-moja-historie";
export const CONTACT_ROUTE = "/kontakt";
export const MAP_ROUTE = "/mapa-serwisu";

export const WANT_CHILD_ROUTE =
  "/chce-zaopiekowac-sie-dzieckiem-ze-swojego-otoczenia";
export const WANT_BUILD_FAMILY_ROUTE =
  "/chce-stworzyc-zawodowa-rodzine-zastepcza";
export const WHO_CAN_BUILD_ROUTE = "/czy-kazdy-moze-stworzyc-rodzine-zastepcza";
