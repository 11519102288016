import * as React from "react";

const WhoCanHaveFSvg = (props) => (
  <svg
    viewBox="0 0 528 357"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    }}
    {...props}
  >
    <path
      d="M0 0h331.815"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#94c7d4",
        strokeWidth: "2.38px",
      }}
      transform="translate(51.398 353.395)"
    />
    <path
      d="M258.227 180.813c6.882.22 28.028 4.749 42.815 16.246 14.787 11.497 34.247 33.024 40.072 55.908 5.825 22.884 1.621 53.891-6.6 62.007-6.456.17-195.385 0-207.618.17-9.573-1.94-20.284-6.871-17.78-31.989 2.504-25.118 21.9-54.431 40.808-69.217 18.908-14.786 57.545-30.506 78.841-31.843 21.296-1.337 29.467-1.283 29.467-1.283"
      style={{
        fill: "#94c7d4",
        fillRule: "nonzero",
      }}
      transform="translate(-86.452 -74.902)"
    />
    <path
      d="M138.757 329.562c-20.4-.995-28.9 21.092-28.9 21.092"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-86.917 -136.917)"
    />
    <path
      d="M300.74 185.882c.51 4.927 7.985 11.553 23.616 10.534 15.631-1.019 22.087-7.645 23.107-11.043"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-166.516 -76.803)"
    />
    <path
      d="M222.347 232c6.626 10.7 13.932 42.135 14.272 46.553"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-133.826 -96.247)"
    />
    <path
      d="M448.872 332.429c-7.985-1.869-33.81-4.757-56.917-2.719"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-204.553 -136.701)"
    />
    <path
      d="M415.191 225.006c-6.8 15.631-10.194 54.708-10.7 60.824"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-209.778 -93.331)"
    />
    <path
      d="M313.636 44.493c1.144.079 4.125-2.4 7.374-3.194 3.249-.794 7.925-.09 8.723 3.158.798 3.248.24 5.765 1.739 6.252 1.499.487 4.384-.591 7.435-.38a6.373 6.373 0 0 1 6.14 5.406 9.1 9.1 0 0 1-1.952 7.912c-1.232 1.192-2.863 2.612-2.824 3.892.039 1.28 2.7 3.381 2.467 6.813-.233 3.432-2.47 8-7.344 8.305-4.874.305-35.615 16.312-35.615 16.312s-.256 7.391-8.962 7.682c-8.706.291-10.257-3.137-9.888-4.771.369-1.634 3.46-3.848 2.22-6.233-1.24-2.385-5.4-5.1-7.7-12.411s4.417-14 7.626-16.077c3.209-2.077 3.04-8.859 2.975-13.461-.065-4.602 3.877-11.738 13.043-13.148 9.166-1.41 11.493 3.733 14.544 3.944"
      style={{
        fill: "#292f44",
        fillRule: "nonzero",
      }}
      transform="translate(-155.772 -16.31)"
    />
    <path
      d="M310.54 90.81a84.474 84.474 0 0 0-.408-8.884c-.443-3.437-6.738-3.531-9.263-1.492-2.525 2.039-4.707 6.486-3.124 10.683a8.283 8.283 0 0 0 5.786 5 86.447 86.447 0 0 1 2.1 11.556c.484 5.313.417 18.592.281 23.011a13.59 13.59 0 0 1-1.655 6.7c-1.119 2.053 7.4 6.3 19.392 5.259 11.992-1.041 18.032-7.1 18.032-7.1a32.34 32.34 0 0 1-2.505-10.562c-.425-6.16-.686-14.693-.686-14.693s4.062-2.1 5.449-9.842c1.387-7.742 2.231-17.473-.424-23.4a17.203 17.203 0 0 0-11.983-9.646 21.77 21.77 0 0 0-6.584-.838c-2.225.059-6.085 3.028-8.178 9.269a39.935 39.935 0 0 0-2.317 15.04l-3.913-.061Z"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-165.043 -27.25)"
    />
    <path
      d="M306.33 96.776c2.384 2.464 4.228 7.19 4.228 7.19"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.19px",
      }}
      transform="translate(-168.847 -39.859)"
    />
    <path
      d="M308.053 115.974a6.57 6.57 0 0 0 5.192-1.812"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.19px",
      }}
      transform="translate(-169.565 -47.109)"
    />
    <path
      d="M366.327 96.563c1.275 1.877 4.9 6.725 4.575 7.725-.325 1-4.949-.47-4.949-.47"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.19px",
      }}
      transform="translate(-193.71 -39.77)"
    />
    <path
      d="M347.83 114.244c.866-1.372 8.5.844 10.371 1.484 1.871.64 3.037 2.254 1.4 3.8a8.663 8.663 0 0 1-8.15 1.48c-2.988-.911-4.623-5.174-3.619-6.765"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-186.029 -46.959)"
    />
    <path
      d="M347.83 114.244c.866-1.372 8.5.844 10.371 1.484 1.871.64 3.037 2.254 1.4 3.8a8.663 8.663 0 0 1-8.15 1.48c-2.989-.908-4.625-5.173-3.621-6.764Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.19px",
      }}
      transform="translate(-186.029 -46.959)"
    />
    <path
      d="M339.748 81.773c1.832-.64 5.852-1.511 9.223-.384"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "2.38px",
      }}
      transform="translate(-182.782 -33.215)"
    />
    <path
      d="M368.839 82.987a5.831 5.831 0 0 1 5.513 1.531"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "2.38px",
      }}
      transform="translate(-194.913 -34.023)"
    />
    <path
      d="M348.455 141.574a20.096 20.096 0 0 1-14.955 4.458c-5.2-.487-10.928-2.672-12.383-3.794"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.19px",
      }}
      transform="translate(-175.013 -58.539)"
    />
    <path
      d="M349.679 96.471c-.592-.041-.862-.5-.8-1.369.062-.869.392-1.286.983-1.245.591.041.862.5.8 1.369-.062.869-.391 1.286-.983 1.245"
      style={{
        fill: "#303336",
        fillRule: "nonzero",
      }}
      transform="translate(-186.586 -38.64)"
    />
    <path
      d="M374.349 97.739c-.592-.041-.862-.5-.8-1.369.062-.869.392-1.286.983-1.245.591.041.862.5.8 1.369-.062.869-.391 1.286-.983 1.245"
      style={{
        fill: "#303336",
        fillRule: "nonzero",
      }}
      transform="translate(-196.873 -39.169)"
    />
    <path
      d="M336.356 109.752c-1.768-.138-2.564-1.526-2.362-4.115.202-2.589 1.206-3.836 2.974-3.7 1.768.136 2.564 1.526 2.361 4.115-.203 2.589-1.206 3.836-2.973 3.7"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-180.37 -42.008)"
    />
    <path
      d="M324.467 58.219c-1.293-1.622-7.854-4.631-12.423-1.37s-1.024 9.253-3.124 11.918"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.19px",
      }}
      transform="translate(-169.927 -22.602)"
    />
    <path
      d="M351.454 51.988c2.293-1.757 6.944-.669 7.451 3.07.507 3.739 3.984 3.341 5.01 3.285"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.19px",
      }}
      transform="translate(-187.663 -20.84)"
    />
    <path
      d="M384.34 67.649c2.082 1.294 1.27 3.792.394 5.392-.876 1.6-2.986 4.391-2.027 7.14"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.19px",
      }}
      transform="translate(-200.594 -27.713)"
    />
    <path
      d="M286.93 99.276c-4.831 3.37-1.2 10.007 1.3 12.607 2.5 2.6 4.724 5.564 4.031 8.2"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.19px",
      }}
      transform="translate(-159.766 -40.901)"
    />
    <path
      d="M346.588 349.14h-60.662a2.692 2.692 0 0 1-2.672-2.672v-79.01a2.692 2.692 0 0 1 2.672-2.672h60.661a2.692 2.692 0 0 1 2.672 2.672v79.01a2.692 2.692 0 0 1-2.672 2.672"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-159.224 -109.918)"
    />
    <path
      d="M66.006 4.584A4.586 4.586 0 0 0 61.422 0H4.584A4.586 4.586 0 0 0 0 4.584v75.187a4.586 4.586 0 0 0 4.584 4.584h56.838a4.586 4.586 0 0 0 4.584-4.584V4.584Z"
      style={{
        fill: "none",
        stroke: "#292f44",
        strokeWidth: "4.77px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
      }}
      transform="translate(124.03 154.867)"
    />
    <path
      d="M140.829 319.581c4.717-1.718 29.555-2.554 29.555-2.554s10.549-8.467 20.912-10.56 20.445-.118 24.846 2.741 4.522 8.487 3.364 10.451a26.543 26.543 0 0 1-3.622 4.348c-1.359 1.316 3.4 2.534 2.629 6.313-.771 3.779-4.814 6.82-4.814 6.82s5.488 1.912 4.373 6.944c-1.115 5.032-4.709 5.294-6.252 5.443-1.543.149-6.452-2.746-6.452-2.746a59.72 59.72 0 0 1-6.543 13.343c-3.964 5.6-5.63 7.529-9.733 7.757-4.103.228-50.183 1.637-59.538.224a24.381 24.381 0 0 1-14.727-8.683c-3.699-4.576 1.624-12.405 7.957-22.7s18.046-17.145 18.046-17.145"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-88.989 -126.936)"
    />
    <path
      d="M270.2 330.312c-3.713-4.741-13.567-12.726-25.12-8.309"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.19px",
      }}
      transform="translate(-143.305 -133.241)"
    />
    <path
      d="M263.395 352.994c-4.416-3.772-12.231-11.417-19.027-8.869A64.75 64.75 0 0 0 234 348.882"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.19px",
      }}
      transform="translate(-138.687 -142.791)"
    />
    <path
      d="M279.028 308.936c-1.359 3.568 4.417 10.364 7.645 11.723"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.19px",
      }}
      transform="translate(-157.377 -128.329)"
    />
    <path
      d="M279.547 330.793c-2.379 4.248-1.02 8.325 4.927 12.743"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.19px",
      }}
      transform="translate(-157.215 -137.444)"
    />
    <path
      d="M278.924 356.438c-1.7 3.568 3.228 9.344 5.437 10.874"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.19px",
      }}
      transform="translate(-157.273 -148.138)"
    />
    <path
      d="M200.029 325.2a52.031 52.031 0 0 0-14.11 13.452"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.19px",
      }}
      transform="translate(-118.635 -135.113)"
    />
    <path
      d="M691.965 436.192v60.661a2.694 2.694 0 0 1-2.672 2.673h-79.01a2.694 2.694 0 0 1-2.672-2.673v-60.661a2.694 2.694 0 0 1 2.672-2.672h79.01a2.692 2.692 0 0 1 2.672 2.672"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-294.481 -180.281)"
    />
    <path
      d="M4.584 0H79.77c2.515 0 4.585 2.07 4.585 4.585v56.837c0 2.515-2.069 4.584-4.584 4.584H4.584C2.069 66.006 0 63.937 0 61.422V4.584C0 2.069 2.069 0 4.584 0Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: 3,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
      }}
      transform="translate(313.129 253.239)"
    />
    <path
      d="M0 0h53.519"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "2.38px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
      }}
      transform="translate(330.459 269.295)"
    />
    <path
      d="M0 0h53.519"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "2.38px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
      }}
      transform="translate(330.459 284.586)"
    />
    <path
      d="M0 0h53.519"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "2.38px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
      }}
      transform="translate(330.459 299.877)"
    />
    <path
      d="M620.4 63.643s-11.894-4-17.649 2.337c-5.755 6.337-5.424 10.728-7.224 13.366-1.8 2.638-9.327 6.241-11.527 10.375-2.2 4.134-2.737 9.459-4.638 13.112-1.901 3.653-7.3 6.443-10.877 8.9-3.577 2.457-5.906 7.862-6.337 12.174-.431 4.312-.43 6.872-4.258 9.308s-5.323 9.713-2.76 14.836c2.563 5.123 123.522 1.821 123.522 1.821s4.918-8.217 3.47-16.814-6.013-11.613-8.9-13.438c-2.887-1.825-.382-6.441-3.908-9.61-3.526-3.169-5.3-3.346-4.211-6.567 1.089-3.221 2.483-9.485-1.627-14.5-4.11-5.015-11.057-4.689-13.036-7.96-1.979-3.271-1.769-9.088-9.547-14.7-10.991-7.926-20.482-2.641-20.482-2.641"
      style={{
        fill: "#94c7d4",
        fillRule: "nonzero",
      }}
      transform="translate(-272.063 -25.322)"
    />
    <path
      d="M711.29 81.643c2.622 4.53 6.173 8.3 10.7 9.092 4.527.792 10.111 4.765 10.873 9.11.762 4.345-.048 9.044-1.518 13.508-1.47 4.464 2.436 8.1 7.035 9.922 4.599 1.822 13.813 5.305 16.03 12.185 2.217 6.88-1.908 12.274-4.223 14.946-2.315 2.672.12 7.354 4.719 9.178 4.599 1.824 4.262 8.621.358 11.817"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#9ec5cf",
        strokeWidth: "1.19px",
      }}
      transform="translate(-337.715 -33.548)"
    />
    <path
      d="M586.86 93c-3.414 3.416-8.858 3.214-11.7 6a16.369 16.369 0 0 0-5.373 11.075c-.32 4.919-1.249 7.388-6.016 8.961-4.767 1.573-9.907.037-14.081 4.23a12.162 12.162 0 0 0-3.125 12.494c1.016 3.517.865 8.453-3.04 9.941-3.905 1.488-10.075 3.434-10.648 9.183-.573 5.749 2.793 11.377 7.087 12.829"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#9ec5cf",
        strokeWidth: "1.19px",
      }}
      transform="translate(-263.291 -38.285)"
    />
    <path
      d="M614.361 178.268c16.2 1.873 38.9 5.417 47.272 16.5s16.1 27.167 18.818 51.272c2.676 23.7 6.881 43.2-3.058 56.492-11.326 15.15-32.587 10.617-42.857 10.874-10.27.257-79.462 1.1-91.407.17-11.945-.93-32.621-3.4-34.766-27.809-1.9-21.663 2.02-35.654 8.684-56.248 6.664-20.594 15.283-32.538 27.225-39.287 11.942-6.749 44.983-11.9 44.983-11.9l25.106-.064Z"
      style={{
        fill: "#292f44",
        fillRule: "nonzero",
      }}
      transform="translate(-252.894 -73.841)"
    />
    <path
      d="M581.237 320.516c-7.541-.8-33.046-.51-40.946-.17"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.19px",
      }}
      transform="translate(-266.409 -132.943)"
    />
    <path
      d="M601.452 244.677c0 11.213-1.274 44-1.274 44"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.19px",
      }}
      transform="translate(-291.381 -101.533)"
    />
    <path
      d="M735.738 244.24c-2.379 13.592-3.4 49.1-3.4 49.1"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.19px",
      }}
      transform="translate(-346.493 -101.351)"
    />
    <path
      d="M749.73 328.68c-8.495-.17-33.3-.51-37.208-.51"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.19px",
      }}
      transform="translate(-338.229 -136.35)"
    />
    <path
      d="M649.046 77.145a32.658 32.658 0 0 1-7.3 11.566 150.97 150.97 0 0 1-11.282 10.4c-3.934 3.273-1.849 8.268-1.137 13.973s9.233 10.141 11.262 10.344l-1.822 15.7 15.122 33.267 13.989-34.973-2.942-1.318 3.824-20.364s1.269 2.688 6.39.125c5.121-2.563 6.9-7.507 4.08-10.093-2.82-2.586-9.762 2.868-9.762 2.868s-1.929-6.339-3.73-8.824c-1.801-2.485-6.29-6.262-8.954-10.369-2.664-4.107-7.738-12.3-7.738-12.3"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-303.468 -31.672)"
    />
    <path
      d="M649.193 97.331c.482 1.585 1.573 6.047.408 8.748-1.165 2.701-4.31 4.693-4.373 5.326-.063.633 5.4 1.051 5.4 1.051"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.19px",
      }}
      transform="translate(-310.167 -40.09)"
    />
    <path
      d="M665.278 111.126c.824-1.838 2-4.666 5.3-4.337a6.05 6.05 0 0 1 5.326 4.373"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-318.528 -44.023)"
    />
    <path
      d="M645.391 108.665a3.969 3.969 0 0 0-4.21-2.725c-2.573.127-3.524 3.234-3.524 3.234"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-307.01 -43.677)"
    />
    <path
      d="M665.415 96.128a11.893 11.893 0 0 1 5.757.062"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#9ec5cf",
        strokeWidth: "2.38px",
      }}
      transform="translate(-318.585 -39.453)"
    />
    <path
      d="M652.407 129.731c2.751-.622 8.241-1.739 8.241-1.739"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "2.98px",
      }}
      transform="translate(-313.161 -52.876)"
    />
    <path
      d="M648.76 155.711c5.009 1.14 10.752.048 12.92-1.144"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.19px",
      }}
      transform="translate(-311.64 -63.957)"
    />
    <path
      d="M707.562 130.9a19.956 19.956 0 0 0-4.894 2.842"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.19px",
      }}
      transform="translate(-334.12 -54.086)"
    />
    <path
      d="M676.921 129.561c-2.064-.225-2.946-1.877-2.617-4.9.329-3.023 1.547-4.446 3.611-4.221 2.064.225 2.947 1.877 2.617 4.9-.33 3.023-1.547 4.446-3.611 4.221"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-322.264 -49.717)"
    />
    <path
      d="M633.453 127.861c-2.064-.225-2.946-1.877-2.617-4.9.329-3.023 1.547-4.446 3.611-4.221 2.064.225 2.946 1.877 2.617 4.9-.329 3.023-1.547 4.446-3.611 4.221"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-304.138 -49.008)"
    />
    <path
      d="M672.384 364.926h-33.767c-2.622 0-4.779-2.157-4.779-4.779v-57.33a1.1 1.1 0 0 1 1.094-1.094h41.136a1.1 1.1 0 0 1 1.094 1.094v57.33c0 2.622-2.157 4.779-4.779 4.779"
      style={{
        fill: "#ffd585",
        fillRule: "nonzero",
      }}
      transform="translate(-305.418 -125.322)"
    />
    <path
      d="m610.1 320.809 8.927.167v7.518c3.399.36 6.815.527 10.233.5 6.91.049 9.991-.027 13.947.111 3.956.138 7.621 1.913 7.508 6.9-.113 4.987-6.49 6.012-6.49 6.012s8.736-1.562 9.611 2.5-.733 8.635-7.555 10.26c0 0 4.146-.483 4.4 3.384.254 3.867-1.38 7.418-7.364 9.638-5.984 2.22-19.577 4.665-29.517 4.92-4.022.1-11.563-.054-11.563-.054s-4.2-9.255-3.911-23.7c.289-14.445 6.789-28.264 6.789-28.264l4.985.108Z"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-290.602 -133.233)"
    />
    <path
      d="M707.714 360.354v-7.664c6.007 0 10.95-4.943 10.95-10.95s-4.943-10.95-10.95-10.95v-7.664c10.211 0 18.613 8.402 18.613 18.613s-8.402 18.613-18.613 18.613"
      style={{
        fill: "#ffd585",
        fillRule: "nonzero",
      }}
      transform="translate(-336.224 -134.247)"
    />
    <path
      d="M639.083 183.368c.255 3.058 12.743 30.327 14.781 34.15 2.038 3.823 3.568 6.117 5.607 5.607 2.039-.51 4.587-9.175 7.391-16.565 2.804-7.39 7.9-21.917 8.665-23.955"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.19px",
      }}
      transform="translate(-307.605 -75.649)"
    />
    <path
      d="M666.055 357.261a113.69 113.69 0 0 1-15.386.88"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.19px",
      }}
      transform="translate(-312.436 -148.481)"
    />
    <path
      d="M670.938 379.139c-4.829.868-11.073 1.759-13.494 1.887"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.19px",
      }}
      transform="translate(-315.261 -157.604)"
    />
    <path
      d="M677.114 343.925a6.484 6.484 0 0 0 2.209 4.672l.354-.1c1.78-.82 3.831-2.372 3.9-5.18a6.146 6.146 0 0 0-2-5.074l-.384.073c-2.039.34-4.077 2.719-4.077 5.607"
      style={{
        fill: "#292f44",
        fillRule: "nonzero",
      }}
      transform="translate(-323.464 -140.551)"
    />
    <path
      d="M686.121 357.917a7.526 7.526 0 0 0-4.368 1.177c-1.159.957-2.294 5.947-.595 7.9a4.438 4.438 0 0 0 3.792 1.367 7.78 7.78 0 0 0 3.069-8.331 3.02 3.02 0 0 0-1.9-2.113"
      style={{
        fill: "#292f44",
        fillRule: "nonzero",
      }}
      transform="translate(-324.79 -148.754)"
    />
    <path
      d="M684.566 382.651a3.288 3.288 0 0 0-2.5-3.205 4.042 4.042 0 0 0-2.4 3.237c-.448 2.415.694 5.326 2.864 6.279a8.195 8.195 0 0 0 2.033-6.311"
      style={{
        fill: "#292f44",
        fillRule: "nonzero",
      }}
      transform="translate(-324.489 -157.732)"
    />
    <path
      d="M0 0h463.828"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#94c7d4",
        strokeWidth: "2.38px",
      }}
      transform="translate(1.192 240.242)"
    />
    <path
      d="M333.914 324.432h-12.408v-3.948a17.693 17.693 0 0 1 1.692-8.225 23.297 23.297 0 0 1 4.795-6.158l1.6-1.692a20.342 20.342 0 0 0 2.3-2.961 6.42 6.42 0 0 0 .987-3.525 8.144 8.144 0 0 0-1.222-4.747 4.494 4.494 0 0 0-3.948-1.739 4.796 4.796 0 0 0-2.726.752 6.168 6.168 0 0 0-1.833 1.88 10.845 10.845 0 0 0-1.128 2.445 10.97 10.97 0 0 0-.517 2.444l-12.315-2.158c.299-2.39.916-4.729 1.834-6.956a18.977 18.977 0 0 1 3.76-5.828 17.792 17.792 0 0 1 5.781-4.042 19.568 19.568 0 0 1 7.991-1.5c5.514 0 9.916 1.598 13.207 4.794 3.291 3.196 4.936 7.737 4.936 13.622a19.968 19.968 0 0 1-.658 5.358 19.014 19.014 0 0 1-1.786 4.324 20.575 20.575 0 0 1-2.726 3.713 41.777 41.777 0 0 1-3.572 3.431 12.313 12.313 0 0 0-3.243 4 10.124 10.124 0 0 0-.8 4.089l-.001 2.627Zm2.444 14.759a8.652 8.652 0 0 1-2.209 6.016 8.063 8.063 0 0 1-5.781 2.445 8.063 8.063 0 0 1-5.782-2.445 8.641 8.641 0 0 1-2.209-6.016 8.862 8.862 0 0 1 2.209-6.016 7.856 7.856 0 0 1 5.782-2.539c2.196 0 4.295.922 5.781 2.539a8.874 8.874 0 0 1 2.209 6.016"
      style={{
        fill: "#f8c53d",
        fillRule: "nonzero",
      }}
      transform="translate(-170.04 -115.623)"
    />
    <path
      d="M405.95 320.3c-3.228-1.869-11.044-2.379-12.743 4.248-1.699 6.627 4.588 11.553 7.136 12.742s6.286.68 6.286.68l-.679-17.67Z"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-204.955 -132.557)"
    />
    <path
      d="M482.879 325c1.7-.849 7.985-2.208 9.175 3.738 1.19 5.946-3.4 11.384-6.456 12.4-1.528.568-3.13.912-4.757 1.02L482.879 325Z"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-241.617 -134.71)"
    />
    <path
      d="M415.217 361.975c-.51 5.607-2.888 21.407-3.568 23.786a4.673 4.673 0 0 1-2.208 3.058l40.436 1.529s-2.039-9.684-1.869-14.441c.17-4.757-.34-12.573-.34-12.573l-32.451-1.359Z"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-211.844 -150.447)"
    />
    <path
      d="M419.2 264.489c6.683-6.1 15.8-6.456 18.179-5.776 2.379.68 7.985-2.549 7.985-8.665s5.437-13.762 12.4-16.99c6.963-3.228 12.913.679 17.33 1.529 4.417.85 12.743-2.379 14.611-5.776 1.868-3.397 7.816-7.476 14.951-6.286 7.135 1.19 12.4 3.568 13.932 7.985s5.267 15.121-.51 22.6c-5.777 7.479-14.951 8.665-18.859 9.344-3.908.679-7.476 5.776-8.495 9a15.257 15.257 0 0 1-12.4 9.515c-7.136.849-10.194-3.4-13.592-5.607-3.398-2.207-10.534-7.136-13.083-6.626a25.753 25.753 0 0 1 5.607 16.48c0 10.364-6.133 24.291-16.48 28.034-7.985 2.888-18.18 1.359-23.786-6.8-5.606-8.159-6.286-15.8-5.437-24.976.778-8.4 3.738-13.422 7.646-16.99"
      style={{
        fill: "#4b6694",
        fillRule: "nonzero",
      }}
      transform="translate(-212.6 -92.207)"
    />
    <path
      d="M462.772 288.245c2.888 4.078 8.325 6.966 8.325 6.966"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-234.083 -119.701)"
    />
    <path
      d="M485.794 265.188c1.529-2.548 4.587-6.626 9.515-6.116"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-243.683 -107.518)"
    />
    <path
      d="M505.32 286.788c1.529 1.529 7.645 4.927 12.572 2.379"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-251.826 -119.094)"
    />
    <path
      d="M530.091 262.39c1.869-2.209 6.626-5.607 10.364-3.908"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-262.155 -107.099)"
    />
    <path
      d="M563.022 238.374c2.039-1.869 6.966-2.379 9.515-.51"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-275.887 -98.203)"
    />
    <path
      d="M567.393 267.368c1.529-3.058 5.776-6.286 10.024-4.757"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-277.71 -108.848)"
    />
    <path
      d="M438.362 307.187a11.699 11.699 0 0 0-5.607 7.813"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-221.566 -127.6)"
    />
    <path
      d="M465.431 318.844a17.943 17.943 0 0 0-2.379 8.835"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-234.194 -132.461)"
    />
    <path
      d="M442.955 347.987c-1.529 3.228-1.189 10.194-1.189 10.194"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-225.31 -144.614)"
    />
    <path
      d="M474.986 348.861c0 2.548-2.888 6.8-2.888 6.8"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-237.972 -144.978)"
    />
    <path
      d="M319.734 403.32c19.114-.764 45.618 7.136 59.38 13.762s45.873 28.289 61.164 38.483c15.291 10.194 24.338 16.82 24.848 31.6.51 14.78-10.449 32.111-24.976 33.131-14.527 1.02-15.673-1.02-30.455-8.665-14.782-7.645-47.911-25.23-47.911-25.23l-5.734 36.571s-19.751-.128-43.579 0c-23.714.127-41.413.382-41.413.382L268 495.066s-43.579 17.075-57.086 22.172c-13.507 5.097-45.619 12.233-53.519-20.133-7.9-32.366 25.995-44.854 46.128-55.558s48.676-23.955 70.594-30.327c21.918-6.372 35.429-7.493 45.618-7.9"
      style={{
        fill: "#eb8b8b",
        fillRule: "nonzero",
      }}
      transform="translate(-106.252 -167.666)"
    />
    <path
      d="M508.817 512.141c2.293-12.743 5.607-43.07 6.116-47.147"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-253.284 -193.406)"
    />
    <path
      d="M344.123 522.265c-3.313-28.288-4.078-49.951-5.352-53.774"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-182.375 -194.864)"
    />
    <path
      d="M512.314 520.073c19.114 12.488 59.253 36.189 67.153 38.992"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-254.742 -216.374)"
    />
    <path
      d="M284.408 522.7c-12.488 6.116-73.4 34.15-83.336 37.718"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "1.19px",
      }}
      transform="translate(-124.954 -217.467)"
    />
    <path
      d="M12.6 0h100.565c6.912 0 12.6 5.688 12.6 12.6v33.23c0 6.912-5.688 12.6-12.6 12.6H12.6C5.688 58.43 0 52.742 0 45.83V12.6C0 5.688 5.688 0 12.6 0Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#292f44",
        strokeWidth: "2.38px",
      }}
      transform="translate(145.383 296.691)"
    />
    <path
      d="M89.394 476.4c1.065-8.8 9.3-13.482 15.856-10.745 6.556 2.737 15.882 4.234 20.25-5.379s11.312-12.714 19.442-8.682 14.069.546 16.233-6.917"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f6b60d",
        strokeWidth: "2.38px",
      }}
      transform="translate(-78.384 -184.935)"
    />
    <path
      d="M918.892 465.784c-6.3 6.24-15.663 4.794-19.1-1.42-3.437-6.214-9.822-13.179-19.215-8.355s-16.761 2.944-20.632-5.264c-3.871-8.208-10.69-9.162-17.02-4.656"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f6b60d",
        strokeWidth: "2.38px",
      }}
      transform="translate(-392.607 -184.407)"
    />
    <path
      d="m0 0 19.879 10.704"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f6b60d",
        strokeWidth: "2.38px",
      }}
      transform="translate(91.409 34.832)"
    />
    <path
      d="m0 0 4.077 15.8"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f6b60d",
        strokeWidth: "2.38px",
      }}
      transform="translate(133.714 1.192)"
    />
    <path
      d="M8.665 0 0 18.349"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f6b60d",
        strokeWidth: "2.38px",
      }}
      transform="translate(189.272 2.211)"
    />
    <path
      d="M815.67 168.592c-.51-8.155 7.136-20.388 16.82-19.368 9.684 1.02 7.645 8.155 2.548 10.194-5.097 2.039-12.742-2.039-13.252-13.252-.51-11.213 15.291-17.33 15.291-17.33"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f6b60d",
        strokeWidth: "2.38px",
      }}
      transform="translate(-381.231 -53.228)"
    />
    <path
      d="M10.194 0 0 14.781"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f6b60d",
        strokeWidth: "2.38px",
      }}
      transform="translate(396.721 24.128)"
    />
    <path
      d="m0 0 9.684 13.252"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f6b60d",
        strokeWidth: "2.38px",
      }}
      transform="translate(305.993 19.031)"
    />
    <path
      d="m0 0 16.311 21.917"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f6b60d",
        strokeWidth: "2.38px",
      }}
      transform="translate(193.859 137.792)"
    />
    <path
      d="m0 0 5.607 15.8"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f6b60d",
        strokeWidth: "2.38px",
      }}
      transform="translate(216.286 133.204)"
    />
    <path
      d="m0 0 17.84 2.039"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f6b60d",
        strokeWidth: "2.38px",
      }}
      transform="translate(267.766 214.757)"
    />
    <path
      d="M0 3.568 14.272 0"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f6b60d",
        strokeWidth: "2.38px",
      }}
      transform="translate(271.334 194.878)"
    />
    <path
      d="M784.574 451.442s13.252 1.529 21.917 9.175c8.665 7.646 13.762 11.213 23.956 10.7l-6.626 14.271s-11.213 2.549-17.84-2.039c-6.627-4.588-5.607-8.155-12.233-11.213a58.195 58.195 0 0 0-14.781-4.077l5.607-16.817Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "2.38px",
      }}
      transform="translate(-365.936 -187.754)"
    />
  </svg>
);

export default WhoCanHaveFSvg;
