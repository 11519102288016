import React from "react";
import MainContainerComponent from "../../components/MainContainerComponent";
import { Container, Grid } from "@mui/material";
import InformationItemComponent from "./components/InformationItemComponent";
import {
  WANT_BUILD_FAMILY_ROUTE,
  WANT_CHILD_ROUTE,
  WHO_CAN_BUILD_ROUTE,
} from "../../constants/routes";
import { useTranslation } from "react-i18next";
import WantChildSvg from "../../svg/WantChildSvg";
import WantFamilySvg from "../../svg/WantFamilySvg";
import WhoCanHaveFSvg from "../../svg/WhoCanHaveFSvg";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  childIcon: {
    "& .hand": {
      transform: "translate(0, 0)",
      animation: "$hand 3s ease-in 1",
    },
  },
  familyIcon: {
    "& .eye-close": {
      transform: "translate(-30.938px, -138px) scale(1.05)",
      animation: "$family 3s ease-in 2",
    },
  },
  "@keyframes hand": {
    "0%": {
      transform: "translate(0, 100px)",
    },
    "100%": {
      transform: "translate(0, 0)",
    },
  },
  "@keyframes family": {
    "0%": {
      transform: "translate(-30.938px, -138px) scale(1.05)",
    },
    "25%": {
      transform: "translate(-30.938px, -110px) scale(1.05)",
    },
    "50%": {
      transform: "translate(-30.938px, -138px) scale(1.05)",
    },
    "100%": {
      transform: "translate(-30.938px, -138px) scale(1.05)",
    },
  },
}));
const InformationsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <MainContainerComponent>
      <Container>
        <Grid container>
          <InformationItemComponent
            to={WANT_CHILD_ROUTE}
            iconClassName={classes.childIcon}
            Icon={WantChildSvg}
            title={t("information_page.want_child")}
          />
          <InformationItemComponent
            to={WANT_BUILD_FAMILY_ROUTE}
            Icon={WantFamilySvg}
            iconClassName={classes.familyIcon}
            title={t("information_page.want_build_family")}
          />
          <InformationItemComponent
            to={WHO_CAN_BUILD_ROUTE}
            Icon={WhoCanHaveFSvg}
            title={t("information_page.who_can_build_family")}
          />
        </Grid>
      </Container>
    </MainContainerComponent>
  );
};

export default React.memo(InformationsPage);
