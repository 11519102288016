import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Grid, useTheme } from "@mui/material";
import MoreSvg from "../../../svg/MoreSvg";
import LinkComponent from "../../../components/LinkComponent";
import TopicComponent from "../../../components/TopicComponent";
import { classNames } from "../../../functions";
import { INFORMATIONS_ROUTE } from "../../../constants/routes";

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(1),
    },
  },
  icon: {
    width: "100%",
    height: 250,
  },
  topicBox: {
    flex: 1,
    marginTop: theme.spacing(3),
  },
  moreSvg: {
    width: 75,
    height: 75,
    right: theme.spacing(3),
    bottom: theme.spacing(3),
    transform: "rotate(-45deg)",
  },
  title: {
    fontSize: theme.typography.h1.fontSize - 5,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h1.fontSize - 10,
    },
  },
  box: {
    "&:hover": {
      "& .icon": {
        animation: "$icon 2s ease-in-out infinite",
      },
    },
  },
  "@keyframes icon": {
    "0%": {
      transform: "rotate(-45deg)",
    },
    "50%": {
      transform: "rotate(-20deg)",
    },
    "100%": {
      transform: "rotate(-45deg)",
    },
  },
}));

const InformationItemComponent = ({ Icon, to, title, iconClassName = "" }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid item md={4} xs={12} className={classes.grid} data-aos="fade-up">
      <Box>
        <Icon className={classNames([classes.icon, iconClassName])} />
      </Box>
      <Box className={classes.topicBox}>
        <LinkComponent to={`${INFORMATIONS_ROUTE}${to}`}>
          <TopicComponent
            boxClassName={classes.box}
            titleClassName={classes.title}
            iconClassName={classes.moreSvg}
            data={{
              Icon: MoreSvg,
              title,
              color: theme.palette.text.primary,
              background: theme.palette.background.darkGray,
            }}
          />
        </LinkComponent>
      </Box>
    </Grid>
  );
};

InformationItemComponent.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  iconClassName: PropTypes.string,
  Icon: PropTypes.func,
};
export default React.memo(InformationItemComponent);
