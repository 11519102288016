import React from "react";
import PropTypes from "prop-types";
import GridItemComponent from "./GridItemComponent";
import TopicComponent from "../../../components/TopicComponent";

const MainTopicComponent = ({ data = {}, md, iconClassName }) => {
  return (
    <GridItemComponent
      to={data?.to}
      external_link={data?.external_link}
      md={md}
    >
      <TopicComponent data={data} iconClassName={iconClassName} />
    </GridItemComponent>
  );
};

MainTopicComponent.propTypes = {
  data: PropTypes.object,
  md: PropTypes.number,
  iconClassName: PropTypes.string,
};
export default React.memo(MainTopicComponent);
