import { useTheme } from "@emotion/react";
import * as React from "react";

const DisablePSvg = (props) => {
  const theme = useTheme();
  return (
    <svg
      viewBox="0 0 33 35"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
      {...props}
    >
      <path
        d="M22.227 146.14a24.7 24.7 0 0 1-1.352 2.782 9.662 9.662 0 0 1-8.554 4.666c-4.925-.041-9.11-3.744-9.752-8.627a9.759 9.759 0 0 1 2.161-7.672c.177-.203.271-.465.264-.734-.012-1.754 0-3.507-.007-5.261a1.306 1.306 0 0 0-1.47-1.456H1.226a1.251 1.251 0 0 1-1.246-1.246c0-.683.562-1.246 1.246-1.246l.039.001c.844-.005 1.688-.005 2.533 0a3.737 3.737 0 0 1 3.682 3.668c.007 1.156 0 2.312 0 3.468v.415c.4-.192.772-.348 1.116-.546.085-.049.122-.236.127-.362-.011-.855.022-1.711.1-2.563a4.985 4.985 0 0 1 4.89-4.082 4.985 4.985 0 0 1 4.893 4.098c.115 1.163.143 2.333.085 3.5a1.551 1.551 0 0 0 .573 1.323 15.075 15.075 0 0 1 1.706 1.96.768.768 0 0 0 .692.356 21.811 21.811 0 0 1 2.835.082 4.434 4.434 0 0 1 3.329 2.2 275.794 275.794 0 0 1 4.15 7.172c.288.541.439 1.145.439 1.758a3.756 3.756 0 0 1-3.738 3.738 3.742 3.742 0 0 1-3.201-1.808c-1.055-1.756-2.06-3.542-3.088-5.314-.064-.111-.131-.219-.16-.267m-2.277-2.594c-.008-4.098-3.382-7.466-7.48-7.466-4.103 0-7.48 3.377-7.48 7.48 0 4.104 3.377 7.48 7.48 7.48h.08c4.072-.059 7.392-3.421 7.4-7.494m2.552 0c1.158.075 1.2.1 1.788 1.117 1.1 1.908 2.193 3.82 3.3 5.724.227.38.638.613 1.08.613.69 0 1.258-.568 1.258-1.258 0-.199-.047-.396-.138-.573a954.457 954.457 0 0 0-3.989-6.9 2.15 2.15 0 0 0-1.525-1.109 20.746 20.746 0 0 0-2.1-.13l.322 2.511m-11.23-9.884a22.427 22.427 0 0 1 2.487.02c.825.133 1.643.311 2.449.533 0-.643.032-1.319-.007-1.991a2.51 2.51 0 0 0-2.385-2.379 2.457 2.457 0 0 0-2.53 2.113c-.046.566-.05 1.134-.014 1.7"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(.02 -118.595)"
      />
      <path
        d="M153.028 3.75a3.758 3.758 0 0 1-3.741 3.711 3.757 3.757 0 0 1-3.74-3.74v-.03a3.758 3.758 0 0 1 3.74-3.711 3.758 3.758 0 0 1 3.741 3.741v.029m-3.734 1.234a1.284 1.284 0 0 0 1.237-1.25v-.029a1.25 1.25 0 0 0-1.244-1.244 1.25 1.25 0 0 0-1.244 1.244l.001.041c.02.676.574 1.225 1.25 1.238"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(-135.552 .02)"
      />
      <path
        d="M127.353 313.105v-.009a3.757 3.757 0 0 1 3.74-3.74 3.757 3.757 0 0 1 3.74 3.74 3.757 3.757 0 0 1-3.74 3.74h-.022a3.735 3.735 0 0 1-3.718-3.718v-.013m2.5 0a1.25 1.25 0 0 0 1.244 1.234 1.25 1.25 0 0 0 1.244-1.244 1.25 1.25 0 0 0-1.244-1.244h-.011a1.285 1.285 0 0 0-1.233 1.254"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(-118.604 -288.13)"
      />
    </svg>
  );
};

export default DisablePSvg;
