import * as React from "react";

const WantFamilySvg = (props) => (
  <svg
    viewBox="0 0 431 324"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    }}
    {...props}
  >
    <path
      d="M274.061 256.752c9.589 1.2 21.276 6.593 29.966 22.774 8.69 16.181 14.684 38.057 18.28 49.445 3.596 11.388 26.37 50.943 26.37 50.943l27.569 50.643h-86.3s-13.485-61.73-20.976-74.616c-7.491-12.886-23.374-36.859-23.374-36.859l28.465-62.33Z"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-100.133 -106.693)"
    />
    <path
      d="M277.752 349.721s-2.8-6.493-3.9-10.189a12.21 12.21 0 0 0-3.9-5.794l-23.685 15.842-.682 1.493s12.359 18.656 20.415 31.9c5.6-7.022 12.453-28.639 11.743-33.252"
      style={{
        fill: "#f3a8a3",
        fillOpacity: 0.32,
        fillRule: "nonzero",
      }}
      transform="translate(-100.133 -138.686)"
    />
    <path
      d="M383.655 487.591h4.686c7.448-14.87 19.644-41.953 19.644-41.953l-.4-5.02-2-3.67s-15.634-27.161-23.04-42.948c2.815 23.663 2.433 72.837 1.11 93.592"
      style={{
        fill: "#f3a8a3",
        fillOpacity: 0.32,
        fillRule: "nonzero",
      }}
      transform="translate(-157.043 -163.727)"
    />
    <path
      d="M358.179 68.65c-4.795-6.892-12.37-21.989-3.2-32.264 9.989-11.187 15.283-6.093 24.573-9.989 9.29-3.896 9.511-6.1 15.383-12.785 5.794-6.593 19.378-10.588 27.969-5.794 6.479 3.616 14.783 8.59 24.972-.4 10.189-8.99 27.731-10.607 39.356-.4 8.19 7.192 9.089 14.284 15.083 21.476 5.994 7.192 20.077 7.192 29.067 3.9 8.99-3.292 33.563-3.2 40.954 22.974 6.326 22.4-6.193 43.351-19.378 50.743-13.509 7.574-30.766 4.595-40.355-.8-9.589-5.395-23.574-6.393-30.166 3.2-6.592 9.593-7.991 23.374-25.072 29.667-17.081 6.293-29.667 2.4-29.667 2.4L358.179 68.65Z"
      style={{
        fill: "#4b6694",
        fillRule: "nonzero",
      }}
      transform="translate(-143.797)"
    />
    <path
      d="M413.225 150.93c-1.987-1.383 1.769 5.271 3.826 11.9 5.407 17.419 7.446-3.66-3.826-11.9"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-169.559 -62.642)"
    />
    <path
      d="M449.854 173.488c.943-2.819-6.265 4.77-7.954 14.4 3.842 3.974 2.953 3.706 3.318 1.441.88-5.445 2.883-10.632 4.636-15.837"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-181.709 -71.845)"
    />
    <path
      d="M470.693 178.84c-8.657 8.336-8.023 23.017-6.463 23.788.873.432.242.774 7.1-23.409a.394.394 0 0 0-.384-.473.39.39 0 0 0-.254.094"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-190.597 -74.276)"
    />
    <path
      d="M421.218 135.243c.173.127 1.029.931.923.04-2.372-16.817-18.556-29.078-16.81-25.806.634 1.154 15.243 25.294 15.887 25.767"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-166.458 -45.272)"
    />
    <path
      d="M441.591 142.051c2.927 9.9 4.754-9.337-3.418-19.885-2.133-2.61 2.541 16.923 3.418 19.885"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-179.93 -50.666)"
    />
    <path
      d="M466.9 145.643c7.33-10.505 2.261-26.226 1.567-23.175-.706 3.468-3.473 25.907-1.567 23.175"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-191.823 -50.73)"
    />
    <path
      d="M373.99 93.183c-2.057-6.152-3.85-14.419-4.456-12.068-2.513 10.547 4.887 21.241 6.635 21.461 1.169.147.711-.747-2.179-9.393"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-151.423 -33.537)"
    />
    <path
      d="M394.529 63.244c-7.089 12.427 5.524 27.466 3.57 19.84-3.809-14.863-2.221-22.11-3.57-19.84"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-161.116 -26.11)"
    />
    <path
      d="M425.734 86.829c1.524-.355-2.424-6.695.583-24.343.327-2.256-5.037 3.741-4.894 12.742.094 5.929 3.238 11.851 4.311 11.6"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-173.198 -25.767)"
    />
    <path
      d="M454.946 92.8c1.842.4-1.176-2.653.084-10.86.93-6.061 6.174-18.559 3.688-15.754-11.629 13.514-6.241 26.07-3.772 26.614"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-185.71 -27.333)"
    />
    <path
      d="M488.892 82.077c2.912-6.656-7.988 2.155-9.943 10.8-.49 2.493 7.469-5.144 9.943-10.8"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-197.096 -33.135)"
    />
    <path
      d="M502.963 103.173c6.782-5.071-11.8-1.376-15.6 11.666-.6 2.243 4.4-3.368 9.841-7.442 1.906-1.427 3.852-2.8 5.76-4.223"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-200.58 -41.964)"
    />
    <path
      d="M491.1 156.065c1.469 1.5-.182-2.933 5.551-11.06 5.662-8.026 14.569-14.682 11.185-13.444-13.5 5.144-20.46 20.7-16.735 24.5"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-201.74 -54.608)"
    />
    <path
      d="M502.209 179.762c-14.25 7.868-14.6 26.632-12.713 26.605 2.306-.032-1.491-5.731 13.2-26.049a.388.388 0 0 0-.491-.556"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-201.148 -74.683)"
    />
    <path
      d="M517.518 203.281c-7.174 8.418-7.073 12.718-5.913 11.56 4.687-4.9 10.5-10.493 12.621-15.4 3.5-8.124-1.281-2.523-6.708 3.845"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-210.43 -81.195)"
    />
    <path
      d="M519.654 156.526c-.68 1.766 4.213-.968 7.4-5.207.561-.746 3.97-5.786 2.857-6.885-1.94-1.916-9.57 10.31-10.255 12.092"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-213.993 -59.934)"
    />
    <path
      d="M526.668 99.905c7.071-3.275 7.795-14.491 6.085-12.8-5.027 4.963-7.446 13.341-6.085 12.8"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-216.779 -36.123)"
    />
    <path
      d="M508.994 65.437c-.776 1.327 3.86.419 7.368-2.993.677-.658 3.9-4.3 3.044-5.553-1.446-2.11-9.655 7.252-10.412 8.546"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-209.554 -23.512)"
    />
    <path
      d="M357.325 61.254c-2.7 2.919-2.551 3.081-1.98 4.289 2.176 4.6 2.531-.273 1.98-4.289"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-145.599 -25.453)"
    />
    <path
      d="M551.972 215.18c4.365-4.141 6.855-9.089 9.175-13.888a21.433 21.433 0 0 0-9.175 13.888"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-227.45 -83.647)"
    />
    <path
      d="M552.281 175.974c-1.83 2.451 7.927-3.583 14.466-6.879 1.94-.978 13.173-6.43 10.77-6.445-9.448.161-19.518 5.942-25.235 13.324"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-227.485 -67.59)"
    />
    <path
      d="M618.964 172.71a17.253 17.253 0 0 0-6.979.9c1.586.329 3.145.777 4.664 1.341 1.092-.621 3.565-2.116 2.315-2.243"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-252.388 -71.742)"
    />
    <path
      d="M679.6 169.657c-14.3-5.739-30.346 5.372-26.608 4.822 20.1-3.333 28.471-4.03 26.608-4.822"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-269.2 -69.815)"
    />
    <path
      d="M667.6 145.5c-4.055 1.08 27.47 6.287 24.072 3.829-6.537-4.456-16.469-5.663-24.072-3.829"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-275.349 -60.12)"
    />
    <path
      d="M714.8 124.845a19.232 19.232 0 0 0 5.4 12.777c1.087 1.022.856 1.138 1.808-1.247-1.307-6.234-7.106-13.654-7.207-11.53"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-295.11 -51.725)"
    />
    <path
      d="M553.938 138.392c-1.774 2.849 6.289-4.361 15.47-7.971 10.218-4.019 11.166-2.98 13.055-3.947 5.012-2.565-18.172-4.219-28.525 11.918"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-228.164 -51.876)"
    />
    <path
      d="M571.128 107.71c37.679-4.819 8.992-9.8-.32-.67a.401.401 0 0 0-.106.271c0 .219.181.4.4.4l.026-.001"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-235.233 -42.361)"
    />
    <path
      d="M604.884 145.642c5.948-.082 16.3-3.1 14.763-3.93-7.585-4.109-17.379 3.966-14.763 3.93"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-249.258 -58.403)"
    />
    <path
      d="M643.866 114.07c8.85-.518-7.143-6.559-18.32-2.6-3.35 1.276 13.271 2.9 18.32 2.6"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-257.84 -45.765)"
    />
    <path
      d="M663.288 93.341c-2.981-.05 16.7 12.969 15.586 10.2-2.3-5.752-9.5-9.964-15.586-10.2"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-273.58 -38.787)"
    />
    <path
      d="M554.052 87.959c-.581 2.264 4.137-3.425 9.964-7.247 4.562-2.99 9.68-5.4 7.946-5.6-7.746-.892-15.841 5.493-17.91 12.844"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-228.294 -31.18)"
    />
    <path
      d="M603.772 54.782c3.696.753 7.505.765 11.206.034a17.606 17.606 0 0 1-5.734-2.953c-2.451.442-7.266 2.435-5.472 2.918"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-248.812 -21.551)"
    />
    <path
      d="M615.652 83.285c5.819 1.82 15.164 1.327 13.923.027-5.812-5.427-16.249-.755-13.923-.027"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-253.774 -33.543)"
    />
    <path
      d="M670.711 66.818c2.245 1.459 8.108 4.559 7.237 2.823-4.848-9.665-28.048-16.341-7.237-2.823"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-273.247 -24.594)"
    />
    <path
      d="M709.409 72.271c1-2.908.4-6.463-1.011-9.507a29.134 29.134 0 0 0-4.181-2.845c1 6.754 4.726 13.707 5.192 12.352"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-290.716 -24.9)"
    />
    <path
      d="M375.5 51.483c.847-2.421.4-2.07-1.461-1.408.427.892 1.117 2.391 1.461 1.408"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-153.508 -20.605)"
    />
    <path
      d="M405.044 41.706c-2.659 1.437-3.431 1.546-3.4 1.677 3.511 15.1 5.086 5.7 3.4-1.677"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-164.98 -17.33)"
    />
    <path
      d="M439.4 21.088c4.057-6.767-9.28 1.475-10.411 11.991-.237 2.783 8.538-8.856 10.411-11.991"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-176.342 -7.74)"
    />
    <path
      d="M453.515 43.038c3.842-8.811 22.955-20.851 19.746-21.657-1.414-.355-20.775 10.248-20.475 21.517a.387.387 0 0 0 .384.355c.146 0 .28-.084.345-.215"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-186.23 -8.88)"
    />
    <path
      d="M483.638 49.359c-.109.718 1.345.361.643-.275.118 0 .21.221.2.343 53.265-26.724-1.39-7.233-.84-.068"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-199.05 -14.863)"
    />
    <path
      d="M485.061 58.968a.664.664 0 0 0 .01.1.259.259 0 0 0-.01-.1"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-199.645 -24.503)"
    />
    <path
      d="M548.321 38.167c32.492-7.365 7.544-11.4-.4-.624a.41.41 0 0 0 .346.628c.018 0 .036-.002.054-.004"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-225.738 -12.857)"
    />
    <path
      d="M561.491 13.812c-1.67 1.36 5.174.185 11.811.147 4.767-.027 5.192.649 1.435-2.65-2.732-2.4-10.1.037-13.246 2.5"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-231.299 -4.29)"
    />
    <path
      d="M548.8 59.537c-2.235 3.108 21.961-8.854 18.226-9.1-6.636-.434-14.274 3.85-18.226 9.1"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-226.07 -20.946)"
    />
    <path
      d="M466.317 12.379c2.15-1.487 2.55-1.15.29-1.687-.282.382-.569.759-.821 1.168a.385.385 0 0 0 .531.518"
      style={{
        fill: "#2b332b",
        fillRule: "nonzero",
      }}
      transform="translate(-191.608 -4.442)"
    />
    <path
      d="M108.187 205.041s20.6-17.776 42.883-13.054c22.283 4.722 41.575 18.6 36.6 48.811-4.975 30.211-30.961 44.622-39.56 50.054-8.599 5.432-28.3 15.118-41.184 20.5-12.884 5.382-19.017 20.284-19.017 20.284a42.848 42.848 0 0 0-15.042-31.351c-15.494-13.02-37.833-29.736-50.423-46.094-12.59-16.358-17.882-47.351-3.3-64.335 14.582-16.984 30.077-23.01 54.763-18.053 24.686 4.957 34.278 33.235 34.278 33.235"
      style={{
        fill: "#ef473e",
        fillRule: "nonzero",
      }}
      transform="translate(-2.379 -70.696)"
    />
    <path
      d="M63.147 187.94c8.2-3.678 23.063-5.546 33.606.826"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#fff",
        strokeWidth: "1.02px",
      }}
      transform="translate(-24.317 -76.7)"
    />
    <path
      d="M211.417 221.053c9.636-4.583 25.553-1.605 30.485 1.044"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#fff",
        strokeWidth: "1.02px",
      }}
      transform="translate(-85.93 -90.868)"
    />
    <path
      d="M149 271.815c-.829 5.035-1.536 12.249 5.771 13.9 7.307 1.651 9.758-11.929 9.758-11.929"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2b332b",
        strokeWidth: "1.02px",
      }}
      transform="translate(-59.786 -112.953)"
    />
    <g>
      <path
        d="M93.049 259.019a13.89 13.89 0 0 1-9.71 3.961c-7.617 0-13.884-6.268-13.884-13.884 0-7.617 6.267-13.884 13.884-13.884 3.733 0 7.312 1.505 9.923 4.173 5.327 5.444 5.23 14.307-.213 19.634"
        style={{
          fill: "#fff",
          fillRule: "nonzero",
        }}
        transform="translate(-26.938 -97.742)"
      />
      <path
        d="M97.578 236.248a13.876 13.876 0 0 0-3.625 9.35 13.902 13.902 0 0 0 9.819 13.266 13.876 13.876 0 0 0 3.625-9.35 13.902 13.902 0 0 0-9.819-13.266"
        style={{
          fill: "#1b4851",
          fillRule: "nonzero",
        }}
        transform="translate(-37.119 -98.173)"
      />
    </g>
    <path
      className="eye-close"
      d="M93.049 259.019a13.89 13.89 0 0 1-9.71 3.961c-7.617 0-13.884-6.268-13.884-13.884 0-7.617 6.267-13.884 13.884-13.884 3.733 0 7.312 1.505 9.923 4.173 5.327 5.444 5.23 14.307-.213 19.634"
      style={{
        fill: "rgb(239, 71, 62)",
        fillRule: "nonzero",
      }}
      transform="translate(-26.938 -97.742)"
    />
    <path
      d="M221.811 281.919a13.89 13.89 0 0 1-10.551 4.859c-7.617 0-13.884-6.267-13.884-13.884 0-7.616 6.267-13.884 13.884-13.884a13.889 13.889 0 0 1 13.884 13.884 13.89 13.89 0 0 1-3.333 9.025"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-80.096 -107.631)"
    />
    <path
      d="M224.02 259.521a13.871 13.871 0 0 0-2.837 8.405c0 6.534 4.617 12.225 11.01 13.573a13.871 13.871 0 0 0 2.837-8.405c0-6.534-4.617-12.225-11.01-13.573"
      style={{
        fill: "#1b4851",
        fillRule: "nonzero",
      }}
      transform="translate(-89.99 -107.844)"
    />
    <path
      d="M489.561 409.913c7.192 14.084 25.971 63.629 29.167 82.008H388.474l8.79-19.978s15.183-37.957 29.567-50.343c14.384-12.386 47.946-17.181 47.946-17.181l14.784 5.494Z"
      style={{
        fill: "#ffd585",
        fillRule: "nonzero",
      }}
      transform="translate(-159.508 -168.057)"
    />
    <path
      d="M362.493 99.515c-8.69 3.3-16.781 9.989-21.076 28.768-4.408 19.271-4.795 38.656 5.094 53.64a32.745 32.745 0 0 0 25.471 14.983c10.382.657 7.192 37.159 10.788 52.441 3.596 15.282 5.993 41.054 4.2 52.441-1.793 11.387-10.488 43.451-10.488 43.451s24.872-36.26 44.051-47.347c19.179-11.087 40.155-16.182 48.246-14.683 0 0-15.882-39.556-20.677-56.936-4.795-17.38-15.882-58.135-15.882-58.135s6.593-3 9.59-9.589-.3-13.185-.3-13.185-12.186 10.588-24.473 6.093c-12.287-4.495-14.581-14.754-16.781-27.769-2.4-14.184-15.96-16.241-21.376-17.78a101.032 101.032 0 0 1-16.381-6.393"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-138.722 -41.353)"
    />
    <path
      d="M352.652 166.756c0 1.712-.646 2.584-1.915 2.584-1.269 0-1.915-.871-1.915-2.584s.645-2.584 1.915-2.584 1.915.871 1.915 2.584"
      style={{
        fill: "#1d1d1b",
        fillRule: "nonzero",
      }}
      transform="translate(-143.03 -68.221)"
    />
    <path
      d="M409.878 167.068c0 1.712-.646 2.584-1.915 2.584-1.269 0-1.915-.871-1.915-2.584s.646-2.584 1.915-2.584c1.269 0 1.915.871 1.915 2.584"
      style={{
        fill: "#1d1d1b",
        fillRule: "nonzero",
      }}
      transform="translate(-166.81 -68.351)"
    />
    <path
      d="M420.325 190.37c0 5.48-2.067 8.269-6.128 8.269s-6.127-2.789-6.127-8.269 2.067-8.269 6.127-8.269 6.128 2.789 6.128 8.269"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-167.65 -75.672)"
    />
    <path
      d="M338.691 178.707a73.707 73.707 0 0 0 .524 16.515c.192 0 .386-.006.587-.022 4.047-.334 5.878-3.283 5.427-8.746-.441-5.347-2.649-7.953-6.539-7.747"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-138.725 -74.257)"
    />
    <path
      d="M486.95 214.16c-5.194 2.3-13.385-.3-16.781-3.9"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.02px",
      }}
      transform="translate(-193.456 -87.375)"
    />
    <path
      d="M483.5 194.408a41.379 41.379 0 0 1 5.594-4.995"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.02px",
      }}
      transform="translate(-198.996 -78.71)"
    />
    <path
      d="M372.684 163.094c-3.8 4.795-10.189 7.591-10.189 10.189s9.589 5.394 9.589 5.394"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.02px",
      }}
      transform="translate(-148.712 -67.773)"
    />
    <path
      d="M357.368 201.036a13.055 13.055 0 0 0 10.588 5.194c4.2-.2 9.39-3.6 9.39-3.6"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "2.05px",
      }}
      transform="translate(-146.58 -83.54)"
    />
    <path
      d="M395.823 262.531c9.689.3 18.479-3.9 20.077-5.094"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.02px",
      }}
      transform="translate(-162.56 -106.978)"
    />
    <path
      d="M350.532 136.236c3.4-2.2 10.389-1.7 13.185-.1"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1b4851",
        strokeWidth: "3.07px",
      }}
      transform="translate(-143.74 -55.998)"
    />
    <path
      d="M396.165 137.24c3.4-2.2 12.086-2.7 17.281.7"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1b4851",
        strokeWidth: "3.07px",
      }}
      transform="translate(-162.704 -56.303)"
    />
    <path
      d="M290.731 298.351a25.876 25.876 0 0 0-19.378-5.193c-6.992 1-19.578 2-21.176 7.991-1.598 5.991 3.8 9.989 9.789 10.388 4.192.229 8.394.229 12.586 0l4 12.186 14.179-25.372Z"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-101.92 -121.722)"
    />
    <path
      d="M250.18 301.634a7.258 7.258 0 0 0 .107 4.341c4.852 1.5 10.63.924 14.177-1.395 2.977-1.947 3.5-7.826 3.587-10.493-7.072.944-16.5 2.413-17.871 7.546"
      style={{
        fill: "#edbe15",
        fillRule: "nonzero",
      }}
      transform="translate(-101.923 -122.208)"
    />
    <path
      d="M288.662 324.278a36.705 36.705 0 0 0 7.342-.7"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.02px",
      }}
      transform="translate(-118.03 -134.463)"
    />
    <path
      d="M312.248 297.429c4.944 2.847 18.429 15.732 18.429 15.732"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.02px",
      }}
      transform="translate(-127.832 -123.597)"
    />
    <path
      d="M292.251 337.935c1.049 4.2 4.944 16.332 8.99 19.928a30.9 30.9 0 0 0 9.439 5.394"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.02px",
      }}
      transform="translate(-119.522 -140.43)"
    />
    <circle
      cx={9.789}
      cy={9.789}
      r={9.789}
      style={{
        fill: "none",
        stroke: "#edbe15",
        strokeWidth: "3.07px",
      }}
      transform="translate(132.675 255.54)"
    />
    <circle
      cx={5.794}
      cy={5.794}
      r={5.794}
      style={{
        fill: "none",
        stroke: "#edbe15",
        strokeWidth: "3.07px",
      }}
      transform="rotate(-79.1 98.72 -14.32)"
    />
    <path
      d="M7.591 0 0 15.982"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#eb9c88",
        strokeWidth: "3.07px",
      }}
      transform="translate(135.471 80.937)"
    />
    <path
      d="M0 13.185 20.776 0"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#99b1b6",
        strokeWidth: "3.07px",
      }}
      transform="translate(37.981 237.16)"
    />
    <path
      d="m0 0 7.192 9.989"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#99b1b6",
        strokeWidth: "3.07px",
      }}
      transform="translate(14.807 83.334)"
    />
    <circle
      cx={6.593}
      cy={6.593}
      r={6.593}
      style={{
        fill: "none",
        stroke: "#eb9c88",
        strokeWidth: "3.07px",
      }}
      transform="rotate(-80.008 127.877 105.513)"
    />
    <path
      d="m0 0 11.187 7.591"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#eb9c88",
        strokeWidth: "3.07px",
      }}
      transform="translate(317.667 156.052)"
    />
    <path
      d="M575.487 451.966c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.088 1.088 0 0 0-1.082-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-236.772 -187.815)"
    />
    <path
      d="M556.985 470.468c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082 0-.594-.488-1.082-1.082-1.082"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-229.083 -195.503)"
    />
    <path
      d="M556.985 433.463c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082 0-.594-.488-1.082-1.082-1.082"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-229.083 -180.126)"
    />
    <path
      d="M593.989 544.477c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.088 1.088 0 0 0-1.082-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-244.46 -226.258)"
    />
    <path
      d="M593.989 507.473c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.088 1.088 0 0 0-1.082-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-244.46 -210.881)"
    />
    <path
      d="M575.487 525.975c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082 0-.594-.488-1.082-1.082-1.082"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-236.772 -218.57)"
    />
    <path
      d="M575.487 488.97c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.088 1.088 0 0 0-1.082-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-236.772 -203.192)"
    />
    <path
      d="M556.985 544.477c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.088 1.088 0 0 0-1.082-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-229.083 -226.258)"
    />
    <path
      d="M556.985 507.473c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.088 1.088 0 0 0-1.082-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-229.083 -210.881)"
    />
    <path
      d="m521.682 362.236-.886.532c.367-.012.703-.214.886-.532"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-214.494 -150.527)"
    />
    <path
      d="M519.98 470.468c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082 0-.594-.488-1.082-1.082-1.082"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-213.706 -195.503)"
    />
    <path
      d="M519.98 433.463c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082 0-.594-.488-1.082-1.082-1.082"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-213.706 -180.126)"
    />
    <path
      d="M538.483 451.966h-.001c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.087 1.087 0 0 0-1.081-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-221.395 -187.815)"
    />
    <path
      d="M501.478 451.966h-.001c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.087 1.087 0 0 0-1.081-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-206.017 -187.815)"
    />
    <path
      d="M538.483 414.961h-.001c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082 0-.593-.488-1.081-1.081-1.082"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-221.395 -172.437)"
    />
    <path
      d="M482.976 470.468c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082 0-.594-.488-1.082-1.082-1.082"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-198.329 -195.503)"
    />
    <path
      d="M519.98 544.477c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.088 1.088 0 0 0-1.082-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-213.706 -226.258)"
    />
    <path
      d="M519.98 507.473c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.088 1.088 0 0 0-1.082-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-213.706 -210.881)"
    />
    <path
      d="M538.483 525.975h-.001c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082 0-.593-.488-1.081-1.081-1.082"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-221.395 -218.57)"
    />
    <path
      d="M501.478 525.975h-.001c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082 0-.593-.488-1.081-1.081-1.082"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-206.017 -218.57)"
    />
    <path
      d="M538.483 488.97h-.001c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.087 1.087 0 0 0-1.081-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-221.395 -203.192)"
    />
    <path
      d="M501.478 488.97h-.001c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.087 1.087 0 0 0-1.081-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-206.017 -203.192)"
    />
    <path
      d="M482.976 544.477c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.088 1.088 0 0 0-1.082-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-198.329 -226.258)"
    />
    <path
      d="M482.976 507.473c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.088 1.088 0 0 0-1.082-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-198.329 -210.881)"
    />
    <path
      d="M445.971 470.468c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082 0-.594-.488-1.082-1.082-1.082"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-182.95 -195.503)"
    />
    <path
      d="M464.474 451.966h-.001c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.087 1.087 0 0 0-1.081-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-190.64 -187.815)"
    />
    <path
      d="m429.171 417.743-.886.532c.367-.013.702-.214.886-.532"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-176.05 -173.593)"
    />
    <path
      d="M445.971 544.477c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.088 1.088 0 0 0-1.082-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-182.95 -226.258)"
    />
    <path
      d="M445.971 507.473c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.088 1.088 0 0 0-1.082-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-182.95 -210.881)"
    />
    <path
      d="M464.474 525.975h-.001c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082 0-.593-.488-1.081-1.081-1.082"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-190.64 -218.57)"
    />
    <path
      d="M427.469 525.975c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082 0-.594-.488-1.082-1.082-1.082"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-175.262 -218.57)"
    />
    <path
      d="M464.474 488.97h-.001c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.087 1.087 0 0 0-1.081-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-190.64 -203.192)"
    />
    <path
      d="M427.469 488.97c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.088 1.088 0 0 0-1.082-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-175.262 -203.192)"
    />
    <path
      d="M408.967 544.477c-.594 0-1.082.488-1.082 1.082 0 .594.488 1.082 1.082 1.082.594 0 1.082-.488 1.082-1.082v-.001a1.088 1.088 0 0 0-1.082-1.081"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-167.574 -226.258)"
    />
    <path
      d="m497.243 249.122-9.143-33.541a11.191 11.191 0 0 1-5.549.53c-7.192-1-9.989-3.8-9.989-3.8 1.336 7.444 9.973 32.018 24.681 36.806"
      style={{
        fill: "#f3a8a3",
        fillOpacity: 0.32,
        fillRule: "nonzero",
      }}
      transform="translate(-194.45 -88.228)"
    />
  </svg>
);

export default WantFamilySvg;
