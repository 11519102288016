import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles(() => ({}));

const TextSkelComponent = () => {
  const classes = useStyles();

  return <Skeleton className={classes.text} />;
};

TextSkelComponent.propTypes = {
  name: PropTypes.string,
};
export default React.memo(TextSkelComponent);
