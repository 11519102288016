import * as React from "react";

const ContactSvg = (props) => (
  <svg
    viewBox="0 0 676 606"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeMiterlimit: 10,
    }}
    {...props}
  >
    <path
      d="M19.081 809.042H484.5l77.069-185.209H96.15L19.081 809.042Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#21bf82",
        strokeWidth: "3.55px",
      }}
      transform="translate(-1.258 -260.19)"
    />
    <path
      d="M19.081 809.042v19.99H484.5l77.069-185.209v-19.99"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1ebfe8",
        strokeWidth: "3.55px",
      }}
      transform="translate(-1.258 -260.19)"
    />
    <path
      d="M0 0v19.99"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1ebfe8",
        strokeWidth: "3.55px",
      }}
      transform="translate(483.237 548.852)"
    />
    <path
      d="M605.08 719.922c-1.2.451-1.825.706-1.825.706l-91.238 36.564-44.423-73.81s24.6-14.694 47.84-21.186a190.624 190.624 0 0 1 43.056-6.834c14.566-.624 18.453-10.935 45.448-15.377s40.322-2.392 40.322-2.392l46.131 63.217s-17.427 1.025-43.4 6.834c-1.875.42-3.709.846-5.5 1.277"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#a8c6f6",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-188.86 -265.747)"
    />
    <path
      d="M623.847 668.477c17.086 22.553 44.765 65.268 44.765 65.268"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#a8c6f6",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-254.218 -278.863)"
    />
    <path
      d="M666.533 673.993c20.731-9.568 52.4-11.391 56.5-11.391"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1ebfe8",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-272.072 -276.406)"
    />
    <path
      d="M675.932 688.679c20.731-9.568 52.4-11.391 56.5-11.391"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1ebfe8",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-276.003 -282.549)"
    />
    <path
      d="M688.072 703.364c20.731-9.568 52.4-11.391 56.5-11.391"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1ebfe8",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-281.08 -288.691)"
    />
    <path
      d="M741.167 712.053a200.171 200.171 0 0 1 30.849-3.632"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1ebfe8",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-303.29 -295.571)"
    />
    <path
      d="M552.183 789.68c13.213-6.834 48.979-21.87 63.331-23.693"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1ebfe8",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-224.242 -319.65)"
    />
    <path
      d="M537.106 758.155c13.213-6.834 48.979-21.87 63.331-23.692"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1ebfe8",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-217.936 -306.464)"
    />
    <path
      d="M544.742 774.6c13.213-6.834 48.979-21.87 63.331-23.692"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1ebfe8",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-221.13 -313.344)"
    />
    <path
      d="M425.316 137.227s-3.759-10.935-17.769-10.935c-14.01 0-27.337 9.91-33.488 14.352s-13.668 5.468-26.653 4.784c-12.985-.684-30.754-2.734-40.322 4.784-9.568 7.518-16.744 26.312-23.578 32.121-6.834 5.809-22.895 4.1-30.07 9.226-7.175 5.126-8.543 14.694-9.568 24.945-1.025 10.251-23.236 11.276-30.754 19.477-7.518 8.201-6.493 14.694-3.759 23.578 2.734 8.884-6.834 13.669-11.96 19.819-5.126 6.15-4.1 14.352 2.392 19.478 6.492 5.126 8.543 9.226 7.86 16.744-.683 7.518 10.593 12.644 17.086 12.644 6.493 0 214.255-71.76 214.255-71.76l17.085-83.72s6.493-23.92-6.151-32.462a26.452 26.452 0 0 0-24.6-3.076"
      style={{
        fill: "#4485ed",
        fillRule: "nonzero",
      }}
      transform="translate(-74.48 -52.08)"
    />
    <path
      d="M450.574 165.423s-3.759-10.935-17.769-10.935c-14.01 0-27.337 9.91-33.488 14.352s-13.669 5.468-26.654 4.784c-12.985-.684-30.754-2.733-40.322 4.784-9.568 7.517-16.741 26.312-23.578 32.121-6.837 5.809-22.895 4.1-30.07 9.226-7.175 5.126-8.543 14.694-9.568 24.945-1.025 10.251-23.236 11.276-30.754 19.477-7.518 8.201-6.493 14.694-3.759 23.578 2.734 8.884-6.834 13.669-11.96 19.819-5.126 6.15-4.1 14.352 2.392 19.478 6.492 5.126 8.543 9.226 7.86 16.744-.683 7.518 10.593 12.644 17.086 12.644 6.493 0 214.255-71.76 214.255-71.76l17.085-83.72s6.493-23.92-6.151-32.462a26.452 26.452 0 0 0-24.6-3.076"
      style={{
        fill: "#2f77eb",
        fillRule: "nonzero",
      }}
      transform="translate(-85.045 -63.874)"
    />
    <path
      d="M389.854 323.714c-35.88 2.734-87.821 10.593-127.8 45.106-39.979 34.513-53.99 80.644-49.89 109.348 4.1 28.704 17.769 43.4 25.286 48.182l59.459-69.026 46.131-31.438 6.834 71.418h129.168l-2.734-56.724s54.333 32.12 70.392 39.981c16.059 7.861 52.282 29.387 89.529-12.643s-6.493-88.846-21.87-103.54l-52.624 27.338s-68.343-50.915-84.745-63.9-35.88-6.493-46.815-6.493c-10.935 0-40.322 2.392-40.322 2.392"
      style={{
        fill: "#1a3591",
        fillRule: "nonzero",
      }}
      transform="translate(-81.733 -132.978)"
    />
    <path
      d="M589.595 211.289c-2.282 9.341-11.165 34.856-12.984 39.87-1.819 5.014-.232 15.26-1.141 36.448-.909 21.188-5.014 36.906-16.857 38.724a17.873 17.873 0 0 1-5.22.077c1.433-14.885 4.244-42.162 6.355-51.107 2.754-11.643 19.088-78.194 18.451-107.285 4.12 2.43 9.3 5.009 11.509 10.353 3.191 7.748 2.163 23.578-.113 32.919"
      style={{
        fill: "#e3c4a8",
        fillRule: "nonzero",
      }}
      transform="translate(-224.748 -69.533)"
    />
    <path
      d="M544.989 272.838c-2.112 8.945-4.922 36.222-6.355 51.107-8.729-1.2-14.083-9.361-16.2-20.12-2.507-12.757-4.331-46.02-4.331-46.02 2.055-5.693 9.114-38.5 9.114-38.5s-4.67-6.494-4.213-22.556c.5-17.556 8.77-27.791 8.77-27.791s5.693 2.733 14.808 1.366a16.323 16.323 0 0 0 12.3-8.2 17.52 17.52 0 0 0 4.553 3.427c.637 29.091-15.7 95.642-18.451 107.285"
      style={{
        fill: "#f5d4bf",
        fillRule: "nonzero",
      }}
      transform="translate(-209.989 -67.069)"
    />
    <path
      d="M516.127 196.512s-6.684-3.742-10.154 2.031c-3.47 5.773-1.64 12.839 1.725 15.624a8.202 8.202 0 0 0 6.835 1.823 65.48 65.48 0 0 1 0-10.138 42.095 42.095 0 0 1 1.594-9.34"
      style={{
        fill: "#f5d4bf",
        fillRule: "nonzero",
      }}
      transform="translate(-204.133 -80.953)"
    />
    <path
      d="M549.754 206.675c-.795 0-1.2-.592-1.2-1.757s.4-1.756 1.2-1.756c.8 0 1.2.592 1.2 1.756 0 1.164-.4 1.757-1.2 1.757"
      style={{
        fill: "#413c5f",
        fillRule: "nonzero",
      }}
      transform="translate(-222.724 -84.233)"
    />
    <path
      d="M596.429 208.041c-.793-.053-1.158-.671-1.08-1.832.078-1.161.52-1.726 1.314-1.673.794.053 1.157.671 1.08 1.832-.077 1.161-.52 1.726-1.314 1.673"
      style={{
        fill: "#413c5f",
        fillRule: "nonzero",
      }}
      transform="translate(-242.293 -84.806)"
    />
    <path
      d="M568.763 199.131c.57 2.278-1.481 6.72-3.076 8.2-1.595 1.48-.8 3.3 1.708 4.671"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#ef9fab",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-229.546 -82.547)"
    />
    <path
      d="M588.994 190.822c2.848-2.277 10.138-2.62 13.213.684"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f77eb",
        strokeWidth: "2.66px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-239.639 -78.338)"
    />
    <path
      d="M542.588 189.782c2.848-2.278 9.34-2.848 13.213.684"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f77eb",
        strokeWidth: "2.66px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-220.229 -77.868)"
    />
    <path
      d="M555.84 230.49c.741-1.138 3.873-1.253 10.138-.8 6.265.453 9.112 1.14 9.682 2.279.57 1.139-1.2 8.315-7.347 10.023-4.324 1.451-9.112-.401-11.334-4.384-1.47-2.5-1.88-5.981-1.139-7.12"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-225.62 -95.226)"
    />
    <path
      d="M555.84 230.49c.741-1.138 3.873-1.253 10.138-.8 6.265.453 9.112 1.14 9.682 2.279.57 1.139-1.2 8.315-7.347 10.023-4.324 1.451-9.112-.401-11.334-4.384-1.47-2.502-1.879-5.979-1.139-7.118Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#ef516c",
        strokeWidth: "2.66px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-225.62 -95.226)"
    />
    <path
      d="M514.784 202.068c1.708 4.67 1.48 13.1 1.48 13.1"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#ef9fab",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-208.599 -83.776)"
    />
    <path
      d="M533.19 261.593c2.733 4.784 7.632 11.96 18.794 13.441 11.162 1.481 20.275-4.67 24.717-9.568"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#ef9fab",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-216.298 -108.673)"
    />
    <path
      d="M531.9 168.836a45.556 45.556 0 0 1-13.5-7.005"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#221c45",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-210.114 -66.945)"
    />
    <path
      d="M463.042 224.978c-1.88 4.272-13.839 7.176-17.77 10.935"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#221c45",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-179.524 -93.358)"
    />
    <path
      d="M351.812 263.16c-4.1 2.221-7.176 11.106-12.814 14.865-5.638 3.759-9.739 3.759-9.739 3.759"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#221c45",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-130.998 -109.329)"
    />
    <path
      d="M389.175 323.78c2.051-1.88 9.739-5.468 15.719-5.126"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#221c45",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-156.06 -132.531)"
    />
    <path
      d="M275.6 334.825c-4.784 3.076-4.272 12.814-10.081 15.719"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#221c45",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-104.339 -139.305)"
    />
    <path
      d="M539.65 226.079c-1.773-.016-2.663-1.346-2.639-3.942.024-2.596.938-3.91 2.71-3.893 1.772.017 2.664 1.346 2.64 3.942-.024 2.596-.938 3.91-2.711 3.893"
      style={{
        fill: "#eb8998",
        fillRule: "nonzero",
      }}
      transform="translate(-217.896 -90.541)"
    />
    <path
      d="M598.988 232.419c-1.707-.477-2.221-1.992-1.522-4.493.699-2.501 1.923-3.53 3.631-3.053 1.708.477 2.22 1.992 1.522 4.493-.698 2.501-1.924 3.53-3.631 3.053"
      style={{
        fill: "#eb8998",
        fillRule: "nonzero",
      }}
      transform="translate(-243.056 -93.267)"
    />
    <path
      d="M435.425 461.206c-2.051-26.312-3.076-57.066-3.076-57.066"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#221c45",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-174.118 -168.297)"
    />
    <path
      d="M664.763 477.865c-1.025-26.654-2.392-61.85-2.733-69.026"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#221c45",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-270.189 -170.263)"
    />
    <path
      d="M484.63 463.469c8.2 7.86 12.985 10.593 12.643 17.086-.342 6.493-12.643 13.668-12.643 13.668"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#221c45",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-195.986 -193.113)"
    />
    <path
      d="M617.386 453.483c8.2 7.859 12.985 10.593 12.643 17.086-.342 6.493-12.643 13.668-12.643 13.668"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#221c45",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-251.515 -188.937)"
    />
    <path
      d="M614.385 177.776s9.28.93 13.669 2.392a15.437 15.437 0 0 1 10.137 14.466c-.114 6.037-1.594 8.885-1.594 8.885s-21.87 59.231-24.831 65.837c-2.961 6.606-9.454 19.819-20.389 17.769-9.2-1.725-17.655-3.914-17.655-11.163 0-7.176 4.2-19.992 7.632-21.3 2.392-.912 8.771.455 8.771.455l14.238-44.992s-5.126-1.6-2.734-10.821c2.392-9.221 4.784-22.1 12.757-21.528"
      style={{
        fill: "#a9d152",
        fillRule: "nonzero",
      }}
      transform="translate(-233.25 -73.607)"
    />
    <path
      d="M642.961 222.041c9.568 3.19 38.044 20.5 57.18 34.4 19.136 13.9 68.115 61.964 72.1 67.09 0 0 1.481 14.921-14.238 29.045a94.122 94.122 0 0 1-34.4 19.136c-12.687 3.883-40.55-37.133-58.774-53.307-18.224-16.174-42.5-34.533-46.018-37.475-6.265-5.24-17.769-7.176-21.414-7.4-3.645-.224-7.29-.8-7.29-.8s-2.392-4.9-.341-9.568c2.051-4.668 5.126-5.809 5.126-5.809a9.618 9.618 0 0 1-.57-8.771c2.164-4.556 4.1-5.24 4.1-5.24a11.482 11.482 0 0 1 1.595-9.682c3.3-4.9 11.049-5.126 16.175-4.556 5.126.57 22.1 5.468 22.1 5.468l4.669-12.531Z"
      style={{
        fill: "#f5d4bf",
        fillRule: "nonzero",
      }}
      transform="translate(-239.615 -92.13)"
    />
    <path
      d="M605.246 258.261c4.9-1.709 18.339.912 24.148 3.19"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#ef9fab",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-246.437 -107.05)"
    />
    <path
      d="M599.176 282.582c6.037-1.253 22.553 3.19 25.856 4.9"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#ef9fab",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-243.898 -117.361)"
    />
    <path
      d="M642.711 179.943c-4.215 2.848-5.7 7.518-8.087 14.694-2.387 7.176-3.531 13.441-2.734 15.947"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#221c45",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-257.476 -74.521)"
    />
    <path
      d="M592.659 311.132c-2.051 1.823-3.645 6.948-5.582 13.669-1.937 6.721-2.961 12.415-1.253 14.921"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#221c45",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-237.95 -129.394)"
    />
    <path
      d="M673.778 243.579a107.767 107.767 0 0 1 10.935 4.556"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#ef9fab",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-275.102 -101.139)"
    />
    <path
      d="M573.942 630.557 535.5 532.315a9.382 9.382 0 0 1 8.134-3.183l38.443 98.243.717 14.235-8.852-11.053Z"
      style={{
        fill: "#ef516c",
        fillRule: "nonzero",
      }}
      transform="translate(-217.264 -220.559)"
    />
    <path
      d="m563.43 864.9-103.461 20.62a9.383 9.383 0 0 1-1.707-8.566l103.462-20.619 14.14 1.791-12.434 6.774Z"
      style={{
        fill: "#ffdb9c",
        fillRule: "nonzero",
      }}
      transform="translate(-184.797 -357.44)"
    />
    <path
      d="M282.475 550.994s38.955 3.076 65.951 5.126c26.996 2.05 51.257 5.809 68.343 14.01 17.086 8.201 34.513 23.92 40.322 30.071 5.809 6.151 6.151 10.593 3.758 15.719-2.393 5.126-11.276 3.93-15.719 1.2-4.443-2.73-12.131-6.493-18.282-9.91s-12.472-6.322-12.472-6.322 12.985 5.809 17.769 14.01c4.784 8.201 3.076 14.01 1.367 17.086-1.709 3.076-7.176 5.126-7.176 5.126s.342 7.176-3.075 11.618c-3.417 4.442-9.568 6.493-21.87 4.784-12.302-1.709-32.463-6.493-46.131-8.885-13.668-2.392-82.011-12.643-99.781-22.211 0 0-1.367-19.819 7.176-43.739 8.543-23.92 19.819-27.679 19.819-27.679"
      style={{
        fill: "#f5d4bf",
        fillRule: "nonzero",
      }}
      transform="translate(-100.104 -229.723)"
    />
    <path
      d="M274.824 550.286c-17.313-.911-31.437-.228-35.538.456"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#221c45",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-93.365 -229.243)"
    />
    <path
      d="M531.636 689.686c-6.037-9.112-24.489-12.985-24.489-12.985"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#ef9fab",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-205.405 -282.303)"
    />
    <path
      d="M517.752 631.966a78.625 78.625 0 0 0-15.206-6.664"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#ef9fab",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-203.48 -260.804)"
    />
    <path
      d="M595.589 636.624c-3.8-1.935-7.628-2.3-10.013.282-4.1 4.443-.342 10.764 4.272 14.01a72.024 72.024 0 0 0 4.885 3.16 5.769 5.769 0 0 0 3.486-2.989c2.226-4.77 2.082-8.949-2.63-14.463"
      style={{
        fill: "#6da0f1",
        fillRule: "nonzero",
      }}
      transform="translate(-237.474 -264.89)"
    />
    <path
      d="M229.1 716.058 104.028 808.32l-3.909 13.467a2.82 2.82 0 0 0-.113.79 2.843 2.843 0 0 0 2.829 2.829h93.917a6.699 6.699 0 0 0 6.209-4.2l36.877-92.193a4.508 4.508 0 0 0-1.305-5.131l-9.433-7.824Z"
      style={{
        fill: "#a9d152",
        fillRule: "nonzero",
      }}
      transform="translate(-35.107 -298.765)"
    />
    <path
      d="M190.415 808.32h-83.494l35.172-87.929a6.905 6.905 0 0 1 6.4-4.334h83.494l-35.172 87.928a6.912 6.912 0 0 1-6.4 4.334"
      style={{
        fill: "#c4e089",
        fillRule: "nonzero",
      }}
      transform="translate(-38 -298.765)"
    />
    <path
      d="m247.058 768.5 17.181-42.665h-38.553a3.189 3.189 0 0 0-2.956 2L206.355 768.5h40.703Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-79.59 -302.855)"
    />
    <path
      d="m192.7 811.219-12.247 30.413h38.553a3.189 3.189 0 0 0 2.956-2l11.438-28.413h-40.7Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-68.756 -338.569)"
    />
    <path
      d="M183.953 753.578H159.2l10.427-26.067a2.047 2.047 0 0 1 1.9-1.284h24.752l-10.429 26.066a2.046 2.046 0 0 1-1.897 1.285Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#221c45",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-59.867 -303.019)"
    />
    <path
      d="m268.909 726.135-17.146 42.671"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1a3591",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-98.584 -302.98)"
    />
    <path
      d="M238.227 811.154c-6.6 16.434-12.306 30.625-12.306 30.625"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1a3591",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-87.774 -338.542)"
    />
    <path
      d="m245.681 725.933-17.111 42.586"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1a3591",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-88.882 -302.896)"
    />
    <path
      d="M214.962 811.007 202.67 841.6"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1a3591",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-78.049 -338.48)"
    />
    <path
      d="M148.121 844.04h-24.752l10.431-26.067a2.047 2.047 0 0 1 1.9-1.284h24.752l-10.427 26.066a2.046 2.046 0 0 1-1.904 1.285Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#221c45",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-44.879 -340.857)"
    />
    <path
      d="M150.188 851.266h-19.14l3.734-9.335a.735.735 0 0 1 .679-.46H154.6l-3.734 9.335a.732.732 0 0 1-.678.46Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#221c45",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-48.09 -351.223)"
    />
    <path
      d="M0 0h41.006"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1a3591",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(139.656 433.011)"
    />
    <path
      d="M0 0h41.006"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1a3591",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(135.442 443.946)"
    />
    <path
      d="M0 0h41.006"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1a3591",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(131.227 454.881)"
    />
    <path
      d="M0 0h41.006"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1a3591",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(119.893 482.674)"
    />
    <path
      d="M0 0h41.006"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1a3591",
        strokeWidth: "1.77px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(115.792 492.811)"
    />
    <path
      d="M204.432 516.606c.684-16.4 19.819-49.89 73.127-49.207 30.82.395 82.695 33.488 86.8 95 4.105 61.512 7.518 120.967 95.68 122.334 88.162 1.367 88.846-84.062 110.032-125.751 21.186-41.689 63.559-89.529 114.816-86.112 51.257 3.417 55.357 49.207 33.488 73.127-21.869 23.92-66.976-3.417-88.162-22.553-21.186-19.136-56.042-66.976-59.459-125.067-3.417-58.091 3.076-114.132 37.93-145.57 34.854-31.438 84.4-23.92 78.253 18.452-6.147 42.372-32.8 67.66-67.66 94.313-34.86 26.653-125.409 59.8-172.224 37.247-45.177-21.764-14.352-96.021-14.352-96.021"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#ef516c",
        strokeWidth: "3.55px",
      }}
      transform="translate(-78.786 -96.921)"
    />
    <path
      d="M365.566 814.95c.7-.07-2.651-18.1 19.24-18.06 12.349.025 19.313 5.8 19.146 18.5"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#7c7175",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-146.185 -332.576)"
    />
    <path
      d="M436.218 795.877c.65 2.247.921 4.586.8 6.922a6.606 6.606 0 0 1 4.629 6.285v5.749c-.004 3.608-2.972 6.577-6.58 6.581h-2.281c-1.009 4.017-1.465 5.848-1.675 6.679h3.956c7.271-.008 13.252-5.989 13.26-13.26v-5.749c-.009-6.837-5.298-12.606-12.109-13.207Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#21bf82",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-173.6 -332.152)"
    />
    <path
      d="M405.6 815.4a15.707 15.707 0 0 1-1.813 4.078c-6.968 10.9-32.693 10.693-41.519 3.024a14.32 14.32 0 0 1-4.591-7.559l5.06 21.813c.487 0 .329 12.962 18.7 13 10.782.022 17.082-4.356 18.764-13 .048-.003-.161.623 5.399-21.356Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#21bf82",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-142.884 -340.125)"
    />
    <path
      d="M400.219 806.217c4.046-3.244 5.66-7.9 5.67-13.108.027-13.7-10.779-18.121-24.588-18.149-16.32-.032-24.637 6.055-24.66 18.051-.017 8.516 5.008 12.784 5.192 12.765"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#21bf82",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-142.45 -323.403)"
    />
    <path
      d="M373.576 741.61c-2.819 3.844-2.307 8.885.427 13.412 2.734 4.527 1.538 11.533-.256 14.608"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#88b1f3",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-148.747 -309.453)"
    />
    <path
      d="M397.589 733.68c-2.934 4.477-2.4 10.347.444 15.62s1.6 13.431-.266 17.013"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#88b1f3",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-158.759 -306.136)"
    />
    <path
      d="M420.275 741.61c-2.819 3.844-2.307 8.885.427 13.412 2.734 4.527 1.538 11.533-.256 14.608"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#88b1f3",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-168.28 -309.453)"
    />
    <path
      d="M735.759 804.552c3.146 2.528 4.728 6.2 4.718 11-.02 10.021-6.967 15.106-20.6 15.079-13.633-.027-20.56-5.141-20.541-15.161.01-4.807 1.605-8.471 4.784-10.994"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f77eb",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-285.793 -335.747)"
    />
    <path
      d="m699.337 823.371 3.576 30.92c-.016 8.279 5.707 12.5 16.97 12.525 11.263.025 17-4.179 17.018-12.457l3.578-30.906"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f77eb",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-285.793 -343.652)"
    />
    <path
      d="M739.9 742.61c.018-9.024-6.22-13.628-18.5-13.653-12.28-.025-18.533 4.555-18.551 13.579 0 19.41 4.861 41.357 4.861 41.357-.013 6.656 4.588 10.052 13.643 10.07 9.055.018 13.669-3.359 13.683-10.015.006 0 4.864-24.394 4.864-41.338Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1ebfe8",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-287.265 -304.161)"
    />
    <path
      d="M718.691 735.3c-6.493 5.211-4.694 14.267-3.84 28.619.854 14.352 2.392 27.166 2.392 27.166"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1ebfe8",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-291.909 -306.812)"
    />
    <path
      d="M728.532 738.967c-2.733 6.578-1.619 36.222-.081 53.649"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1ebfe8",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-297.308 -308.348)"
    />
    <path
      d="M745.973 735.3c6.493 5.211 4.694 14.267 3.84 28.619-.854 14.352-2.392 27.166-2.392 27.166"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1ebfe8",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-305.3 -306.812)"
    />
    <path
      d="M738.336 738.967c2.733 6.578 1.619 36.222.081 53.649"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1ebfe8",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-302.105 -308.348)"
    />
    <path
      d="M464.321 70.548s-2.683-23.335-14.689-39.148c-10.793-14.219-22.549-12-23.684-3.543-1.234 9.188 12.954 17.137 22.34 26.354 9.386 9.217 16.033 16.337 16.033 16.337Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#ffdb9c",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-171.41 -8.087)"
    />
    <path
      d="M207.836 215.378c-2.019-9.233 4.628-16.833 12.182-16.379 7.554.454 17.468-1.316 18.489-12.523 1.021-11.207 6.92-16.765 16.516-15.562 9.596 1.203 14.349-4.39 13.907-12.659"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#21bf82",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-80.057 -65.45)"
    />
    <path
      d="M1003.8 556.956c3.48-8.786 13.24-11.378 19.25-6.783 6.02 4.595 15.23 8.665 22.33-.061 7.11-8.726 15.1-10.042 22.4-3.685 7.29 6.357 14.35 4.372 18.6-2.734"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#7aa8f2",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-413.145 -226.153)"
    />
    <path
      d="M843.5 108.839c-10.97 2.23-26.427 9.251-31.9 20.135-5.473 10.884-6.159 26.255 2.6 32.593 8.759 6.338 15.689-3.4 12.119-10.366-3.57-6.966-17.72-10.121-30.27-.817s-14.886 26.089-12.025 35.566"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f77eb",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-320.757 -44.78)"
    />
    <path
      d="M658.671 40.181s-12.007 11.983-19.859.737c-7.315-10.476 6.787-18.7 6.787-18.7S637.252 12.842 646.662 5c9.41-7.842 16.873.472 18.6 5.263 0 0 4.349-12.013 15.606-6.7s2.84 17.583 2.84 17.583 12.361 1.775 11.03 13.088c-1.331 11.313-14.365 10.192-19.572 5.312 0 0-2.311 8.72-9.117 9.25-6.806.53-7.378-8.615-7.378-8.615Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#97bbf5",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-259.597 .001)"
    />
    <path
      style={{
        fill: "none",
        stroke: "#a9d152",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      d="M0 0h38.835v16.07H0z"
      transform="rotate(-14.638 1147.354 -2.899)"
    />
    <path
      d="M0 32.805 35.88 0"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#a9d152",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(490.596 124.102)"
    />
    <path
      d="M1027.05 461.235c0 6.185-5.09 11.275-11.28 11.275-6.18 0-11.27-5.09-11.27-11.276 0-6.185 5.09-11.275 11.27-11.276 6.19 0 11.28 5.091 11.28 11.277Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#a9d152",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-413.433 -187.462)"
    />
    <path
      d="M114.772 308.363c0 9.33-7.677 17.007-17.007 17.007-9.33 0-17.007-7.677-17.007-17.007 0-9.33 7.677-17.007 17.007-17.007 9.33 0 17.007 7.677 17.007 17.007Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#ef9fab",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-27.056 -121.123)"
    />
    <path
      d="M337.719 53.13c0 5.624-4.627 10.251-10.251 10.251s-10.251-4.627-10.251-10.251 4.627-10.251 10.251-10.251 10.251 4.627 10.251 10.251Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#a9d152",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(-125.96 -17.191)"
    />
    <path
      d="M0 1.025 43.056 0"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#a9d152",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(39.534 230.717)"
    />
    <path
      d="M30.754 0 0 22.553"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#21bf82",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(75.414 309.652)"
    />
    <path
      d="m0 0 2.051 32.805"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#a9d152",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(331.7 21.588)"
    />
    <path
      d="m0 0 32.805 14.352"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f77eb",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(38.51 429.594)"
    />
    <path
      d="m0 0 7.176 20.503"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f77eb",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(94.892 382.438)"
    />
    <path
      d="M38.955 0 0 5.126"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f77eb",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(7.755 511.605)"
    />
    <path
      d="M0 0h17.427"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f77eb",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(34.408 477.776)"
    />
    <path
      d="M0 23.578 27.679 0"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f77eb",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(395.258 61.568)"
    />
    <path
      d="M0 11.277 21.528 0"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f77eb",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(411.661 92.322)"
    />
    <path
      d="M0 2.05 27.679 0"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f77eb",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="translate(415.761 125.127)"
    />
    <circle
      cx={8.714}
      cy={8.714}
      r={8.714}
      style={{
        fill: "none",
        stroke: "#a9d152",
        strokeWidth: "3.55px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 4,
      }}
      transform="rotate(-22.5 709.017 -359.8)"
    />
  </svg>
);

export default ContactSvg;
