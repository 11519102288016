import React from "react";
import { useGetStoryPostQuery } from "../../services";
import { useParams } from "react-router-dom";
import PostContainerComponent from "../../components/PostContainerComponent";
import { STORIES_ROUTE } from "../../constants/routes";
import { useTranslation } from "react-i18next";

const StoryPostPage = () => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const { data, loading } = useGetStoryPostQuery(slug);
  return (
    <PostContainerComponent
      data={data?.data}
      loading={loading}
      hiddenOtherPosts
      to={STORIES_ROUTE}
      topic={t("stories_page.title")}
    />
  );
};

export default React.memo(StoryPostPage);
