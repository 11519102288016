import * as React from "react";

const MainSvg = (props) => (
  <svg
    viewBox="0 0 877 679"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    }}
    {...props}
  >
    <path
      d="M149.952 631.353c-10.209 2.723-31.308 2.723-54.448-11.57s-17.016-41.517-25.182-55.81-20.419-13.612-32.669-24.5c-12.25-10.888-10.209-33.35-18.377-46.962-8.168-13.612-19.738-31.988-17.7-47.643 2.038-15.655 13.615-31.99 23.144-32.668 9.529-.678 14.973 14.293 20.418 27.224 5.445 12.931 19.737 14.29 29.262 23.819 9.525 9.529 17.7 25.864 23.145 36.757 5.445 10.893 41.517 23.821 41.517 23.821l10.89 107.532Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#98b8f7",
        strokeWidth: "2.68px",
      }}
      transform="translate(-1.34 -54.214)"
    />
    <path
      d="M806.57 336.309c-.68-12.251 3.4-27.905 20.418-40.156 17.018-12.251 16.335-25.183 19.057-42.2 2.722-17.017 6.125-23.821 18.377-31.308 12.252-7.487 25.182-23.821 27.905-31.308 2.723-7.487 14.293-17.015 21.1-11.57 6.807 5.445 2.042 19.057-4.084 25.863-6.126 6.806-21.779 20.418-29.266 30.627-7.487 10.209-14.293 21.78-14.293 34.03s-1.361 26.544-11.57 38.114c-10.209 11.57-16.335 51.727-16.335 51.727l-31.309-23.819Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#98b8f7",
        strokeWidth: "2.68px",
      }}
      transform="translate(-104.962 -24.096)"
    />
    <path
      d="M808.754 320.344c-3.063-6.636-11.741-14.293-13.782-22.46-2.041-8.167-11.23-20.418-21.439-18.884-10.209 1.534-16.845 11.23-14.293 20.929 2.552 9.699 8.168 14.8 8.168 20.929 0 6.129 2.041 18.887 2.041 18.887"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#98b8f7",
        strokeWidth: "2.68px",
      }}
      transform="translate(-98.81 -37.057)"
    />
    <path
      d="M841.994 242.09c-2.552-7.657-7.146-21.439-23.481-25.012-16.335-3.573-21.95-2.041-29.1-10.72-7.15-8.679-8.678-17.866-11.741-23.991-3.063-6.125-9.7-4.594-11.74 2.041-2.04 6.635-5.616 30.117 1.02 44.41 6.636 14.293 21.95 20.418 28.586 25.012 6.636 4.594 6.636 13.782 4.084 17.866-2.552 4.084-4.084 13.272-4.084 13.272"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#98b8f7",
        strokeWidth: "2.68px",
      }}
      transform="translate(-99.38 -24.14)"
    />
    <path
      d="M277.7 228.242c-3.289 1.7-25.069 13.952-26.43 15.541-1.361 1.589-5.9 18.6-7.941 33.8-2.041 15.2-2.948 41.29-2.948 41.29"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#79e9c1",
        strokeWidth: "2.68px",
      }}
      transform="translate(-32.103 -30.542)"
    />
    <path
      d="M243.113 285.93c2.269-6.579 11.57-14.973 21.1-19.284 9.53-4.311 33.8-15.881 33.8-15.881s-12.024 15.427-22.913 25.182c-10.889 9.755-32.9 23.821-32.9 23.821"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#79e9c1",
        strokeWidth: "2.68px",
      }}
      transform="translate(-32.338 -33.44)"
    />
    <path
      d="M283.216 219.741s-.341-18.716 6.466-33.009 17.355-25.183 19.057-24.5c1.702.683-3.063 29.947-3.063 29.947-.987-.353 25.646-31.288 29.266-29.947 2.726 1.01-10.209 29.266-10.209 29.266s9.075-9.869 16.221-14.293c7.146-4.424 13.726-7.6 14.746-7.261 1.02.339-9.868 23.821-17.015 31.989s-18.6 16.108-30.627 18.6c-10.265 2.132-24.842-.792-24.842-.792Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f5a5d9",
        strokeWidth: "2.68px",
      }}
      transform="translate(-37.615 -22.04)"
    />
    <path
      d="M333.632 292.374c-2.382-10.209-5.1-48.323 3.063-49.684 8.163-1.361 12.591 47.3 12.591 47.3l-15.654 2.384Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#98deea",
        strokeWidth: "2.68px",
      }}
      transform="translate(-43.792 -32.397)"
    />
    <path
      d="M366.014 292.006c-.342-6.466 4.083-33.35 9.187-33.69 5.104-.34-.339 33.009-.339 33.009l-8.848.681Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#98b8f7",
        strokeWidth: "2.68px",
      }}
      transform="translate(-48.27 -34.412)"
    />
    <path
      d="M894.987 595.123c9.529-2.042 61.144-10.551 64.548-37.1 3.404-26.549-1.362-37.433 9.529-49.684 10.891-12.251 23.821-25.863 20.418-46.961-3.403-21.098 4.764-29.947-2.723-34.712-7.487-4.765-14.974 9.534-19.059 15.659-4.085 6.125-16.334 8.847-21.1 19.057-4.766 10.21-6.125 22.46-13.612 28.585"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#98b8f7",
        strokeWidth: "2.68px",
      }}
      transform="translate(-116.35 -55.956)"
    />
    <path
      d="M885.465 526.384c9.529-4.764 35.391-21.1 36.072-49.684.681-28.584-7.486-44.921 4.084-61.936s31.308-17.016 36.073-40.836c4.765-23.82-16.335-40.837-10.89-57.852 5.445-17.015 12.25-43.559-2.042-50.366-14.292-6.807-44.239 15.654-46.281 31.308-2.042 15.654-.681 40.836-16.335 49.684-15.654 8.848-34.03 14.973-41.517 35.391-7.487 20.418 2.722 31.308-6.126 45.6-8.848 14.292-27.224 27.9-27.224 27.9l74.186 70.791Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#98deea",
        strokeWidth: "2.68px",
      }}
      transform="translate(-105.577 -35.203)"
    />
    <path
      d="M939.263 307.3c-10.209 11.57-10.209 34.03-10.209 50.365s-9.529 37.434-24.5 46.962c-14.971 9.528-24.5 23.141-24.5 36.073 0 12.932-.68 38.113-15.654 52.406"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#98deea",
        strokeWidth: "2.68px",
      }}
      transform="translate(-112.413 -40.716)"
    />
    <path
      d="M215.32 354.4c4.437-13.069.431-31.574-12.41-40.707s-12.308-21.486-7.227-31.88c5.081-10.394-.491-39.163-27.449-36.4-26.958 2.763-21.83 36.73-14.992 48.666 6.838 11.936 18.163 20.416 15.312 35.366-2.851 14.95-6.931 29.971 6.258 45.246L215.32 354.4Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#98b8f7",
        strokeWidth: "2.68px",
      }}
      transform="translate(-20.185 -32.727)"
    />
    <path
      d="M211.391 442.036c-3.345-12.712-14.633-33.866-38.11-50.244s-15.981-34.02-30.672-49.392-30.18-11.663-41.544-32.141c-11.364-20.478-30.049-30.829-35.69-29.417-5.641 1.412-.227 26 4.915 40.976 5.142 14.976 3.523 29.173 2.288 39.993-1.235 10.82 6.672 31.589 16.934 43.716C99.774 417.654 102.133 433 100.205 449.9c-1.928 16.9 19.674 31.7 26.033 42.016 6.359 10.316 10.38 23.105 10.652 32.726.272 9.621 35.169 27.988 35.169 27.988l39.332-110.594Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#98deea",
        strokeWidth: "2.68px",
      }}
      transform="translate(-9.271 -37.294)"
    />
    <path
      d="M91.638 329.414c3.962 7.3 8.369 16.711 19.23 29.595s12.989 30.252 13.262 39.874c.273 9.622 16.388 24.476 27.634 33.978 11.246 9.502 9.917 27.163 7.93 38.581-1.987 11.418 7.444 24.825 17.646 31.469 10.202 6.644 19.6 32.377 19.6 32.377"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#98deea",
        strokeWidth: "2.68px",
      }}
      transform="translate(-12.96 -43.562)"
    />
    <g className="bird">
      <path
        d="m352.969 40.929 4.065-1.7a9.53 9.53 0 0 1 8.659-6.981c7.063-.389 17.314-.346 17.314-.346s.837-14.548 1.75-22.206c.913-7.658 3.233-7.619 5.47-7.415 1.438.132 3.064 1.711 2.83 8.271 0 0-.227-9.38 4.573-9.21 5.774.206 4.131 10 4.131 10s.463-6.173 3.5-6.2c3.525-.035 2.7 5.062 2.6 12.427-.1 7.365-.992 14.383-.992 14.383s11.357.484 14.557.6c3.2.116 9.131-2.239 9.131-2.239a30.94 30.94 0 0 1-4.181 13.95c-3.735 5.941-10.573 6.753-21.156 7.017-10.583.264-31.7-.486-38.554-1.531a14.09 14.09 0 0 1-9.847-6.918l-3.85-1.902Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#8ccbe9",
          strokeWidth: "2.68px",
        }}
        transform="translate(-46.593 -1.34)"
      />
      <path
        d="M387.369 36.418c-.288 3.594-.511 7.592-.511 7.592"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#8ccbe9",
          strokeWidth: "2.68px",
        }}
        transform="translate(-50.954 -5.854)"
      />
      <path
        d="M414.724 36.472c-.2 3.517-.77 8.143-.77 8.143"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#8ccbe9",
          strokeWidth: "2.68px",
        }}
        transform="translate(-54.442 -5.86)"
      />
      <path
        d="M369.243 45.244a3.86 3.86 0 0 1-3.84 3.704 3.86 3.86 0 0 1-3.842-3.842c0-2.107 1.735-3.842 3.842-3.842a3.86 3.86 0 0 1 3.84 3.98Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#8ccbe9",
          strokeWidth: "2.68px",
        }}
        transform="translate(-47.7 -6.478)"
      />
    </g>
    <path
      className="dream"
      d="M655.427 102.776c-13.379-1.672-13.246-13.957-8.043-18.451 5.203-4.494 12.537-1.419 14.193.709 0 0-2.839-12.3 6.15-19.633 8.989-7.333 21.289-2.839 24.6 7.57 0 0 4.731-12.3 17.267-10.409s12.3 16.085 11.355 18.451c0 0 10.408-5.914 18.214 1.183 7.806 7.097 4.731 18.924 4.731 18.924s-75.22 3.312-88.467 1.656Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#a7e0f0",
        strokeWidth: "2.68px",
      }}
      transform="translate(-84.063 -9.119)"
    />
    <g className="big_air_balloon">
      <path
        d="M189.222 136.194v-.008c0-18.015 14.824-32.839 32.839-32.839s32.839 14.824 32.839 32.839c0 8.933-3.645 17.49-10.086 23.679-4.8 4.607-13.891 13.14-13.569 17.934.034.503.042 1.008.022 1.512-.217 5.737-1.1 10.992-9.036 10.992-8.337 0-9.387-4.968-9.387-11.543 0-.333.012-.667.035-1 .333-4.757-8.69-13.246-13.47-17.8a32.741 32.741 0 0 1-10.187-23.766Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#4881f1",
          strokeWidth: "2.68px",
        }}
        transform="translate(-25.52 -14.469)"
      />
      <path
        d="M200.158 136.194c0-18.656 11.044-33.679 24.423-32.8 12 .786 21.707 14.566 22.18 31.475.275 9.83-2.52 18.743-7.141 25-3.4 4.607-9.861 13.14-9.631 17.934.023.496.029 1 .016 1.512-.155 5.737-.78 10.992-6.415 10.992-5.919 0-6.664-4.968-6.664-11.543 0-.336.009-.669.024-1 .237-4.757-6.169-13.246-9.561-17.8-4.454-5.98-7.231-14.417-7.231-23.77Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#4881f1",
          strokeWidth: "2.68px",
        }}
        transform="translate(-26.926 -14.469)"
      />
      <path
        d="M217.538 136.194c0-18.656 3.869-33.679 8.557-32.8 4.2.786 7.606 14.566 7.771 31.475.1 9.83-.883 18.743-2.5 25-1.192 4.607-3.455 13.14-3.375 17.934.009.496.011 1 .006 1.512-.055 5.737-.274 10.992-2.248 10.992-2.074 0-2.334-4.968-2.334-11.543 0-.333.003-.667.009-1 .083-4.757-2.162-13.246-3.35-17.8a99.112 99.112 0 0 1-2.536-23.77Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#4881f1",
          strokeWidth: "2.68px",
        }}
        transform="translate(-29.163 -14.469)"
      />
      <path
        d="M222.811 209.666V214a3.228 3.228 0 0 0 3.2 3.2h1.76a3.229 3.229 0 0 0 3.2-3.2v-4.335l-8.16.001Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#6879e8",
          strokeWidth: "2.68px",
        }}
        transform="translate(-29.842 -28.15)"
      />
    </g>
    <g className="air_balloon">
      <path
        d="M829.527 117.437v-.006c0-12.461 10.253-22.714 22.714-22.714 12.461 0 22.714 10.253 22.714 22.714a22.721 22.721 0 0 1-6.977 16.379c-3.317 3.186-9.608 9.088-9.385 12.4.023.348.028.697.015 1.046-.15 3.968-.759 7.6-6.25 7.6-5.767 0-6.493-3.436-6.493-7.984 0-.233.009-.463.024-.691.23-3.29-6.011-9.162-9.317-12.311a22.64 22.64 0 0 1-7.045-16.433Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#7de9c3",
          strokeWidth: "2.68px",
        }}
        transform="translate(-107.925 -13.358)"
      />
      <path
        d="M837.091 117.437c0-12.9 7.639-23.3 16.893-22.689 8.3.544 15.014 10.075 15.341 21.77a28.036 28.036 0 0 1-4.939 17.291c-2.354 3.186-6.82 9.088-6.662 12.4.017.342.021.692.011 1.046-.107 3.968-.539 7.6-4.437 7.6-4.093 0-4.609-3.436-4.609-7.984 0-.233.006-.463.017-.691.163-3.29-4.267-9.162-6.613-12.311a27.718 27.718 0 0 1-5.002-16.432Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#7de9c3",
          strokeWidth: "2.68px",
        }}
        transform="translate(-108.9 -13.358)"
      />
      <path
        d="M849.112 117.437c0-12.9 2.677-23.3 5.919-22.689 2.908.544 5.26 10.075 5.375 21.77a70.165 70.165 0 0 1-1.73 17.291c-.825 3.186-2.39 9.088-2.334 12.4.005.342.007.692 0 1.046-.038 3.968-.189 7.6-1.554 7.6-1.434 0-1.615-3.436-1.615-7.984 0-.233.002-.463.006-.691.057-3.29-1.5-9.162-2.318-12.311a68.51 68.51 0 0 1-1.749-16.432Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#7de9c3",
          strokeWidth: "2.68px",
        }}
        transform="translate(-110.446 -13.358)"
      />
      <path
        d="M852.759 168.255v3a2.233 2.233 0 0 0 2.216 2.216h1.217a2.233 2.233 0 0 0 2.216-2.216v-3h-5.649Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#6879e8",
          strokeWidth: "2.68px",
        }}
        transform="translate(-110.915 -22.82)"
      />
    </g>
    <path
      d="M595.672 102.154v.001c0 4.107 3.38 7.487 7.487 7.487s7.487-3.38 7.487-7.487-3.38-7.487-7.487-7.487-7.486 3.379-7.487 7.486Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#8ccbe9",
        strokeWidth: "2.68px",
      }}
      transform="translate(-77.83 -13.35)"
    />
    <path
      d="M731.981 154.49c.002 4.106 3.381 7.484 7.487 7.484 4.107 0 7.487-3.38 7.487-7.487s-3.38-7.487-7.487-7.487-7.487 3.38-7.487 7.487v.003Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#98b8f7",
        strokeWidth: "2.68px",
      }}
      transform="translate(-95.371 -20.086)"
    />
    <path
      d="M380.024 97.919c-7.6-4.95-16.671-1.571-18.833 5.358-2.162 6.929-7.118 15.223-17.518 12.323-10.4-2.9-17.389.507-19.573 9.522-2.184 9.015-8.815 11.378-16.082 8.17"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#48c4da",
        strokeWidth: "2.68px",
      }}
      transform="translate(-40.808 -13.466)"
    />
    <path
      d="M22.988 5.326 0 0"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#79e9c1",
        strokeWidth: "2.68px",
      }}
      transform="translate(95.272 189.391)"
    />
    <path
      d="m0 0 17.006 13.76"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#79e9c1",
        strokeWidth: "2.68px",
      }}
      transform="translate(125.488 151.144)"
    />
    <path
      d="m0 0 12.932 19.738"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#48c4da",
        strokeWidth: "2.68px",
      }}
      transform="translate(249.343 58.516)"
    />
    <path
      d="M381.249 205.655c0 4.107 3.38 7.487 7.487 7.487s7.487-3.38 7.487-7.487-3.38-7.487-7.487-7.487-7.487 3.38-7.487 7.487Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#98b8f7",
        strokeWidth: "2.68px",
      }}
      transform="translate(-50.232 -26.67)"
    />
    <path
      d="M610.7 143.281c-1.532-3.4-15.314-10.55-31.478-4.084-16.164 6.466-29.1 20.078-38.625 31.138-9.525 11.06-18.547 13.953-19.227 16.335-.68 2.382 15.144 8.167 25.012 9.018 9.868.851 2.5 27.451 2.5 27.451s-16.334-6.353-35.165 12.478-11.344 59.213-11.344 59.213 43.101 45.37 95.054 38.57c51.953-6.8 77.589-22.006 92.789-48.1 15.2-26.094 14.293-64.431 4.764-91.2-9.529-26.769-32.442-44.013-46.054-50.138-13.612-6.125-20.872-5.218-26.543-4.538a39.682 39.682 0 0 0-11.684 3.857"
      style={{
        fill: "#0047c2",
        fillRule: "nonzero",
      }}
      transform="translate(-65.631 -18.745)"
    />
    <path
      d="M649.18 161.455c8.848 2.382 37.434 13.612 47.3 51.727 9.866 38.115-8.846 73.505-20.08 85.418"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-84.715 -21.946)"
    />
    <path
      d="M680.38 221.212c2.042 5.445 5.445 42.878-10.89 59.553"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-87.33 -29.637)"
    />
    <path
      d="M508.965 281.027C512.368 271.5 527 248.7 548.1 249.719"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-66.67 -33.3)"
    />
    <path
      d="M538.845 270.424c-4.424 12.932 2.723 29.266 15.654 37.773 12.931 8.507 34.03 10.209 40.5 9.529"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-70.346 -35.97)"
    />
    <path
      d="M561.3 280.579c0 7.487 19.057 19.738 35.732 17.016"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-73.405 -37.277)"
    />
    <path
      d="M523.192 280.969a39.789 39.789 0 0 0 5.445 33.35"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-68.306 -37.327)"
    />
    <path
      d="M616.372 173.983a62.223 62.223 0 0 0 11.4-12.137"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-80.493 -21.996)"
    />
    <path
      d="M607.463 163.6c-3.743 4.254-29.1 23.141-45.771 26.543"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-73.455 -22.223)"
    />
    <path
      d="M612.042 148.619c-7.487-2.552-25.182-.85-42.538 15.314"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-74.461 -20.17)"
    />
    <path
      d="M532.595 192.822c5.785-.17 15.484-2.382 20.759-5.785"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-69.711 -25.239)"
    />
    <path
      d="M624.574 177.273c4.764 7.657 20.248 8.678 23.991 14.973"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-81.548 -23.982)"
    />
    <path
      d="M640.911 168.789c1.433 4.335 9.609 7.241 12.027 11.892"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-83.651 -22.89)"
    />
    <path
      d="M634.143 148.672c6.636-1.872 30.8-.17 47.983 16.334"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-82.78 -20.223)"
    />
    <path
      d="M356.952 291.876c-45.941 1.021-120.468 4.084-177.639 62.275-52.625 53.566-71.463 134.761-71.463 134.761s28.585 22.46 71.463 40.837c42.878 18.377 95.966 45.941 147.012 63.3 51.046 17.359 240.935 6.125 270.541 0 29.606-6.125-8.168-178.66-35.732-220.517s-84.734-64.321-116.383-73.509c-31.649-9.188-87.8-7.146-87.8-7.146"
      style={{
        fill: "#4881f1",
        fillRule: "nonzero",
      }}
      transform="translate(-15.046 -38.706)"
    />
    <path
      d="M289.585 483.285c-1.361 16.334-17.7 93.243-17.7 93.243"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-36.158 -63.365)"
    />
    <path
      d="M428.37 107.5s-11.777-3.995-19.722 5.691c-7.945 9.686-7.833 21.292-3.29 27.972s15.134 8.092 15.134 8.092-.506 10.653.735 15.742c0 0-9.144 33.708-15.464 52.83s-18.889 53.049-18.889 53.049 34.863 32.3 47.338 35.779c11.379 3.172 56.073-21.722 56.073-21.722s7.113-43.854 9.816-58.336c2.703-14.482 9.209-49.509 9.209-49.509s5.259-7.029 6.9-11.009c0 0 16.1.653 22.351-14.152 6.251-14.805-5.965-25.654-15.44-26.76 0 0-22.707-36.812-53.66-36.442-30.953.37-41.091 18.775-41.091 18.775"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-50.956 -12.585)"
    />
    <path
      d="M429.562 131.752a63.104 63.104 0 0 0-.485-12.768c-.984-5.525-2.034-19.981 5.264-29.371 9.366-12.05 21.733-6.008 21.733-6.008s1.909-13.683 17.354-16.713c15.445-3.03 33.38 5.652 40.881 5.715 7.501.063 14.37-2.019 18.573-4.69 6.778-4.309 15.56-2.2 17 3.218 1.763 6.6-5.014 14.208-10.079 18.408 0 0 4.729-2.631 7.984-.36s1.283 7.817-1.137 9.926c0 0 5.269-.546 5.878 3.816s-7.017 11.911-12 14.979a29.722 29.722 0 0 1-8.848 3.825c-3.138.785-1.481 14.907-6.889 24.085l-.126-17.975s-8.758-4.924-10.311-12.079c-1.553-7.155-13.761-14.82-24.758-16.942s-18.973 1.422-28.026-5.8c0 0-2.286 10.075-9.754 16.157-7.468 6.082-12.97 10.022-16.193 13.893a76.336 76.336 0 0 0-6.056 8.679"
      style={{
        fill: "#48c4da",
        fillRule: "nonzero",
      }}
      transform="translate(-56.298 -9.61)"
    />
    <path
      d="M451.827 124.427a15.152 15.152 0 0 1 5.729-2.146 22.003 22.003 0 0 1 5.927-.227c1.971.178 3.911.609 5.772 1.283a20.104 20.104 0 0 1 5.16 2.8 2.08 2.08 0 0 1 .813 1.648 2.087 2.087 0 0 1-2.077 2.077c-.175 0-.35-.022-.519-.066l-.106-.028c-1.645-.423-3.21-.861-4.8-1.18a40.746 40.746 0 0 0-4.767-.687 43.825 43.825 0 0 0-4.794-.134 39.64 39.64 0 0 0-4.667.392l-.25.036a2.075 2.075 0 0 1-2.356-2.044c0-.696.351-1.346.932-1.727"
      style={{
        fill: "#31a2d8",
        fillRule: "nonzero",
      }}
      transform="translate(-59.196 -16.862)"
    />
    <path
      d="M502.592 131.026a16.109 16.109 0 0 1 5.873-1.584 21.525 21.525 0 0 1 6.016.323 19.822 19.822 0 0 1 5.719 2.027 16.47 16.47 0 0 1 4.715 3.772 2.1 2.1 0 0 1 .493 1.351 2.11 2.11 0 0 1-2.1 2.1c-.239 0-.476-.04-.701-.12l-.139-.05c-1.622-.578-3.115-1.157-4.634-1.642a43.657 43.657 0 0 0-4.6-1.187 46.88 46.88 0 0 0-4.742-.707c-1.6-.174-3.248-.255-4.851-.328l-.206-.01a2.096 2.096 0 0 1-1.991-2.085 2.09 2.09 0 0 1 1.143-1.861"
      style={{
        fill: "#31a2d8",
        fillRule: "nonzero",
      }}
      transform="translate(-65.702 -17.816)"
    />
    <path
      d="M460.3 144.014c-1.317-.091-1.919-1.118-1.786-3.046.133-1.928.87-2.864 2.187-2.773 1.317.091 1.919 1.118 1.786 3.047s-.87 2.863-2.187 2.772"
      style={{
        fill: "#100c27",
        fillRule: "nonzero",
      }}
      transform="translate(-60.174 -18.952)"
    />
    <path
      d="M514.218 153.541c-1.269-.362-1.645-1.493-1.114-3.351.531-1.858 1.446-2.62 2.716-2.258 1.27.362 1.645 1.493 1.114 3.351-.531 1.858-1.446 2.62-2.716 2.258"
      style={{
        fill: "#100c27",
        fillRule: "nonzero",
      }}
      transform="translate(-67.172 -20.194)"
    />
    <path
      d="M490.049 135.913c1.75 1.826 12.123 9.7 11.825 11.56-.298 1.86-13.322 5.223-14.288 6.861"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-63.918 -18.659)"
    />
    <path
      d="M462.08 163.015c1.365-2.318 35.572 1.085 36.8 3.046 1.228 1.961-6.483 14.9-19.97 12.61-13.487-2.29-18.182-13.361-16.83-15.656"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-60.607 -22.047)"
    />
    <path
      d="M462.08 163.015c1.365-2.318 35.572 1.085 36.8 3.046 1.228 1.961-6.483 14.9-19.97 12.61-13.487-2.29-18.182-13.361-16.83-15.656Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-60.607 -22.047)"
    />
    <path
      d="M513.676 176.576c-3.686-.941-4.861-4.167-3.483-9.566 1.378-5.399 3.956-7.667 7.642-6.726 3.686.941 4.861 4.167 3.483 9.566-1.378 5.399-3.956 7.667-7.642 6.726"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-66.756 -21.769)"
    />
    <path
      d="M447.491 163.762c-3.774.487-6.056-2.078-6.769-7.6-.713-5.522.844-8.586 4.618-9.073 3.774-.487 6.056 2.078 6.769 7.6.713 5.522-.844 8.586-4.618 9.073"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-57.865 -20.089)"
    />
    <path
      d="M417.871 126.219c1.341 2.821 4.4 12.924 4.309 16.67"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-54.946 -17.41)"
    />
    <path
      d="M551.6 147.078c-2.529 2.043-9.9 15.009-9.9 15.009"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-70.883 -20.096)"
    />
    <path
      d="M429.186 141.521c-1.106 3.677-3.158 8.71-3.729 14.525"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-55.922 -19.38)"
    />
    <path
      d="M538.549 157.661c-.737 5.748-1.594 13.251-3.237 17.287"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-70.06 -21.458)"
    />
    <path
      d="M429.566 183.791c4.069 7.784 16.773 21.451 39.267 24.194 22.494 2.743 39.521-9.217 43.858-15.229"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-56.451 -24.82)"
    />
    <path
      d="M480.759 189.1c.905 2.59-.286 6.632-2.136 8.722"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-62.764 -25.504)"
    />
    <path
      d="M553.523 93.076c-8.93 5.013-16.5 6.027-18.553 6.056"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#31a2d8",
        strokeWidth: "1.34px",
      }}
      transform="translate(-70.016 -13.146)"
    />
    <path
      d="M561.806 104.057c-4.478 4.751-15.669 8.757-15.669 8.757"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#31a2d8",
        strokeWidth: "1.34px",
      }}
      transform="translate(-71.453 -14.559)"
    />
    <path
      d="M456.926 90.478c-3.19-.731-11.444.235-15.961 6.4"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#31a2d8",
        strokeWidth: "1.34px",
      }}
      transform="translate(-57.918 -12.785)"
    />
    <path
      d="M460.025 95.164c-4.46.375-8.656 4.35-10.044 7.154"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#31a2d8",
        strokeWidth: "1.34px",
      }}
      transform="translate(-59.078 -13.415)"
    />
    <path
      d="M467.8 84.606c1.065-5.555 10.675-11.374 17.114-10.761"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#31a2d8",
        strokeWidth: "1.34px",
      }}
      transform="translate(-61.372 -10.665)"
    />
    <path
      d="M481.419 84.123c2.335-1.716 13.357-5.731 24.619-.863"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#31a2d8",
        strokeWidth: "1.34px",
      }}
      transform="translate(-63.124 -11.583)"
    />
    <path
      d="M471.142 91.717c3.559 2.8 10.712 2.955 17.584 2.234 6.872-.721 15.307 3.1 18.55 5.541"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#31a2d8",
        strokeWidth: "1.34px",
      }}
      transform="translate(-61.802 -12.97)"
    />
    <path
      d="M523.952 88.179c2.874.369 18.573.255 28.066-8.812"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#31a2d8",
        strokeWidth: "1.34px",
      }}
      transform="translate(-68.598 -11.38)"
    />
    <path
      d="M682.953 340.427c22.97-1.021 76.058-5.615 118.936 33.18s65.849 88.309 65.849 130.677c0 42.368-10.72 59.723-10.72 59.723s-187.337 36.753-232.257 36.242c-44.92-.511-155.18.511-155.18.511s-16.845-115.873 5.1-159.773c21.945-43.9 51.556-62.275 96.987-78.1 45.431-15.825 111.279-22.46 111.279-22.46"
      style={{
        fill: "#31a2d8",
        fillRule: "nonzero",
      }}
      transform="translate(-60.806 -44.889)"
    />
    <path
      d="M585.322 473.521c5.615 8.678 13.782 20.418 11.23 26.034-2.552 5.616-9.7 15.824-6.636 22.46 3.064 6.636 16.334 15.314 16.334 15.314"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-76.496 -62.109)"
    />
    <path
      d="M762.836 457.9c.341 13.272 6.125 50.365 9.188 59.893"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-99.342 -60.098)"
    />
    <path
      d="M552.779 200.732c-1.361 5.445-4.764 28.586 1.361 44.92 6.125 16.334 24.5 21.553 30.628 21.553a168.313 168.313 0 0 1 7.032 31.082c1.815 15.88 4.311 35.391 4.084 37.433-.227 2.042-12.989 6.069-12.989 6.069s8.678 19.908 18.887 27.735c13.4 10.274 37.434 13.612 58.078 9.075 20.644-4.537 27.735-20.021 30.231-30.684 2.496-10.663 4.084-28.586 4.084-28.586l-19.907 1.02s-9.189-24.559-15.087-41.8c-5.898-17.241-11.571-39.249-11.571-39.249s17.7-1.362 19.284-22.234c1.584-20.872-5.9-25.636-16.561-26.317 0 0-5.672 17.47-7.714 17.7-2.042.23-1.815-10.436-7.941-12.932-6.126-2.496-25.182-9.755-26.771-19.738 0 0-13.385 11.343-27.9 17.469a107.492 107.492 0 0 1-27.225 7.487"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-72.028 -23.789)"
    />
    <path
      d="M572.357 242.186c.454-1.36 5.19.324 11.344-.908 5.671-1.133 11.8-3.062 13.158-2.381 1.358.681-.567 6.579-5.558 10.1-3.658 2.577-10.776 2.61-13.839.68-3.888-2.447-5.559-6.126-5.1-7.487"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-74.82 -31.895)"
    />
    <path
      d="M572.357 242.186c.454-1.36 5.19.324 11.344-.908 5.671-1.133 11.8-3.062 13.158-2.381 1.358.681-.567 6.579-5.558 10.1-3.658 2.577-10.776 2.61-13.839.68-3.887-2.451-5.562-6.13-5.105-7.491Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-74.82 -31.895)"
    />
    <path
      d="M582.739 218.088c-.113 1.248-2.723 4.538-4.084 5.9-1.361 1.362-5.9 3.289-4.878 6.125 1.079 3 9.188 2.836 10.89 2.723"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-74.992 -29.235)"
    />
    <path
      d="M617.235 244.372c-4.537-.831-6.225-4.634-5.007-11.276 1.218-6.642 4.143-9.6 8.679-8.768 4.536.832 6.224 4.634 5.007 11.275-1.217 6.641-4.145 9.6-8.679 8.769"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-79.91 -30.02)"
    />
    <path
      d="M561.52 249.464c-4.42 1.314-7.648-1.311-9.572-7.784-1.924-6.473-.654-10.436 3.766-11.749 4.42-1.313 7.648 1.311 9.572 7.784 1.924 6.473.654 10.436-3.766 11.749"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-72.09 -30.716)"
    />
    <path
      d="M566.389 222.918c-1.261 0-1.9-.939-1.9-2.786s.641-2.785 1.9-2.785 1.9.939 1.9 2.785-.641 2.786-1.9 2.786"
      style={{
        fill: "#100c27",
        fillRule: "nonzero",
      }}
      transform="translate(-73.815 -29.14)"
    />
    <path
      d="M605.706 219.012c-1.261 0-1.9-.939-1.9-2.786s.641-2.785 1.9-2.785 1.9.939 1.9 2.785-.641 2.786-1.9 2.786"
      style={{
        fill: "#100c27",
        fillRule: "nonzero",
      }}
      transform="translate(-78.875 -28.637)"
    />
    <path
      d="M594.405 201.29h-.003a1.54 1.54 0 0 1-1.534-1.534c0-.47.216-.915.586-1.206 4.057-3.2 11.85-5.705 20.463-2.261.582.233.965.799.965 1.426 0 .842-.693 1.535-1.535 1.535-.196 0-.39-.037-.571-.11-7.4-2.959-14.01-.869-17.423 1.822-.27.213-.605.329-.949.328"
      style={{
        fill: "#415a96",
        fillRule: "nonzero",
      }}
      transform="translate(-77.468 -26.216)"
    />
    <path
      d="M556.151 208.383h-.002a1.542 1.542 0 0 1-1.535-1.535c0-.46.207-.896.562-1.187 7.146-5.862 18.065-3.832 20.054-2.627.461.277.743.777.743 1.315 0 .842-.693 1.535-1.535 1.535-.279 0-.553-.076-.792-.22-1.178-.64-10.59-2.5-16.522 2.371a1.526 1.526 0 0 1-.972.349"
      style={{
        fill: "#415a96",
        fillRule: "nonzero",
      }}
      transform="translate(-72.545 -27.125)"
    />
    <path
      d="M589.813 280.164c7.827 1.248 19.4-1.815 22.29-3.686"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-77.074 -36.749)"
    />
    <path
      d="M670.406 205.98c-1.872 4.424-6.636 15.484-8.338 21.269"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-86.374 -27.676)"
    />
    <path
      d="M660.85 247.911c-3.63.4-6.862-4.027-7.373-5.218"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-85.268 -32.4)"
    />
    <path
      d="M778.329 585.2s-12.93 17.351-52.068 25.86c-18.21 3.955-45.2 7.107-68.84 9.348-.937-6.371-2.513-17.335-3.644-26.361-1.7-13.616-5.786-45.263-5.786-45.263a120.756 120.756 0 0 0-17.694-1.361c-7.644 0-17.7.875-24.719 1.223.625-3.869 4.011-20.605 16.89-30.49 11.938-9.154 23.824-10.208 34.375-11.227l46.281-7.829s19.055-3.064 31.647 0a30.746 30.746 0 0 1 17.6 11.339c-9.845 6.744-29.852 20.651-29.852 20.651l.681 75.886 55.051-22.135.077.358"
      style={{
        fill: "#4881f1",
        fillRule: "nonzero",
      }}
      transform="translate(-79.103 -65.225)"
    />
    <path
      d="M645.478 374.845c6.458 1.55 9.79-6.55 14.293-9.3 4.084-2.5 12.989-3.409 18.83.908 5.218 3.856 9.756 6.579 17.242 4.084 7.486-2.495 12.705-.68 15.655 2.5 2.95 3.18 3.856 7.26 9.3 9.075 5.444 1.815 12.7 2.042 17.7 9.3 5 7.258 4.764 14.065 4.31 18.6-.454 4.535-.454 12.024 2.269 15.881 2.723 3.857 4.31 19.964-1.361 28.358-5.671 8.394-16.789 17.469-27.679 16.788-10.89-.681-67.607 12.251-67.607 12.251s-10.436 7.26-22.006 3.856-16.788-15.2-16.788-15.2a35.43 35.43 0 0 1-20.418-19.738c-6.353-15.427 2.042-28.813 6.806-32.443 4.764-3.63 17.469-6.125 18.15-15.654.681-9.529.453-17.242 8.847-23.594s16.788-7.033 22.46-5.672"
      style={{
        fill: "#9ee0eb",
        fillRule: "nonzero",
      }}
      transform="translate(-76.703 -47.942)"
    />
    <path
      d="M632.255 462.175s14.747 0 21.553-12.024c6.806-12.024 4.538-23.991 9.529-32.159s17.923-13.669 28.132-12.081c0 0-.454 17.015 12.251 23.367s22.913 4.31 22.913 4.31l3.177 4.084s4.083-8.168 11.8-9.982c7.717-1.814 15.881 3.177 17.016 13.158 1.135 9.981-5.445 19.285-11.571 21.553-6.126 2.268-16.108 0-16.108 0s-.907 13.612-14.293 25.41c-11.082 9.767-29.89 14.065-44.636 11.343-14.746-2.722-24.389-10.663-29.607-17.7-5.218-7.037-10.152-19.284-10.152-19.284"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-82.537 -53.372)"
    />
    <path
      d="M700.207 403.967a41.96 41.96 0 0 1 1.815-13.442"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#31a2d8",
        strokeWidth: "1.34px",
      }}
      transform="translate(-91.276 -51.427)"
    />
    <path
      d="M630.094 466.488c-8.735-.113-19.964-12.875-11.8-27.338"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#31a2d8",
        strokeWidth: "1.34px",
      }}
      transform="translate(-80.376 -57.685)"
    />
    <path
      d="M612.987 487.645c4.481 1.191 15.71 3.063 22.176-2.212"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#31a2d8",
        strokeWidth: "1.34px",
      }}
      transform="translate(-80.057 -63.642)"
    />
    <path
      d="M635.9 402.942c1.191-8.507 8.337-13.782 17.866-12.591 9.529 1.191 15.825-2.552 17.525-6.466"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#31a2d8",
        strokeWidth: "1.34px",
      }}
      transform="translate(-83.006 -50.573)"
    />
    <path
      d="M726.318 403.362c5.955-1.872 18.887 3.914 17.525 17.355"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#31a2d8",
        strokeWidth: "1.34px",
      }}
      transform="translate(-94.643 -53.035)"
    />
    <path
      d="M647.492 419.817c-3.233 4.594-1.191 17.016-3.573 24.5-2.382 7.484-9.868 10.379-14.463 9.189"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#31a2d8",
        strokeWidth: "1.34px",
      }}
      transform="translate(-82.176 -55.197)"
    />
    <path
      d="M599.869 447.938c-2.212 4.594.85 19.738 12.081 23.821"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#31a2d8",
        strokeWidth: "1.34px",
      }}
      transform="translate(-78.282 -58.816)"
    />
    <path
      d="M607.389 433.521c2.382-4.084 11.23-4.594 15.143-9.529a20.438 20.438 0 0 0 3.914-11.4"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#31a2d8",
        strokeWidth: "1.34px",
      }}
      transform="translate(-79.336 -54.267)"
    />
    <path
      d="M705.422 455.617c.511-3.573 3.914-7.146 9.7-7.487a8.333 8.333 0 0 1 8 4.255"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-91.953 -58.839)"
    />
    <path
      d="M657.968 463.82c.511-3.573 3.914-7.146 9.7-7.486a8.332 8.332 0 0 1 8 4.254"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-85.846 -59.894)"
    />
    <path
      d="M686.344 457.507c-1.7 2.041-5.615 4.084-4.764 7.316s8.848 3.914 8.848 3.914"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-88.87 -60.048)"
    />
    <path
      d="M676.911 476.943c3.742 1.021 14.973 3.233 23.99-4.594"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-88.284 -61.958)"
    />
    <path
      d="M701.755 439.209a1.028 1.028 0 0 1-1.023-1.023c0-.239.084-.471.237-.655 1.937-2.325 8.06-5.167 14.511-4.446.516.057.91.498.91 1.017a1.028 1.028 0 0 1-1.136 1.017c-5.857-.65-11.308 2.036-12.713 3.722a1.02 1.02 0 0 1-.787.369"
      style={{
        fill: "#a6c7d8",
        fillRule: "nonzero",
      }}
      transform="translate(-91.35 -56.89)"
    />
    <path
      d="M661.138 446.322h-.001a1.023 1.023 0 0 1-.613-1.842c2.3-1.729 9.145-3.118 13.252-2.223.469.102.806.52.806 1a1.028 1.028 0 0 1-1.241 1c-3.761-.819-9.883.581-11.589 1.861a1.022 1.022 0 0 1-.613.2"
      style={{
        fill: "#a6c7d8",
        fillRule: "nonzero",
      }}
      transform="translate(-86.122 -58.049)"
    />
    <path
      d="M716.125 476.969c-4.33-.793-5.942-4.424-4.781-10.765 1.161-6.341 3.957-9.164 8.287-8.371 4.33.793 5.942 4.424 4.78 10.765s-3.957 9.164-8.286 8.371"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-92.668 -60.073)"
    />
    <path
      d="M669.3 486.21c-3.8 2.215-7.39.506-10.634-5.065-3.244-5.571-2.958-9.534.847-11.749s7.39-.506 10.634 5.065c3.244 5.571 2.957 9.534-.847 11.749"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-85.648 -61.457)"
    />
    <path
      d="M763.432 445.009c-2.212 2.041-9.188 11.741-9.188 11.741"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-98.237 -58.439)"
    />
    <path
      d="M744.219 442.4c1.418 3.119 2.893 14.349 1.134 24.728"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-96.946 -58.104)"
    />
    <path
      d="m795.526 586.714-55.051 22.135-.681-75.886s20.007-13.907 29.852-20.651c3.812 4.769 7.225 11.16 10.643 19.628 7.368 18.262 14.434 51 15.237 54.774"
      style={{
        fill: "#a7e0f0",
        fillRule: "nonzero",
      }}
      transform="translate(-96.377 -67.1)"
    />
    <path
      d="M657.307 627.743c-27.165 2.563-49.924 3.924-49.924 3.924-4.426-19.735-2.042-74.867-2.042-74.867s.036-.287.123-.819c7.015-.348 17.074-1.223 24.719-1.223 5.923.019 11.837.474 17.693 1.361 5.856.887 4.083 31.647 5.787 45.263 1.131 9.026 2.706 19.991 3.643 26.361"
      style={{
        fill: "#a7e0f0",
        fillRule: "nonzero",
      }}
      transform="translate(-78.99 -72.564)"
    />
    <path
      d="M739.794 532.544c.339-12.593 1.7-23.482 1.7-23.482"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-96.377 -66.683)"
    />
    <path
      d="M653.2 552.651c-2.04-12.25-5.443-21.778-7.145-24.842"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-84.313 -69.096)"
    />
    <path
      d="M376.753 314.494c16.133-1.716 40.156 1.361 52.747 14.633 12.591 13.272 15.994 20.419 17.355 31.308 1.361 10.889-2.722 25.182-5.785 30.287s-43.559 43.559-43.559 43.559-29.266 39.475-50.025 44.239c-20.759 4.764-33.009-6.125-47.3-4.084-14.291 2.041-20.419 7.827-33.01 5.785-12.591-2.042-19.738-9.869-23.821-17.355-4.083-7.486-6.806-11.911-20.418-9.189-13.612 2.722-29.947 4.424-38.114-4.424-8.167-8.848-5.785-20.759 2.042-24.843s17.7-7.486 15.313-19.4c-2.387-11.914 4.764-21.78 14.293-23.482 9.529-1.702 16.335 3.744 21.439 6.807 5.104 3.063 18.717 1.02 22.46-14.293 3.743-15.313 6.466-27.225 17.7-31.648 11.234-4.423 21.438-1.362 26.884 3.743 5.446 5.105 15.654 9.529 22.12 1.361 6.466-8.168 17.7-29.606 49.684-33.009"
      style={{
        fill: "#0047c2",
        fillRule: "nonzero",
      }}
      transform="translate(-24.297 -41.586)"
    />
    <path
      d="M355.077 492.919c-20.872 1.475-56.093 15.257-72.428 42.822-16.335 27.565-18.377 59.213-18.377 59.213s82.693 23.481 129.145 27.054c46.452 3.573 107.2 2.042 107.2 2.042s-5.616-58.7-14.293-77.589c-8.677-18.889-26.544-34.2-36.753-39.816s-22.346-7.827-22.346-7.827l-72.148-5.899Z"
      style={{
        fill: "#48c4da",
        fillRule: "nonzero",
      }}
      transform="translate(-35.178 -64.605)"
    />
    <path
      d="M357.987 392.874s-10.209-2.723-18.377 6.806.227 22.687 7.033 26.318c6.806 3.631 17.242.453 20.418-3.857 0 0 7.487 10.209 12.024 14.293 4.537 4.084.681 23.367-.227 28.359-.908 4.992-2.269 9.982-2.269 9.982l-12.25 1.815s13.611 18.6 23.594 30.4c9.983 11.8 20.872 20.872 23.141 20.872 2.269 0 12.932-15.653 16.788-22.233s8.622-23.14 8.622-23.14-4.084-1.361-2.949-11.343c1.135-9.982 3.4-27.225 3.4-27.225s17.016-8.847 22.006-25.182c4.99-16.335 5.445-28.132 1.588-36.3-3.857-8.168-10.606-13.272-23.311-15.313-12.705-2.041-36.753-2.1-51.046 7.657s-24.1 24.672-25.239 29.89c0 0-1.588-4.538-2.269-7.487a21.89 21.89 0 0 1-.681-4.31"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-44.44 -48.275)"
    />
    <path
      d="M456.407 389.5c-1.452 0-2.19-1.081-2.19-3.207s.738-3.207 2.19-3.207 2.19 1.081 2.19 3.207-.739 3.207-2.19 3.207"
      style={{
        fill: "#100c27",
        fillRule: "nonzero",
      }}
      transform="translate(-59.623 -50.47)"
    />
    <path
      d="M404.484 394.715c-1.424.282-2.359-.635-2.772-2.72-.413-2.085.1-3.29 1.524-3.572 1.424-.282 2.359.635 2.772 2.72.413 2.085-.1 3.29-1.525 3.572"
      style={{
        fill: "#100c27",
        fillRule: "nonzero",
      }}
      transform="translate(-52.848 -51.15)"
    />
    <path
      d="M431.242 378.612c2.723.17 11.911.511 12.421 4.594.51 4.083-9.189 9.358-9.189 9.358"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-56.666 -49.894)"
    />
    <path
      d="M345.9 411.029c3.573 1.191 13.612 9.869 13.612 9.869"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-45.683 -54.066)"
    />
    <path
      d="M370.71 427.848c-3.006-5.672-6.125-17.469-6.125-17.469"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-48.088 -53.982)"
    />
    <path
      d="M445.546 455.315c-9.245 3.176-27.792 5.9-42.425-.737"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-53.047 -59.67)"
    />
    <path
      d="M420.1 404.938c.349-1.576 9.528-3.063 15.484-3.743 5.956-.68 11.91-.851 12.25 1.02.34 1.871-2.381 9.7-9.868 11.911-7.487 2.211-12.421-1.531-14.8-3.4-2.379-1.869-3.4-4.254-3.062-5.785"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-55.224 -52.739)"
    />
    <path
      d="M420.1 404.938c.349-1.576 9.528-3.063 15.484-3.743 5.956-.68 11.91-.851 12.25 1.02.34 1.871-2.381 9.7-9.868 11.911-7.487 2.211-12.421-1.531-14.8-3.4-2.379-1.869-3.406-4.256-3.066-5.788Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-55.224 -52.739)"
    />
    <path
      d="M464.639 411.738c-3.8-.7-5.21-3.878-4.192-9.437 1.018-5.559 3.469-8.033 7.264-7.338 3.795.695 5.21 3.878 4.191 9.437-1.019 5.559-3.469 8.033-7.263 7.338"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-60.384 -51.984)"
    />
    <path
      d="M399.812 417.043c-3.442 1.746-6.494.071-9.051-4.969-2.557-5.04-2.109-8.493 1.333-10.24 3.442-1.747 6.494-.071 9.051 4.969 2.557 5.04 2.108 8.493-1.333 10.24"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-51.246 -52.797)"
    />
    <path
      d="M392.983 376.954a10.481 10.481 0 0 1 2.861-2.688 15.066 15.066 0 0 1 3.486-1.687c1.234-.423 2.52-.68 3.822-.763 1.331-.1 2.668.066 3.934.489.411.147.686.539.686.975a1.04 1.04 0 0 1-1.017 1.036h-.049a17.947 17.947 0 0 0-3.212.338c-1.069.218-2.122.51-3.151.874a29.944 29.944 0 0 0-5.906 2.867l-.05.031a1.04 1.04 0 0 1-.545.155 1.039 1.039 0 0 1-.855-1.627"
      style={{
        fill: "#415a96",
        fillRule: "nonzero",
      }}
      transform="translate(-51.72 -49.017)"
    />
    <path
      d="M449.334 367.412a13.339 13.339 0 0 1 3.8-.515c1.261-.011 2.521.1 3.761.332 1.258.225 2.485.597 3.657 1.108a10.778 10.778 0 0 1 3.258 2.125c.2.196.313.465.313.745a1.048 1.048 0 0 1-1.386.985l-.047-.017c-1.063-.369-2.1-.7-3.167-.99a62.72 62.72 0 0 0-3.242-.767c-1.095-.236-2.214-.4-3.334-.567-1.12-.167-2.258-.3-3.343-.411l-.078-.008a1.038 1.038 0 0 1-.93-1.03c0-.455.301-.86.737-.991"
      style={{
        fill: "#415a96",
        fillRule: "nonzero",
      }}
      transform="translate(-58.9 -48.386)"
    />
    <path
      d="M344.442 535.621c2.041 14.293-4.594 81.163-4.594 81.163"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-44.904 -70.1)"
    />
    <path
      d="M459.946 546.167c-.511 13.272 9.188 83.715 9.188 83.715"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-60.358 -71.458)"
    />
    <path
      d="M360.976 393.086c-1.928-7.714-1.588-21.666-1.248-25.41"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-47.43 -48.487)"
    />
    <path
      d="M325.61 375.248c-6.806 0-11.57-9.188-17.355-9.529-5.785-.341-11.23 2.723-12.932 11.57"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-39.174 -48.232)"
    />
    <path
      d="M373.948 357.912c-2.042 6.125-3.063 23.821.68 29.947"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-49.057 -47.23)"
    />
    <path
      d="M391.385 345.023c-.68 6.125 1.377 22.329 4.262 25.8"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-51.52 -45.57)"
    />
    <path
      d="M444.912 333.7c5.785 6.125 14.856 23.468 11.512 29.259"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-58.426 -44.113)"
    />
    <path
      d="M464.831 341.9c4.764 5.445 9.869 19.4 7.146 29.607"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-60.99 -45.169)"
    />
    <path
      d="M415.224 335.649c-.341 8.168 2.723 22.46 4.424 26.543"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-54.602 -44.365)"
    />
    <path
      d="M435.147 341.117c3.063 6.125 5.785 16.334 4.764 21.1"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-57.17 -45.068)"
    />
    <path
      d="M239.862 459.786c9.188-1.7 20.078 1.361 23.141 12.591 3.063 11.23 14.973 11.23 14.973 11.23"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-32.036 -60.285)"
    />
    <path
      d="M323.432 451.649c-1.021 8.848 8.168 14.633 15.994 12.932 7.826-1.701 16.334-4.084 21.1 2.382"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-42.781 -59.294)"
    />
    <path
      d="M308.576 418.439c-6.466-.68-13.272 3.4-14.633 9.869-1.361 6.469-8.843 14.292-14.633 10.892"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-37.113 -55.01)"
    />
    <path
      d="M217.99 420.987c0-8.168 7.487-13.952 14.633-11.23 7.146 2.722 11.911 5.445 18.036 4.424"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-29.221 -53.815)"
    />
    <path
      d="M209.081 443.056c-4.084.68-13.952 6.806-12.591 14.633"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-26.438 -58.188)"
    />
    <path
      d="M290.83 400.875c-4.424.68-10.209 5.1-11.911 11.23"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-37.063 -52.759)"
    />
    <path
      d="M231.27 437.226c4.084-5.445 14.973-7.146 19.4-2.041"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-30.93 -56.78)"
    />
    <path
      d="M289.074 465.319c.341 4.084 6.125 10.209 11.911 10.889 5.786.68 10.209-4.424 16.675.341"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-38.37 -61.053)"
    />
    <path
      d="M322.273 401.27c1.021-5.445 9.188-9.188 14.973-7.487"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-42.642 -51.793)"
    />
    <path
      d="M319.539 436.722c4.084-4.764 16.675-4.084 20.078 1.021"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-42.29 -56.959)"
    />
    <path
      d="M352.737 486.564c2.723-5.445 13.612-5.1 17.7-2.042"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-46.563 -63.24)"
    />
    <path
      d="M365.235 453.2c1.7-2.382 13.952-4.424 16.334-.68"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-48.171 -59.14)"
    />
    <g className="hand">
      <path
        d="M565 457.755c0 4.723-1.828 30.123.854 48.118-15.662 3.147-42.494 2.42-54.272.819-2.062-9.1-5.23-14.506-11.58-24.32-7.485-11.569-8.509-38.458-7.485-48.323.445-4.282 9.19-5.444 12.25-.68 3.06 4.764 3.009 11.736 4.425 15.483a62.081 62.081 0 0 1 2.379 7.823c.62 2.657 1.479-21.04 2.84-31.591 1.361-10.551 3.116-22.688 9.752-22.688 5.8 0 6.124 4.426 6.124 7.148 0 0 1.535-9.881 8-10.039 6.98-.169 6.124 8.847 6.124 8.847s.681-5.613 6.469-4.932 5.618 9.527 5.274 14.291c-.344 4.764-1.529 20.932-1.529 20.932s.849 1.187 1.529.281c.68-.906 3.4-12.25 5.22-17.924 1.82-5.674 7.43-6.238 9.246-2.609 1.816 3.629-1.08 14.863-2.441 19.852-1.361 4.989-3.179 14.524-3.179 19.512"
        style={{
          fill: "#f7e5dd",
          fillRule: "nonzero",
        }}
        transform="translate(-64.51 -52.583)"
      />
      <path
        d="M525.565 442.029c5.274-.68 24.671-.511 33.35 2.042"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#f3a8a3",
          strokeWidth: "1.34px",
        }}
        transform="translate(-68.806 -58.012)"
      />
      <path
        d="M535.563 411.029c-.454 3.63-2.382 26.43-2.382 26.43"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#f3a8a3",
          strokeWidth: "1.34px",
        }}
        transform="translate(-69.786 -54.066)"
      />
      <path
        d="M551.7 409.662c-.057 7.2-2.893 28.188-2.893 28.188"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#f3a8a3",
          strokeWidth: "1.34px",
        }}
        transform="translate(-71.797 -53.89)"
      />
      <path
        d="M514.433 465.123c1.361 2.439 15.994 5.615 21.326 14.8 5.332 9.185 3.289 19.851 2.722 21.892"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#f3a8a3",
          strokeWidth: "1.34px",
        }}
        transform="translate(-67.373 -61.028)"
      />
      <path
        d="M497.746 434.984c3.63 2.836.227 15.994-5.445 15.881"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#f3a8a3",
          strokeWidth: "1.34px",
        }}
        transform="translate(-64.525 -57.149)"
      />
      <path
        d="m594.064 629.783-64.655-2.047s-11.909-87.455-13.953-100.047a85.393 85.393 0 0 0-1.013-5.286c11.778 1.6 38.611 2.328 54.273-.818.153 1.039.322 2.057.506 3.039 3.4 18.037 24.842 105.159 24.842 105.159"
        style={{
          fill: "#63cde0",
          fillRule: "nonzero",
        }}
        transform="translate(-67.374 -68.295)"
      />
    </g>
    <path
      d="M789.987 573.783c-18.377 6.125-138.844 47.643-144.289 49.684-5.445 2.041 0 87.8 0 87.8s91.2 0 142.928-25.182c51.728-25.182 82.354-69.762 91.542-92.052 0 0-12.591-13.442-39.816-18.207a194.026 194.026 0 0 0-50.365-2.041"
      style={{
        fill: "#31a2d8",
        fillRule: "nonzero",
      }}
      transform="translate(-83.955 -74.913)"
    />
    <path
      d="M800.331 577.975c16.845-4.084 58.192-4.084 76.058 2.552"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-104.168 -75.19)"
    />
    <path
      d="M833.128 543.823c-10.89 1.361-31.648 9.869-37.093 14.293"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.34px",
      }}
      transform="translate(-103.615 -71.157)"
    />
    <path
      d="M121.189 527.81s-17.7 64.657 14.293 115.7c31.993 51.043 91.2 83.034 172.875 95.966 81.675 12.932 219.836 6.806 255.908 1.361 36.072-5.445 65.335-12.928 79.635-20.415 14.3-7.487 22.46-17.7 23.141-24.5 0 0 14.293-5.445 17.015-19.057 0 0 12.932-6.126 14.973-17.016a29.824 29.824 0 0 0-2.722-19.057c-2.989-5.876 8.848-10.89-2.723-18.376-11.571-7.486-28.586 4.083-51.726 2.042-23.14-2.041-37.434-6.806-37.434-6.806s20.418-6.807 26.543-16.335S635.218 582.6 629.6 578c-5.618-4.6-19.4.511-28.075 4.084-8.675 3.573-30.117 5.615-41.857 7.146-11.74 1.531-25.012 4.594-31.648 14.293-6.636 9.699-8.168 16.334-8.168 16.334s-90.35 0-144.459-6.125C321.284 607.607 245.74 587.7 245.74 587.7l3.573-19.907a645.06 645.06 0 0 1-70.443-18.887c-36.753-12.25-57.682-21.1-57.682-21.1"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-16.135 -69.096)"
    />
    <path
      d="M256.113 594.74c-17.7-4.764-46.962-12.251-59.213-12.251"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-26.507 -76.133)"
    />
    <path
      d="M672.549 630.883c-9.529-1.361-26.884 3.233-26.884 3.233"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-84.263 -82.329)"
    />
    <path
      d="M673.2 590.106c1.872 9.359 22.29 14.123 32.839 5.955"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-87.806 -77.113)"
    />
    <path
      d="M773.027 657.479c-5.1 6.806-29.266 21.779-60.574 21.439"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-92.858 -85.784)"
    />
    <path
      d="M761.058 667.8c-1.588-4.764 0-15.2 6.353-18.377 6.353-3.177 14.519-3.4 14.519-3.4"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-99.04 -84.31)"
    />
    <path
      d="M760.507 698.88c-10.663 4.764-34.03 10.89-50.138 11.8"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-92.59 -91.112)"
    />
    <path
      d="M780.917 672.06c-5.445 1.134-26.09 6.579-26.544 27.225"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-98.253 -87.66)"
    />
    <path
      d="M740.611 720.752c-9.529 5.445-34.03 14.747-52.634 14.066"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-89.708 -93.927)"
    />
    <path
      d="M734.845 723.959c.227-4.991 5.9-14.973 12.932-18.831"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-95.74 -91.916)"
    />
    <path
      d="M738.709 725.178c-1.588 2.269-2.723 8.168-1.588 10.89"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.34px",
      }}
      transform="translate(-95.974 -94.497)"
    />
  </svg>
);

export default MainSvg;
