import React from "react";
import { Typography, Box, Breadcrumbs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import PropTypes from "prop-types";
import LinkComponent from "./LinkComponent";
import { MAIN_ROUTE } from "../constants/routes";
import { classNames } from "../functions";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
    fontSize: 12,
    transition: "all 0.2s ease-out",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "&:visited": {
      color: theme.palette.text.primary,
    },
  },
  arrow: {
    transform: "rotate(180deg)",
    marginRight: 10,
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "&:visited": {
      color: theme.palette.text.primary,
    },
  },
  margin: {
    margin: "0 10px",
  },
  firstMargin: {
    marginLeft: 0,
  },
  last: {
    color: theme.palette.primary.main,
  },
}));
const BreadcrumbsComponent = ({ color, links = [] }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box mt="20px" mb="20px">
      <Breadcrumbs aria-label="breadcrumb" separator="|">
        <LinkComponent to={MAIN_ROUTE}>
          <Box
            display="flex"
            alignItems="center"
            className={classNames([
              classes.link,
              classes.margin,
              classes.firstMargin,
            ])}
          >
            <ArrowRightAltIcon className={classes.arrow} />
            <Typography variant="body1" className={classes.link}>
              {t("breadcrumbs.main")}
            </Typography>
          </Box>
        </LinkComponent>
        {links.length
          ? links.map((l, i) =>
              l.to ? (
                <LinkComponent to={l.to} key={i}>
                  <Typography
                    variant="body1"
                    className={classNames([classes.link, classes.margin])}
                  >
                    {l.title}
                  </Typography>
                </LinkComponent>
              ) : (
                <Typography
                  key={i}
                  variant="body1"
                  style={{ color }}
                  className={classNames([
                    classes.link,
                    classes.margin,
                    classes.last,
                  ])}
                >
                  {l.title}
                </Typography>
              )
            )
          : ""}
      </Breadcrumbs>
    </Box>
  );
};

BreadcrumbsComponent.propTypes = {
  links: PropTypes.array,
  color: PropTypes.array,
};

export default React.memo(BreadcrumbsComponent);
