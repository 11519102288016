import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACCESS_CONTARST, ACCESS_TEXT } from "../constants";
import { MENU_API } from "../constants/api";
import { GET } from "../functions/superagentSending";

export const FETCH_MENU_REDUCER = createAsyncThunk(
  "MainReducer/fetchMenu",
  async () => {
    const response = await GET(MENU_API);
    return response.body.data;
  }
);

export const MainReducer = createSlice({
  name: "MainReducer",
  initialState: {
    data: {
      [ACCESS_TEXT]: localStorage.getItem(ACCESS_TEXT),
      [ACCESS_CONTARST]: localStorage.getItem(ACCESS_CONTARST),
    },
    loading: false,
    error: {},
  },
  reducers: {
    CLEAR_STATE: () => {
      //clear state
    },
    UPDATE_MAIN_REDUCER: (state, action) => {
      const { id, newValue } = action.payload;
      state.data[id] = newValue;
    },
  },
  extraReducers: {},
});

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  incrementByAmount,
  UPDATE_MAIN_REDUCER,
  CLEAR_STATE,
} = MainReducer.actions;

export default MainReducer.reducer;
