import { DARK_PRIMARY_COLOR, KNWOLEDGES_DATA, LIGHT_PRIMARY_COLOR } from ".";
import {
  NEWS_ROUTE,
  INFORMATIONS_ROUTE,
  CONTACT_ROUTE,
  STORIES_ROUTE,
} from "./routes";

const menuItems = [
  {
    name: "menu.news",
    to: NEWS_ROUTE,
  },
  {
    name: "menu.knowledges",
    background: KNWOLEDGES_DATA.background,
    to: KNWOLEDGES_DATA.to,
  },
  {
    name: "menu.how_build",
    to: INFORMATIONS_ROUTE,
    background: LIGHT_PRIMARY_COLOR,
  },
  {
    name: "menu.stories",
    to: STORIES_ROUTE,
    background: DARK_PRIMARY_COLOR,
  },
  {
    name: "menu.contact",
    to: CONTACT_ROUTE,
  },
];

export default menuItems;
