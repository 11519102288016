import * as React from "react";

const WavesSvg = (props) => (
  <svg
    viewBox="0 0 381 231"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    }}
    {...props}
  >
    <path
      d="M380.024 97.919c-7.6-4.95-16.671-1.571-18.833 5.358-2.162 6.929-7.118 15.223-17.518 12.323-10.4-2.9-17.389.507-19.573 9.522-2.184 9.015-8.815 11.378-16.082 8.17"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#bbebf4",
        strokeWidth: "2.68px",
      }}
      transform="translate(-148.63 94.206)"
    />
    <path
      d="M380.024 97.919c-7.6-4.95-16.671-1.571-18.833 5.358-2.162 6.929-7.118 15.223-17.518 12.323-10.4-2.9-17.389.507-19.573 9.522-2.184 9.015-8.815 11.378-16.082 8.17"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#a7e8f4",
        strokeWidth: "2.68px",
      }}
      transform="translate(-306.68 15.52)"
    />
    <path
      d="M456.042 100.4c-15.614-10.176-34.272-3.229-38.716 11.016-4.444 14.245-14.632 31.29-36.011 25.341-21.379-5.949-35.748 1.042-40.236 19.575-4.488 18.533-18.121 23.391-33.059 16.8"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#a4e6f2",
        strokeWidth: "2.68px",
      }}
      transform="translate(-76.63 -94.225)"
    />
  </svg>
);

export default WavesSvg;
