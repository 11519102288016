import * as React from "react";

const LogoSvg = (props) => (
  <svg
    viewBox="0 0 96 84"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="M94.541 21.27C89.915 1.358 65.62-6.74 50.028 6.461c-.82.694-1.6 1.439-2.418 2.181-.194-.182-.351-.326-.5-.474C40.381 1.682 32.374-1.16 23.149.434 11.994 2.362 4.681 8.99 1.146 19.728A50.391 50.391 0 0 0 0 24.951v5.209c.071.163.131.33.181.5.47 2.075.77 4.205 1.428 6.219 2.22 6.79 6.146 12.606 10.747 17.974 9.528 11.117 20.92 20.02 33.249 27.8a3.524 3.524 0 0 0 4.144 0 154.064 154.064 0 0 0 29.293-23.49 68.57 68.57 0 0 0 12.74-17.623c3.095-6.457 4.418-13.14 2.759-20.27M33.579 31.4 47.4 17.58 61.212 31.4v18.17H33.579V31.4Zm51.2 10.66a76 76 0 0 1-13.755 16.631 163.413 163.413 0 0 1-22.679 17.717 1.06 1.06 0 0 1-1.381.039 154.55 154.55 0 0 1-26.183-21.176c-5.019-5.189-9.536-10.762-12.456-17.453a23.27 23.27 0 0 1-2.213-12.362c1.151-9.1 6.069-15.312 14.692-18.323 8.042-2.759 17-.416 22.66 5.929L27.621 28.9H27.6v26.642h39.587V28.9h-.027L51.595 13.33c5.4-6.044 12.276-8.533 20.487-6.812 9.161 1.921 14.684 7.859 16.8 16.942.218 1.156.363 2.325.432 3.5a30.03 30.03 0 0 1-4.534 15.093"
      style={{
        fill: "#cb1314",
        fillRule: "nonzero",
      }}
    />
  </svg>
);

export default LogoSvg;
