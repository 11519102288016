import React from "react";
import PropTypes from "prop-types";
import LinkComponent from "./LinkComponent";

const ContactGroupComponent = ({ className = "" }) => {
  return (
    <>
      <LinkComponent to="tel:+48914410427" external_link className={className}>
        tel: 91 44 10 427
      </LinkComponent>
      <LinkComponent to="tel:+48697801772" external_link className={className}>
        tel: 697 801 772
      </LinkComponent>
      <LinkComponent to="tel:+48914227027" external_link className={className}>
        tel: 91 422 70 27
      </LinkComponent>
    </>
  );
};

ContactGroupComponent.propTypes = {
  className: PropTypes.string,
};
export default React.memo(ContactGroupComponent);
