import React from "react";
import PropTypes from "prop-types";
import LinkComponent from "./LinkComponent";
import { FACEBOOK_LINK } from "../constants";
import FacebookIconSvg from "../svg/FacebookIconSvg";

const FacebookBtnComponent = ({ className = "" }) => {
  return (
    <LinkComponent to={FACEBOOK_LINK} external_link>
      <FacebookIconSvg className={className} />
    </LinkComponent>
  );
};

FacebookBtnComponent.propTypes = {
  className: PropTypes.string,
};
export default React.memo(FacebookBtnComponent);
