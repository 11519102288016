import { useTheme } from "@mui/styles";
import * as React from "react";

const KnowledgeSvg = (props) => {
  const theme = useTheme();
  return (
    <svg
      viewBox="0 0 208 168"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      {...props}
    >
      <path
        d="M22.618 0c.007 5.375-3.863 10.031-9.149 11.007a1.913 1.913 0 0 0-.546.286H9.694c-2.3-.7-4.581-1.422-6.3-3.238A11.144 11.144 0 0 1 0 0"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: "2.6px",
        }}
        transform="rotate(-13 701.15 -382.371)"
      />
      <path
        d="M2.136.514A2.935 2.935 0 0 1 0 0"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: "2.6px",
        }}
        transform="rotate(-13 628.928 -348.364)"
      />
      <path
        d="M22.761 94.1c-2.406-.841-2.99-1.508-2.85-4.149A28.027 28.027 0 0 0 10.885 67.2a30.784 30.784 0 0 1-4.573-5.826A39.858 39.858 0 0 1 .005 39.857C.005 17.993 17.996.001 39.861.001c17.584 0 33.187 11.637 38.201 28.491 4.071 14.23 1.091 27.167-8.846 38.217-3.941 4.383-7.547 8.806-8.8 14.737a41.425 41.425 0 0 0-.563 8.506c-.008 2.65-.467 3.31-2.872 4.151"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: "2.6px",
        }}
        transform="rotate(-13 227.347 -201.428)"
      />
      <path
        d="M4.411 16.535C1.413 15.428 0 13.737 0 9.619V0"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: "2.6px",
        }}
        transform="rotate(-13 630.667 -356.67)"
      />
      <path
        d="M16.735 3.878 0 0"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: "2.68px",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        transform="rotate(-30 178.244 -20.961)"
      />
      <path
        d="M4.624 0 0 18.097"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: "2.68px",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        transform="rotate(-30 46.458 -110.85)"
      />
      <path
        d="m0 0 9.415 14.369"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: "2.68px",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        transform="rotate(-30 96.17 -23.026)"
      />
      <path
        d="M8.264 0 0 10.801"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: "2.68px",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        transform="rotate(-30 65.56 -191.682)"
      />
      <path
        d="M15.544 0 0 2.081"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: "2.68px",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        transform="rotate(-30 147.308 -239.794)"
      />
    </svg>
  );
};
export default KnowledgeSvg;
