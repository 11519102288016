import React from "react";
import PropTypes from "prop-types";
import ListContainerComponent from "../../components/ListContainerComponent";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FETCH_NEWS_DATA_REDUCER } from "../../reducers/NewsReducer";

const NewsPage = () => {
  const { t } = useTranslation();
  const { data, meta, postLoading, loading } = useSelector(
    (state) => state.NewsReducer
  );

  return (
    <ListContainerComponent
      data={data}
      meta={meta}
      loading={loading}
      fetchFun={FETCH_NEWS_DATA_REDUCER}
      postLoading={postLoading}
      title={t("news_page.title")}
    />
  );
};

NewsPage.propTypes = {
  name: PropTypes.string,
};
export default React.memo(NewsPage);
