import React, { useCallback, useRef, useState } from "react";
import {
  Box,
  Container,
  Modal,
  Typography,
  ClickAwayListener,
} from "@mui/material";
import PropTypes from "prop-types";
import ArticlesTextfieldComponent from "./form/ArticlesTextfieldComponent";
import { useTranslation } from "react-i18next";
import { BLUR_EFFECT } from "../constants/csses";
import LinkComponent from "./LinkComponent";
import { useDispatch, useSelector } from "react-redux";
import LoaderAnimationComponent from "./LoaderAnimationComponent";
import { FETCH_SEARCH_DATA_REDUCER } from "../reducers/SearchReducer";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  cont: {
    marginTop: "10vh",
  },
  modal: {
    "& > div:first-child ": {
      ...BLUR_EFFECT,
    },
    "& .active-text": {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
  },
  resultBox: {
    marginTop: theme.spacing(6),
  },
  resultText: {
    transition: "all 0.2s ease-in",
    color: theme.palette.text.white,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  resultBlock: {
    marginBottom: 40,
  },
  loader: {
    width: 200,
    margin: "auto",
  },
  topicBox: {
    marginBottom: theme.spacing(4),
  },
  linkBox: {
    marginTop: theme.spacing(2),
    width: "fit-content",
  },
  title: {
    color: theme.palette.text.white,
    fontSize: theme.typography.h6.fontSize,
  },
  itemTitle: {
    color: theme.palette.text.white,
    fontWeight: "bold",
    fontSize: theme.typography.body2.fontSize,
  },
  hiddenBlock: {
    display: "none",
  },
}));

const SearchComponent = ({ open, handleToggleSearch = () => {} }) => {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const resultRef = useRef();
  const { data, loading } = useSelector((state) => state.SearchReducer);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleChange = useCallback(
    (e) => {
      const v = e.target.value;
      setValue(v);
      if (v.length > 2) {
        dispatch(FETCH_SEARCH_DATA_REDUCER({ name: v }));
      }
    },
    [dispatch]
  );

  return (
    <Modal
      className={classes.modal}
      open={open}
      disableAutoFocus
      disableEnforceFocus
    >
      <Container className={classes.cont}>
        <ClickAwayListener onClickAway={handleToggleSearch}>
          <div>
            <ArticlesTextfieldComponent
              value={value}
              title={t("form.search")}
              onChange={handleChange}
            />
            <Box className={classes.resultBox} ref={resultRef}>
              {loading ? (
                <LoaderAnimationComponent className={classes.loader} />
              ) : data?.length && value?.length > 2 ? (
                data.map(({ title, to, items }, key) => (
                  <Box
                    key={key}
                    onClick={handleToggleSearch}
                    className={classes.topicBox}
                  >
                    <Typography className={classes.title}>{title}</Typography>
                    {items?.length
                      ? items.map(({ title, intro, slug }, key) => (
                          <Box
                            key={key}
                            onClick={handleToggleSearch}
                            className={classes.linkBox}
                          >
                            <LinkComponent title={title} to={`/${to}/${slug}`}>
                              <Typography className={classes.itemTitle}>
                                {title}
                              </Typography>
                              <Typography className={classes.resultText}>
                                {intro}
                              </Typography>
                            </LinkComponent>
                          </Box>
                        ))
                      : null}
                  </Box>
                ))
              ) : value?.length > 2 ? (
                <Typography variant="h3" className={classes.title}>
                  {t("empty_search")}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </div>
        </ClickAwayListener>
      </Container>
    </Modal>
  );
};

SearchComponent.propTypes = {
  handleToggleSearch: PropTypes.func,
  open: PropTypes.bool,
};
export default React.memo(SearchComponent);
