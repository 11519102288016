import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  skeleton: {
    height: theme.spacing(6),
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

const TopicItemSkelComponent = () => {
  const classes = useStyles();

  return <Skeleton className={classes.skeleton} variant="rect" />;
};

TopicItemSkelComponent.propTypes = {
  name: PropTypes.string,
};
export default React.memo(TopicItemSkelComponent);
