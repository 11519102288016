import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import LinkComponent from "./LinkComponent";
import { useMemo } from "react";
import { classNames } from "../functions";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  box: {
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    background: theme.palette.background.gray,
    marginBottom: theme.spacing(2),
    "&:hover": {
      paddingRight: 10,
      borderRightWidth: theme.spacing(2),
      borderRightStyle: "solid",
      borderRightColor: theme.palette.primary.main,
      "& *": {
        fontWeight: "bold",
      },
    },
  },
  activeCurrentItem: {
    paddingRight: 10,
    borderRightWidth: theme.spacing(2),
    borderRightStyle: "solid",
    borderRightColor: theme.palette.primary.main,
    "& *": {
      fontWeight: "bold",
    },
  },
}));

const TopicItemComponent = ({ title, color, to, slug, active }) => {
  const classes = useStyles();
  const { slug: pageSlug } = useParams();
  const currentItem = useMemo(
    () => active || slug === pageSlug,
    [active, slug, pageSlug]
  );

  return (
    <LinkComponent to={to} className={classes.link}>
      <Box
        style={{ borderColor: color }}
        className={classNames([
          classes.box,
          currentItem ? classes.activeCurrentItem : "",
        ])}
      >
        <Typography>{title}</Typography>
      </Box>
    </LinkComponent>
  );
};

TopicItemComponent.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  color: PropTypes.string,
  slug: PropTypes.string,
  active: PropTypes.bool,
};
export default React.memo(TopicItemComponent);
