import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NEWS_API } from "../constants/api";
import { GET } from "../functions/superagentSending";

export const FETCH_NEWS_DATA_REDUCER = createAsyncThunk(
  "NewsReducer/fetchNewsData",
  async ({ page }) => {
    const response = await GET(NEWS_API, { page });
    return response.body;
  }
);

export const NewsReducer = createSlice({
  name: "NewsReducer",
  initialState: {
    data: [],
    meta: {},
    postLoading: false,
    loading: false,
    name: "",
    error: {},
  },
  reducers: {},
  extraReducers: {
    [FETCH_NEWS_DATA_REDUCER.pending]: (state) => {
      const oldData = JSON.parse(JSON.stringify(state.data));
      if (oldData?.length) {
        state.postLoading = true;
      } else state.loading = true;
    },
    [FETCH_NEWS_DATA_REDUCER.fulfilled]: (state, { payload }) => {
      const oldData = JSON.parse(JSON.stringify(state.data));

      state.data = [
        ...(payload?.meta?.current_page > 1 && oldData?.length ? oldData : []),
        ...(payload?.data || []),
      ];
      state.meta = payload?.meta;
      state.name = payload?.name;
      state.loading = false;
      state.postLoading = false;
    },
    [FETCH_NEWS_DATA_REDUCER.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.error = {};
    },
  },
});

export default NewsReducer.reducer;
