import AudioSvg from "../svg/AudioSvg";
import BiggestTxtSvg from "../svg/BiggestTxtSvg";
import BigTxtSvg from "../svg/BigTxtSvg";
import ContrastSvg from "../svg/ContrastSvg";
import DefaultTxtSvg from "../svg/DefaultTxtSvg";
import DisablePSvg from "../svg/DisablePSvg";
import InfoSvg from "../svg/InfoSvg";
import KnowledgeSvg from "../svg/KnowledgeSvg";
import StoriesSvg from "../svg/StoriesSvg";
import { KNOWLEDGES_ROUTE, INFORMATIONS_ROUTE, STORIES_ROUTE } from "./routes";

// eslint-disable-next-line
export const API_URL = process.env.NODE_ENV === "production" ? "" : "";

export const ACCESS_TEXT = "accessText";
export const ACCESS_BIGGEST_TEXT = "accessBiggestText";
export const ACCESS_BIG_TEXT = "accessBigText";
export const ACCESS_CONTARST = "accessContrast";

export const RECAPTCHA_ACTION = "contact_form";
export const POLICY_LINK = "https://www.szczecin.eu/pl/polityka-prywatnosci";

export const PRIMARY_COLOR = "#BBEDF5";
export const LIGHT_PRIMARY_COLOR = "#A9D8EF";
export const DARK_PRIMARY_COLOR = "#99B9F8";

export const PRIMARY_COLOR_ID = "PRIMARY_COLOR";
export const LIGHT_PRIMARY_COLOR_ID = "LIGHT_PRIMARY_COLOR";
export const DARK_PRIMARY_COLOR_ID = "DARK_PRIMARY_COLOR";

export const KNWOLEDGES_DATA = {
  title: "knowledge_page.title",
  subtitle: "knowledge_page.subtitle",
  Icon: KnowledgeSvg,
  to: KNOWLEDGES_ROUTE,
  background: PRIMARY_COLOR,
  id: PRIMARY_COLOR_ID,
};
export const INFORMATIONS_DATA = {
  title: "information_page.title",
  Icon: InfoSvg,
  to: INFORMATIONS_ROUTE,
  background: LIGHT_PRIMARY_COLOR,
  id: LIGHT_PRIMARY_COLOR_ID,
};
export const STORIES_DATA = {
  title: "stories_page.box_title",
  Icon: StoriesSvg,
  to: STORIES_ROUTE,
  background: DARK_PRIMARY_COLOR,
  id: DARK_PRIMARY_COLOR_ID,
};
export const DISABLED_PEOPLE_FUNC = (t) => [
  {
    Icon: DefaultTxtSvg,
    title: t("app_bar.default_txt"),
    id: ACCESS_TEXT,
    value: "",
    small: true,
  },
  {
    Icon: BigTxtSvg,
    title: t("app_bar.big_txt"),
    id: ACCESS_TEXT,
    value: ACCESS_BIG_TEXT,
    small: true,
  },
  {
    Icon: BiggestTxtSvg,
    title: t("app_bar.biggest_txt"),
    id: ACCESS_TEXT,
    value: ACCESS_BIGGEST_TEXT,
    small: true,
  },
  {
    Icon: DisablePSvg,
    title: t("app_bar.audio"),
    to: "http://bip.um.szczecin.pl/chapter_11254.asp",
  },
  {
    Icon: ContrastSvg,
    title: t("app_bar.contrast"),
    id: ACCESS_CONTARST,
  },
  {
    Icon: AudioSvg,
    title: t("app_bar.disable"),
    to: "https://eurzad.szczecin.pl/chapter_51179.asp",
  },
];

export const MOBILE_DEVICE_WIDTH = "@media (max-width: 600px)";

export const FACEBOOK_LINK = "https://fb.com/zastepczydom";
