import { useTheme } from "@mui/material";
import * as React from "react";

const ContrastSvg = (props) => {
  const theme = useTheme();
  return (
    <svg
      viewBox="0 0 40 34"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
      {...props}
    >
      <path
        d="M36.056 5.235a8.824 8.824 0 0 1-.845 1.17C23.363 18.27 17.77 23.918 5.905 35.766a8.824 8.824 0 0 1-1.17.845h-.773l-.327-.17a1.655 1.655 0 0 1-.581-2.469c.111-.142.233-.276.363-.4C15.37 21.616 21.062 15.871 33.012 3.913a1.796 1.796 0 0 1 1.976-.587c.499.212.887.624 1.067 1.136l.001.773Z"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(-.177 -3.233)"
      />
      <path
        d="M216.509 177.312c-.268.415-.565.81-.889 1.183-2.306 2.323-4.6 4.658-6.961 6.926a16.88 16.88 0 0 1-8.686 4.407 17.16 17.16 0 0 1-6.825-.065 1.653 1.653 0 0 1-1.415-1.631 1.655 1.655 0 0 1 1.925-1.625c.965.15 1.937.25 2.913.3a14.042 14.042 0 0 0 10.2-4.146c1.888-1.848 3.741-3.732 5.609-5.6.036-.036.066-.077.117-.138-.1-.1-.189-.208-.286-.305-1.13-1.13-2.263-2.257-3.389-3.391a1.656 1.656 0 0 1-.564-1.244c0-.907.747-1.654 1.654-1.654.36 0 .711.118.998.335.141.112.274.234.4.363 1.432 1.429 2.872 2.851 4.286 4.3.331.383.637.788.915 1.212l-.002.773Z"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(-176.893 -160.36)"
      />
      <path
        d="M4.02 98.2c1.248 1.248 2.472 2.464 3.687 3.69.358.314.564.768.564 1.244 0 .908-.746 1.654-1.654 1.654-.362 0-.714-.119-1.002-.338a3.956 3.956 0 0 1-.373-.336A9682.249 9682.249 0 0 1 .7 99.58a1.712 1.712 0 0 1 .007-2.773c2.382-2.374 4.734-4.78 7.159-7.11a16.872 16.872 0 0 1 8.687-4.405 17.179 17.179 0 0 1 6.825.066c.8.123 1.397.819 1.397 1.629a1.656 1.656 0 0 1-1.915 1.626 28.292 28.292 0 0 0-2.875-.3 14.07 14.07 0 0 0-10.259 4.173c-1.867 1.832-3.7 3.7-5.553 5.547-.044.045-.084.094-.153.171"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(.005 -81.63)"
      />
      <path
        d="M149.117 157.058a8.295 8.295 0 0 1 6.59-7.913 9.012 9.012 0 0 1 1.571-.171l.078-.002c.908 0 1.654.747 1.654 1.654 0 .899-.733 1.642-1.632 1.654a4.955 4.955 0 0 0-4.834 3.952 5.911 5.911 0 0 0-.114.957v.039c0 .904-.744 1.649-1.649 1.649l-.071-.002a1.676 1.676 0 0 1-1.595-1.818"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(-137.557 -140.663)"
      />
      <path
        d="M236.561 244.119a1.916 1.916 0 0 1-1.462-.316 1.652 1.652 0 0 1-.574-1.827 1.63 1.63 0 0 1 1.533-1.143 4.962 4.962 0 0 0 4.868-3.96c.065-.33.103-.664.113-1a1.663 1.663 0 0 1 1.656-1.644c.908 0 1.656.747 1.656 1.656l-.001.056a8.165 8.165 0 0 1-4.53 7.294 7.67 7.67 0 0 1-3.259.881"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(-216.273 -219.317)"
      />
    </svg>
  );
};

export default ContrastSvg;
