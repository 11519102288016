import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import LinkComponent from "./LinkComponent";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MoreSvg from "../svg/MoreSvg";
import { MAIN_BLOCK } from "../constants/csses";
import "react-lazy-load-image-component/src/effects/blur.css";

const useStyles = makeStyles((theme) => ({
  imgBox: {
    position: "relative",
    ...MAIN_BLOCK(theme, true),
    "& span": {
      width: "100%",
    },
  },
  img: {
    ...MAIN_BLOCK(theme, true, 400),
    width: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      height: 300,
    },
  },
  icon: {
    width: 50,
    height: 50,
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    transform: "rotate(-45deg)",
    "& path": {
      fill: `${theme.palette.text.white} !important`,
    },
  },
  desc: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  line: {
    height: 2,
    width: "100%",
    border: "none",
    background: theme.palette.background.gray,
  },
  title: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

const ListItemComponent = ({ title, desc, to, src, color }) => {
  const classes = useStyles();

  return (
    <LinkComponent to={to} title={title}>
      <Box>
        <Typography variant="h3" style={{ color }} className={classes.title}>
          {title}
        </Typography>
        <Box className={classes.imgBox}>
          <LazyLoadImage
            src={src}
            alt={title}
            className={classes.img}
            effect="blur"
          />
          <MoreSvg className={classes.icon} />
        </Box>
        <Typography
          className={classes.desc}
          dangerouslySetInnerHTML={{ __html: desc }}
        />
        <hr className={classes.line} />
      </Box>
    </LinkComponent>
  );
};

ListItemComponent.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  to: PropTypes.string,
  src: PropTypes.string,
  color: PropTypes.string,
};
export default React.memo(ListItemComponent);
