import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HeaderComponent from "./HeaderComponent";
import loadable from "@loadable/component";
import { useDispatch } from "react-redux";
import { useGetMainQuery } from "../services";
// import { FETCH_MENU_REDUCER } from '../reducers/MainReducer';

const FooterComponent = loadable(() => import("./FooterComponent"));

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(8),
  },
}));

const MainContainerComponent = ({ hiddenSpacing, children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data } = useGetMainQuery();

  useEffect(() => {
    if (!data?.menu) {
      // dispatch(FETCH_MENU_REDUCER());
    }
  }, [dispatch, data]);
  return (
    <section className={classes.section}>
      <HeaderComponent />
      <main className={hiddenSpacing ? "" : classes.main}>{children}</main>
      <FooterComponent />
    </section>
  );
};

MainContainerComponent.propTypes = {
  children: PropTypes.any,
  hiddenSpacing: PropTypes.bool,
};
export default React.memo(MainContainerComponent);
