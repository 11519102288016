import React, { useCallback } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import ListItemComponent from "./ListItemComponent";
import MainContainerComponent from "./MainContainerComponent";
import { Container } from "@mui/material";
import BreadcrumbsComponent from "./BreadcrumbsComponent";
import ListItemSkelComponent from "./skeletons/ListItemSkelComponent";
import ScrollComponent from "./ScrollComponent";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({}));

const ListContainerComponent = ({
  title,
  data = [],
  meta = {},
  loading,
  fetchFun = () => {},
  postLoading,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFun({ page: 1 }));
  }, []);
  const handlePagination = useCallback(
    (page) => {
      dispatch(fetchFun({ page }));
    },
    [dispatch]
  );

  return (
    <MainContainerComponent>
      <Container className={classes.section}>
        <BreadcrumbsComponent
          links={[
            {
              title,
            },
          ]}
        />
        <ScrollComponent
          length={data?.length}
          next={handlePagination}
          loading={postLoading}
          meta={meta}
        >
          {loading ? (
            <>
              <ListItemSkelComponent />
              <ListItemSkelComponent />
              <ListItemSkelComponent />
              <ListItemSkelComponent />
            </>
          ) : data?.length ? (
            data.map((item, key) => (
              <ListItemComponent
                key={key}
                title={item?.title}
                desc={item?.intro}
                src={item?.src}
                to={item?.slug}
              />
            ))
          ) : null}
        </ScrollComponent>
      </Container>
    </MainContainerComponent>
  );
};

ListContainerComponent.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  meta: PropTypes.object,
  loading: PropTypes.bool,
  postLoading: PropTypes.bool,
  setCurrentPage: PropTypes.func,
  fetchFun: PropTypes.func,
};
export default React.memo(ListContainerComponent);
