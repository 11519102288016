import { createTheme } from "@mui/material/styles";
import {
  PRIMARY_COLOR_ID,
  LIGHT_PRIMARY_COLOR_ID,
  DARK_PRIMARY_COLOR_ID,
} from "../constants";

export const newTheme = () => {
  const theme = createTheme({
    palette: {
      text: {
        primary: "#ffff00",
        secondary: "#ffff00",
        white: "#ffff00",
        [PRIMARY_COLOR_ID]: "#ffff00",
        [LIGHT_PRIMARY_COLOR_ID]: "#ffff00",
        [DARK_PRIMARY_COLOR_ID]: "#ffff00",
      },
      primary: {
        main: "#ffff00",
        dark: "#ffff00",
        light: "#ffff00",
      },
      background: {
        primary: "#161615",
        default: "#000000",
        gray: "#161615",
        darkGray: "#161615",
        moreIcon: "#ffff00",
        [PRIMARY_COLOR_ID]: "#161615",
        [LIGHT_PRIMARY_COLOR_ID]: "#161615",
        [DARK_PRIMARY_COLOR_ID]: "#161615",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 50,
            padding: "8px 24px",
          },
          outlinedPrimary: {
            color: "#ffff00",
          },
        },
      },
    },
  });
  return {
    ...theme,
  };
};

const ContrastTheme = newTheme();
export default ContrastTheme;
