import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from "@reduxjs/toolkit/query";
import { serverApi } from "./services";
import MainReducer from "./reducers/MainReducer";
import StoriesReducer from "./reducers/StoriesReducer";
import NewsReducer from "./reducers/NewsReducer";
import SearchReducer from "./reducers/SearchReducer";

export const store = configureStore({
  reducer: {
    NewsReducer,
    StoriesReducer,
    MainReducer,
    SearchReducer,
    // Add the generated reducer as a specific top-level slice
    [serverApi.reducerPath]: serverApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(serverApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
