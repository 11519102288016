import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import Root from "./Root";
import mainTheme from "./themes/MainTheme";
import textTheme from "./themes/TextTheme";
import textBiggestTheme from "./themes/TextBiggestTheme";
import contrastTheme from "./themes/ContrastTheme";
import moment from "moment";
import "moment/locale/pl";
import { CookiesProvider } from "react-cookie";
import { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useSelector } from "react-redux";
import {
  ACCESS_BIGGEST_TEXT,
  ACCESS_BIG_TEXT,
  ACCESS_CONTARST,
  ACCESS_TEXT,
} from "./constants";
import AOS from "aos";
import "aos/dist/aos.css";
function App() {
  const mainData = useSelector((state) => state.MainReducer.data);
  useEffect(() => {
    AOS.init({});
    // eslint-disable-next-line
    moment.locale('pl');
  }, []);

  return (
    <CookiesProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider
          theme={{
            ...mainTheme,
            typography: {
              ...mainTheme.typography,
              ...(mainData[ACCESS_TEXT] === ACCESS_BIG_TEXT ? textTheme : {}),
              ...(mainData[ACCESS_TEXT] === ACCESS_BIGGEST_TEXT
                ? textBiggestTheme
                : {}),
            },
            palette: {
              ...mainTheme.palette,
              ...(mainData[ACCESS_CONTARST] ? contrastTheme.palette : {}),
            },
            components: {
              ...mainTheme.components,
              ...(mainData[ACCESS_CONTARST] ? contrastTheme.components : {}),
            },
          }}
        >
          <CssBaseline />
          <GoogleReCaptchaProvider
            // eslint-disable-next-line
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA}
          >
            <Root />
          </GoogleReCaptchaProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </CookiesProvider>
  );
}

export default App;
