import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import LoaderAnimationComponent from "./LoaderAnimationComponent";

const useStyles = makeStyles(() => ({
  cont: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  loaderBox: {
    width: 150,
    heihgt: 150,
  },
}));

const SmallLoaderComponent = ({ show }) => {
  const classes = useStyles();

  return (
    <>
      {show ? (
        <section className={classes.cont}>
          <div className={classes.loaderBox}>
            <LoaderAnimationComponent />
          </div>
        </section>
      ) : null}{" "}
    </>
  );
};

SmallLoaderComponent.propTypes = {
  show: PropTypes.bool,
};
export default React.memo(SmallLoaderComponent);
