import * as React from "react";

const WantChildSvg = (props) => (
  <svg
    viewBox="0 0 397 349"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    }}
    {...props}
  >
    <path
      d="M382.6 250.9c0 26-21.838 47.079-48.775 47.079-26.937 0-48.777-21.079-48.777-47.079s21.838-47.079 48.776-47.079c26.938 0 48.776 21.079 48.776 47.079"
      style={{
        fill: "#1c4489",
        fillRule: "nonzero",
      }}
      transform="translate(-167.736 -91.704)"
    />
    <path
      d="M309.526 97.372s.4-4.655 1.462-5.187c1.062-.532 4.389-2.66 3.857-7.846s-2.793-6.384-5.718-7.847.8-6.65-1.6-9.974c-2.4-3.324-4.518-4.788-8.641-4.518-4.123.27-7.182-.931-9.177-3.724a13.287 13.287 0 0 0-12.634-5.453c-6.117.931-6.916 3.857-10.639 6.65-3.723 2.793-6.65 3.857-12.5 3.59-5.85-.267-11.3-1.33-16.358 4.123s-4.123 7.847-7.846 11.837c-3.723 3.99-8.379 4.389-12.634 2.66-4.255-1.729-11.7-7.447-20.082-2.926-8.382 4.521-8.91 12.368-9.442 16.225-.532 3.857-4.123 9.442-10.374 9.709-6.251.267-12.9-2.128-18.885 2.127-5.985 4.255-8.515 10.37-7.315 18.082 1.2 7.712 3.856 12.235-.266 16.358-4.122 4.123-11.7 1.995-16.226 6.915s-6.782 9.177-3.724 16.624c3.058 7.447 11.571 5.852 15.162 9.975 3.591 4.123 3.99 10.506.4 15.826-3.59 5.32-4.522 14.5-.665 18.885 3.857 4.385 12.634 4.522 16.89 5.453a7.9 7.9 0 0 1 5.796 7.6 7.885 7.885 0 0 1-.609 3.039c-1.995 4.389-2.261 11.57 1.33 15.693 3.591 4.123 16.358 4.787 22.742 2.394s84.816-103.2 91.7-110.983c6.884-7.783 26-35.31 26-35.31"
      style={{
        fill: "#ffd585",
        fillRule: "nonzero",
      }}
      transform="translate(-98.786 -24.754)"
    />
    <path
      d="M426.43 80.532a1.337 1.337 0 0 0-1.154-.311c-.226.014-.45.119-.674.116a4.968 4.968 0 0 1-.777-.123c-.19.53-.129 1.12.165 1.6h3.088c.137-.606-.3-.915-.648-1.278"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-229.16 -36.953)"
    />
    <path
      d="M413.762 80.384c-.969-.3-1.67.324-1.654 1.47h3.092c-.479-1.092-.6-1.212-1.434-1.47"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-224.015 -36.997)"
    />
    <path
      d="M365.308 80.388c-.321.157-.653.325-.994.481a.993.993 0 0 0-.59 1.021h3.089c-.042-1.143-.042-1.143-1.5-1.5"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-202.585 -37.032)"
    />
    <path
      d="M402.73 81.031a1.152 1.152 0 0 0-.895-.314 2.934 2.934 0 0 0-1.39.152c-.143.38-.288.771-.433 1.161h3.088a1.045 1.045 0 0 0-.37-1"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-218.658 -37.172)"
    />
    <path
      d="M390.273 79.877a2.158 2.158 0 0 0-1.6.448c-.3.306-.2.7-.227 1.06-.008.091-.014.185-.021.277h2.807c-.891-.279-.774-1.143-.954-1.785"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-213.523 -36.805)"
    />
    <path
      d="M378.341 80.859a.962.962 0 0 0-.934-.452c-.487.064-.963.206-1.433.311-.074.426.315.861-.154 1.175h2.807a1.315 1.315 0 0 0-.286-1.034"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-207.942 -37.035)"
    />
    <path
      d="m354.428 80.97-.928-.609-1.269.29c-.358.37-.572.748-.233 1.226h2.246a.714.714 0 0 0 .184-.907"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-197.316 -37.02)"
    />
    <path
      d="M448.7 81.85h1.01a6.568 6.568 0 0 0-.161-1.55c-.38.308-.722.667-1.095 1.015l.246.535Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-240.115 -36.992)"
    />
    <path
      d="M438.583 81.185c-.547-.2-1.067-.633-1.709-.379a1.147 1.147 0 0 0-.409.355c-.235.284-.449.585-.672.878h3.089c.037-.335.049-.729-.3-.855"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-234.506 -37.181)"
    />
    <path
      d="M320.2 82.523c-.08.1-.16.209-.236.311h.233a1.47 1.47 0 0 0 0-.311"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-183.201 -37.977)"
    />
    <path
      d="M341.517 80.936a1.483 1.483 0 0 0-2.02.608c-.144.18-.31.341-.468.511h3.089a.947.947 0 0 0-.6-1.119"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-191.646 -37.197)"
    />
    <path
      d="M329.875 80.425a.63.63 0 0 0-.779-.034 1.445 1.445 0 0 0-.651 1.447h2.246a2.044 2.044 0 0 0-.816-1.413"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.956 -36.98)"
    />
    <path
      d="M407.092 54.587c.122.078.254.139.392.18-.129-.062-.26-.123-.392-.18"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-221.794 -25.602)"
    />
    <path
      d="M407.133 65.041c.406-.234.79-.5 1.208-.689a.902.902 0 0 0 .545-1.239c-.321-.271-.625-.556-.958-.8a1.162 1.162 0 0 0-1.769.348c-.182.266-.352.538-.561.858.548.486.726 1.306 1.534 1.525"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-221.132 -28.904)"
    />
    <path
      d="M402.382 68.3a2.125 2.125 0 0 0-1.351-.155c-.452.057-.7.4-.95.843.113.512.177.969.317 1.4a.944.944 0 0 0 1.52.533 2.055 2.055 0 0 0 1.009-1.4.927.927 0 0 0-.545-1.22"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-218.688 -31.592)"
    />
    <path
      d="M419.293 76.93c.409.102.843.033 1.2-.191.307-.198.626-.378.954-.539v-1.544a3.037 3.037 0 0 0-2.224-.417 2.312 2.312 0 0 0-.492 2.149.611.611 0 0 0 .56.543"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-226.927 -34.278)"
    />
    <path
      d="M388.217 70.466c.099.095.215.168.343.217.495.165 1.042.04 1.417-.323a2.21 2.21 0 0 0 .741-1.208 3.894 3.894 0 0 0-1.868-1.45c-.167.109-.321.159-.389.264-.272.413-.961.441-.986 1.083-.023.564.649.765.662 1.307a.184.184 0 0 0 .081.11"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-213.104 -31.413)"
    />
    <path
      d="M352.131 72.174c.247.126.5.238.87.411.325-.176.616-.313.887-.483a1.11 1.11 0 0 0 .479-.778c-.65.009-1.337 0-2.071-.036-.282-.013-.562-.028-.841-.043-.046.436.163.669.676.93"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-197.148 -32.98)"
    />
    <path
      d="M384.289 74.234a.915.915 0 0 0-1.6-.2 1.666 1.666 0 0 0-.345 1.81c.2.622.6.866 1.4.885.18-.014.358-.04.534-.078l.755-1.18c-.163-.235-.306-.419-.426-.618s-.2-.42-.323-.62"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-210.779 -34.047)"
    />
    <path
      d="M418.566 65.5c.31.224.635.427.973.606.123-.041.242-.09.358-.148l.267-.178a8.358 8.358 0 0 1-1.838-1.52.93.93 0 0 0 .241 1.24"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-226.709 -29.888)"
    />
    <path
      d="M414.115 70.694c.121-.193.268-.368.438-.519a1.038 1.038 0 0 0-.221-1.78 1.444 1.444 0 0 0-1.8.2c-.129.365-.265.749-.41 1.164l.669 1.359c.658.171 1.057.079 1.321-.423"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-224.023 -31.617)"
    />
    <path
      d="M395.828 64.563c.384.018.7-.274.861-.756a1.121 1.121 0 0 0-.21-1.278 2.254 2.254 0 0 0-2.557.051l-.334.969c.086.154.129.235.175.313.486.814 1.334.667 2.064.7"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-215.812 -28.956)"
    />
    <path
      d="M401.172 59.023c.352.004.705-.013 1.055-.052.621-.953.621-.953.635-1.439.018-.677-.218-.978-.875-1a3.044 3.044 0 0 0-1.6.172c-.136.318-.238.65-.307.989a1.134 1.134 0 0 0 1.095 1.332"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-218.682 -26.45)"
    />
    <path
      d="M431.071 76.86a3.897 3.897 0 0 0 1.31-.377c.417-.248.672-.7.668-1.185-.236-.224-.466-.45-.7-.667a1.224 1.224 0 0 0-1.925.079c-.22.288-.423.588-.607.9.199.332.421.649.663.951a.85.85 0 0 0 .595.3"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-231.858 -34.29)"
    />
    <path
      d="M424.3 69.511c.182.488.345.964.539 1.428.11.19.24.367.387.53.362-.109.621-.21.888-.26a1.05 1.05 0 0 0 .712-.595 1.47 1.47 0 0 0 .023-1.549 11.14 11.14 0 0 1-1.679-.316 1.86 1.86 0 0 0-.871.761"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-229.418 -31.877)"
    />
    <path
      d="M407.31 74.179c-.429.101-.851.23-1.263.385-.113.172-.21.354-.29.544a1.454 1.454 0 0 0 1.028 1.8.695.695 0 0 0 .606-.19c.294-.508.826-.7 1.314-1.05-.114-.35-.178-.57-.257-.784-.226-.613-.507-.814-1.138-.7"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-221.176 -34.268)"
    />
    <path
      d="M412.663 59.293c.452 0 .866.027 1.275-.012.015 0 .031-.006.047-.008a14.401 14.401 0 0 0-1.685-1.543 2.12 2.12 0 0 0 .363 1.562"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-224.079 -26.995)"
    />
    <path
      d="m381.993 64.284.948.663c.421-.089.78-.172 1.143-.241a.667.667 0 0 0 .607-.656 4.232 4.232 0 0 0-.032-.974.717.717 0 0 0-.3-.44 2.14 2.14 0 0 0-2.008-.03c-.6.247-.423.86-.426 1.359.014.107.038.213.071.316"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-210.635 -29.051)"
    />
    <path
      d="M395.449 53.967c.139.007.279.004.418-.009.368-.037.734-.3.612-.593a.63.63 0 0 1 .132-.692 12.946 12.946 0 0 0-2.208.148l-.134.022.531 1.057c.282.027.466.054.65.064"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-216.114 -24.754)"
    />
    <path
      d="M436.91 70.861c.195.321.524.537.895.589.4.146.848.076 1.184-.185.313-.3.009-.6 0-.9a3.88 3.88 0 0 0-.042-.457 8.087 8.087 0 0 0-2.279-.485c-.084.243-.141.5-.218.77.151.215.328.431.463.67"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-234.796 -32.173)"
    />
    <path
      d="M377.535 71.285c.627-.212.875-.8 1.3-1.216.165-.162.013-.61-.24-.72-.628-.27-1.272-.5-1.887-.746l-.941 1.331c.197.373.415.735.653 1.083a.852.852 0 0 0 1.116.267"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-207.918 -31.812)"
    />
    <path
      d="M395.776 74.889a.953.953 0 0 0-1.365.154c-.406.389-.414.569-.065 1.2.088.16.176.32.252.486.363.787.45.828 1.237.579.175-.056.36-.089.531-.158a.725.725 0 0 0 .477-.763c.016-.763-.524-1.138-1.067-1.5"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-216.037 -34.498)"
    />
    <path
      d="m334.6 77.4 1.135-.732c-.014-.457-.247-.846.04-1.254.1-.141.016-.411.016-.584-1.3-.533-1.944.333-2.676.983.266.704.8 1.275 1.485 1.587"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-189.026 -34.495)"
    />
    <path
      d="M346.06 76.769a.69.69 0 0 0 .281.288 1.489 1.489 0 0 0 1.535-.235c.489-.296.71-.897.529-1.439a4.98 4.98 0 0 0-.445-.751c-.519.108-1 .2-1.485.31-.739.176-.87.434-.629 1.179.058.22.13.436.214.648"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.617 -34.482)"
    />
    <path
      d="M374.028 65.324c-.33.241-.655.468-.977.682.371-.086.707-.282.964-.563a.594.594 0 0 0 .013-.119"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-206.716 -30.358)"
    />
    <path
      d="M366.461 71.129c.147-.308.274-.625.382-.949a.712.712 0 0 0-.082-.522 1.868 1.868 0 0 0-.366-.309c-.611.252-1.239.46-1.88.621l.825 1.212 1.122-.052"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-202.935 -32.142)"
    />
    <path
      d="M370.993 77.109c.65.2 1.051-.169 1.022-.86-.008-.179-.042-.36-.037-.538.022-.871-.03-.948-.829-1.083a5.894 5.894 0 0 0-.637-.046c-.134.484-.187.9-.729.971-.182.025-.331.273-.576.489a3.588 3.588 0 0 0 1.787 1.067"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-205.013 -34.46)"
    />
    <path
      d="M340.532 71.772c.744.125 1.495.245 2.019-.677a8.912 8.912 0 0 1-.063-.228 26.155 26.155 0 0 0-2.429.037c-.004.26.082.513.244.716.061.071.14.123.229.152"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-192.103 -32.809)"
    />
    <path
      d="M444.127 76.766c.305-.133.618-.25.935-.351l-.261-1.644a2.33 2.33 0 0 0-2.031.3.876.876 0 0 0-.156 1.326c.433.336.9.675 1.514.371"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-237.421 -34.495)"
    />
    <path
      d="M324.406 77.8c.322-.199.511-.559.49-.937-.012-.116-.015-.241-.02-.365-.452.389-.882.804-1.288 1.242l-.022.024c.181.087.372.152.569.192.094-.045.185-.097.271-.155"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-184.797 -35.306)"
    />
    <path
      d="m389.921 58.665.917-1.016c-.3-1.1-.3-1.1-1.124-1.589-.288.1-.6.193-.9.3a1.376 1.376 0 0 0-.241.152 1.686 1.686 0 0 0 .277 1.693c.25.408.707.374 1.071.457"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-213.532 -26.255)"
    />
    <path
      d="M358.6 77.213a.973.973 0 0 0 1.159.058.83.83 0 0 0 .253-.885 14.83 14.83 0 0 0-.34-1.327 2.588 2.588 0 0 0-.256-.482l-1.529.74c-.005.337.033.673.114 1 .138.337.342.643.6.9"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-199.998 -34.458)"
    />
    <path
      d="m327.736 83.082-.26 1.609c.55.172 1.133.209 1.7.11.2-.19.414-.365.641-.521.49-.285.5-.725.448-1.2l-2.529.002Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.529 -38.224)"
    />
    <path
      d="M423.7 83.082c.1.828.695 1.517 1.5 1.735.288-.158.584-.301.887-.427a1.038 1.038 0 0 0 .7-1.308H423.7Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-229.149 -38.224)"
    />
    <path
      d="M351.627 83.082a1.01 1.01 0 0 0 .5.949c.635.474 1.455.63 2.22.421.108-.464.04-.953-.191-1.37h-2.529Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-197.226 -38.224)"
    />
    <path
      d="M376.958 84.387a1.023 1.023 0 0 0 1.687-.892 4.043 4.043 0 0 0-.018-.412h-2.807a2.19 2.19 0 0 0 1.138 1.3"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-207.942 -38.224)"
    />
    <path
      d="M436.21 83.922c.208.02.414.057.616.112.214.084.417.192.607.322.5.331.836.189 1.222-.219.231-.299.315-.687.229-1.055h-3.089l.415.84Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-234.507 -38.224)"
    />
    <path
      d="M412.119 83.218c-.06.515.252 1.005.743 1.17a1.01 1.01 0 0 0 1.245-.192c.167-.15.4-.228.576-.367a.746.746 0 0 0 .232-.747h-2.807c.008.045.011.09.011.136"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-224.016 -38.224)"
    />
    <path
      d="M339.84 83.351a.579.579 0 0 0 .051.759c.232.237.464.5.827.423.568-.115 1.128-.272 1.834-.448a5.44 5.44 0 0 0-.448-.6 4.823 4.823 0 0 0-.523-.4H339.9a.995.995 0 0 1-.058.269"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-191.954 -38.224)"
    />
    <path
      d="M364.656 84.5a3.544 3.544 0 0 0 1.964-.8c.226-.163.2-.388.193-.616h-3.089l.932 1.416Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-202.585 -38.224)"
    />
    <path
      d="M387.413 83.082c.368.463.764.904 1.187 1.318a.907.907 0 0 0 .79.111c.611-.258 1.149-.64 1.108-1.432l-3.085.003Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-213.077 -38.224)"
    />
    <path
      d="M449.785 84.7c.072-.537.112-1.078.121-1.62H448.9c.039.709.131 1.38.889 1.62"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-240.311 -38.224)"
    />
    <path
      d="M400.542 84.118c.245.058.497.085.749.082.754-.115 1.559-.177 1.81-1.119h-3.088c.181.358.364.715.529 1.036"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-218.658 -38.224)"
    />
    <path
      d="M319.07 83.656c-.216.3-.413.595-.6.879l.072.021.528-.9Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-182.542 -38.479)"
    />
    <path
      d="M176.989 162.2c.279.02.56 0 .838.012 1.195.074 1.565.421 1.585 1.613a1.447 1.447 0 0 0 .941 1.476 1.816 1.816 0 0 1 1.534-.338l.031.001a.574.574 0 0 0 .569-.634 1.638 1.638 0 0 0-.215-.775 9.373 9.373 0 0 0-.8-.967c-1.039-.3-2.2-.384-2.849-1.408.085-.564.2-1.059.222-1.558a.677.677 0 0 0-.665-.751c-.365-.033-.736-.007-1.072-.007-.315.302-.612.623-.89.959a2.335 2.335 0 0 0-.413.866 1.199 1.199 0 0 0 1.15 1.511l.04-.001"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-119.33 -71.787)"
    />
    <path
      d="M176.639 318.187a3.254 3.254 0 0 0-.236-.508.872.872 0 0 0-1.383-.385c-.528.287-1.053.577-1.6.878-.02.055-.086.147-.076.226.1.881-.412 1.271-1.156 1.483-.179.052-.356.112-.536.158-1.152.3-1.508.762-1.411 1.837a1.158 1.158 0 0 0 1.068 1.1 2.246 2.246 0 0 0 1.97-1.463 1.715 1.715 0 0 1 1.147-1.439 6.625 6.625 0 0 0 2.236-1.583 1.12 1.12 0 0 0-.019-.3"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-116.876 -141.879)"
    />
    <path
      d="M197.669 316.328c.414.126.832.408 1.083.3a2.791 2.791 0 0 0 1.719-1.4c-.578-.492-1.077-1.059-1.888-1.048-.6.007-.914-.4-1.261-.768a1.057 1.057 0 0 1-.155-1.05 1.89 1.89 0 0 0-.033-.662 1.244 1.244 0 0 0-1.039-1.154 1.51 1.51 0 0 0-1.631.785c-.136.236-.239.491-.368.759a3.473 3.473 0 0 0 2.266 1.549c.388.094.761.243 1.108.441.166.36.291.737.373 1.125a4.663 4.663 0 0 1-.17 1.12"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-127.449 -138.964)"
    />
    <path
      d="M173.093 167.379c.016-.285.014-.57-.006-.855a1.714 1.714 0 0 0-1.706-1.681c-.193 0-.384.033-.566.097a7.37 7.37 0 0 0-.982.394c-.339 1.078.213 1.859.692 2.746a2.152 2.152 0 0 0 2.567-.7"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-116.658 -74.447)"
    />
    <path
      d="M231.054 349.2c1.6.58 1.6.58 2.646-.125l-.525-2.319c-1.141-.7-1.437-.683-2.332.217-.224.225-.408.487-.6.719.207.434.38.812.569 1.183.07.116.151.225.242.325"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.461 -154.796)"
    />
    <path
      d="M159.663 301.415c.138.011.276-.006.407-.049a2.195 2.195 0 0 0 1.139-1.718 3.029 3.029 0 0 0-.112-.333 2.103 2.103 0 0 0-1.233-1.028c-.208.373-.433.742-.679 1.106-.294.439-.565.893-.813 1.359.327.305.724.524 1.156.637a.37.37 0 0 0 .136.026"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-111.627 -133.546)"
    />
    <path
      d="M275.538 317.235c-.294-.288-.59-.574-.842-.818a1.392 1.392 0 0 0-1.56 1.14 1.78 1.78 0 0 0-.16 1.031c.036.229.036.461 0 .69-.05.87-.009.909.753.678.177-.053.359-.087.536-.139a2.636 2.636 0 0 0 1.475-1.556.865.865 0 0 0-.2-1.026"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.374 -141.576)"
    />
    <path
      d="M322.9 279.885a.822.822 0 0 0-.756.12c-.448.433-.871.89-1.267 1.371.156.357.34.701.549 1.029.308.413.775.68 1.287.737.419.055.638-.314.918-.545.176-.146.366-.276.686-.515-.064-.4-.013-.834-.2-1.092a4.711 4.711 0 0 0-1.213-1.1"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-183.605 -125.372)"
    />
    <path
      d="M421.411 111.082a2.307 2.307 0 0 0-1.427-.623 1.617 1.617 0 0 0-1.289.613c0 .408.022.876 0 1.34a1.234 1.234 0 0 0 .774 1.333c.829-.05 1.58-.51 2-1.226a5.99 5.99 0 0 0 .035-.642c-.011-.274-.056-.548-.084-.795"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-226.93 -50.35)"
    />
    <path
      d="M237.519 355.5a.972.972 0 0 0 1.526-.595c.082-.262.1-.542.164-.811a1.58 1.58 0 0 0-1.364-1.717 1.683 1.683 0 0 0-1.569 1.763c.054.213.148.414.276.592.283.302.609.562.967.769"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.134 -157.501)"
    />
    <path
      d="M413.23 119.757a1.027 1.027 0 0 0 1.544-.373 2.354 2.354 0 0 0 .386-1.719 2.996 2.996 0 0 0-2.013-.967c-1.392 1.035-1.112 1.3-.6 2.559.219.179.448.346.685.5"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-224.024 -53.114)"
    />
    <path
      d="M443.5 110.493c-.645.129-.855.8-1.114 1.437.449 1.029.767 1.4 1.365 1.507a1.715 1.715 0 0 0 1.388-.585 6.537 6.537 0 0 1 .079-1.1c.111-.351.253-.692.423-1.019a2.35 2.35 0 0 0-2.141-.239"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-237.425 -50.303)"
    />
    <path
      d="M299.179 279.788c-1.436.015-1.519-.213-2.189 1.637.342.42.721.808 1.132 1.16a.742.742 0 0 0 1.21-.08c.308-.338.583-.709.9-1.1-.1-.5-.2-1.031-.306-1.616h-.749"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.026 -125.352)"
    />
    <path
      d="M390.237 201.474c-.29-.128-.6-.2-.934-.31a5.974 5.974 0 0 0-1.685.953c.079 1.291.543 2.127 1.891 2.285.52-.427.947-.954 1.257-1.551a1.059 1.059 0 0 0-.53-1.377"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-213.169 -90.526)"
    />
    <path
      d="M190.174 174.026c.528-.051.738-.248.784-.775.016-.184.009-.37.01-.555.008-1.462-.3-1.753-1.807-1.716a1.147 1.147 0 0 0-.294.105l-.983 1.624c.252.274.487.5.679.751a1.625 1.625 0 0 0 1.61.565"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-124.699 -77.155)"
    />
    <path
      d="M191.276 342.774c-.116-1.754-.87-2.217-3.014-1.844l-.21 1.437.095.113c.14.028.277.056.409.085a6.59 6.59 0 0 1 2.006.813c.277-.149.52-.353.714-.6"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-124.774 -152.38)"
    />
    <path
      d="M213.967 270.6c.337-.345.713.065 1.056-.077a1.66 1.66 0 0 0 .059-1.936c-.295-.6-.862-.433-1.355-.479-.274-.024-.546-.056-.859-.089a5.854 5.854 0 0 0-.811 1.81l.765 1.012c.395.154.845.06 1.145-.24"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.406 -120.14)"
    />
    <path
      d="M408.218 98.657a1.186 1.186 0 0 0-1.413-.2 1.237 1.237 0 0 0-.729 1.089 2.532 2.532 0 0 0 .809 1.865h.969c.329-.016.632-.186.816-.459.674-.817.641-1.221-.153-2-.1-.1-.2-.193-.3-.291"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-221.34 -44.97)"
    />
    <path
      d="M420.2 135c-.477-.127-.968-.206-1.517-.316l-1.067 2.267 1.635 1c.199-.081.389-.18.57-.295.251-.2.477-.434.715-.653.743-.681.5-.95.094-1.686a.76.76 0 0 0-.43-.317"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-226.453 -61.079)"
    />
    <path
      d="M256.76 334.554a4.416 4.416 0 0 1-.608-.179c-.986.15-1.239.963-1.677 1.6.065.181.142.358.231.529a1.47 1.47 0 0 0 2.239.609c.468-.254.937-.946.823-1.354-.149-.534-.318-1.094-1.008-1.206"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.195 -149.53)"
    />
    <path
      d="M309.744 208.155a1.204 1.204 0 0 0-.334 1.508c.216.581.761.978 1.38 1.006a.98.98 0 0 0 1.1-.646c.152-.43.316-.857.5-1.364-1.134-1.055-1.134-1.055-1.863-1.245-.246.236-.5.505-.782.741"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.467 -93.295)"
    />
    <path
      d="M244.846 334.458a.637.637 0 0 0-.884-.014c-.217.174-.4.391-.61.573a1.26 1.26 0 0 0-.334 1.614c.192.487.423.847.911.914a2.15 2.15 0 0 0 1.533-.5.588.588 0 0 0 .158-.372c.015-.2.018-.4.007-.6.173-.709-.247-1.188-.782-1.609"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.053 -149.484)"
    />
    <path
      d="M171.733 334.189c-.367.421-.694.75-.964 1.121-.19.265-.337.557-.437.867a.573.573 0 0 0 .1.507c.377.34.804.619 1.266.829.754.279 1.184-.031 1.387-.841.083-.215.152-.436.206-.66a.951.951 0 0 0-.1-.655 2.986 2.986 0 0 0-1.46-1.169"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-116.916 -149.448)"
    />
    <path
      d="m322.469 247.042 1.539-.794c0-.494.029-.867-.007-1.234a1.167 1.167 0 0 0-1.022-.99 1.514 1.514 0 0 0-1.478.594c-.21.3-.4.612-.567.877.071.815.72 1.47 1.535 1.546"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-183.631 -109.506)"
    />
    <path
      d="M396.024 195.819a2.649 2.649 0 0 0-.8-.185c-.909-.048-1.177.2-1.206 1.1-.008.231.021.464.035.74.4.472.862.888 1.374 1.236a11.13 11.13 0 0 0 1.092-.561c.191-.125.336-.31.412-.526a1.568 1.568 0 0 0-.906-1.8"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-216.001 -88.075)"
    />
    <path
      d="M195.869 180.459c.476-.288 1.121-.379 1.412-.967-.194-.512-.352-1-.564-1.46-.089-.192-.277-.443-.454-.475-.829-.149-1.693-.306-2.277.752.418.907.688 1.9 1.883 2.15"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-127.402 -80.023)"
    />
    <path
      d="M167.546 211.2c.264-.181.517-.377.758-.587l-.188-.877a8.5 8.5 0 0 1-.929 1.094 7.336 7.336 0 0 1-.656.577 1.41 1.41 0 0 0 1.014-.207"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-115.242 -94.323)"
    />
    <path
      d="M425.6 131.957a5.42 5.42 0 0 0 .627-.555 2.806 2.806 0 0 0 .651-2.056.709.709 0 0 0-.583-.683 2.207 2.207 0 0 0-1.672.506 4.452 4.452 0 0 0-.735 1.283c.223.441.408.808.575 1.143.413.131.761.242 1.136.363"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-229.236 -58.409)"
    />
    <path
      d="M377.256 225.487c-1.012.486-1.266.691-1.369 1.115-.186.783.27 1.3.769 1.829h.567l1.487-1.868-.227-.686-1.227-.39Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-207.953 -101.3)"
    />
    <path
      d="M201.789 231.543c-.27.178-.5.33-.731.487a1.105 1.105 0 0 0-.56 1.4c.143.39.355.75.626 1.065a.97.97 0 0 0 1.375.113 2.546 2.546 0 0 0 1-1.544c-.487-.516-.668-1.4-1.714-1.522"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.251 -103.983)"
    />
    <path
      d="M244.262 252.853a1.709 1.709 0 0 0 1.268-.975c.276-.437-.18-.641-.4-.913a.268.268 0 0 1-.054-.126.804.804 0 0 0-.822-.776c-.458-.014-.941-.095-1.3.308-.243.271-.482.546-.772.877.141.306.225.468.292.638a1.524 1.524 0 0 0 1.787.968"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-148.75 -112.178)"
    />
    <path
      d="M214.091 186.609c.812-.48.966-1.25 1.337-1.917-.132-.249-.26-.495-.378-.724-1.63-.58-1.9-.559-2.985.248.248.694.558 1.363.928 2l1.098.393Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.409 -82.733)"
    />
    <path
      d="M298.414 270.939c.716-.06 1.076-.6 1.641-.925 0-.485.021-.99-.006-1.493-.031-.579-.307-.789-.888-.828a1.973 1.973 0 0 0-1.869 1.535.983.983 0 0 0 .192.759c.287.34.597.658.93.953"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.15 -119.992)"
    />
    <path
      d="M190.386 231.962c-1.153-.26-1.587.479-1.912 1.368 1.074 1.705 1.588 1.937 3.288 1.429-.535-.906-.436-2.09-1.375-2.8"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-124.961 -104.145)"
    />
    <path
      d="M449.7 119.852a.82.82 0 0 0 .649-.125 2.365 2.365 0 0 0 1.119-1.826l-1.981-1.347c-.275.241-.565.459-.812.719a1.451 1.451 0 0 0-.188 1.565c.236.499.68.87 1.213 1.014"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-240.066 -53.05)"
    />
    <path
      d="M309.92 246.76c.564-.167 1.047-.312 1.531-.452.466-.136.606-.3.634-.777a1.295 1.295 0 0 0-.378-1.139 8.543 8.543 0 0 1-.7-.819 1.385 1.385 0 0 0-1.623 1.039 1.672 1.672 0 0 0 .537 2.147"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.442 -109.298)"
    />
    <path
      d="M176.273 341.4c.876.158 1.742.292 2.573.413.085-.173.15-.355.215-.516-.793-.775-1.3-.77-2.788.1"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-119.556 -152.346)"
    />
    <path
      d="M372 219.909a1.844 1.844 0 0 0-2.487 1.045c-.038.084-.072.169-.135.318l.657 1.241a2.407 2.407 0 0 0 2.654-1.223c-.115-.276-.206-.534-.328-.777-.122-.243-.275-.463-.361-.6"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-205.09 -98.772)"
    />
    <path
      d="M243.069 167.937c.181.041.363.069.544.106 1.3.263 2.02-.33 2-1.661 0-.092-.006-.186 0-.277a.85.85 0 0 0-.53-.889c-.206-.091-.414-.176-.595-.252-.434.209-.82.37-1.181.574-.521.3-.576.573-.268 1.132.082.149.168.3.234.414l-.465.679c.184.126.219.165.262.174"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.026 -74.492)"
    />
    <path
      d="M387.794 191.876a2.439 2.439 0 0 0 2.131.283c.543-.51.74-1.293.505-2a.875.875 0 0 0-.116-.154c-.3-.21-.613-.414-.99-.67-.157.033-.246.062-.336.071-.974.1-1.174.95-1.516 1.624a.684.684 0 0 0 .322.846"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-213.079 -85.287)"
    />
    <path
      d="m274.3 340.726-1.515.769-.586 1.486 1.678.825 1.153-.583.709-1.416a5.985 5.985 0 0 0-1.438-1.082"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.046 -152.344)"
    />
    <path
      d="M196.326 240.744c.875-.144.971-.28.974-1.177a2.174 2.174 0 0 0-.355-1.184 1.415 1.415 0 0 0-2.075-.043c-.653.921-.123 1.722.2 2.649.533-.106.891-.187 1.253-.246"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-127.656 -106.802)"
    />
    <path
      d="M444.693 123.145h-1.765a1.543 1.543 0 0 0-.526 1.293c.002.229.072.452.2.641.261.26.557.481.88.658.6.345 1.075-.018 1.523-.348a1.023 1.023 0 0 0 .287-1.471 9.338 9.338 0 0 0-.6-.773"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-237.43 -55.97)"
    />
    <path
      d="m179.1 256.07-1.584-.314c-.25.237-.488.457-.721.682a2.62 2.62 0 0 0-.29.306 1.09 1.09 0 0 0 .407 1.718c.371.172.736.352 1.148.549.518-.919 1.561-1.576 1.041-2.941"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-119.541 -114.707)"
    />
    <path
      d="M195.254 264.722a.748.748 0 0 0 1.21-.1c.422-.514.8-1.05.383-1.768a1.497 1.497 0 0 1-.082-.547.79.79 0 0 0-1.18-.576c-.667.382-1.439.72-1.555 1.765.371.373.78.819 1.224 1.224"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-127.421 -117.305)"
    />
    <path
      d="M365.192 201.281c-.728.277-1.449.557-1.431 1.578.007.419.276.661.456.978.323.569.922.519 1.529.835l1.065-1.711c-.181-.923-.895-1.276-1.618-1.679"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-202.601 -90.578)"
    />
    <path
      d="M292.386 300.777a.97.97 0 0 0 1.353-.243v-1.869c-.9-.86-1.35-.934-2.445 0-.14.121-.269.255-.384.4a.657.657 0 0 0-.1.773c.11.2.23.391.286.485.44.115.87.264 1.286.447"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.252 -133.416)"
    />
    <path
      d="M341.752 140.9c-.21-.251-.589-.32-.72-.08-.249.46-.756.326-1.059.605l-.394 1.1c.03.117.069.232.116.344.171.323.257.782.676.823.551.039 1.105.02 1.652-.057.124-.013.275-.252.321-.413.111-.4.169-.809.252-1.238-.274-.353-.55-.724-.845-1.081"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-191.89 -63.733)"
    />
    <path
      d="M444.942 100.707c.164-.215.308-.446.484-.7a3.24 3.24 0 0 0-.481-.995c-.822-.777-1.4-.839-2.021.119.036.555-.554.679-.751 1.033.005.107.026.213.06.315a1.474 1.474 0 0 0 2.71.233"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-237.332 -45.017)"
    />
    <path
      d="M184.466 238.126c-.662.167-1.336.33-2.006.51-.357.094-.638.42-.577.692.031.474.293.903.7 1.147.886.385 1.789.626 2.586-.347.053-.727.27-1.548-.7-2"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-122.037 -106.898)"
    />
    <path
      d="M269.559 264.912c0-.392.021-.851-.01-1.308a.98.98 0 0 0-.219-.495 1.833 1.833 0 0 0-2.6.037.975.975 0 0 0-.213.5 2.47 2.47 0 0 0 .128 1.379c.451-.076.907-.12 1.364-.134.482.015.939.457 1.554.021"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.511 -117.733)"
    />
    <path
      d="M359.556 183.56a2.225 2.225 0 0 0-.93.192c-.45.278-.882.583-1.294.915.29.799.838 1.48 1.556 1.935a2.555 2.555 0 0 0 1.753-1.437c-.121-.4-.218-.753-.336-1.1a.71.71 0 0 0-.749-.506"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-199.754 -82.728)"
    />
    <path
      d="M454.02 100.355c.123.538.444 1.01.9 1.321 1.644-.578 1.644-.578 2.176-1.358-.094-.245-.158-.517-.243-.773a6.752 6.752 0 0 0-.971-.971 1.677 1.677 0 0 0-.3-.03c-.7.44-1.491.8-1.564 1.81"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-242.58 -45.074)"
    />
    <path
      d="M237.125 234.777a1.074 1.074 0 0 0 1.6.14c.242-.14.475-.293.7-.458a.654.654 0 0 0 .1-.882 2.833 2.833 0 0 0-1.553-1.126 1.677 1.677 0 0 0-1.613.468c-.023 1.135-.023 1.138.763 1.857"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.169 -104.36)"
    />
    <path
      d="m383.375 162.307 1.132-.95V160c-.568-.413-1.043-.8-1.71-.587a1.754 1.754 0 0 0-1.279 1.322 1.9 1.9 0 0 0 1.857 1.568"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-210.466 -72.01)"
    />
    <path
      d="M266.844 191.938c.164.321.418.588.73.769a1.387 1.387 0 0 0 1.522-.319 1.73 1.73 0 0 0 .44-1.9c-.149-.558-.737-.442-1.165-.559a1.048 1.048 0 0 0-1.017.282c-.274.24-.512.521-.786.807.053.317.145.625.275.919"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.551 -85.534)"
    />
    <path
      d="M250.741 283.127a6.55 6.55 0 0 1 .466-1.036c.46-.681.157-1.236-.242-1.768a.926.926 0 0 0-1.666.118c-.2.554-.72.839-.882 1.393a2.135 2.135 0 0 0 2.324 1.294"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.511 -125.384)"
    />
    <path
      d="M413.729 95.458a1.509 1.509 0 0 0 1.428-1.095 1.14 1.14 0 0 0-.944-1.536 1.982 1.982 0 0 0-1.68.455l-.38 1.541.676.712c.359-.032.628-.074.9-.077"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-224.036 -42.529)"
    />
    <path
      d="M185.251 215.085a5.975 5.975 0 0 0-.23-.961 1.648 1.648 0 0 0-2.139-.23c-.814.568-.536 1.392-.511 2.059.505.719 1.078.231 1.6.253.426.024.838-.157 1.108-.487a.951.951 0 0 0 .172-.635"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-122.229 -96.031)"
    />
    <path
      d="M215.178 328.694c-.5 0-1.01-.023-1.515.007a.746.746 0 0 0-.773.671c-.1.4-.174.808-.272 1.26.184.187.356.385.516.592.194.276.409.6.764.495.385-.109.9-.277 1.055-.576.496-.72.581-1.65.224-2.448"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.655 -147.01)"
    />
    <path
      d="M256.678 322.715a1.085 1.085 0 0 0-1.572.608 3.51 3.51 0 0 1-.226.5 1.166 1.166 0 0 0 .19 1.394.887.887 0 0 0 1.209.346c.318-.114.644-.202.976-.264.083-.478.138-.841.209-1.2a1.095 1.095 0 0 0-.786-1.381"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.301 -144.311)"
    />
    <path
      d="M245.812 178.933a2.844 2.844 0 0 0-1.812-1.178c-.762 0-1.137.449-1.226 1.212-.077.658.35 1.037.633 1.5.13.131.308.204.492.2a2.147 2.147 0 0 0 1.929-1.322.666.666 0 0 0-.02-.407"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.009 -80.158)"
    />
    <path
      d="M316.8 95.536c.168.038.46.033.523-.063.38-.589 1.169-1.012.828-1.993a12.33 12.33 0 0 0-1.18-.6 1.203 1.203 0 0 0-1.2.092c-.414.24-.723.628-.862 1.086.231.365.467.741.708 1.128.389.116.782.256 1.185.346"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-180.963 -42.515)"
    />
    <path
      d="M438.749 129.631a1.568 1.568 0 0 0-2.5.2c-.12.202-.221.415-.3.636.185.89.847 1.063 1.517 1.368.458-.193.978-.384 1.468-.631a.562.562 0 0 0 .267-.722 2.599 2.599 0 0 0-.452-.852"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-234.576 -58.607)"
    />
    <path
      d="M179.327 245.121a10.398 10.398 0 0 0-.96-1.342.633.633 0 0 0-.982-.017 2.847 2.847 0 0 0-1.032 1.534c.282.345.529.62.748.916.606.815.7.813 1.526.265.318-.188.561-.48.689-.826a.714.714 0 0 0 .01-.53"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-119.592 -109.295)"
    />
    <path
      d="M394.685 137.229c.429.179.896.248 1.358.2.377 0 .8 0 .94-.427a1.302 1.302 0 0 0-.144-1.318c-.089-.107-.193-.2-.288-.3a2.607 2.607 0 0 0-1.462-.759 8.88 8.88 0 0 0-.836.775 1.149 1.149 0 0 0 .432 1.829"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-215.976 -61.055)"
    />
    <path
      d="M305.48 189.594a1.629 1.629 0 0 0-.247-.164c-.491.15-.877.27-1.263.391a.804.804 0 0 0-.674.9c-.039.325.06.652.274.9.184.2.337.426.495.628a2.077 2.077 0 0 0 1.963-.4c.428-1.3.428-1.3-.548-2.255"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-175.818 -85.33)"
    />
    <path
      d="M388.85 119.915c.281-.173.546-.35.825-.5.279-.15.55-.262.85-.4-.1-.4-.189-.7-.237-1.011-.056-.358-.071-.721-.094-.977a1.201 1.201 0 0 0-1.723-.106c-.348.287-.678.595-.987.923.096.381.235.75.413 1.1.288.351.607.675.953.969"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-213.109 -53.075)"
    />
    <path
      d="M268.706 301.045c.233-.045.45-.148.632-.3a2.327 2.327 0 0 0-.077-2.543.68.68 0 0 0-.489-.192c-.27.049-.534.129-.785.24a1.432 1.432 0 0 0-.989 1.855c.112.615.645.955 1.709.937"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.719 -133.423)"
    />
    <path
      d="M268.568 334.426a1.063 1.063 0 0 0-.646.012c-.12.027-.212.172-.323.253a1.66 1.66 0 0 0-.557 1.869c.145.635.836.476 1.238.749a.395.395 0 0 0 .135.03 1.105 1.105 0 0 0 1.362-1.019 9.519 9.519 0 0 0-.041-1.107 11.653 11.653 0 0 0-1.169-.787"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.728 -149.532)"
    />
    <path
      d="M238.228 291.9c-.986.1-1.2.882-1.431 1.546.229.407.415.712.576 1.032a.99.99 0 0 0 1.067.491.793.793 0 0 0 .85-.573c.124-.4.3-.779.48-1.252-.308-.738-.672-1.33-1.542-1.243"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.364 -130.712)"
    />
    <path
      d="M221.445 250.88c-.039-.133-.071-.267-.113-.432l-1.255-.828c-.368.206-.725.432-1.068.677a.82.82 0 0 0-.329.564 2.553 2.553 0 0 0 .745 1.86c.293.243.825.326 1.024.1.484-.559 1.278-.98 1-1.939"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.339 -111.99)"
    />
    <path
      d="M341.419 250.057c-.9-.141-1.389.31-1.787 1.686 1.051 1.369 1.375 1.434 2.962.519.108-.552.224-1.06-.183-1.49a1.8 1.8 0 0 0-.993-.715"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-191.913 -112.172)"
    />
    <path
      d="M408.508 89.214c.342-.38.536-.87.546-1.381a.909.909 0 0 0-.586-.988c-.556-.19-1.127-.336-1.833-.543-.109.595-.18 1.196-.212 1.8.017.365.13.72.329 1.027a1.054 1.054 0 0 0 1.756.081"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-221.497 -39.65)"
    />
    <path
      d="M196.993 145.4a1.4 1.4 0 0 0 .243-.126c-.477-.007-.954 0-1.429.014a1.16 1.16 0 0 0 1.187.113"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-128.208 -65.77)"
    />
    <path
      d="M268.918 141.173a8.562 8.562 0 0 1-.865-.385 1.447 1.447 0 0 0-1.141-.2c-.224.567.489.51.525.957-.153.258-.333.567-.514.878.27.641.277 1.435 1.259 1.5.421-.254.825-.536 1.21-.843a1.094 1.094 0 0 0-.474-1.908"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.684 -63.668)"
    />
    <path
      d="M340.731 192.321c.123.062.253.11.426.184l1.18-.813a2.39 2.39 0 0 0-.258-1.93 1.255 1.255 0 0 0-1-.356l-1.391.835c-.134 1.482-.134 1.483 1.041 2.08"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-191.913 -85.319)"
    />
    <path
      d="M425.2 165.523c-.352.326-.849.647-.9 1.171-.062.62.272 1.216.834 1.486.03.016.063.029.095.043.525-.717 1.05-1.438 1.575-2.161l-.02-.05a1.057 1.057 0 0 0-1.588-.489"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-229.414 -74.648)"
    />
    <path
      d="M407.688 125.857a1.974 1.974 0 0 0 .776-2.17c-.216-.464-.456-.919-.985-1.036l-2.018 1.247c.37.646.629 1.188.972 1.67.295.381.823.502 1.255.289"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-221.071 -55.75)"
    />
    <path
      d="m288 256.292-1.291-.752a2.904 2.904 0 0 0-.47.259 9.128 9.128 0 0 0-.7.677 1.057 1.057 0 0 0-.163 1.5c.315.465.912.656 1.439.461.442-.07.846-.294 1.138-.633 0-.211-.006-.487 0-.762s.031-.529.043-.748"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-167.777 -114.612)"
    />
    <path
      d="M263.846 256.784a2.383 2.383 0 0 0-.608-.577c-1.036-.57-1.785-.344-2.176.98.125.365.244.716.394 1.15.426.161.767.315 1.123.416a1.552 1.552 0 0 0 1.368-.521 1.45 1.45 0 0 0-.1-1.448"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.112 -114.777)"
    />
    <path
      d="M208.156 394.834a.709.709 0 0 0-.862.23l-.011.014c.748.272 1.512.5 2.288.681a3.893 3.893 0 0 0-1.415-.925"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-133.292 -176.282)"
    />
    <path
      d="M220.09 395.212a1.248 1.248 0 0 0-1.383.7c-.076.22-.125.448-.146.68.834.08 1.681.132 2.53.154.085-.262.217-.518.3-.8a2.184 2.184 0 0 0-1.3-.737"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.287 -176.47)"
    />
    <path
      d="M176.546 221.591c.2.691.582.931 1.22.721a2.6 2.6 0 0 0 1.547-1.291 3 3 0 0 0-1.493-1.757 8.744 8.744 0 0 0-.814.383 1.375 1.375 0 0 0-.774.942c-.028.227.185.48.277.726.031.086.012.188.037.276"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-119.537 -98.543)"
    />
    <path
      d="M230.731 216.239c.4.223.832.4 1.209.574.788-.458.788-.458 1.244-1.469a6.231 6.231 0 0 0-.18-.957.92.92 0 0 0-.4-.523 1.13 1.13 0 0 0-1.31.141 5.384 5.384 0 0 0-.857 1.085c-.329.559-.239.852.3 1.148"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.461 -96.09)"
    />
    <path
      d="M195.94 213.38c-.877.367-1.9.634-1.937 1.942.326.275.649.533.956.808.373.288.868.367 1.312.209.369-.077.428-.419.538-.733a4.75 4.75 0 0 1 .436-.806c-.473-.513-.868-.944-1.3-1.42"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-127.409 -95.938)"
    />
    <path
      d="M231.959 395.544a.25.25 0 0 1-.138-.01c-.28-.075-.574-.119-.759.155a2.199 2.199 0 0 0-.331.737 1.503 1.503 0 0 0-.026.292c1-.09 1.972-.222 2.887-.4-.3-.732-.75-1.115-1.634-.778"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.666 -176.57)"
    />
    <path
      d="M209.089 201.553a5.506 5.506 0 0 0-.712-.336 1.273 1.273 0 0 0-.549-.031.778.778 0 0 0-.487.2c-.384.555-.726 1.138-1.1 1.735.237.172.5.264.592.443a1.182 1.182 0 0 0 1.568.611l1.042-1.556a252.41 252.41 0 0 0-.354-1.065"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-132.831 -90.53)"
    />
    <path
      d="M291.247 287.719c.226.634.534 1.252 1.261 1.416l1.646-1.356a2.094 2.094 0 0 0-.65-1.452c-.637-.584-.836-.6-1.5.029a3.02 3.02 0 0 0-.568.6 1.099 1.099 0 0 0-.192.762"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.473 -128.054)"
    />
    <path
      d="M298.976 307.39c.5-.44 1.184-.779 1.174-1.423l-1.106-1.558c-.736.275-1.743.1-2.032 1.257.161.427.343.846.546 1.255.309.559 1.031.815 1.418.47"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.036 -136.257)"
    />
    <path
      d="M376.251 202.133c-.123.5-.256 1.028-.385 1.549.272.436.654.794 1.107 1.036a7.172 7.172 0 0 1 1.035-.514c.38-.099.696-.365.86-.722l-.4-1.466a1.718 1.718 0 0 0-2.217.116"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-207.963 -90.748)"
    />
    <path
      d="M305.389 201.746c-.548.089-1.05.162-1.547.256a1.203 1.203 0 0 0-1.039 1.138c-.008.471.2.675.9.875l1.414.4c.295-.336.588-.635.838-.966a.963.963 0 0 0-.065-1.277 3.191 3.191 0 0 0-.505-.423"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-175.601 -90.784)"
    />
    <path
      d="M202.424 355.524c.326.084.632.191.909-.05s-.022-.513 0-.769c.014-.135.075-.264.1-.4a1.154 1.154 0 0 0-1.261-1.454 1.048 1.048 0 0 0-1.195.769c-.112.3-.3.575-.412.875a.64.64 0 0 0 .5.834c.452.078.919.082 1.36.193"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.299 -157.708)"
    />
    <path
      d="M208.432 325.37c.8-.231.814-1.019 1.022-1.634a1.079 1.079 0 0 0-.041-.158 2.042 2.042 0 0 0-.125-.247c-.416-.7-.637-.837-1.316-.595a3.251 3.251 0 0 0-1.678.818l.218 1.236c.476.544 1.223.77 1.92.58"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-132.853 -144.326)"
    />
    <path
      d="M244.322 322.643c-.416.249-.81.485-1.182.706-.085.327-.192.632-.24.946-.078.471.157.941.579 1.163a.918.918 0 0 0 1.153.041c.237-.139.481-.265.731-.379.474-.275.721-.948.43-1.293-.408-.483-.77-1.053-1.471-1.183"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.062 -144.334)"
    />
    <path
      d="M315.95 226.071a1.065 1.065 0 0 0-.417 1.46c.165.377.389.728.575 1.067l1.6.226c.155-.516.3-.995.441-1.476a.681.681 0 0 0 .033-.276 1.525 1.525 0 0 0-.111-.537 1.705 1.705 0 0 0-2.126-.464"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-181.18 -101.472)"
    />
    <path
      d="M244.753 346.517a2.166 2.166 0 0 0-.3-.042l-1.17.765c-.124.383-.248.775-.375 1.173.178.282.361.551.521.832a.912.912 0 0 0 1.2.421c.207-.246.4-.5.623-.739.741-.8.825-2.021-.5-2.409"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.073 -154.89)"
    />
    <path
      d="M262.3 364.461a1.553 1.553 0 0 0-1.156.733 1.401 1.401 0 0 0-.157 1.209c.18.376.45.702.784.95.446.258.992.28 1.456.057l.761-1.435c-.439-.434-.828-.834-1.234-1.216a2.98 2.98 0 0 0-.455-.3"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.046 -162.856)"
    />
    <path
      d="M275.483 234.263c.343-.499.48-1.112.382-1.709a.723.723 0 0 0-.651-.617c-.18-.027-.367-.024-.48-.031-.48.017-.908-.052-1.213.338a1.655 1.655 0 0 0-.172 1.923c.35.787 1.78.667 2.134.1"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.454 -104.143)"
    />
    <path
      d="M395.085 183.119a5.087 5.087 0 0 0-.649.372c-.112.48-.206.877-.3 1.306l.5.791c.025.038.045.081.072.118a.944.944 0 0 0 1.22.3c.506-.3 1.055-.594 1.177-1.283a2.845 2.845 0 0 0-.878-1.354 1.003 1.003 0 0 0-1.138-.252"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-216.053 -82.497)"
    />
    <path
      d="M214.763 341.35a1.055 1.055 0 0 0-1.627-.194 5.272 5.272 0 0 0-.91.856.569.569 0 0 0 .026.768c.384.515.923.894 1.538 1.08 1.593-.779 1.642-.941.973-2.508"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.42 -152.403)"
    />
    <path
      d="M196.529 252.628c.363-.437.685-.828 1.028-1.243-.138-.4-.271-.783-.4-1.15l-1.189-.578c-.822.225-1.266.6-1.208 1.416a3.142 3.142 0 0 1-.179.868c.491.918 1.245.756 1.951.687"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-127.664 -112.006)"
    />
    <path
      d="M257.581 250.448a3.518 3.518 0 0 0-.713-.66 1.207 1.207 0 0 0-.78-.153 1.606 1.606 0 0 0-1.148 1.812c.211.424.478.817.794 1.169.645-.241 1.138-.384 1.593-.607.738-.36.822-.954.255-1.56"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.38 -111.99)"
    />
    <path
      d="M306.256 214.4a1.549 1.549 0 0 0-2.4-.124 5.4 5.4 0 0 0-.485.894 2.802 2.802 0 0 0 1.213 1.585c.32-.2.642-.373.943-.582.301-.209.574-.448.918-.72a3.003 3.003 0 0 0-.185-1.053"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-175.851 -96.108)"
    />
    <path
      d="M245.351 264.31c.112-.188.219-.34.3-.5.329-.709.28-.99-.321-1.421-.4-.29-.851-.521-1.257-.765-1 .4-1.1 1.23-1.288 2.127l1.138.89 1.428-.331Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.015 -117.304)"
    />
    <path
      d="M334.777 222.341a1.452 1.452 0 0 0 1.51-.576 2.596 2.596 0 0 0-1.912-2.534c-.26.334-.481.571-.65.841a1.409 1.409 0 0 0-.297.864c0 .75.6 1.375 1.349 1.405"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-189.172 -98.53)"
    />
    <path
      d="M430.746 125.6c.648.584.642.59 1.324.125.149-.1.3-.206.421-.288l.319-1.339a2.196 2.196 0 0 0-1.32-1.007c-.744-.3-1.922.95-1.683 1.565.055.173.148.331.271.464.2.185.466.3.668.481"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-231.841 -55.927)"
    />
    <path
      d="M207.875 264.916a9.253 9.253 0 0 0 1.1-.363c.659-1.568.428-2.049-1.049-2.37-.046-.011-.091-.013-.137-.021a.903.903 0 0 0-1.162.715c-.084.36-.106.732-.067 1.1a1.094 1.094 0 0 0 1.316.936"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-132.963 -117.534)"
    />
    <path
      d="M275.476 294.477c.468-.284.237-.843.38-1.261.025-.074-.03-.177-.048-.267-.571-.584-.571-.584-1.629-.9a1.169 1.169 0 0 0-.765.729 3.571 3.571 0 0 0-.3 1.189c.055.338.166.665.329.967a2.48 2.48 0 0 0 2.033-.455"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.45 -130.781)"
    />
    <path
      d="M352.4 155.978c.5-.05.995-.148 1.48-.224.573-1 .573-1 .032-2.031-.233-.086-.486-.192-.749-.272a1.149 1.149 0 0 0-1.577.685 5.453 5.453 0 0 0-.146.533c-.264.81.151 1.39.961 1.309"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-197.11 -69.353)"
    />
    <path
      d="M305.6 250.814a8.771 8.771 0 0 0-1.063-.764c-.462.2-.912.427-1.348.679a2.217 2.217 0 0 0-.456.614c.276.349.515.621.721.916.28.371.692.622 1.151.7.455.098.926-.103 1.17-.5a1.293 1.293 0 0 0-.175-1.643"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-175.567 -112.18)"
    />
    <path
      d="M267.062 311.722a2.192 2.192 0 0 0 1.773 1.708l.914-.535c.085-.485.16-.93.185-1.073l-1.275-1.628c-1.2 0-1.024 1.135-1.6 1.529"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.77 -138.82)"
    />
    <path
      d="M317.432 237.536c-1.392-.26-1.428.992-1.943 1.606.1.359.159.631.257.888a.671.671 0 0 0 .605.495c.744.07 1.479-.22 1.974-.78.119-.145.181-.549.1-.613-.541-.416-.453-1.209-.99-1.595"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-181.22 -106.621)"
    />
    <path
      d="M237.291 282.768c.354.2.74.344 1.215.559a1.34 1.34 0 0 0 1.053-1.161 1.949 1.949 0 0 0-.433-1.554.851.851 0 0 0-.458-.274 1.683 1.683 0 0 0-1.838 1.965.997.997 0 0 0 .46.465"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.355 -125.582)"
    />
    <path
      d="M335.2 134.727a.605.605 0 0 0-.5.15c-.3.281-.577.586-.829.912a1.143 1.143 0 0 0 .144 1.595c.64.13 1.303.092 1.924-.11a.866.866 0 0 0 .706-.9c.016-.853-.833-1.413-1.445-1.649"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-189.246 -61.093)"
    />
    <path
      d="M255.328 202.345c-.1.1-.176.215-.294.36.09.462.152.823.232 1.179.121.549.612.668 1.034.828a.817.817 0 0 0 .956-.4c.275-.37.51-.77.764-1.156a3.18 3.18 0 0 0-.155-.4c-.674-1.227-1.561-1.369-2.539-.406"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.442 -90.77)"
    />
    <path
      d="M188.684 268.663a.564.564 0 0 0-.225.737c.326.653.5 1.423 1.288 1.814l1.364-.38c.1-.618.194-1.155.29-1.721a6.33 6.33 0 0 0-1.734-.96 9.86 9.86 0 0 0-.984.511"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-124.927 -120.198)"
    />
    <path
      d="M437.8 95.852a1.933 1.933 0 0 0 1.268-2.531c-.765-.824-1.556-.362-2.322-.074a.615.615 0 0 0-.377.688c.147.875.289 1.775 1.43 1.917"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-234.757 -42.553)"
    />
    <path
      d="M353.714 177.437a4.609 4.609 0 0 1-.844.262c-.759.076-1.029.632-1.156 1.23-.188.88.067 1.38 1.056 1.334.187-.008.375 0 .561 0a1.585 1.585 0 0 0 1.311-.925c.295-.452-.095-.775-.345-1.106-.191-.252-.376-.508-.583-.79"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-197.239 -80.017)"
    />
    <path
      d="M278.706 251.4a.57.57 0 0 0 .027.519c.377.54.795 1.043 1.568.959.319-.035.639-.037.921-.051.272-.5.5-.936.772-1.429l-1.629-1.3c-.983.057-1.28.741-1.659 1.3"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-164.905 -112.2)"
    />
    <path
      d="M384.692 172.37a2.09 2.09 0 0 0-2.625 0 2.35 2.35 0 0 0 .139 1.506c.285.829.683 1.026 1.451.715a1.512 1.512 0 0 0 1.1-1.714 4.579 4.579 0 0 0-.064-.508"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-210.685 -77.567)"
    />
    <path
      d="M401.5 168.317c.149-.161.334-.284.54-.359a1.03 1.03 0 0 0 .591-1.143 1.218 1.218 0 0 0-1.056-.946c-1.088-.138-1.281-.028-1.8 1.024-.061.123-.11.252-.2.466.23.374.488.73.772 1.065a.711.711 0 0 0 1.157-.108"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-218.461 -74.872)"
    />
    <path
      d="M323.205 186.117a1.114 1.114 0 0 0 1.037-1.108 2.093 2.093 0 0 0-.768-1.723.652.652 0 0 0-.989.043c-.328.379-.638.772-1.021 1.237-.074.38.002.774.21 1.1.388.42.977.593 1.531.451"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-183.861 -82.516)"
    />
    <path
      d="m384.46 125.225.392-1.231c-.531-.386-.89-1.007-1.5-1.327a2.095 2.095 0 0 0-1.512 1.216c.369.524.251 1.26.8 1.709.629.19 1.313.052 1.819-.367"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-210.609 -55.758)"
    />
    <path
      d="M323.1 291.885c-.48.261-.893.449-1.263.7a.926.926 0 0 0-.365.55 4.862 4.862 0 0 0-.018 1.118.72.72 0 0 0 .728.7 2.075 2.075 0 0 0 1.77-.668c.344-.342.392-.886.114-1.283-.277-.37-.6-.7-.964-1.121"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-183.851 -130.71)"
    />
    <path
      d="M226.754 353.085a1.24 1.24 0 0 0-1.435-.043 3.35 3.35 0 0 0-.5.524l.529 1.987.666.3c.8-.352 1.637-.606 1.763-1.742-.3-.311-.638-.7-1.019-1.027"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-141.057 -157.707)"
    />
    <path
      d="M214.2 389.208c-.17-.135-.329-.282-.452-.388a2.27 2.27 0 0 0-1.7 1.836c1.027 1.262 1.229 1.31 2.721.7.472-1.325.472-1.325-.569-2.15"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.4 -173.647)"
    />
    <path
      d="M315.014 154.625c.31.4.625.786.926 1.164 1.606.167 1.652.14 2.22-1.19-.235-.263-.593-.49-.683-.8-.2-.666-.714-.759-1.258-.978l-1.251 1.618a.683.683 0 0 0 .046.183"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-180.989 -69.115)"
    />
    <path
      d="M214.1 280.324a1.302 1.302 0 0 0-1.274.119l.209 2.11c1.078.68 1.174.677 2.054-.035.212-.172.432-.338.613-.48.126-.377.04-.793-.225-1.089a2.668 2.668 0 0 0-1.378-.626"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.747 -125.541)"
    />
    <path
      d="M383.706 198.213c.382-.024.722-.254.886-.6a1.717 1.717 0 0 0-.437-2 6.863 6.863 0 0 0-.833-.435 2.768 2.768 0 0 0-1.411 1.935l.768 1.1c.379 0 .7.007 1.027 0"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-210.641 -87.873)"
    />
    <path
      d="M390.7 141.867c.027-.043-.275-.441-.417-.436a9.643 9.643 0 0 0-1.767.224.847.847 0 0 0-.477.45c-.343.722.644 1.044.569 1.735-.015.132.388.355.633.453a.7.7 0 0 0 .516-.1c.434-.253.848-.54 1.407-.9-.225-.534-.842-.843-.463-1.418"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-213.321 -64.07)"
    />
    <path
      d="M237.92 159.112a1.96 1.96 0 0 0-.877 1.444l.516.983c2.018.193 2.571-.379 2.269-2.336-.619-.056-1.211-.728-1.908-.091"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.473 -71.78)"
    />
    <path
      d="M170.732 216.05c.2.112.416.21.623.316a1 1 0 0 0 .936-.022c.824-.386.99-.754.763-1.8a1.079 1.079 0 0 0-1.551-.811c-.294.133-.575.29-.871.439-.056.27-.126.537-.21.8-.262.71-.256.767.31 1.077"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-116.891 -96.045)"
    />
    <path
      d="M231.086 384.117c-.141.454.032.95.424 1.219a1.713 1.713 0 0 0 1.833.009.732.732 0 0 0 .253-.321c.129-.526.228-1.061.338-1.593l-1.966-.694c-.534.252-.878.79-.883 1.38"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.821 -170.951)"
    />
    <path
      d="M202.405 304.524a1.588 1.588 0 0 0-1.522.773 7.17 7.17 0 0 0-.26.861c.075.993 1.05.819 1.445 1.344.35-.187.683-.405.995-.651.392-.275.59-.755.505-1.227a1.633 1.633 0 0 0-1.163-1.1"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.342 -136.298)"
    />
    <path
      d="M372.611 184.491a.743.743 0 0 0-.383-.515c-.57-.152-1.149-.27-1.733-.354-.071-.012-.16.076-.247.121l-.357.987c.339.672.3 1.51 1.14 1.937l1.6-1.021c.026-.385.02-.772-.017-1.156"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-205.317 -82.756)"
    />
    <path
      d="M418.712 88.528c.066.246.154.485.264.715.975-.174 1.98-.091 2.49-1.3-.318-1.172-.318-1.172-1.565-1.653-.147.065-.311.149-.484.211a1.041 1.041 0 0 0-.724 1.194c.021.277-.03.563.018.833"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-226.93 -39.645)"
    />
    <path
      d="M172.316 313.028c.614-.583.48-1.347.733-1.974.056-.14-.109-.462-.264-.577a5.975 5.975 0 0 0-.971-.491c-.285.238-.529.433-.763.64-.82.721-.906 1.218-.347 2.4l1.612.002Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-116.932 -138.727)"
    />
    <path
      d="M268.243 128.846a.98.98 0 0 1-.75.7.703.703 0 0 0-.472.772c.007.228.037.454.052.642 1.217.711 1.217.711 2.29.655a5.87 5.87 0 0 0 .625-.861.772.772 0 0 0-.023-.646 12.965 12.965 0 0 0-1.007-1.315c-.189-.225-.625-.217-.715.049"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.751 -58.401)"
    />
    <path
      d="M354.434 129.805a1.533 1.533 0 0 0-1.792-.688 1.214 1.214 0 0 0-.892.786c-.13.295.1.5.218.751.143.374.258.759.343 1.15.485.132.996.132 1.481 0 .49-.154.35-.711.6-1.024.113-.137.2-.294.291-.427-.1-.212-.164-.386-.253-.546"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-197.264 -58.595)"
    />
    <path
      d="M268.416 201.645c-.178.041-.361.056-.487.075a2.93 2.93 0 0 0-.606 1.886c.11.124.236.257.354.4a1.492 1.492 0 0 0 2.485-.587.75.75 0 0 0-.014-.661c-.472-.514-.78-1.33-1.731-1.112"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.885 -90.724)"
    />
    <path
      d="M328.947 253.045c.156.027.522-.223.515-.322a1.5 1.5 0 0 1 .453-1.22c.373-.416.228-.838-.284-1.131a2.288 2.288 0 0 0-1.924-.2l-.586 1.688a2.457 2.457 0 0 0 1.824 1.188"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.373 -112.177)"
    />
    <path
      d="M166.009 267.9a2.921 2.921 0 0 0-.882.256c-.245.179-.541.475-.558.736-.164.693.101 1.42.673 1.844.3.16.609.301.926.424.379-.38.751-.725 1.09-1.1a.639.639 0 0 0-.01-.889c-.4-.443-.835-.857-1.239-1.268"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-114.367 -120.087)"
    />
    <path
      d="M135.106 250.1c-.683.219-1.241.45-1.159 1.3.021.224 0 .452 0 .751l1.773.72c.373-.354.686-.624.967-.923a.825.825 0 0 0 .1-1.229 1.511 1.511 0 0 0-1.684-.622"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-100.805 -112.171)"
    />
    <path
      d="M298.556 316.582c-.4-.006-.853.128-.925.466a2.527 2.527 0 0 0-.079 1.683c.169.443.624.714 1.094.653a1.24 1.24 0 0 0 .872-.343c.431-.347.726-.836.831-1.38-.676-.826-1.106-1.074-1.792-1.081"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.219 -141.65)"
    />
    <path
      d="M287.668 244.389a2.277 2.277 0 0 0-1.446-.389c-.618.048-.848.14-.936.782-.058.425-.375.612-.535.98.593 1.026 1.49.842 2.271.655.378-.09.711-.563.962-.931a.848.848 0 0 0-.315-1.1"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-167.605 -109.496)"
    />
    <path
      d="M390.546 154.1a2.83 2.83 0 0 0-1.9-.282.735.735 0 0 0-.664.9c.2.763.413 1.546 1.434 1.844.296-.164.579-.349.847-.556.24-.211.443-.461.6-.739a.742.742 0 0 0-.315-1.165"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-213.32 -69.537)"
    />
    <path
      d="m315.612 286.436-.724 1.382c.639.808 1.136 1.743 2.337 1.572.625-.89.601-2.09-.059-2.954h-1.554Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-180.954 -128.297)"
    />
    <path
      d="M200.776 343.283c1.168.523 1.665.363 2.271-.731.134-.242.25-.494.408-.807-.9-.958-1.527-1.238-2.064-.852-.36.29-.664.642-.9 1.039-.252.382-.055.8.043 1.184.075.062.155.118.24.167"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.23 -152.344)"
    />
    <path
      d="M251.6 256.743c-.212-.146-.363-.247-.511-.352a1.488 1.488 0 0 0-1.91.177c-.569.442-.282.99-.075 1.509.025.134.064.265.117.391a.909.909 0 0 0 .39.362 1.89 1.89 0 0 0 1.7-.309c.422-.284.338-.722.34-1.129 0-.183-.028-.365-.053-.649"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.701 -114.866)"
    />
    <path
      d="M249.884 161.908c.273.016.544.065.806.144.276-.319.534-.588.759-.883.093-.151.168-.312.224-.481-.641-1.379-.725-1.423-2.288-1.195-.105.178-.21.386-.343.575-.158.223-.34.43-.56.7a1.243 1.243 0 0 0 1.4 1.136"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.54 -72.023)"
    />
    <path
      d="M436.955 119.508c.858.5 1.267.4 1.829-.434.051-.077.119-.144.169-.222.331-.511.357-.8-.028-1.247a4.072 4.072 0 0 0-.788-.594c-.994.227-1.833.48-2.242 1.469.16.211.331.414.511.608.164.164.348.305.548.421"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-234.552 -53.253)"
    />
    <path
      d="M357.854 173.374c.307.556.825.967 1.436 1.141l1.483-1.858a5.056 5.056 0 0 0-.859-.907 2.035 2.035 0 0 0-1.041-.28 1.25 1.25 0 0 0-1.206 1.244c0 .232.065.459.187.656"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-199.895 -77.374)"
    />
    <path
      d="M420 150.06c.21-.173.411-.358.6-.554a1.311 1.311 0 0 0-.043-1.861 7.174 7.174 0 0 0-.7-.673.636.636 0 0 0-.974.1c-.27.37-.5.772-.814 1.268.554.756.818 1.675 1.938 1.722"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-226.655 -66.442)"
    />
    <path
      d="M419.116 171.925c-.7.074-1.052.431-.868 1.094.12.381.15.785.086 1.179a7.996 7.996 0 0 1 1.238.013c.2.033.385.114.578.166l1.121-1.517c0-.016.008-.031.012-.048a2.318 2.318 0 0 0-2.167-.886"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-226.714 -77.567)"
    />
    <path
      d="M201.883 243.618c-.945.29-1.09 1.158-1.375 1.906a.684.684 0 0 0 .131.519 1.91 1.91 0 0 0 1.388.843c.423.05.65-.318.91-.577.137-.158.266-.322.385-.494-.27-.953-.464-1.819-1.438-2.2"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.283 -109.331)"
    />
    <path
      d="M190.487 244.283c-.144-.1-.214-.3-.343-.427a.886.886 0 0 0-1.506.152 2.697 2.697 0 0 0-.427 1.483c.208.216.421.46.658.681.522.484.729.476 1.366.176.461-.241.775-.694.839-1.21.1-.436-.275-.637-.588-.855"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-124.843 -109.28)"
    />
    <path
      d="M346.391 123.126c-.271.129-.457.437-.617.6.063.506.111.906.157 1.287.314.18.619.377.911.59a.893.893 0 0 0 .916.144 8.142 8.142 0 0 0 1.017-.556c-.153-.52-.233-.884-.368-1.226a1.403 1.403 0 0 0-2.016-.838"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.634 -55.887)"
    />
    <path
      d="M309.926 195.9v2.027c.447.4.956.691 1.429.408a5.312 5.312 0 0 0 1.26-1.252c.089-.109-.008-.463-.125-.63-.65-.914-1.34-1.053-2.564-.552"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.756 -88.066)"
    />
    <path
      d="m334.844 279.829-1.536.785.193 1.3c.315.197.646.367.99.509.013 0 .026.007.04.011l1.546-1.934c-.019-.027-.034-.053-.055-.08l-1.178-.591Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-189.112 -125.37)"
    />
    <path
      d="M250.63 207.369c-1.082.212-1.508.971-1.664 2.145.489.261.935.507 1.387.734a.732.732 0 0 0 .9-.121c.263-.26.5-.541.722-.781-.314-1.316-.314-1.316-1.349-1.976"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.754 -93.275)"
    />
    <path
      d="M370.259 99.254c-.158.647-.592 1.273-.314 2.028l1.321.64c.8-.446 1-1.282 1.74-1.894-.442-.368-.787-.675-1.154-.954-.507-.385-1.476-.305-1.593.18"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-205.3 -45.2)"
    />
    <path
      d="m162.386 276.257-.705-1.946a3.486 3.486 0 0 0-.795-.089c.484.75.881 1.553 1.183 2.393l.241-.179c.032-.024.037-.084.076-.178"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-112.741 -122.886)"
    />
    <path
      d="M418.884 101.578a.752.752 0 0 0 1.2.115 3.49 3.49 0 0 0 1.242-1.725c-1.159-1.392-1.708-1.414-2.914-.172a.456.456 0 0 0-.06.121c.068.357.115.72.214 1.067.063.218.17.42.316.593"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-226.782 -45.227)"
    />
    <path
      d="M200.049 221.2c.1.136.215.281.327.427a1.765 1.765 0 0 0 2.131.489.91.91 0 0 0 .533-.4c.143-.383.256-.776.338-1.177l-1.878-.939a2.123 2.123 0 0 0-1.451 1.6"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.087 -98.69)"
    />
    <path
      d="M227.593 293.276c-.445-.863-.731-1.017-1.649-.894a.789.789 0 0 0-.759.837c-.024.411 0 .824 0 1.238l.267-.174.841 1.083a2.64 2.64 0 0 0 1.7-1.237c-.159-.349-.264-.607-.392-.853"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-141.216 -130.913)"
    />
    <path
      d="M341.79 119.608c.2-.462.482-.89.758-1.387-.142-.157-.284-.333-.443-.491-1.01-1-1.414-.982-2.363.131a2.13 2.13 0 0 0-.2.437c.2.428.422.886.637 1.344a.946.946 0 0 0 1.612-.034"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-191.873 -53.248)"
    />
    <path
      d="M376.244 117.72c-.062.069-.155.136-.168.214a1.545 1.545 0 0 0-.043.652c.087.262.22.507.392.724.32.482.957.649 1.472.386a1.783 1.783 0 0 0 1.092-1.277c-.081-.174-.128-.306-.2-.423-.712-1.179-1.666-1.281-2.545-.276"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-208.026 -53.263)"
    />
    <path
      d="M400.884 119.8c.86.288 1.475-.058 1.854-1.009.123-.295.157-.62.1-.935a.993.993 0 0 0-.819-.72c-.432.133-.854.294-1.266.48-.425.248-.835.521-1.228.816.12.695.665 1.244 1.359 1.369"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-218.442 -53.31)"
    />
    <path
      d="M149.516 217.953c-.49.113-.977.236-1.458.377.159.037.32.061.483.073a.986.986 0 0 0 .975-.45"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-107.059 -97.963)"
    />
    <path
      d="M183.96 250.086c-.506-.092-.93.175-1.307.824-.406.7-.38.811.225 1.391.228.217.426.466.666.731.434-.084.751-.141 1.068-.207a.638.638 0 0 0 .523-.57c.036-.276.04-.555.014-.832a1.424 1.424 0 0 0-1.189-1.338"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-122.262 -112.188)"
    />
    <path
      d="M249.268 221.784c.403.099.732.392.875.782.032.091.374.183.487.119.574-.324 1.107-.711 1.057-1.49-.016-.273-.038-.546-.055-.763l-1.218-.96c-.978.069-1.255.745-1.456 1.491-.1.378-.039.693.309.821"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.732 -98.636)"
    />
    <path
      d="M261.028 330.317c.714.256 1.041 1.293 2.029.9.807-1.228 1.206-1.4-.241-2.914l-1.788.375v1.639Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.097 -146.841)"
    />
    <path
      d="M430.677 159.842a1.496 1.496 0 0 0-.2 1.795c.051.057.107.113.164.168l1.611-2.235a2.239 2.239 0 0 0-.723-.142c-.322.04-.621.186-.852.414"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-232.056 -72.04)"
    />
    <path
      d="M160.369 322.629a7.067 7.067 0 0 0-1.172-.432c-.206.171-.375.326-.559.464a1.59 1.59 0 0 0-.437 1.78.7.7 0 0 0 .468.448c.461.059.927.068 1.39.028a.942.942 0 0 0 .479-.255 1.282 1.282 0 0 0-.169-2.033"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-111.514 -144.136)"
    />
    <path
      d="M305 156.144c.258-.137.574-.306.927-.5l.323-1.949a5.335 5.335 0 0 0-.808-.7 1.095 1.095 0 0 0-1.634.857c-.034.41-.007.826-.007 1.173.319.37.579.683.856.984.054.06.164.066.343.13"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-176.039 -69.128)"
    />
    <path
      d="M208.443 310.6a2.072 2.072 0 0 0-1.875.373c-.323.911-.381 1.716.468 2.191a1.53 1.53 0 0 0 1.926-.363c-.035-.773.217-1.595-.52-2.2"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-132.878 -138.966)"
    />
    <path
      d="M407.776 173.746a.749.749 0 0 0 .624-.8 1.436 1.436 0 0 0-.284-1.04 4.784 4.784 0 0 1-.471-.878 1.05 1.05 0 0 0-1.2.367c-.3.341-.747.578-.828 1.082a.857.857 0 0 0 .3.876c.325.246.678.455.983.657.351-.109.609-.2.876-.267"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-221.133 -77.153)"
    />
    <path
      d="M311.923 183.989c-.2-.179-.429-.327-.637-.483-.449.152-.838.284-1.21.412l-.323.482c-.637.946-.435 1.55.626 1.852.18.041.364.063.549.064.808.047 1.11-.535 1.4-1.132.267-.539-.053-.877-.409-1.195"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.519 -82.706)"
    />
    <path
      d="M347.215 183.52c-.83.08-1.084.5-1.223 2.093.18.177.375.337.583.479a.922.922 0 0 0 .633.17c.686-.245 1.44-.449 1.616-1.333-.475-1.388-.609-1.5-1.609-1.409"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.731 -82.7)"
    />
    <path
      d="M402.612 141.986c-.026-.571-.167-.739-.7-.779-.275-.021-.554-.011-.831-.007a.852.852 0 0 0-.9.692 8.488 8.488 0 0 1-.475 1.057c.311.263.589.5.873.739a1.026 1.026 0 0 0 1.481-.014c.222-.213.429-.44.621-.681-.024-.373-.048-.69-.064-1.008"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-218.52 -63.965)"
    />
    <path
      d="M377.708 180.358a4.849 4.849 0 0 0 .957-1.795l-.432-1.173-1.117-.307c-.366.36-.715.661-1.014 1.008a.915.915 0 0 0 .083 1.355c.422.428.946.741 1.523.911"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-207.951 -79.86)"
    />
    <path
      d="M341.127 131.737c.467-.36 1.111-.385 1.336-.936-.2-.355-.351-.68-.546-.977a2.719 2.719 0 0 0-.53-.631 1.001 1.001 0 0 0-1.56.232c-.208.364-.395.74-.56 1.125l.61.9 1.25.287Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-191.752 -58.526)"
    />
    <path
      d="M262.724 343.6c.172-.026.34-.081.6-.144-.02-.424-.017-.848.009-1.271a1.105 1.105 0 0 0-.871-1.241.744.744 0 0 0-.989.213c-.309.334-.585.7-.873 1.051.012.087.033.173.062.256.624 1.35.625 1.351 2.066 1.135"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-156.906 -152.398)"
    />
    <path
      d="m329.619 95.526.926-1.134c-.153-.3-.277-.6-.449-.867a1.21 1.21 0 0 0-1.893-.36c-.227.152-.437.33-.618.468V94.9c.655.482 1.23 1.006 2.033.629"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.578 -42.556)"
    />
    <path
      d="M414.579 143.451a1.375 1.375 0 0 0 .1-1.991 5.125 5.125 0 0 0-.608-.58.57.57 0 0 0-.772-.012c-.416.363-.793.773-1.218 1.193.313.487.65.959 1.011 1.412a1 1 0 0 0 1.488-.022"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-224.003 -63.756)"
    />
    <path
      d="M425.035 119.1c.162.23.419.377.7.4.457.085.929-.047 1.276-.357a2.264 2.264 0 0 0-1.077-2.6c-.453.194-.893.416-1.319.663a.693.693 0 0 0-.128 1.094c.208.248.358.544.548.807"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-229.405 -53.041)"
    />
    <path
      d="M347.016 137.939a2.033 2.033 0 0 0 1.846-.985l-.337-1.4a1.63 1.63 0 0 0-1.671-.289 2.22 2.22 0 0 0-.747 1.856c.087.562.315.8.909.818"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.772 -61.28)"
    />
    <path
      d="M180.245 149.87c-.47.334-.916.681-1.343 1.038.238-.023.471-.087.688-.188a.994.994 0 0 0 .655-.851"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-120.721 -67.806)"
    />
    <path
      d="M245.583 117.784c-.444-1.263-.443-1.257-1.751-.9a.801.801 0 0 0-.65.633c-.084.4-.144.8-.216 1.209.332.429.725.809 1.166 1.126a2.159 2.159 0 0 0 1.545-1.684 3.874 3.874 0 0 0-.095-.382"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.097 -53.105)"
    />
    <path
      d="M231.712 240.406c.257.121.497.276.713.461.226-.282.363-.47.519-.642a2.42 2.42 0 0 0 .548-1.533c.035-.567-.637-.6-.95-.916a1.76 1.76 0 0 0-.382-.223l-.5.214a1.531 1.531 0 0 0-.9 1.453 1.118 1.118 0 0 0 .953 1.186"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.69 -106.644)"
    />
    <path
      d="M243.054 312.278c-.025.724.521.877.9 1.178 1.521-.709 1.6-.874 1.25-2.576l-1.539-.641c-1.169.577-.592 1.36-.616 2.039"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.082 -138.84)"
    />
    <path
      d="M164.768 328.952a1.624 1.624 0 0 0-.5 1.222c.61.7 1.224 1.332 2.339.994.16-.401.285-.814.375-1.236a1.315 1.315 0 0 0-.155-.914c-.862-1.039-1.144-1.1-2.054-.066"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-114.234 -146.798)"
    />
    <path
      d="M365.107 213.8a2.426 2.426 0 0 0-.86 1.908c.032.471.32.887.75 1.082.463.111.948-.077 1.215-.471a2.656 2.656 0 0 0 .715-1.678 2.222 2.222 0 0 0-1.821-.841"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-202.812 -96.125)"
    />
    <path
      d="M245.122 276.754c.227.116.683-.475.632-.782-.1-.585-.225-1.164-.346-1.778l-1.4-.322a2.653 2.653 0 0 0-.5 2.939c.527.155 1.04-.35 1.614-.057"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.237 -122.733)"
    />
    <path
      d="M340.221 228.015c.53.143.782.592 1.242.973.408-.857 1.463-1.243 1.235-2.367a4.109 4.109 0 0 0-.353-.245 6.71 6.71 0 0 0-.617-.31c-1.092-.478-1.6-.231-1.911.925-.114.422.006.916.4 1.023"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-191.977 -101.454)"
    />
    <path
      d="M206.771 191.929a2.11 2.11 0 0 0 1.867-.2c.436-.364.608-.96.431-1.5a1.479 1.479 0 0 0-1.482-.777 2.43 2.43 0 0 0-1.468 1.094c.072.34.165.676.279 1.005a.7.7 0 0 0 .374.382"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-132.776 -85.331)"
    />
    <path
      d="M136.335 228.552c.366-.196.627-.543.715-.949-.135-.876-.741-1.185-1.409-1.414-.5.583-.961 1.16-1.382 1.739a1.383 1.383 0 0 0 2.076.624"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-100.947 -101.61)"
    />
    <path
      d="M342.484 202.338a.82.82 0 0 0-.442-.311 9.42 9.42 0 0 0-1.073-.273.691.691 0 0 0-.825.565c-.04.553-.01 1.113-.01 1.809.313.159.645.276.988.347a1.802 1.802 0 0 0 1.657-.862c.318-.485-.073-.885-.295-1.275"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-192.131 -90.782)"
    />
    <path
      d="M177.235 328.779c-.335.144-.765.256-.845.66-.064.41-.064.828 0 1.238a.545.545 0 0 0 .349.386c.64.046 1.209.536 1.973.281.071-.336.165-.666.281-.989a1.28 1.28 0 0 0-.442-1.492 1.143 1.143 0 0 0-1.314-.085"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-119.586 -146.978)"
    />
    <path
      d="m147.733 249.659-1.255.6a8.925 8.925 0 0 0-.387 1.146c-.035.227.005.46.113.663a1.677 1.677 0 0 0 2.1.443c.074-.052.164-.13.176-.208.133-.53.197-1.075.192-1.622a1.294 1.294 0 0 0-.94-1.021"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-106.182 -112.006)"
    />
    <path
      d="M294.041 178.5a.878.878 0 0 0-.216-.49 1.884 1.884 0 0 0-1.254-.523c-.457 0-.95-.08-1.246.507l.282 1.874c.392.155.792.286 1.2.392a.82.82 0 0 0 .939-.433c.254-.392.36-.863.3-1.326"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.517 -80.038)"
    />
    <path
      d="M316.374 165.22c-.217.059-.425.148-.649.228-.039.649-.653 1.208-.115 1.862a1.574 1.574 0 0 0 1.888.66l.759-1.087c-.207-.426-.382-.848-.611-1.238-.292-.5-.64-.6-1.272-.425"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-181.176 -74.57)"
    />
    <path
      d="M249.875 316.6c-.577.046-.749.515-.92.943-.227.422-.185.941.108 1.32.141.115.308.194.487.231.377.13.758.247 1.175.382l1.117-1.595c-.172-.253-.321-.483-.481-.7a1.537 1.537 0 0 0-1.486-.577"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.687 -141.651)"
    />
    <path
      d="M268.6 180.261a1.749 1.749 0 0 0 1.155-1.96.496.496 0 0 0-.126-.242 1.753 1.753 0 0 0-1.517-.515 1.497 1.497 0 0 0-1.1 1.289c-.057.53.127 1.058.5 1.438.364.025.729.022 1.093-.009"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.742 -80.047)"
    />
    <path
      d="M365.689 105.292c-.169-.07-.353-.109-.528-.161a1.053 1.053 0 0 0-1.362 1.179c.064.268.151.529.26.782.213.583.4.708 1.1.689.111 0 .221-.01.33-.03.681-.154 1.053-.528 1.083-1.072a1.218 1.218 0 0 0-.886-1.386"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-202.61 -47.968)"
    />
    <path
      d="m275.7 328.816-1.613-.609L272.99 330c.146.281.223.674.455.816.402.223.844.368 1.3.426.414.069 1.134-.474 1-.748-.275-.567.213-1.081-.047-1.682"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.395 -146.798)"
    />
    <path
      d="M382.679 89.45c.48.176 1.017.118 1.448-.157a.988.988 0 0 0 .522-.908 5.26 5.26 0 0 0-.286-1.2.518.518 0 0 0-.275-.3 1.737 1.737 0 0 0-1.6.057 1.04 1.04 0 0 0-.352 1.549c.173.315.353.626.538.954"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-210.653 -39.837)"
    />
    <path
      d="M280.977 335.236a2.902 2.902 0 0 0-1.731.947c-.065.111-.133.329-.08.382.4.411.5 1.062 1.08 1.341.438.181.944.086 1.287-.242.455-.262.701-.782.614-1.3-.21-.8-.48-1.147-1.171-1.13"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.123 -149.911)"
    />
    <path
      d="m273.73 113.474.985.28c.548-.394.93-.977 1.071-1.637a3.481 3.481 0 0 0-1.6-1.581c-1.378 1.051-1.4 1.133-.683 2.534.061.124.135.241.226.4"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.417 -50.385)"
    />
    <path
      d="M197.253 287.473a2.257 2.257 0 0 0-1.04-1.509 2.67 2.67 0 0 0-1.467.919c-.294.31-.378.766-.214 1.161.2.662.8.729 1.314.884.588.176.891-.041 1.13-.684.1-.258.187-.52.277-.771"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-127.61 -128.088)"
    />
    <path
      d="M208.679 145.91h.009c-.4-.023-.8-.049-1.2-.079a1.05 1.05 0 0 0 1.194.082"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-133.381 -66.016)"
    />
    <path
      d="m245.666 286.362-1.016-.516c-1.331.537-1.531.822-1.287 1.785.3 1.21.437 1.3 1.714 1.192a2.105 2.105 0 0 0 .589-2.461"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.233 -128.036)"
    />
    <path
      d="M274.3 159.108a2.588 2.588 0 0 0-1.858 1.867c.371.163.628.258.867.386s.458.313.7.441a1.324 1.324 0 0 0 1.82-.877.456.456 0 0 0-.077-.388c-.567-.4-.721-1.218-1.453-1.428"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.154 -71.898)"
    />
    <path
      d="M189.015 162.048c.275.18.5.416.865.334.365-.082.484-.36.57-.667l-.031.007-.008-.02a.33.33 0 0 1-.183.069.95.95 0 0 1-.3-.154c-.008-.006.02-.1.038-.105a.736.736 0 0 1 .33-.01c.055.018.08.125.116.2l.051-.027c0 .013-.008.027-.012.04.128-.029.294-.03.342-.105.155-.269.293-.547.416-.832l-.773-1.426c-.3 0-.623-.021-.94 0-.521.043-.994.4-.978.831.021.62-.228 1.382.5 1.861"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-124.968 -72.003)"
    />
    <path
      d="M324.3 257.815a.768.768 0 0 0 .124-.63 1.719 1.719 0 0 0-1.187-1.061 1.68 1.68 0 0 0-1.628.57 1.262 1.262 0 0 0-.015 1.435c.062.12.139.232.29.482.337.017.674.015 1.01-.007a1.937 1.937 0 0 0 1.4-.789"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-183.827 -114.856)"
    />
    <path
      d="M244.16 240.826a.646.646 0 0 0 .828-.315c.251-.531.446-1.09.628-1.547a2.29 2.29 0 0 0-1.316-1.364c-1.018.128-.9 1.122-1.421 1.568a2.3 2.3 0 0 1 .194.551c.046.688.565.9 1.083 1.1"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.06 -106.667)"
    />
    <path
      d="M135.79 237.872c-1.38.583-1.38.583-1.59 2.223.253.464.779.716 1.3.621a2.077 2.077 0 0 0 1.523-.874.612.612 0 0 0-.039-.779c-.368-.409-.779-.78-1.195-1.192"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-100.921 -106.786)"
    />
    <path
      d="M263.094 173.926c.208-.237.397-.491.565-.758a1.15 1.15 0 0 0 .125-.659 2.062 2.062 0 0 0-.829-1.387.602.602 0 0 0-.77-.016 2.822 2.822 0 0 0-1.124 1.534c.643 1.4.672 1.416 2.033 1.285"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.112 -77.154)"
    />
    <path
      d="M164.9 246.615c.477.296 1.09.272 1.542-.062a1.34 1.34 0 0 0-.295-2.209 3.089 3.089 0 0 0-.5-.237.91.91 0 0 0-1.2.359c-.182.258-.33.54-.484.8.182.528.505.996.934 1.354"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-114.103 -109.506)"
    />
    <path
      d="M426.991 153.721a2.846 2.846 0 0 0-1.97-.33c-.98 1.371-.98 1.371.053 2.634.121-.026.251-.063.385-.081.713-.1 1.408-.219 1.409-1.182 0-.055.141-.1.193-.164.309-.4.3-.741-.071-.877"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-229.412 -69.33)"
    />
    <path
      d="M262.521 247.121c.336-.466.653-.9.953-1.319a1.73 1.73 0 0 1-.146-1.7c-1.447-.271-2.163.617-2.948 1.463.409.995 1.3 1.178 2.141 1.559"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-156.81 -109.522)"
    />
    <path
      d="M166.669 316.892a1.12 1.12 0 0 0-1.414-.1 1.085 1.085 0 0 0-.472 1.33c.09.354.2.7.353 1.206.327-.025.594-.059.862-.062.312 0 .624.025.954.041.179-.29.34-.591.484-.9a.686.686 0 0 0-.013-.53 4.372 4.372 0 0 0-.754-.983"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-114.44 -141.651)"
    />
    <path
      d="M256.416 310.595a1.206 1.206 0 0 0-1.444 1.473c.344 1.092.448 1.186 1.679 1 .093-.014.189 0 .28-.018.62-.1.792-.3.785-.925-.009-.888-.35-1.241-1.3-1.533"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.392 -138.977)"
    />
    <path
      d="M195.224 189.163c-.243-.076-.413.141-.453.4-.07.448-.12.9-.209 1.346-.105.532-.028.724.47.994a1.248 1.248 0 0 0 1.831-.264c.146-.163.282-.335.406-.515-.1-1.309-1.07-1.655-2.044-1.963"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-127.639 -85.204)"
    />
    <path
      d="M261.737 283.06a7.128 7.128 0 0 1 1.041-.475 1.528 1.528 0 0 0 1.07-.738 3.774 3.774 0 0 0-1-1.371 1.108 1.108 0 0 0-1.7.491c-.144.328-.284.658-.412.953.127.52.501.946 1 1.14"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-156.966 -125.558)"
    />
    <path
      d="M273.081 185.319c.156.7 1.247 1.13 1.652.634.56-.686 1.3-1.344.99-2.482-.4-.1-.84-.2-1.407-.34-.338.322-.631.618-.94.9a1.238 1.238 0 0 0-.3 1.293"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.41 -82.538)"
    />
    <path
      d="M431.779 89.591c.021.03.087.04.13.04.235 0 .5-.007.569-.282.081-.304.19-.601.326-.885a1.56 1.56 0 0 0-1-2.107.818.818 0 0 0-.6.222c-.06.048.056.313.082.482a1.83 1.83 0 0 1 0 .277c-.608.108-1.187.271-1.377.964a.393.393 0 0 0 .046.128c.5.557 1.382.513 1.826 1.161"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-231.9 -39.67)"
    />
    <path
      d="M449.237 107.347c.04.024.077.051.112.082.707.453 1.183.331 1.558-.423.183-.365.327-.749.487-1.122a.987.987 0 0 0-.734-.768 6.581 6.581 0 0 0-1.366-.106.684.684 0 0 0-.439.305c-.681 1.032-.583 1.69.382 2.031"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-240.098 -47.937)"
    />
    <path
      d="M236.568 390.47c.151.288.285.585.4.889a.802.802 0 0 0 .813.612 2.18 2.18 0 0 0 1.95-.965l-1.167-1.8c-.852.335-1.921.063-1.994 1.267"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.263 -173.815)"
    />
    <path
      d="M203.274 390.737a2.66 2.66 0 0 0-.228-.919 1.118 1.118 0 0 0-1.813-.4 8.494 8.494 0 0 0-.744.762c.031.037.061.076.093.113a6.879 6.879 0 0 0 1.77 1.4l.152-.036c.644-.153.808-.32.771-.916"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.282 -173.774)"
    />
    <path
      d="M384.767 135.325a5.438 5.438 0 0 0-1.36-.645.761.761 0 0 0-.979.7 2.203 2.203 0 0 0 .485 2c.203.033.408.054.614.063.926-.008 1.273-.328 1.516-1.261a.75.75 0 0 0-.275-.855"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-210.854 -61.064)"
    />
    <path
      d="M178.4 208.295a1.183 1.183 0 0 0-1.526.529c-.18.263-.349.534-.514.789.034.095.077.187.129.274.084.11.175.214.272.313 1.17 1.248 1.141.854 2.4.058a.723.723 0 0 0 .225-.479 1.377 1.377 0 0 0-.982-1.485"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-119.596 -93.643)"
    />
    <path
      d="M346.166 88.665a.477.477 0 0 0 .375.634c.414.063.834.072 1.25.025.15-.013.4-.208.4-.331.036-.676.624-1.286.264-2.031-.249-.131-.491-.269-.74-.391-.165-.081-.337-.144-.526-.223a2.345 2.345 0 0 0-.631.3 2.134 2.134 0 0 0-.569.755c-.059.153.158.4.192.608.034.218.028.44-.017.655"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.726 -39.672)"
    />
    <path
      d="M328.689 204.238a1.553 1.553 0 0 0 1.472-.851 1.28 1.28 0 0 0-.221-1.42 8.68 8.68 0 0 0-1.027-.774c-.322.184-.63.391-.921.621-.409.326-.59.864-.462 1.371.133.555.594.974 1.159 1.053"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.532 -90.54)"
    />
    <path
      d="M255.818 228.457c.282.151.609.193.92.119.572-.265 1.194-.562 1.266-1.272-.32-.4-.6-.754-.892-1.1a1.233 1.233 0 0 0-1.41-.243c-.239.145-.44.345-.586.583-.072.114.056.34.054.515-.007.27-.047.538-.077.849.217.215.462.4.728.55"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.467 -101.454)"
    />
    <path
      d="M243.319 144.166c.19.28.557.384.866.245a2.803 2.803 0 0 0 1.476-1.229 2.107 2.107 0 0 0-1.139-1.508c-.223.08-.433.175-.653.231-.267.069-.553.075-.814.16-.762.249-.867.6-.393 1.211.226.291.433.6.657.891"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-148.836 -64.177)"
    />
    <path
      d="M184.121 288.931c.593-.283.978-.877.995-1.534a1.524 1.524 0 0 0-1.469-1.373 2.708 2.708 0 0 0-1.3 2.17 1.578 1.578 0 0 0 1.769.737"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-122.249 -128.114)"
    />
    <path
      d="M402.8 129.863v-.024a.703.703 0 0 0-.692-.7c-.41-.033-.826-.007-1.327-.007l-.824 1.27c.268.4.283.956.772 1.124a1.99 1.99 0 0 0 1.615-.077c.16-.097.315-.202.463-.316 0-.443.02-.858-.007-1.27"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-218.633 -58.617)"
    />
    <path
      d="M197.373 347.736a1.334 1.334 0 0 0-1.019-.853 6.9 6.9 0 0 0-1.228-.013 4.469 4.469 0 0 1-.132.242 8.261 8.261 0 0 1 1.6 2.2 1.103 1.103 0 0 0 .782-1.575"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-127.848 -155.055)"
    />
    <path
      d="M261.366 149.6c.335.127.677.238 1.017.355.472.164.748.035.978-.461.152-.328.3-.657.456-1-.154-1.012-1.042-.983-1.759-1.243-.367.282-.717.585-1.049.908a.846.846 0 0 0 .358 1.441"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-156.968 -66.645)"
    />
    <path
      d="M194.526 336.009c.112.384.179.7.3.994a.65.65 0 0 0 .786.41c.547-.2 1.181-.235 1.584-.69a2.69 2.69 0 0 0-.321-1.925c-1.409-.7-1.766.393-2.345 1.211"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-127.641 -149.618)"
    />
    <path
      d="M413.547 128.88c-.42.026-.79.291-.95.681a3.995 3.995 0 0 0-.165 1.593c.145.576.875.362 1.33.534.719.271 1.3-.289 1.3-1.251-.009-.14-.03-.28-.064-.416a2.034 2.034 0 0 0-1.446-1.14"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-224.141 -58.51)"
    />
    <path
      d="M185.195 322.658a3.202 3.202 0 0 0-1.588.075c-.206.48-.345.862-.528 1.223-.4.788-.3 1.141.489 1.538.204.086.413.157.627.214.254-.382.539-.742.851-1.078.637-.567.656-.546.376-1.36-.071-.2-.151-.407-.227-.611"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-122.469 -144.314)"
    />
    <path
      d="M244.435 189.348c-.671-.043-1.236-.548-1.965-.53l.944 3.087c1.07.153 1.294.041 2.217-1.143-.252-.585-1.076-.712-1.2-1.414"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-148.877 -85.057)"
    />
    <path
      d="M212.679 318.8c.193.445.666.704 1.145.627a4.149 4.149 0 0 0 1.052-.3.682.682 0 0 0 .408-.913c-.106-.394-.242-.78-.372-1.193-.448-.1-.89-.2-1.372-.311-.238.224-.489.43-.706.667a1.203 1.203 0 0 0-.155 1.424"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.61 -141.706)"
    />
    <path
      d="M274.581 279.946c-.4.394-.774.736-1.119 1.1-.5.529-.466.634-.032 1.3.219.422.619.723 1.085.817.12-.035.238-.08.351-.133a1.424 1.424 0 0 0 .372-2.542c-.185-.188-.408-.341-.658-.548"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.443 -125.422)"
    />
    <path
      d="M171.594 204.3a2.75 2.75 0 0 0 1.862-.8c-.185-.846-.159-1.725-1.041-2.182a1.884 1.884 0 0 0-.977.423 7.835 7.835 0 0 1-.318 2.308.501.501 0 0 0 .474.25"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-117.274 -90.594)"
    />
    <path
      d="M261.153 305.17c-.052.05-.1.102-.145.158a2.04 2.04 0 0 0 1.151 2.17c1.19-.287 1.457-1.169 1.6-2.13-1.35-1.1-1.35-1.1-2.609-.2"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.076 -136.303)"
    />
    <path
      d="M237.79 329.3c-.249.1-.548.1-.781.226a4.481 4.481 0 0 0-.689.546 2.638 2.638 0 0 0 1.292 1.723c.08.002.161-.003.24-.014.678-.194 1.337-.388 1.318-1.317 0-.168.277-.342.554-.662l-1.318-.906a4.086 4.086 0 0 1-.616.4"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.153 -147.103)"
    />
    <path
      d="M401.874 104.917a1.276 1.276 0 0 0-1.46.995c-.08.312-.124.634-.212 1.1.31.165.588.333.88.466.82.374 1.056.331 1.722-.328.133-.149.253-.309.357-.48-.21-.462-.393-.829-.545-1.211a.813.813 0 0 0-.741-.541"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-218.742 -47.892)"
    />
    <path
      d="M231.129 288.373c.058.083.125.159.2.226l1.545.325a1.714 1.714 0 0 0 .546-1.808c-.185-.511-.3-1.085-1.055-1.131-.177.073-.394.147-.6.249-1.229.61-1.3.852-.638 2.139"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.67 -128.096)"
    />
    <path
      d="M366.253 143.075c.079-.31.105-.634.182-1.121-.4-.236-.749-.461-1.116-.652a.935.935 0 0 0-.937.039 1.55 1.55 0 0 0-.174 2.406 1.37 1.37 0 0 0 2.045-.672"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-202.581 -63.965)"
    />
    <path
      d="M293.632 202.049a.803.803 0 0 0-.98-.326c-.472.185-.919.434-1.348.642a2.012 2.012 0 0 0 .861 2.033c.617-.094 1.122-.153 1.619-.249a.646.646 0 0 0 .461-.869 19.054 19.054 0 0 0-.613-1.231"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.488 -90.75)"
    />
    <path
      d="M371.733 232.079a.803.803 0 0 0-1.042-.017c-.347.235-.63.552-.825.923-.195.429-.163.929.085 1.33l1.684.319a8.94 8.94 0 0 0 .676-.6l.489-.615.063-.1a4.063 4.063 0 0 0-1.13-1.241"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-205.249 -104.131)"
    />
    <path
      d="M382.2 220.509c-.11.3-.193.606-.286.9.044.136.097.27.157.4.133.268.333.498.579.668l1.653-2.073a2.775 2.775 0 0 0-.8-.477.883.883 0 0 0-1.3.58"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-210.644 -98.793)"
    />
    <path
      d="m286.265 295.445 1.126-.365c.13-.37.253-.668.34-.978a1.002 1.002 0 0 0-.7-1.4c-.335-.123-.691-.191-1.008-.274-1.157 1.484-1.118 1.918.246 3.015"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-167.801 -130.951)"
    />
    <path
      d="M298.483 183.242c-.25.2-.458.448-.611.729-.21.351-.36.737-.551 1.144l.771 1.07c.6-.155 1.082-.281 1.581-.413.083-.109.156-.225.217-.348.492-1.253.036-1.652-.771-2.219a.692.692 0 0 0-.635.038"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.172 -82.544)"
    />
    <path
      d="M184.392 225.98a.926.926 0 0 0-1.21.34 2.338 2.338 0 0 0-.325 1.732c.087.553.6.645 1.087.873 1.28-.615 1.428-.88 1.325-2.317-.169-.121-.355-.252-.541-.386a2.665 2.665 0 0 0-.335-.242"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-122.454 -101.472)"
    />
    <path
      d="M220.37 225.566a1.685 1.685 0 0 1-1.492.181c.064.706.086 1.334.187 1.949.138.51.628.85 1.154.8.3-.07.591-.176.867-.313a2.18 2.18 0 0 0-.014-2.418c-.21-.066-.582-.3-.7-.2"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.427 -101.325)"
    />
    <path
      d="M303.008 240.477a2.598 2.598 0 0 0 2.473.653 6.259 6.259 0 0 0 .072-1.941.737.737 0 0 0-.992-.681 7.462 7.462 0 0 0-1.5.731.822.822 0 0 0-.055 1.238"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-175.578 -107.047)"
    />
    <path
      d="M364.184 238.594c-.545 1.11-.551 1.579-.042 2.061a1.86 1.86 0 0 0 1.83.2c.774-.494.666-1.22.511-1.926a2.889 2.889 0 0 0-2.3-.336"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-202.604 -107.053)"
    />
    <path
      d="M160.068 217.318c.182-.236.338-.468.525-.674a1.404 1.404 0 0 0 .313-1.742c-.777.319-1.577.576-2.394.77.147.224.265.466.349.72.164.6.6.739 1.207.926"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-111.689 -96.611)"
    />
    <path
      d="M219.2 383.152a.83.83 0 0 0-.441.3 1.036 1.036 0 0 0 .363 1.519c.507.172.332.589.546.832 1.434.1 1.446.092 1.963-1.715-.461-.5-1.175-.616-1.751-.959a1.018 1.018 0 0 0-.68.018"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.294 -171.103)"
    />
    <path
      d="M323.1 138.266a4.76 4.76 0 0 0 .7-.331c.5-.33.832-.721.514-1.392a1.858 1.858 0 0 1-.016-.75 1.7 1.7 0 0 0-2.346-.039c.018.974-.279 2.061 1.148 2.512"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-184.076 -61.354)"
    />
    <path
      d="M346.813 149.9a1.012 1.012 0 0 0 1.409-.445c.156-.223.3-.456.417-.641-.215-.354-.392-.663-.585-.962a1.232 1.232 0 0 0-1.9-.35c-.15.134-.284.285-.4.45-.04 1.034.225 1.658 1.059 1.948"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.623 -66.624)"
    />
    <path
      d="M400.943 155.808c.453.065.911.077 1.367.038.42-.056.493-.506.658-.828a.984.984 0 0 0 .043-.29c-.4-.37-.183-1.112-.74-1.387a2.173 2.173 0 0 0-1.551-.4c-.418.873.06 1.733-.043 2.581-.011.087.166.275.267.284"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-218.894 -69.15)"
    />
    <path
      d="M230.7 324.1c.178.551.725.902 1.3.835 1.252-.177 1.477-.449 1.409-1.74l-1.439-1c-1.223.482-1.516.923-1.266 1.908"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.629 -144.135)"
    />
    <path
      d="M329.943 263.005a4.794 4.794 0 0 1-.407-.837l-1.012.048-.992 1.684c.114.144.178.284.289.35.335.2.778.2.885.755.057.3.682.308.973.067.478-.389.782-.953.842-1.566a3.443 3.443 0 0 1-.578-.5"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.554 -117.547)"
    />
    <path
      d="m310.91 283.067.984-.794V280.6l-.985-.685c-.826.177-1.3.559-1.236 1.435a2.755 2.755 0 0 1-.165.813c.3.7.869.774 1.4.9"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.57 -125.41)"
    />
    <path
      d="M209.356 275.972a2 2 0 0 0-1-1.574c-1.268.348-1.268.348-1.755 1.361.241.373.468.778.747 1.143a.895.895 0 0 0 1.332.286.973.973 0 0 0 .674-1.215"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-132.99 -122.965)"
    />
    <path
      d="M430.324 149.411c.018.084.044.167.076.247.763.389.763.389 2.347-.062l.3-1.327-.758-.959c-1.914.222-2.038.358-1.966 2.1"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-232.078 -66.672)"
    />
    <path
      d="M230.776 264.124c.606.7 1.42.562 2.193.48a1.11 1.11 0 0 0 .555-1.588 3.637 3.637 0 0 0-1.087-.816 1.011 1.011 0 0 0-1.076.132c-.233.565-.747 1.059-.585 1.792"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.684 -117.517)"
    />
    <path
      d="M249.614 268.294a6.814 6.814 0 0 1-.48.641c-.266.33-.357.768-.246 1.177.017.29.242.529.531.562 1.095.1 1.968.289 2.4-1.188-.448-.9-1.056-1.5-2.2-1.192"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.706 -120.224)"
    />
    <path
      d="M255.481 288.907c.642.068 1.3.218 1.782-.3a12.585 12.585 0 0 1-.409-1.734 1.01 1.01 0 0 0-.745-.921l-1.6.96c-.28.936.607 1.292.847 1.94.012.031.079.054.123.059"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.187 -128.08)"
    />
    <path
      d="M231.416 155.955a1.301 1.301 0 0 0 1.422-.081c.354-.346.675-.724 1.005-1.082a2.104 2.104 0 0 0-1.883-1.462c-.235.115-.531.242-.807.4-.102.082-.191.18-.262.29.067.468.107.922.206 1.362.042.219.154.419.319.57"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.749 -69.34)"
    />
    <path
      d="M353.059 202.279a4.008 4.008 0 0 1-.9-.368c-.194.57-.357 1.042-.523 1.524a4.422 4.422 0 0 0 1.323 1.366c1.028-.089 1.311-.881 1.732-1.6l-.643-1.019a4.333 4.333 0 0 1-.99.1"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-197.231 -90.858)"
    />
    <path
      d="M243.752 370.787a1.625 1.625 0 0 0 .418 2.7c.287.07.581.109.877.118l.96-1.6c-.419-.413-.828-.843-1.268-1.241a.647.647 0 0 0-.987.022"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.202 -165.552)"
    />
    <path
      d="M412.653 105.373c-.116.667-.76 1.3.1 2.217.564-.141 1.1.526 1.854.2.085-.207.185-.458.29-.707.105-.249.222-.505.321-.73-.716-1.053-.716-1.053-2.567-.975"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-224.107 -48.084)"
    />
    <path
      d="M202.544 171.487a1.518 1.518 0 0 0-1.632.458c-.082.507-.165 1-.257 1.574 1.683 1 1.59.884 2.742-.3.084-.108.155-.226.211-.351-.2-.593-.429-1.181-1.063-1.383"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.355 -77.347)"
    />
    <path
      d="M200.9 294.815c.042.089.1.17.172.238h1.67a4.62 4.62 0 0 0 .756-1.382 1.856 1.856 0 0 0-1.174-1.308c-.217.1-.386.174-.553.256-1.446.7-1.448.7-.87 2.2"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.304 -130.922)"
    />
    <path
      d="M317.585 203.911a.978.978 0 0 0 .013-1.569 8.806 8.806 0 0 0-1.069-.75 3.566 3.566 0 0 0-1.446 1.251.71.71 0 0 0-.046.636 1.575 1.575 0 0 0 2.548.432"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-180.995 -90.716)"
    />
    <path
      d="M419.913 123.147c-.8.328-1.622.576-1.711 1.73l1.183 1.243a3.934 3.934 0 0 0 1.7-.994.667.667 0 0 0 .042-.889 4.05 4.05 0 0 0-1.212-1.09"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-226.715 -55.97)"
    />
    <path
      d="M220.2 240.762a.558.558 0 0 0 .726-.265c.243-.67.759-1.368-.041-2.081a3.155 3.155 0 0 0-1.9-.313l-.524 1.578a2.601 2.601 0 0 0 1.738 1.082"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.245 -106.877)"
    />
    <path
      d="M323.873 208.008a1.423 1.423 0 0 0-1.93.334v1.325a.83.83 0 0 0 .554.868c.29.11.588.2.931.313.329-.468.633-.953.91-1.453a.955.955 0 0 0-.464-1.387"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-184.078 -93.45)"
    />
    <path
      d="M207.321 334.964a2.274 2.274 0 0 0-.777 1.285.703.703 0 0 0 .3.858c.484.224.811.825 1.466.649a.784.784 0 0 0 .236-.144c.357-.239.552-.657.507-1.084-.046-.321-.077-.643-.139-.96a.99.99 0 0 0-.972-.822.989.989 0 0 0-.623.222"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-132.949 -149.694)"
    />
    <path
      d="M182.294 299.049a4.42 4.42 0 0 0 .733 2.275c1.325.092 1.759-.188 2.372-1.526a2.518 2.518 0 0 0-3.105-.748"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-122.223 -133.785)"
    />
    <path
      d="M450.695 128.647c-.374.174-.74.362-1.123.515-.437.181-.705.63-.657 1.1-.094.57.257 1.126.811 1.288.238.091.467.208.73.335l.6-.863s.059-.686.192-1.561a1.809 1.809 0 0 0-.554-.817"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-240.317 -58.407)"
    />
    <path
      d="M231.489 252.473c.434 0 .938.045 1.435.009a.983.983 0 0 0 .945-1.414c-.07-.218-.15-.432-.237-.676-.177-.105-.377-.217-.57-.34a1.049 1.049 0 0 0-1.373.2c-.305.219-.479.58-.46.955.035.4-.121.829.26 1.271"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.896 -112.102)"
    />
    <path
      d="M317.087 250.052c-.965-.073-1.374.185-1.628 1.121-.04.3-.044.605-.012.906 1.4.794 1.906.709 2.9-.435-.144-.325-.313-.645-.43-.983a.851.851 0 0 0-.833-.609"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-181.191 -112.175)"
    />
    <path
      d="M287.948 197.785c.137-.941-.229-1.5-1.332-2.072-1.041.5-1.041.5-1.414 1.926.182.389.436.753.891.784a6.005 6.005 0 0 0 1.514-.1c.147-.027.313-.34.341-.541"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-167.805 -88.112)"
    />
    <path
      d="M263.5 161.125c.078-.345.133-.695.164-1.047-.365-.463-.586-1.023-1.243-1.09-1.4.621-1.426.658-1.337 2.068.216.18.46.387.707.589a1 1 0 0 0 1.709-.521"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.108 -71.846)"
    />
    <path
      d="M317.4 192.167c.252-.326.434-.7.533-1.1a.747.747 0 0 0-.16-.911 3.169 3.169 0 0 0-2.477.407l-.315 1.33c1.115.813 1.757.912 2.419.276"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-180.996 -85.608)"
    />
    <path
      d="M305.061 140.559c-.316.262-.617.473-.87.731a1.038 1.038 0 0 0-.339.584c-.001.496.032.991.1 1.482a.332.332 0 0 0 .173.211c.284.146.577.272.941.438a2.236 2.236 0 0 1 1.189-1.518.644.644 0 0 0 .03-1.15c-.335-.246-.7-.45-1.224-.778"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-176.064 -63.683)"
    />
    <path
      d="M249.467 244.792a2.197 2.197 0 0 1-.484.795.402.402 0 0 0 .138.6c.35.252.9.192 1.057.778a4.437 4.437 0 0 0 1.795-1.638 7.004 7.004 0 0 0-.708-1.034 1.027 1.027 0 0 0-1.8.5"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.726 -109.47)"
    />
    <path
      d="M280.811 322.969a2.36 2.36 0 0 0-1.577.815c.071.35.134.7.214 1.058.126.543.288.695.844.72 1.677.078 1.725-.123 1.658-1.7a.948.948 0 0 0-1.14-.895"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.162 -144.47)"
    />
    <path
      d="m177.537 292.553-1.211.7c.11.55.18 1 .292 1.445a.751.751 0 0 0 .714.56 1.791 1.791 0 0 0 2.051-1.149c-.537-.574-.71-1.533-1.846-1.553"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-119.58 -131.006)"
    />
    <path
      d="M189.614 295.187c.725.036 1.084-.438 1.547-1-.089-.314-.172-.575-.234-.842-.2-.875-.509-1.112-1.338-.925a3.712 3.712 0 0 0-.813.391l-.373 1.514c.194.503.672.842 1.21.859"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-124.929 -130.922)"
    />
    <path
      d="M286.209 341.278a1.9 1.9 0 0 0-.761.247c-.295.192-.14.475-.05.741a.542.542 0 0 1-.045.393c-.213.381 0 .63.261.826.052.04.108.076.162.113l1.738-2.114a1.213 1.213 0 0 0-.477-.189 2.77 2.77 0 0 0-.828-.016"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-167.833 -152.577)"
    />
    <path
      d="M232.867 337.65a1.624 1.624 0 0 0 1.184-1.413.766.766 0 0 0-.727-.98c-.507-.037-1.019-.008-1.581-.008-.584 1.15-.584 1.15.065 2.5.355-.003.71-.036 1.059-.1"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.935 -149.912)"
    />
    <path
      d="M251.613 113.649c-.35-.624.173-1.222-.27-1.791-.105-.138.1-.56-.2-.711a2.257 2.257 0 0 0-.783-.188 1.247 1.247 0 0 0-1.4.844c.638 1.936.734 2.008 2.651 1.846"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.753 -50.568)"
    />
    <path
      d="M340.928 216.893a1.263 1.263 0 0 0 1.071-.772c.4-.56.49-1.286.238-1.926-.242-.06-.578-.261-.827-.183-.508.16-1.145.028-1.508.574-.09.104-.166.218-.228.34-.042.123-.08.311-.017.395.4.546.6 1.261 1.272 1.572"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-191.91 -96.21)"
    />
    <path
      d="M256.726 213.547c-.441-.389-1.327-.1-1.256.46.085.655-.443 1.095-.4 1.685a2.179 2.179 0 0 0 3.018-.419 1.619 1.619 0 0 0-.19-.54c-.368-.417-.76-.813-1.174-1.185"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.458 -95.932)"
    />
    <path
      d="M383.279 207.693c-.395.266-.772.557-1.129.871a.67.67 0 0 0-.127.619c.305.511.659.992 1.055 1.436a.642.642 0 0 0 .973-.133c.344-.577.649-1.175.984-1.79a2.785 2.785 0 0 0-.38-.313c-.4-.215-.819-.414-1.375-.69"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-210.675 -93.418)"
    />
    <path
      d="M347.593 207.87a.922.922 0 0 0-1.375.459c-.2.36-.361.741-.533 1.1a1.703 1.703 0 0 0 2.184 1.008c.13-.227.274-.453.393-.692.446-.908.231-1.506-.67-1.873"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.595 -93.436)"
    />
    <path
      d="M190.7 207.736a3.336 3.336 0 0 0-2.262 1.3c.168.416.36.823.574 1.218a.938.938 0 0 0 .528.4 1.438 1.438 0 0 0 1.672-1.382 3.542 3.542 0 0 0-.513-1.536"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-124.945 -93.438)"
    />
    <path
      d="M195.4 204.694a2.04 2.04 0 0 0 1.927-1.108 5.07 5.07 0 0 0-1.4-1.4l-1.583.411-.358.726c.311.672.547 1.359 1.418 1.371"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-127.402 -90.98)"
    />
    <path
      d="M359.249 198.305c.413-.225.849-.462 1.278-.7a1.665 1.665 0 0 0-.785-2.1 1.473 1.473 0 0 0-1.864 1.584c.341.471.579 1.12 1.372 1.211"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-199.995 -88.013)"
    />
    <path
      d="M250.867 235.014c.05-.317.134-.629.251-.928.192-.291.414-.562.661-.808a4.01 4.01 0 0 0-2.5-1.111l-.7 1.557c.739.483 1.044 1.626 2.282 1.29"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.586 -104.26)"
    />
    <path
      d="M335.121 231.961c-1.237.51-1.237.51-1.518 1.755.264.444.69.77 1.188.907.66-.2 1.526-.049 1.817-1.027-.322-.68-.514-1.5-1.486-1.635"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-189.243 -104.167)"
    />
    <path
      d="M197.417 373.744c.034-.154.081-.306.141-.452.08-.164.215-.3.306-.461.3-.533.24-.794-.291-1.156-.267-.183-.55-.34-.824-.507-.32.077-.634.177-.939.3a18.77 18.77 0 0 0-.141 2.119 2.235 2.235 0 0 0 1.747.154"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-128.148 -165.826)"
    />
    <path
      d="M294.266 227.262a3.402 3.402 0 0 0-.165-.522.602.602 0 0 0-.3-.274c-.931-.329-2.15 0-2.1 1.208l.03.687c.434.443 1.07.626 1.673.48.941-.189 1.175-.647.86-1.579"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.683 -101.68)"
    />
    <path
      d="M171.99 225.586c-1.619.836-1.7 1.389-.466 2.884a1.842 1.842 0 0 0 1.951-.729c-.247-1.092-.247-1.092-1.485-2.156"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-117.078 -101.344)"
    />
    <path
      d="M297.62 222.435c.544-.008.748.442 1.194.617.274-.271.525-.535.794-.781a.991.991 0 0 0 .323-1.123 1.16 1.16 0 0 0-1.154-.823h-.993a2.001 2.001 0 0 0-.646 1.177c-.11.491.026.941.483.933"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.075 -99.013)"
    />
    <path
      d="M238.152 222.416a2.704 2.704 0 0 0 1.615-1.2 3.532 3.532 0 0 0-1.513-1.778 1.733 1.733 0 0 0-1.473 1.612c.329.568.802 1.04 1.371 1.369"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.357 -98.62)"
    />
    <path
      d="M237.776 171.589a2.974 2.974 0 0 0-.283.126c-1.042.6-1.077 1.433-.093 2.093 1.087.726 1.287.7 2.2-.272.072-.102.129-.214.167-.333-.412-.829-.866-1.558-1.995-1.613"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.315 -77.427)"
    />
    <path
      d="M436.7 142.917c.031.134 0 .284.041.413a.702.702 0 0 0 .857.519c.357-.1.7-.242 1.177-.411.181-.486.589-.848.552-1.369l-1.629-1.11c-1.287.617-1.3.656-1 1.958"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-234.834 -63.86)"
    />
    <path
      d="M375.835 154.358c.242.921.474 1.755 1.364 2.15 1.034-.08 1.256-.957 1.721-1.576-1.059-1.408-1.617-1.518-3.086-.573"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-207.949 -69.522)"
    />
    <path
      d="m396.318 150.009.7-1.3a3.09 3.09 0 0 0-1.433-1.449c-.645.087-1.21.48-1.515 1.055a1.203 1.203 0 0 0 .415 1.376l1.833.318Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-215.989 -66.652)"
    />
    <path
      d="M371.02 147.3c-.079.018-.141.11-.2.16v.7l-.916.4.348 1.1 1.171.575c1.26-.838 1.4-1.228 1.071-2.913a3.084 3.084 0 0 0-1.47-.026"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-205.32 -66.635)"
    />
    <path
      d="M333.312 197.09a2.183 2.183 0 0 0 2.488.763l.716-1.417-1.616-1.236c-1.178.74-1.178.74-1.583 1.894"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-189.114 -87.883)"
    />
    <path
      d="M226.233 195.121a7.88 7.88 0 0 0-.912.878 1.55 1.55 0 0 0 .357 1.988c.334.148.699.217 1.064.2.724.006 1.033-.321.906-1.057a2.896 2.896 0 0 0-1.414-2.012"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-141.186 -87.85)"
    />
    <path
      d="M383.3 183.552a1.705 1.705 0 0 0-1.381 1.649 1.354 1.354 0 0 0 1.485 1.129 1.718 1.718 0 0 0 1.291-1.593 1.791 1.791 0 0 0-1.395-1.185"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-210.645 -82.725)"
    />
    <path
      d="M225.912 183.66c-.547.265-1.122.507-1.219 1.216a.904.904 0 0 0 .037.413c.271.6.85 1.006 1.506 1.058.71 0 .847-.178 1.169-.973a1.254 1.254 0 0 0-.358-1.407.966.966 0 0 0-1.135-.306"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-140.999 -82.736)"
    />
    <path
      d="M302.848 179.171c.07.186.151.368.243.544.8 1.29 1.359 1.382 2.511.421.035-.029.069-.061.1-.094.426-.457-.02-.858-.081-1.286-.05-.355-.262-.717-.587-.651-.77.16-1.7.061-2.185 1.067"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-175.621 -80.31)"
    />
    <path
      d="M322.519 171.44a.953.953 0 0 0-.914.948c0 .248.098.487.271.664.2.254.375.526.573.779a.979.979 0 0 0 1.351.2c.356-.213.558-.614.516-1.026-.027-1.2-.689-1.757-1.8-1.569"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-183.932 -77.346)"
    />
    <path
      d="M281.84 262.931a4.846 4.846 0 0 0-.592-.656c-.48.1-.846.149-1.2.243a1.519 1.519 0 0 0-.893 1.683c.08.215.255.381.473.452a4.85 4.85 0 0 0 1.521 0c1.035-.213 1.256-.808.692-1.725"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.105 -117.594)"
    />
    <path
      d="M279.163 118.133a1.26 1.26 0 0 0-.023.33 9.606 9.606 0 0 0 1.73 1.647c.269-.219.524-.455.765-.705a1.325 1.325 0 0 0-.159-1.851c-1.05-.877-1.78-.7-2.314.578"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.12 -53.254)"
    />
    <path
      d="M220.3 358.549a2.112 2.112 0 0 0-1.634 2.181c.784.518.913.617 1.249.518.645-.189 1.389-.23 1.827-1.155-.608-.4-.429-1.432-1.441-1.544"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.334 -160.237)"
    />
    <path
      d="M317.4 120.056c.164.021.363-.219.563-.352-.176-.383-.488-.732-.441-1.024.111-.7-.309-1.109-.574-1.615a2.143 2.143 0 0 0-2.053 1.091c.296.357.571.732.822 1.122.384.676 1.052.7 1.683.778"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-180.954 -53.275)"
    />
    <path
      d="M432.463 111.088a.624.624 0 0 0-.232-.061c-1.057.135-1.765.592-1.775 1.821.195.23.402.449.622.656a.98.98 0 0 0 1.385.018c.954-.909 1.627-1.255 0-2.434"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-232.142 -50.602)"
    />
    <path
      d="M449.035 93.367a7.918 7.918 0 0 0-.558.921l.5 1.325c.28-.067.565-.108.853-.123.303.044.602.114.892.211.158-.435.272-.723.365-1.012-.205-.105-.413-.21-.624-.315a1.966 1.966 0 0 1-1.071-1.332 1.326 1.326 0 0 0-.36.324"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-240.125 -42.637)"
    />
    <path
      d="m346.172 269.612 1.346-1.688c-.091-.05-.188-.1-.29-.149-.994.8-1.286 1.165-1.056 1.837"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.777 -120.031)"
    />
    <path
      d="M263.176 270.373a1.4 1.4 0 0 0 .129-2 3.76 3.76 0 0 0-.666-.6 3.065 3.065 0 0 0-1.815 1.538c.199.384.425.754.676 1.106a1.048 1.048 0 0 0 1.676-.041"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.007 -120.028)"
    />
    <path
      d="M227.225 366.651c.286-.97.2-1.239-.707-1.578a.977.977 0 0 0-1.249.308c-.237.338-.45.691-.638 1.058l1.1 1.65a1.932 1.932 0 0 0 1.5-1.438"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-140.976 -163.082)"
    />
    <path
      d="M156.21 329.548c-.12-.142-.255-.269-.387-.4a6.983 6.983 0 0 0-.186-.177c.187.518.416 1.019.685 1.5a.683.683 0 0 0-.111-.919"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-110.416 -147.136)"
    />
    <path
      d="M219.807 273.922a1.606 1.606 0 0 0-.826 1.873c.09.738.7 1.136 1.5 1.327l1.147-1.506c-.682-.656-.73-1.731-1.822-1.7"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.461 -122.753)"
    />
    <path
      d="M396.894 171.665c-.258-.131-.537-.285-.826-.419a1.006 1.006 0 0 0-.541-.157 1.013 1.013 0 0 0-1.007 1.048c-.068 1.4-.057 1.75 1.556 1.666.178-.009.354-.053.624-.1-.078-.7.556-1.265.194-2.042"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-216.216 -77.215)"
    />
    <path
      d="M184.472 192.3c.128-.183.235-.33.338-.482.584-.862.471-1.424-.413-1.924a2.856 2.856 0 0 0-.772-.294.783.783 0 0 0-.9.488c-.121.3-.194.614-.287.921.313.917.8 1.585 2.034 1.291"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-122.287 -85.396)"
    />
    <path
      d="M432.315 99.505c-.369-.17-.736-.345-1.043-.491-.937.175-.864 1.028-1.3 1.556.565.418.7 1.183 1.478 1.35.383-.235.754-.491 1.109-.767a.918.918 0 0 0-.243-1.648"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-231.928 -45.282)"
    />
    <path
      d="M293.573 322.661a1.785 1.785 0 0 0-.555-.4c-.137-.048-.464.045-.479.118-.123.645-.7.625-1.164.788l.193 1.452c.753.14 1.429.593 2.19.237.8-1.207.8-1.271-.185-2.192"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.539 -144.157)"
    />
    <path
      d="m382.527 111.154-.651 1.77c.276.485.523.827.987.866a2.076 2.076 0 0 0 1.727-.829c-.414-1.9-.541-2.01-2.063-1.807"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-210.625 -50.627)"
    />
    <path
      d="M274.853 173.935c.267-.638 1.275-.958.913-1.952l-1.277-1.037c-.342.204-.672.427-.988.668-.401.34-.552.896-.377 1.392.389.576.793 1.189 1.73.929"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.414 -77.142)"
    />
    <path
      d="M376.927 167.954c.306.077.61.162.871.232.557-.433.564-1.024.771-1.627a5.737 5.737 0 0 0-.735-.937 1.35 1.35 0 0 0-1.984.812c-.136 1.012.037 1.256 1.077 1.52"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-207.938 -74.691)"
    />
    <path
      d="M280.941 165.368a.979.979 0 0 0-1.413.374c-.13.315-.235.641-.313.973.067.289.151.573.253.852a.892.892 0 0 0 .337.413 1.26 1.26 0 0 0 1.855-.808c.079-.306.1-.625.165-1.012a9.781 9.781 0 0 0-.885-.793"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.153 -74.599)"
    />
    <path
      d="M266.854 167.3c.365.277.722.57 1.1.822.416.228.936.157 1.275-.174a.946.946 0 0 0 .291-1.121c-.267-.453-.591-.873-.87-1.276l-1.709.281c-.121.217-.248.41-.34.617a.655.655 0 0 0 .249.851"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.536 -74.752)"
    />
    <path
      d="M286.583 316.581a1.235 1.235 0 0 0-1.274.66.632.632 0 0 0-.067.516c.309.546.666 1.066 1.018 1.614l1.783-.674a8.733 8.733 0 0 0-.25-1.332 1.302 1.302 0 0 0-1.209-.785"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-167.808 -141.644)"
    />
    <path
      d="M232.113 142.6c-.681.624-.681.624-.376 1.309.208.021.439.035.666.069a1.58 1.58 0 0 0 1.519-.807c.25-.351.25-.826 0-1.177a1.81 1.81 0 0 0-1.941-.795c-.3.06-.571.212-.578.511-.006.177.215.369.353.538.113.124.233.242.36.352"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.974 -63.948)"
    />
    <path
      d="M419.959 159.528a1.79 1.79 0 0 0-1.627 1.272.408.408 0 0 0-.008.135c.041.083.078.169.111.256.221.933.29 1.053 1.465 1.032a.823.823 0 0 0 .9-.689c.108-.299.247-.585.417-.853-.319-.5-.572-1.157-1.253-1.151"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-226.767 -72.084)"
    />
    <path
      d="M318.413 214.82c-.8-1.076-1.7-.856-2.6-.464-.1.567-.183 1.064-.3 1.727.202.12.412.228.628.321 1.136.406 1.7.142 2.119-1a5.59 5.59 0 0 0 .154-.585"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-181.229 -96.227)"
    />
    <path
      d="M131.5 234.356c.107-.2.192-.417.311-.675l-.479-.933a12.827 12.827 0 0 0-.912 2.051c.138 0 .276-.006.414-.012a.759.759 0 0 0 .665-.431"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-99.247 -104.516)"
    />
    <path
      d="m334.508 244.4-1.447 1.175c-.019.267-.015.534.013.8.039.18.141.34.288.452.552.263 1.2.234 1.725-.079a4.887 4.887 0 0 0 1.309-1.47c-.425-.749-1.275-.447-1.888-.878"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-188.997 -109.678)"
    />
    <path
      d="M239.121 244.529a.78.78 0 0 0-1.021-.06.68.68 0 0 1-.4.1c-.283-.041-.587-.105-.741.183a2.01 2.01 0 0 0-.188.784c-.1.508.116 1.029.546 1.317.261-.074.529-.121.8-.14.284.037.564.104.834.2.206-.317.389-.649.546-.993.164-.5.012-1.053-.384-1.4"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.351 -109.637)"
    />
    <path
      d="M279.5 289.047c.62.143 1.272.031 1.81-.309.646-.443.7-.793.237-1.5-.1-.155-.174-.33-.284-.48a.887.887 0 0 0-1.333-.206 9.96 9.96 0 0 0-.842.877l.412 1.618Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.096 -128.252)"
    />
    <path
      d="M461.407 107.9v-.057c-.069-.713-.19-1.42-.363-2.115-.32.34-.584.728-.782 1.151a4.63 4.63 0 0 0 1.149 1.022"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-245.343 -48.253)"
    />
    <path
      d="M445.1 87.224a1.794 1.794 0 0 0-1.529-.452 1.072 1.072 0 0 0-.522.139c-.381.316-.61.779-.629 1.273.222.315.481.682.714 1.016 1.894.15 2.185-.153 1.966-1.976"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-237.443 -39.852)"
    />
    <path
      d="M221.317 335.4c-.883.2-1.637-.437-2.58 0a1.871 1.871 0 0 0 .457 1.936c.384.417.99.552 1.515.338.452-.159.746-.603.715-1.081-.021-.357-.063-.713-.106-1.193"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.316 -149.916)"
    />
    <path
      d="M255.227 117.746a1.48 1.48 0 0 0-.249 1.365c.288.213.506.385.735.54.398.252.897.29 1.329.1.359-.127.373-.491.457-.8a4.78 4.78 0 0 1 .2-.5 5.611 5.611 0 0 0-.257-.522c-.655-1.028-1.413-1.075-2.213-.18"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.394 -53.297)"
    />
    <path
      d="M225.5 306.938c.249-.098.521-.126.785-.082.483.125.787-.108 1.153-.428a7.59 7.59 0 0 0-.487-1.066 2.847 2.847 0 0 0-.734-.778.698.698 0 0 0-.65 0c-.439.326-.848.69-1.224 1.087a.558.558 0 0 0-.011.761c.294.379.612.685 1.169.506"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-140.776 -136.3)"
    />
    <path
      d="M225.243 331.207c.44.115.889.193 1.392.3.272-.27.584-.546.859-.857a.7.7 0 0 0-.162-1.169 3.415 3.415 0 0 0-1.833-.442l-.662.958a.804.804 0 0 0-.074.118.741.741 0 0 0 .481 1.094"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-140.992 -147.166)"
    />
    <path
      d="M159.618 262.173c-.461-.022-1.092.353-1.106.853-.014.552-.578 1.033-.218 1.585.307.474.911.361 1.3.6a1.772 1.772 0 0 0 1.09-1.951 1.22 1.22 0 0 0-1.062-1.086"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-111.54 -117.55)"
    />
    <path
      d="m237.932 379.525 1.145-.236c.739-1.021.739-1.021.594-2.081l-1.344-.635c-.339.245-.775.4-.9.685a3.73 3.73 0 0 0-.131 1.362c.029.393.273.739.633.9"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.586 -168.221)"
    />
    <path
      d="M340.867 95.693a3.006 3.006 0 0 0 1.06-.251.877.877 0 0 0 .5-1.257 4.65 4.65 0 0 0-.483-.841 1.11 1.11 0 0 0-.276-.23l-1.276-.081a3.97 3.97 0 0 0-.338.642 4.6 4.6 0 0 0-.194 1.069.956.956 0 0 0 1.007.949"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-192.016 -42.633)"
    />
    <path
      d="M358.3 101.216a3.478 3.478 0 0 0 1.888-.13c.021-.263.03-.494.061-.72.16-1.151.111-1.245-1.018-1.77-.219.1-.478.2-.713.334a1.55 1.55 0 0 0-.775 1.748c.041.285.27.507.556.539"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-199.914 -45.096)"
    />
    <path
      d="M224.966 343.178a2.91 2.91 0 0 0 2.043.041c.2-.12.362-.295.465-.505a.572.572 0 0 0 0-.516c-.5-.5-.8-1.3-1.744-1.212l-1.462 1.165c.073.515.238.94.695 1.027"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-140.816 -152.456)"
    />
    <path
      d="M198.143 383.975a3.365 3.365 0 0 0-1.5-.536c.24.896.583 1.762 1.022 2.58.648-.484.6-1.233.478-2.043"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-128.579 -171.262)"
    />
    <path
      d="M250.813 330.831c.37-.197.603-.584.603-1.003 0-.415-.228-.798-.592-.997a6.439 6.439 0 0 0-1-.456 5.979 5.979 0 0 0-1.5 1.748l1.863 1.143.626-.435Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.471 -146.871)"
    />
    <path
      d="M166.985 258.184a1.765 1.765 0 0 0-1.13-2.1l-1.232.45-.309 1.831 1.574.571 1.1-.75"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-114.259 -114.852)"
    />
    <path
      d="M207.022 252.45c.291.18.592.344.9.492 1.7-.866 1.844-1.558.651-2.684-.47.067-.928.119-1.378.2a.759.759 0 0 0-.66.772c-.093.467.1.947.491 1.219"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-132.952 -112.272)"
    />
    <path
      d="M159.716 237.679a2.576 2.576 0 0 0-.526.833 5.224 5.224 0 0 0-.008 1.524c.177.542.734.873 1.294.768.182-.064.459-.327.432-.437-.147-.6.449-.768.665-1.268-.48-.642-.739-1.481-1.855-1.42"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-111.963 -106.698)"
    />
    <path
      d="M141.74 232.246a1.566 1.566 0 0 0-1.527.745c-.095.162-.082.587-.016.612.651.241.1.825.306 1.156.786.274 1.357-.281 1.944-.277a2.234 2.234 0 0 0 .323-2.029 7.532 7.532 0 0 0-1.029-.208"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-103.554 -104.292)"
    />
    <path
      d="M178.161 231.511c-.529-.252-.834.274-1.14.6a4.568 4.568 0 0 0-.489.749 2.577 2.577 0 0 0 1.281 1.49.673.673 0 0 0 1.049-.288c.154-.332.264-.683.329-1.044.13-.658-.427-1.218-1.031-1.506"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-119.671 -103.94)"
    />
    <path
      d="M207.741 228.554c.48.009.949-.15 1.326-.448v-1.132a1.621 1.621 0 0 0-1.861-.7c-.692.145-.859.626-1.067 1.122a1.3 1.3 0 0 0 1.6 1.157"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-132.785 -101.623)"
    />
    <path
      d="M323.064 222.4c.861-1.309 1.111-1.949-.2-2.955-.6.551-1.734.492-1.811 1.686a1.687 1.687 0 0 0 2.014 1.269"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-183.683 -98.622)"
    />
    <path
      d="m360.084 113 .328-.977c-.6-.544-.94-1.371-1.874-1.049-.777.267-.984.9-.987 1.747l1.25.956 1.283-.677Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-199.85 -50.547)"
    />
    <path
      d="M304.274 119.589c1.185.087 1.693-.224 1.988-1.217a.924.924 0 0 0-.457-1.18c-.32-.18-.667-.315-1.035-.485l-.955.723c-.008.808-.192 1.573.46 2.158"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-176.035 -53.119)"
    />
    <path
      d="M179.178 172.346a13.265 13.265 0 0 0-1.835-.331 1.858 1.858 0 0 0-1.014.348c-.189.125-.251.443-.393.722.438.368.835.676 1.2 1.019.184.173.091.558.456.623.441.079.5-.326.715-.535.293-.282.6-.549.87-.793l.001-1.053Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-119.407 -77.614)"
    />
    <path
      d="M285.923 162.088a1.245 1.245 0 0 0 1.822-.875c.088-.352.155-.71.216-.994-.277-1.1-1.176-.467-1.753-.7-.393-.16-.633.273-.874.583.074.559.105 1.019.209 1.46a.872.872 0 0 0 .379.53"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-167.864 -72.064)"
    />
    <path
      d="m425.553 141.049-1.237.878c.343 1.542.343 1.542 1.075 2.27l1.264-.6.471-1.486a4.043 4.043 0 0 1-.892-.327 4.368 4.368 0 0 1-.681-.731"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-229.423 -63.9)"
    />
    <path
      d="M218.936 155.793c.121.214.263.415.425.6a4.006 4.006 0 0 0 1.855-1.285.685.685 0 0 0-.26-1.133c-.462-.2-.937-.36-1.506-.575-.76.41-1.028.936-.645 1.727.1.2.04.466.13.667"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.319 -69.37)"
    />
    <path
      d="M358.953 207.751c-.383.5-1.122.558-1.492 1.261h.747c.116.452-.717.576-.32 1.084.723.3 1.444.661 2.2.049a1.16 1.16 0 0 0 .495-1.428l-1.63-.966Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-199.811 -93.444)"
    />
    <path
      d="M227.389 208.26a.915.915 0 0 0-1.515-.221 2.181 2.181 0 0 0-.7 1.337c-.106.783.2 1.125 1 1.149 1.408.141 1.805-1.017 1.343-2.017a4.819 4.819 0 0 0-.128-.248"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-141.209 -93.435)"
    />
    <path
      d="M213.373 196.225c-.5.2-1.031.438-1.166 1.021.264.3.6.538.719.856.147.539.697.875 1.244.759l.968-1.17-.112-1.451a2.554 2.554 0 0 0-1.652-.015"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.473 -88.281)"
    />
    <path
      d="M335.99 185.666c.169-.056.333-.129.6-.233a2.819 2.819 0 0 1-.377-.734c-.018-.301 0-.603.052-.9l-1.681-.506c-.8.38-.76 1.075-.968 1.608 1.036 1.2 1.037 1.2 2.377.762"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-189.248 -82.612)"
    />
    <path
      d="M329.942 178.456c-.285-.238-.583-.461-.852-.674h-1.38l-.922 1.538c.451.208.709.309.949.441 1.07.587 1.569.512 2.294-.363a.569.569 0 0 0-.089-.941"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.224 -80.17)"
    />
    <path
      d="M172.462 177.362c-.095-.16-.184-.322-.278-.487-.872.512-1.66.825-1.84 1.792.209.432.288 1 .938 1.048.801.19 1.64-.138 2.1-.82l-.92-1.533Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-116.93 -79.768)"
    />
    <path
      d="M219.3 310.659c-.206.1-.395.228-.561.325-.259.728.267 1.283.288 1.923 0 .128.228.326.379.357.73.152 1.175.139 1.6-.485.221-.326.524-.671.373-1.172-.295-.25-.613-.51-.918-.784a.985.985 0 0 0-1.159-.164"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.335 -138.975)"
    />
    <path
      d="M389.065 105.191c-.856.474-.668 1.341-.548 2.169a.621.621 0 0 0 .777.426c.657-.15 1.29-.39 1.882-.712-.291-1.037-.612-1.961-2.11-1.883"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-213.52 -48.016)"
    />
    <path
      d="M161.016 311.916a1.025 1.025 0 0 0-.3-1.338c-.2-.169-.421-.323-.6-.457l-1.412.762v1.771c1.354.784 1.786.6 2.31-.738"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-111.775 -138.787)"
    />
    <path
      d="m303.543 323.513.663-.818c-.235.016-.4.152-.707.4l.044.415"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-175.909 -144.357)"
    />
    <path
      d="M255.723 240.465c.836.52 1.31.375 1.781-.53.126-.244.211-.507.251-.779a.736.736 0 0 0-.557-.846 15.12 15.12 0 0 0-1.067-.267.703.703 0 0 0-.843.538c-.1.4-.161.8-.251 1.265.209.227.439.435.686.619"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.444 -106.855)"
    />
    <path
      d="M154.146 222.661a.664.664 0 0 0 .739-.9c-.1-.4-.221-.8-.369-1.338-.17-.1-.323-.21-.489-.288a1.003 1.003 0 0 0-1.554.578 7.643 7.643 0 0 0-.272.936l.319.641c.547.126 1.08.3 1.626.365"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-108.894 -98.855)"
    />
    <path
      d="M274.644 307.172c.454.142.95-.059 1.177-.477.386-.557.3-1.322-.2-1.78-.409-.119-.8-.253-1.209-.348a.688.688 0 0 0-.824.568 3.952 3.952 0 0 0 .081 1.78c.35.092.662.184.979.257"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.631 -136.32)"
    />
    <path
      d="M200.343 257.729c.235.643.882.812 1.385 1.1.395.228.785-.1 1.167-.449 0-.365.025-.784-.007-1.2a1.162 1.162 0 0 0-1.1-1.058 1.676 1.676 0 0 0-1.542 1.2c.005.14.037.278.094.406"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.176 -114.871)"
    />
    <path
      d="M322.954 89.777c1.042-.056 1.37-.8 1.558-1.852-.771-.391-1.465-.873-2.348-.538l-.68 1.031c.331.691.574 1.407 1.469 1.359"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-183.876 -40.081)"
    />
    <path
      d="M262.311 319.41c.16-.158.33-.306.509-.443.202-.12.412-.228.627-.322.036-.222.057-.45.111-.671.054-.221.136-.426.2-.62a2.313 2.313 0 0 0-.281-.372 1.287 1.287 0 0 0-2.2.548c-.066.171-.115.347-.17.523-.164.539-.1.736.4 1.016.263.128.533.242.808.341"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.096 -141.642)"
    />
    <path
      d="M196.323 166.246c-.413-.015-.822-.116-1.234-.168a.586.586 0 0 0-.613.46c-.037.228-.025.465-.046.7-.061.482.21.948.659 1.133a1.279 1.279 0 0 0 1.444-.145c.447-.314.757-.789.863-1.325a1.303 1.303 0 0 0-1.073-.651"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-127.596 -74.984)"
    />
    <path
      d="M435.941 154.438v1.217l.981-1.373c-.045.006-.091.01-.138.014-.267.022-.532.089-.843.143"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-234.572 -69.76)"
    />
    <path
      d="M311.8 147.641a1.012 1.012 0 0 1-.108-.088.925.925 0 0 0-1.14-.2c-.436.18-.715.614-.7 1.085a2.413 2.413 0 0 0 1.014 1.528c.086.035.178.05.271.045a1.417 1.417 0 0 0 1.271-1.056c.2-.672.143-.791-.6-1.317"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.721 -66.641)"
    />
    <path
      d="M213.844 149.955c.309-.086.607-.211 1.014-.355.066-.412.152-.772.178-1.138a1.15 1.15 0 0 0-1.07-1.209 1.753 1.753 0 0 0-1.5.56c.133.547.257.978.339 1.417.126.681.409.9 1.035.725"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.589 -66.644)"
    />
    <path
      d="M238.119 207.841c-.357.628-.357.628-1.282 1.023a1.854 1.854 0 0 0 .763 1.8.766.766 0 0 0 .911.106 4.23 4.23 0 0 0 .974-.947.7.7 0 0 0 .041-.652c-.359-.5-.6-1.153-1.407-1.331"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.368 -93.484)"
    />
    <path
      d="M200.519 208.943c.365.334.748.65 1.146.944a.891.891 0 0 0 1.342-.475c.526-1.369.436-1.574-.993-2.178-.735.315-1.592.539-1.5 1.709"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.292 -93.215)"
    />
    <path
      d="M300 195.831a1.712 1.712 0 0 0-1.925.234.713.713 0 0 0-.312.724c.171.7.229 1.47 1.085 1.887.449-.183.825-.51 1.068-.93.458-.681.484-1.592.084-1.916"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.362 -88.073)"
    />
    <path
      d="M431.837 134.955a3.22 3.22 0 0 0-.773.187c-.242.549-.479 1.045-.68 1.555a.631.631 0 0 0 .491.838c.938.121 1.871.187 2.408-.914-.142-.372-.614-.576-.575-1.136.024-.349-.489-.53-.871-.53"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-232.093 -61.2)"
    />
    <path
      d="M201.856 183.552c-.818.192-1.017.852-1.1 1.623.349.335.707.682 1.066 1.028.38-.079.741-.152 1.165-.239l.6-1.467c-.433-.637-.919-1.138-1.733-.946"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.402 -82.707)"
    />
    <path
      d="M173.028 275.627a2.503 2.503 0 0 0-1.175-1.6c-.7.035-.865.607-1.143 1-.707 1-.61 1.245.6 1.858.443-.095.762-.154 1.076-.233a.772.772 0 0 0 .646-1.032"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-116.896 -122.802)"
    />
    <path
      d="m426.706 107.24.219-1.461-1.492-.875-1.086.74a1.805 1.805 0 0 0 .707 2 1.634 1.634 0 0 0 1.651-.4"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-229.394 -47.89)"
    />
    <path
      d="m366.414 95.035-.177-1.471c-.476-.4-.931-.126-1.419 0a1.934 1.934 0 0 0-1.329 1.117l.531 1.039a2.057 2.057 0 0 0 2.393-.688"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-202.481 -42.773)"
    />
    <path
      d="M382.64 101.3c.41.076.829.089 1.243.038a.666.666 0 0 0 .428-.318c.172-.274.296-.576.367-.892.155-.855-.165-1.232-1.023-1.249a6.838 6.838 0 0 0-.845.077l-.937 1.4c.163.531.4.853.768.94"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-210.623 -45.22)"
    />
    <path
      d="M208.014 349.114c.534-.148.984-.51 1.243-1a.865.865 0 0 0-.328-1.095c-.433-.245-.894-.441-1.276-.626a2.299 2.299 0 0 0-1.149 1.572c.106.228.193.391.261.562a.927.927 0 0 0 1.249.586"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-132.947 -154.855)"
    />
    <path
      d="M286.243 271.075a1.9 1.9 0 0 0 1.56-.744 1.053 1.053 0 0 0-.126-1.174 1.353 1.353 0 0 0-1.253-.477 3.523 3.523 0 0 0-.695.061.87.87 0 0 0-.445.305 1.377 1.377 0 0 0 .958 2.029"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-167.756 -120.43)"
    />
    <path
      d="M299.586 210.171a.641.641 0 0 0 .471-.618 1.893 1.893 0 0 0-.53-1.387 1.233 1.233 0 0 0-1.734-.224 2.98 2.98 0 0 0-.5.592c.16.721.554 1.37 1.12 1.844.364-.061.775-.106 1.171-.207"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.161 -93.414)"
    />
    <path
      d="M208.367 383.785a6.073 6.073 0 0 0-.957-.143c-.682.006-.965.538-.647 1.139.149.281.271.576.408.864a.88.88 0 0 0 .774.5c.844.1 1.264-.426 1.687-1.05-.139-.384-.28-.764-.432-1.178l-.833-.131"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-133.004 -171.352)"
    />
    <path
      d="M322.806 232.411a1.102 1.102 0 0 0-1.245.649c-.113.23-.245.451-.394.66.278.784 1.024.962 1.575 1.618l1.368-1.375c-.1-.292-.176-.507-.25-.72-.262-.745-.351-.8-1.053-.832"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-183.735 -104.365)"
    />
    <path
      d="M287.108 234.865c.235-.267.379-.436.528-.6.18-.2.362-.4.541-.594 0-.261.014-.491 0-.72a1.637 1.637 0 0 0-1.414-1.46c-.675-.1-.786.521-1.018.948a1.69 1.69 0 0 0-.085.462c.223.251.484.512.7.8.237.32.435.67.75 1.163"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-168.006 -103.954)"
    />
    <path
      d="M190.828 329a5.159 5.159 0 0 0-.86-.439.98.98 0 0 0-1.226.593c-.122.394-.222.795-.3 1.2a.669.669 0 0 0 .505.722c.18.039.365.043.878.1.458.112.876-.417 1.263-.943a.912.912 0 0 0-.257-1.225"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-124.94 -146.928)"
    />
    <path
      d="M273.429 149.773c.537.287 1.19.258 1.7-.075a.873.873 0 0 0 .367-1.074c-.065-.265-.137-.528-.191-.795a1.068 1.068 0 0 0-.729-.836l-1.174.558a11.481 11.481 0 0 0-.3 1.536.839.839 0 0 0 .326.686"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.445 -66.532)"
    />
    <path
      d="M408.345 183.7a1.581 1.581 0 0 0-1.29-.046 3.252 3.252 0 0 0-.233.41 1.535 1.535 0 0 0 1.094 2.2c.465.098.94-.137 1.145-.565a1.579 1.579 0 0 0-.715-2"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-221.61 -82.722)"
    />
    <path
      d="m316.067 177.587-.787 1.88c.266.174.411.29.574.373.51.239 1.095.263 1.623.066.42-.133.338-.621.516-.93.107-.187.231-.364.358-.56a2.144 2.144 0 0 0-2.284-.829"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-181.127 -80.045)"
    />
    <path
      d="M365.583 180.014a1.96 1.96 0 0 0 .98-1.8c.042-.528-.516-.829-1.058-1.211a3.573 3.573 0 0 0-1.638 2.031c.1.211.206.416.313.633l1.403.347Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-202.648 -79.825)"
    />
    <path
      d="M292.817 165.016a1.424 1.424 0 0 0-.311-.105c-.756.176-1.012.807-1.234 1.445.247.472.256 1.036.848 1.235a1.48 1.48 0 0 0 1.54-.324c.309-1.47.309-1.471-.843-2.251"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.493 -74.47)"
    />
    <path
      d="M371.359 135.459c-.61.367-1.277.66-1.424 1.6.052.123.116.249.162.38.362 1.042.826 1.19 1.734.554.16-.093.312-.201.453-.321a.951.951 0 0 0 .023-1.471c-.281-.285-.48-.7-.948-.747"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-205.336 -61.424)"
    />
    <path
      d="M366.279 129.751a1.727 1.727 0 0 0-1.358-.1c-.354.08-.468.386-.573.722-.132.33-.294.647-.485.946.347.741 1.13.386 1.614.724.462-.174 1.036-.108 1.347-.633-.079-.409-.137-.818-.245-1.213a.805.805 0 0 0-.3-.446"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-202.646 -58.812)"
    />
    <path
      d="m297.445 329.559.275.331 1.238-1.522a2.455 2.455 0 0 0-1.513 1.19"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.227 -146.87)"
    />
    <path
      d="m324.274 196.261-1.4-.692-1.1.544c.036.47.052.884.1 1.292a.71.71 0 0 0 .707.7h.014a9.889 9.889 0 0 0 2.158-.172 3.316 3.316 0 0 1-.469-.756 4.108 4.108 0 0 1-.015-.914"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-184.004 -88.048)"
    />
    <path
      d="M298.933 134.688c-.786.5-1.375 1.01-.932 2.027.062.143-.016.347-.035.571l.924.4c.959-.139 1.119-.969 1.7-1.409a7.844 7.844 0 0 0-1.659-1.59"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.406 -61.083)"
    />
    <path
      d="M364.031 155.654c.4.32.816.608 1.212.9.924-.434.924-.434 1.459-1.749a1.173 1.173 0 0 0-.832-.663c-.272-.057-.54-.126-.81-.19-.486-.116-.731-.05-.953.329a9.226 9.226 0 0 0-.4.89c.086.178.196.342.329.488"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-202.575 -69.59)"
    />
    <path
      d="M365.074 191.892c.364-.079.721-.19 1.065-.333a2.846 2.846 0 0 0-.257-1.951.73.73 0 0 0-1.048-.361 2.758 2.758 0 0 0-1.3 1.085c.128.27.27.55.4.838.291.662.47.807 1.139.721"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-202.503 -85.206)"
    />
    <path
      d="M230.789 311.726c.104.489.283.96.531 1.394.284.471.67.491 1.212.265.389-.158.696-.47.847-.862.4-.925.1-1.439-.883-1.485h-.558a3.57 3.57 0 0 0-.683.081.561.561 0 0 0-.467.608"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.699 -139.192)"
    />
    <path
      d="M213.746 368.273c.453-.288.858-.509 1.222-.784.594-.449.622-.872.136-1.4-.692-.751-1.078-.818-2.214-.377-.041.531-.085 1.062-.134 1.677l.99.884Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.716 -163.29)"
    />
    <path
      d="M165.78 306.854c.177-.055.363-.089.534-.161a.876.876 0 0 0 .171-1.486c-.33-.252-.7-.452-1.1-.7a1.805 1.805 0 0 0-1.609 1.454c.59 1.029.995 1.21 2 .9"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-114.023 -136.3)"
    />
    <path
      d="M426.681 94.875c.336-.322.166-.9-.519-1.687a1.251 1.251 0 0 0-.329-.207l-1.636.3-.368 1.049c.049.134.111.262.187.383a1.8 1.8 0 0 0 2.665.159"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-229.207 -42.608)"
    />
    <path
      d="M328.125 120.087c.8.135 1.289-.355 1.8-.8l-.136-1.626h-2.16c-.512.649-.279 1.255-.2 1.839.052.368.333.525.69.585"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.456 -53.541)"
    />
    <path
      d="M353.7 250.433c-.2-.116-.415-.2-.751-.352-.338.178-.66.384-.964.615a1.138 1.138 0 0 0 .116 1.967c.389.269.894.307 1.319.1.406-.141.681-.527.681-.957v-.694a.765.765 0 0 0-.4-.682"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-197.159 -112.194)"
    />
    <path
      d="M214.744 256.646a3.03 3.03 0 0 0-1.487.185 1.23 1.23 0 0 0-.227 2.061c.25.212.599.264.9.135a3.903 3.903 0 0 0 1.73-1.568c-.239-.384-.467-.747-.919-.813"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.634 -115.09)"
    />
    <path
      d="M139.931 257.162a.744.744 0 0 0 .319 1.149c.484.187.98.344 1.483.471.9-.459.6-1.33.944-1.924l-1.571-.829c-.475.28-.877.668-1.174 1.133"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-103.393 -114.828)"
    />
    <path
      d="M352.441 141.182a1.6 1.6 0 0 0-.448.192v2.015c.671.166 1.37.181 2.047.042l.506-1.322-.591-1.366a4.27 4.27 0 0 1-1.515.439"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-197.389 -63.765)"
    />
    <path
      d="M291.194 251.842c1.359.807 1.515.788 2.55-.475a1.429 1.429 0 0 0-1.122-1.227c-.6-.045-1.121-.349-1.734-.432-.066.442-.13.829-.176 1.218a.866.866 0 0 0 .482.916"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.242 -112.028)"
    />
    <path
      d="M245.445 107.36c.2-.807-.289-1.306-.624-2.048-.473.057-.816-.407-1.339-.133a1.381 1.381 0 0 0-.9 1.236l1.393 1.357 1.47-.412Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-148.928 -47.973)"
    />
    <path
      d="M198.78 361.322a2.076 2.076 0 0 0-.168-1.026 7.13 7.13 0 0 1-.495 1.54 12.6 12.6 0 0 0-.19.439l.1.042c.365-.283.708-.507.749-.995"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-129.148 -161.011)"
    />
    <path
      d="M220.052 189.306c-.337.432-.646.885-.92 1.266a2.33 2.33 0 0 0 1.405 1.73c.822-.186.7-1.156 1.364-1.556-.4-.553-.685-.973-1-1.375a.547.547 0 0 0-.853-.064"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.54 -85.197)"
    />
    <path
      d="M183.758 177.96a1.932 1.932 0 0 0-1.91 1.175c.39.479.671 1.074 1.388 1.058a2.036 2.036 0 0 0 1.922-1.34c-.6-.871-.6-.829-1.4-.894"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-122.026 -80.245)"
    />
    <path
      d="M377.038 190.08a1.153 1.153 0 0 0-1.058.768 3.216 3.216 0 0 0-.022 1.072l1.343.681c.331-.168.583-.284.82-.423.115-.077.218-.171.305-.279.32-.352.358-.881.091-1.275a1.502 1.502 0 0 0-1.478-.544"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-207.987 -85.613)"
    />
    <path
      d="M335.386 174.068c.909-.34 1.051-.65.734-1.574-.072-.21-.155-.416-.236-.629-.763-.008-1.51-.524-2.125.241l-.206 1.158.868 1.1c.437-.133.706-.2.964-.295"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-189.221 -77.47)"
    />
    <path
      d="M275.854 136.778c-.012-.364-.048-.729-.078-1.155l-1.126-.767c-.862.524-1.725.994-1.486 2.324.688.114 1.36.272 2.04.325.435.035.662-.279.649-.728"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.455 -61.157)"
    />
    <path
      d="M278.787 190.512c.477.819.873 1.663 1.958 1.8.685-.311.829-1.062 1.322-1.588l-.834-1.17c-.834.3-1.754.236-2.447.96"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-164.963 -85.383)"
    />
    <path
      d="m207.028 286.46-.212 1.2.86 1.427h1.116c.055-.258.076-.393.113-.524.028-.133.073-.261.133-.383.473-.8.45-.8-.062-1.358a1.75 1.75 0 0 0-1.947-.368"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-133.085 -128.25)"
    />
    <path
      d="M334.558 111.488a1.71 1.71 0 0 0-.507 1.634.726.726 0 0 0 .728.691 2.92 2.92 0 0 0 1.767-.486c-.106-.5-.178-.9-.279-1.3-.256-1-.953-1.189-1.709-.537"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-189.432 -50.638)"
    />
    <path
      d="M273.4 101.043c.027.084.066.165.123.305.481.137.873.267 1.276.359a.647.647 0 0 0 .811-.55 7.406 7.406 0 0 0 0-1.246.908.908 0 0 0-.042-.206c-.478-.219-.97-.433-1.477-.637a1.46 1.46 0 0 0-.276.191c-.721.631-.764.764-.417 1.783"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.483 -45.306)"
    />
    <path
      d="M376.919 104.834a2.114 2.114 0 0 0-.7.4c-.408.349-.49.715-.1 1.061.414.369.272.8.338 1.212.85.347 1.426-.159 2.037-.537-.047-.383-.349-.6-.255-1.047.19-.908-.441-1.384-1.316-1.093"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-207.961 -47.822)"
    />
    <path
      d="M279.5 227.993c.069.12.135.242.2.365.386.753.511.821 1.268.558.338-.119.656-.3.971-.445a2.553 2.553 0 0 0-.617-1.6 1.198 1.198 0 0 0-1.622-.271c-.553.4-.594.7-.2 1.4"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.162 -101.7)"
    />
    <path
      d="M335.788 89.224a2.32 2.32 0 0 0 .675-1.28l-1-1.327c-1.434-.048-1.56.775-1.609 1.692.147.25.289.54.477.8a.972.972 0 0 0 1.46.116"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-189.353 -39.79)"
    />
    <path
      d="M328.849 152.607a3.461 3.461 0 0 0-.886 2.648 5.069 5.069 0 0 0 2.244.409c0-.441.008-.8 0-1.168-.008-.368-.042-.725-.064-1.074l-1.294-.815Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.732 -69.02)"
    />
    <path
      d="M407.256 149.95c.537-.19.924-.665 1-1.229.177-.711.138-.881-.511-1.218a1.2 1.2 0 0 0-1.113-.144l-1.046 1.321c.223.354.418.717.667 1.038a.815.815 0 0 0 1.007.232"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-221.125 -66.657)"
    />
    <path
      d="M256.977 371.523a4.71 4.71 0 0 0-.964.042.668.668 0 0 0-.56.545c-.176.641.04 1.329.55 1.755.445.365.968.141 1.421-.124.146-.098.285-.204.418-.318.032-.17.068-.3.08-.438.014-.184.016-.369.006-.553-.04-.667-.255-.88-.95-.909"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.606 -165.981)"
    />
    <path
      d="M232.928 226.188c-.436-.318-.807-.061-1.186.046-.574.205-.96.751-.962 1.361.1.546.628.628 1.037.813.143.044.293.062.443.051a1.715 1.715 0 0 0 1.53-1.133c.065-.616-.445-.837-.862-1.138"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.695 -101.542)"
    />
    <path
      d="M250.03 367.892a1.054 1.054 0 0 0 1.515-.523 3.842 3.842 0 0 0-.02-1.492c-.019-.136-.347-.274-.554-.317a1.444 1.444 0 0 0-.553.125 1.81 1.81 0 0 0-.382-.108 1.118 1.118 0 0 0-1.141.8.902.902 0 0 0 .443 1.056c.22.168.451.322.691.461"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.704 -163.34)"
    />
    <path
      d="M293.189 274.664c-.225-.046-.455-.065-.643-.091-.552.284-1.037.582-1.052 1.233-.023.819.621 1.179 1.268 1.753.427-.411.787-.7 1.083-1.051a1.063 1.063 0 0 0-.656-1.843"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.591 -123.042)"
    />
    <path
      d="M329.167 214.521c-.221.012-.444-.04-.666-.029a1.05 1.05 0 0 0-.507.117c-.47.331-.668.936-.486 1.481.194.485.668.803 1.19.8a1.769 1.769 0 0 0 1.521-1.031.995.995 0 0 0 .064-.35.999.999 0 0 0-1.116-.985"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.515 -96.43)"
    />
    <path
      d="M224.4 161.449c.294.216.657.335.958.543.482.31 1.118.254 1.539-.136a.865.865 0 0 0 .258-1.109c-.228-.379-.5-.731-.818-1.189a9.717 9.717 0 0 0-1.222.47 2.958 2.958 0 0 0-.836.681.51.51 0 0 0 .122.741"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-140.77 -72.097)"
    />
    <path
      d="M334.5 268.8c-.7 1.332-.586 1.92.442 2.468a.69.69 0 0 0 .882-.088c.354-.47.663-.973.994-1.467a2.11 2.11 0 0 0-2.317-.912"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-189.443 -120.445)"
    />
    <path
      d="M327.715 131.431c.44.58 1.052.5 1.651.448.537-.046.612-.48.709-.894.061-.198.135-.391.22-.58-.538-.428-.96-.781-1.4-1.11a.834.834 0 0 0-1.086.035c-.024.42.227.928-.215 1.353-.214.2-.061.507.123.749"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.535 -58.611)"
    />
    <path
      d="M387.739 129.95c-.12.048-.222.313-.217.473a1.237 1.237 0 0 0 1.222 1.224c.323.016.647.004.968-.036a.602.602 0 0 0 .546-.546 5.814 5.814 0 0 0-.035-1.727c-.155-.084-.233-.163-.313-.163a5.848 5.848 0 0 0-2.171.775"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-213.125 -58.64)"
    />
    <path
      d="M276.1 244.665a2.253 2.253 0 0 0-.907-.589 1.141 1.141 0 0 0-1.307.222c.032.314.075.629.091.948.016.319 0 .645 0 1.038.137.109.283.206.436.292a1.412 1.412 0 0 0 1.625-.319c.255-.496.277-1.08.059-1.593"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.792 -109.486)"
    />
    <path
      d="M407.9 113.627c.363-.178.7-.409 1.053-.619a3.846 3.846 0 0 0-.211-1.439.842.842 0 0 0-1.091-.564 2.019 2.019 0 0 0-1.234 1.632c.018.92.691 1.378 1.482.99"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-221.496 -50.575)"
    />
    <path
      d="M156.322 306.035c-.241.6-.454 1.228-.639 1.865a.907.907 0 0 0 .232-.084 1.328 1.328 0 0 0 .407-1.781"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-110.436 -136.977)"
    />
    <path
      d="M250.716 352.485c-1.325.612-1.476.966-.995 2.371.042.085.093.166.152.24l1.371.345c.851-.894.54-1.786.3-2.661a.631.631 0 0 0-.824-.3"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.978 -157.53)"
    />
    <path
      d="M270.011 276.145a7.301 7.301 0 0 0-.016-1.014l-1.319-1.044a3.575 3.575 0 0 0-.434.169c-.775.424-.754.423-.605 1.284.032.326.035.654.009.98.7.747 1.39.29 2.059.048a.601.601 0 0 0 .306-.423"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.988 -122.826)"
    />
    <path
      d="M330.6 143.578a.983.983 0 0 0-.28-1.138c-.431-.315-.8-.816-1.449-.739a.957.957 0 0 0-.988.595.936.936 0 0 0 .144 1.033c.303.416.743.713 1.242.838a1.548 1.548 0 0 0 1.332-.589"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.664 -64.186)"
    />
    <path
      d="M154.728 268.337a1.738 1.738 0 0 0-.833-.1c.607.265 1.186.588 1.73.965a2.398 2.398 0 0 0-.9-.866"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-109.644 -120.234)"
    />
    <path
      d="M245.507 298.888a.883.883 0 0 0-1.324-.257 1.735 1.735 0 0 0-.521.635 8.641 8.641 0 0 0-.337 1.138c.812.955.87.973 1.756.537.245-.121.475-.272.68-.391.057-1 .019-1.232-.253-1.661"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.256 -133.61)"
    />
    <path
      d="M263.881 354.106a4.2 4.2 0 0 0-2.221-1.071c-.634.379-.494 1.1-.783 1.709.5.353.95.663 1.37.958 1.023-.424 1.023-.424 1.634-1.595"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.03 -157.796)"
    />
    <path
      d="M174.129 155.389a16.98 16.98 0 0 0-1.264 1.627c.28-.008.545-.132.731-.341a1.45 1.45 0 0 0 .533-1.286"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-118.047 -70.251)"
    />
    <path
      d="M171.587 264.676c.272-.037.55-.032.9-.05a3.64 3.64 0 0 1 .3-.891.663.663 0 0 0-.12-.886 2.603 2.603 0 0 0-.782-.549.99.99 0 0 0-1.27.211 1.042 1.042 0 0 0-.268 1.266c.22.558.525 1 1.232.9"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-116.888 -117.541)"
    />
    <path
      d="M207.389 155.737a.81.81 0 0 0 .825.4.773.773 0 0 0 .749-.512c.125-.291.267-.578.421-.855a.877.877 0 0 0-.005-1.017 2.944 2.944 0 0 0-1.952-.286c-.146.265-.27.541-.372.825a1.378 1.378 0 0 0 0 .687c.082.265.195.52.337.758"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-133.171 -69.376)"
    />
    <path
      d="M293.881 262.241a2.527 2.527 0 0 1-1.366-.051c-.492-.128-.789.1-.973.565a7.738 7.738 0 0 0-.219.655c-.13.435-.014.795.373.909a3.171 3.171 0 0 0 1.612.34c.226-.066.447-.145.664-.237l-.443-.867c.152-.44.675-.666.354-1.314"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.492 -117.541)"
    />
    <path
      d="M280.974 153.3a2.717 2.717 0 0 0-.422.151c-.78.448-.906.779-.665 1.679.08.3.147.6.216.877 1.481.106 1.481.106 2.267-1.736a2.2 2.2 0 0 0-1.4-.971"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.402 -69.325)"
    />
    <path
      d="M189.647 304.513a.79.79 0 0 0-.759.68 1.58 1.58 0 0 0 1.1 1.97.997.997 0 0 0 1.39-.867l.001-.051a1.74 1.74 0 0 0-1.731-1.731h-.001"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-125.126 -136.303)"
    />
    <path
      d="M153.145 243.81c-.365.456-.681.951-1.017 1.424.458.628.761 1.353 1.817 1.323.172-.329.313-.614.471-.89.178-.307.372-.6.539-.872-.242-.344-.43-.592-.6-.853a.701.701 0 0 0-1.212-.131"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-108.862 -109.286)"
    />
    <path
      d="M401.144 95.828c.128.051.259.094.392.129a1.115 1.115 0 0 0 1.343-.882c.072-.359.124-.721.157-1.085a2.51 2.51 0 0 0-2.853.239c-.1 1.265-.1 1.265.961 1.6"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-218.716 -42.91)"
    />
    <path
      d="M209.1 239.615a2.894 2.894 0 0 0-.35-1.6.971.971 0 0 0-1.121-.239c-.416.108-.72.471-.754.9a6.545 6.545 0 0 1-.18.921 2.686 2.686 0 0 0 1.828.91.938.938 0 0 0 .578-.891"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-133.032 -106.706)"
    />
    <path
      d="m208.449 371.159-1.011.319a8.043 8.043 0 0 0-.368 1.034c-.132.404-.057.848.2 1.186.283.4.808.554 1.262.37l.923-1.123c-.249-.666-.08-1.487-1-1.785"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-133.173 -165.823)"
    />
    <path
      d="M218.912 373.143c.228.643.374.755 1.2.763.1-.051.512-.127.62-.351a2.94 2.94 0 0 0 .418-1.736c-.069-.452-.308-.657-.755-.578-.228.038-.452.106-.681.14-1.076.163-1.242.437-.9 1.5.03.089.057.178.089.266"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.331 -165.852)"
    />
    <path
      d="M330.5 227.123a.725.725 0 0 0-.7-.736 1.96 1.96 0 0 0-1.794.552 5.4 5.4 0 0 0 .016.993.806.806 0 0 0 .358.546c.425.182.872.311 1.329.383a.656.656 0 0 0 .781-.618c.031-.373.034-.747.009-1.12"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.756 -101.688)"
    />
    <path
      d="M274.673 219.952c-.262.13-.548.272-.8.4l-.144 1.378c.226.3.443.6.607.818.66.3 1.035-.091 1.461-.367.5-.324.394-.874.6-1.235-.4-.744-1.06-.832-1.72-.992"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.721 -98.849)"
    />
    <path
      d="M178.088 307.264c.509.036.849-.235 1.057-.913a1.088 1.088 0 0 0-.015-.663 1.977 1.977 0 0 0-1.1-1.228 6.68 6.68 0 0 0-.755.321 1.078 1.078 0 0 0-.59 1.382 1.428 1.428 0 0 0 1.4 1.1"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-119.714 -136.28)"
    />
    <path
      d="M346.866 255.832c-.133.178-.275.358-.4.547-.125.189-.237.385-.33.536l.424 1.509a5.27 5.27 0 0 0 2.037-.028c.095-1.882-.066-2.109-1.727-2.564"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.793 -114.741)"
    />
    <path
      d="M201.682 195.6c-.368.253-.775.537-1.129.782a2.335 2.335 0 0 0-.055.372c.051.989.421 1.351 1.344 1.385.587.02 1.16-.179 1.607-.56a3.062 3.062 0 0 0-1.767-1.979"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.286 -88.063)"
    />
    <path
      d="M359.5 134.688a5.658 5.658 0 0 0-1.131.55c-.834.663-.79 1.275-.043 1.8.412.316.928.466 1.445.422.551-.057.881-.412.751-.946a3.432 3.432 0 0 0-1.022-1.827"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-199.939 -61.083)"
    />
    <path
      d="M262.188 195.217c-.129.203-.27.397-.421.584-.183.2-.406.364-.592.561a.761.761 0 0 0 .385 1.29c.228.029.452.077.672.143a1.17 1.17 0 0 0 1.512-.391c-.111-.93-.136-1.926-1.557-2.186"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.06 -87.892)"
    />
    <path
      d="M322.58 101.185a.887.887 0 0 0 1.122.167c.465-.263.68-.825.509-1.331a2.569 2.569 0 0 0-1.3-1.537c-.558.151-.976.358-1.024 1.008a4.38 4.38 0 0 1-.385 1.02c.201.043.395.11.58.2.178.144.343.302.495.473"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-183.884 -45.046)"
    />
    <path
      d="M400.345 190.549c-.128.335-.2.69-.3 1.063.191.13.333.246.49.331.281.151.573.284.863.427l1.663-1.51a4.254 4.254 0 0 0-.385-.7 1.334 1.334 0 0 0-2.327.392"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-218.671 -85.42)"
    />
    <path
      d="m438.3 105.027-1.441.733c-.024.446-.021.893.009 1.338a1.018 1.018 0 0 0 1.46.786c.55-.235.936-.745 1.01-1.339-.555-.318-.345-1.188-1.038-1.517"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-234.972 -47.945)"
    />
    <path
      d="m220.279 201.277-1.581 1.212c.085.3.149.561.237.817a1.029 1.029 0 0 0 1.537.588c.45-.317.884-.656 1.3-1.016-.57-.543-.651-1.42-1.488-1.6"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.348 -90.576)"
    />
    <path
      d="M268.993 359.035a5.935 5.935 0 0 0-.969.29.929.929 0 0 0-.459.477 1.805 1.805 0 0 0 .315 1.583c.279.432.712.389 1.128.375.129-.007.258-.026.384-.056l.408-.484c.44-1.209.18-1.456-.808-2.186"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.96 -160.453)"
    />
    <path
      d="M286.188 137.728a.846.846 0 0 0 1.311-.263c.105-.154.197-.316.275-.486a.858.858 0 0 0-.329-1.308 8.318 8.318 0 0 0-1.21-.474 2.13 2.13 0 0 0-1.029 1.994c.342.188.663.361.983.537"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-167.801 -61.308)"
    />
    <path
      d="M312.132 221.954c.038-.124.086-.252.116-.386.163-.7.036-.93-.581-1.115-.479-.144-.95-.313-1.424-.469-.909.647-.909.647-.514 1.918a1.933 1.933 0 0 0 2.4.052"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.557 -98.862)"
    />
    <path
      d="M208.973 359.131c-.179-.335-.362-.726-.792-.647a1.392 1.392 0 0 0-1.206.811 4.632 4.632 0 0 0-.147.7l1.222 1.5c.201-.009.399-.057.583-.14.464-.347.768-.867.842-1.442-.159-.242-.353-.5-.5-.774"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-133.091 -160.204)"
    />
    <path
      d="M382.081 148.511a1.09 1.09 0 0 0-.043.24c.243.884 1.018 1.019 1.675 1.316l.677-.361.326-1.408c-1.317-1.512-2.094-1.086-2.634.213"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-210.697 -66.685)"
    />
    <path
      d="M273.544 353.245c-.31.279-.593.585-.847.916a.55.55 0 0 0-.051.51c.277.364.602.688.968.962a.884.884 0 0 0 1.107-.288c.215-.3.4-.619.617-.965-.077-.238-.144-.5-.247-.754a.93.93 0 0 0-1.546-.382"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.224 -157.767)"
    />
    <path
      d="M148.55 238.517a5.93 5.93 0 0 0-.8-.432c-1.3.3-1.341.85-1.11 2.262a1.621 1.621 0 0 0 1.775.352c.161-.309.323-.588.457-.88.284-.614.223-.9-.322-1.3"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-106.383 -106.88)"
    />
    <path
      d="M172.569 250.806c-.593-.1-1.139-.837-1.865-.111l-.209 1.245 1.392 1.358a9.94 9.94 0 0 1 .68-.8c.242-.21.496-.406.761-.587-.087-.529-.228-1.012-.759-1.1"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-116.997 -112.325)"
    />
    <path
      d="M318.079 130.341a1.653 1.653 0 0 0-1.42-1.137l-1.287.89.423 1.226 1.307.613c.219-.108.351-.157.468-.229.627-.39.743-.721.51-1.363"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-181.168 -58.654)"
    />
    <path
      d="M238.8 255.721a3.097 3.097 0 0 0-1.485 1.426 2.605 2.605 0 0 0 1.408 1.837l.8-.4c-.023-1.081.28-2.136-.719-2.867"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.592 -114.691)"
    />
    <path
      d="M334.811 125.708c.333-.1.6-.16.856-.263a1.127 1.127 0 0 0 .3-2.044 10.697 10.697 0 0 0-1-.594 1.803 1.803 0 0 0-.964 1.639c.004.54.319 1.032.808 1.262"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-189.419 -55.82)"
    />
    <path
      d="M285.832 125.593a1.08 1.08 0 0 0 1.809-.416c.142-.388.26-.785.352-1.188-.606-.653-1.316-.555-1.919-.756-.242-.08-.548.167-.5.349.138.537-.236.924-.361 1.365.196.226.404.442.622.647"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-167.808 -56.002)"
    />
    <path
      d="M220.264 346.382a.368.368 0 0 0-.136.016c-.354.217-.706.438-1.057.658l-.287.984 1.154 1.31a3.295 3.295 0 0 0 1.5-.888c-.164-.871-.267-1.708-1.173-2.08"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.386 -154.849)"
    />
    <path
      d="M239.719 184.625c-.7-.709-1.378-.073-2.086-.249-.142.444-.254.898-.335 1.357a.995.995 0 0 0 .857 1.082c.504.012.988-.204 1.316-.587a1.839 1.839 0 0 0 .249-1.6"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.583 -83.048)"
    />
    <path
      d="m263.176 186.02.7-1.09a2.502 2.502 0 0 0-1.7-1.243 2.018 2.018 0 0 0-1.5 1.68c.875.322 1.546 1.035 2.509.653"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-156.937 -82.785)"
    />
    <path
      d="M233.252 372.821c.059-.12.112-.243.157-.369-.086-.281-.173-.544-.247-.811a.796.796 0 0 0-.818-.622 1.11 1.11 0 0 0-.915.239c-.4.419-.974.748-.856 1.516.406.212.8.457 1.222.633.7.292 1.052.137 1.457-.586"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.601 -165.76)"
    />
    <path
      d="M231.872 192.6a1.405 1.405 0 0 0 1.485-1.056c.147-.558-.279-.822-.685-1.062-.04-.024-.071-.062-.11-.087a1.1 1.1 0 0 0-1.936.716c0 .263.094.517.265.716.194.462.453.817.98.773"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.672 -85.617)"
    />
    <path
      d="M251.4 172.76c-.259-.733-.362-.809-1.062-.822a1.613 1.613 0 0 0-1.478.626c.239.9.462 1.786 1.617 2.2l1.148-1.374c-.072-.2-.147-.416-.224-.629"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.708 -77.581)"
    />
    <path
      d="M298.292 173.879a.91.91 0 0 0 1.413-.6c.27-.763.09-1.617-.465-2.206-.909.318-1.79.631-2 1.921.329.32.679.616 1.049.887"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.138 -77.197)"
    />
    <path
      d="M233.126 202.265c-1.842-.133-1.842-.133-2.248 1.578a.37.37 0 0 0 .021.123c.642.662 1.437.451 2.225.428l.756-1.044-.754-1.085Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.742 -90.991)"
    />
    <path
      d="M352.958 165.469c-.385.37-.749.762-1.091 1.172a.658.658 0 0 0-.027.631c.376.477.906.809 1.5.938.5.072.753-.15.832-.671.027-.218.078-.432.151-.639.278-.709.2-.941-.435-1.176-.259-.095-.533-.147-.926-.255"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-197.291 -74.716)"
    />
    <path
      d="M255.9 361.826c.217.015.435.002.649-.038.12-.331.033-.666.411-.843.341-.171.561-.518.57-.9l-.624-.84c-.614.069-1.03.091-1.433.17a.793.793 0 0 0-.443.295 1.418 1.418 0 0 0 .871 2.156"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.334 -160.529)"
    />
    <path
      d="M213.466 159.5a6.167 6.167 0 0 0-.351.855c-.041.16 0 .471.1.516.634.3.692.922.873 1.564.264-.095.54-.106.646-.247.523-.7 1.153-1.381.757-2.414a2.4 2.4 0 0 0-2.021-.274"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.868 -72.01)"
    />
    <path
      d="M161.178 335.747a1.473 1.473 0 0 0-1.488-.523c.553.508 1.162.953 1.814 1.326.057-.076.109-.155.156-.237-.19-.221-.348-.385-.482-.567"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-112.211 -149.898)"
    />
    <path
      d="M263.164 292.474a3.696 3.696 0 0 0-1.371.014.501.501 0 0 0-.247.418c.131.44.335.858.507 1.285.251.621.407.73 1 .728.488.032.959-.189 1.246-.585a5.06 5.06 0 0 0-.353-1.147 1.529 1.529 0 0 0-.784-.713"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.325 -130.945)"
    />
    <path
      d="m251.125 101.394.051.001a.99.99 0 0 0 .986-.986.982.982 0 0 0-.181-.569 1.6 1.6 0 0 0-.94-.926c-1.708.611-1.908.982-1.183 2.486.448 0 .86.029 1.268-.006"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.974 -45.236)"
    />
    <path
      d="M172.74 298.931a1.037 1.037 0 0 0-1.47-.295 3.691 3.691 0 0 0-.482.3c-.017.241-.026.47-.05.7-.08.779.46 1.172 1.005 1.522.211.084.45.064.644-.055.491-.277.848-.743.988-1.289-.211-.3-.4-.608-.635-.882"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-117.101 -133.623)"
    />
    <path
      d="M286.775 307.219a.91.91 0 0 0 .824-.661c.124-.506.647-1 .188-1.566a5.085 5.085 0 0 0-.69-.587c-.539.455-1.392.081-1.887.848.2.509.364.946.551 1.37a1.15 1.15 0 0 0 1.014.6"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-167.808 -136.255)"
    />
    <path
      d="M311.2 304.55c-.27-.023-.54.044-.767.192-.3.172-.206.45-.084.727.122.277.042.482-.252.676a.4.4 0 0 0 .035.677c.264.182.551.329.852.438a.891.891 0 0 0 1.144-.5c.162-.28.295-.575.457-.9a1.602 1.602 0 0 0-1.386-1.311"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.751 -136.314)"
    />
    <path
      d="M201.472 329.228a1.03 1.03 0 0 0-.687 1.085 1.285 1.285 0 0 0 1.091 1.28c1.112-.48 1.112-.48 1.79-1.61a1.988 1.988 0 0 0-2.194-.755"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.412 -147.226)"
    />
    <path
      d="m269.306 97.331.032-.019a19.12 19.12 0 0 0-1.454-.346c.111.117.232.224.361.32a.985.985 0 0 0 1.061.046"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-160.134 -44.373)"
    />
    <path
      d="M329.271 286.1c-.436.163-.829.3-1.211.458a1.082 1.082 0 0 0-.445.306 1.159 1.159 0 0 0 .655 1.768c.281.045.564.071.848.077l1.092-1.363a1.967 1.967 0 0 0-.94-1.246"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.501 -128.147)"
    />
    <path
      d="M155.264 318.859c.733-1.085.733-1.2-.232-2.049a2.453 2.453 0 0 0-.406-.228l-.389.128a19.22 19.22 0 0 0-.053 2.551c.107.031.216.058.324.084.361.084.562-.2.757-.485"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-109.763 -141.648)"
    />
    <path
      d="M262.537 207.849c-1.075.555-1.048.558-1.089 1.307-.046.886.482 1.309 1.239 1.716.26-.843 1.34-.86 1.549-1.812l-1.699-1.211Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.282 -93.487)"
    />
    <path
      d="M197.053 324.891c.075-.117.142-.239.2-.365a1.124 1.124 0 0 0-.594-1.7 2.416 2.416 0 0 0-.937-.157c-.49.007-.608.2-.635.82-.015.313-.065.625-.1.96.194.21.368.427.573.613.576.526 1.034.469 1.5-.175"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-127.844 -144.346)"
    />
    <path
      d="M329.914 191.091c-.348-.995-.609-1.184-1.6-1-.32.092-.628.223-.916.39l-.334 1.093c.345.164.8.247.855.765.009.085.344.238.453.19.572-.249 1.12-.553 1.756-.877-.119-.3-.174-.427-.218-.557"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.349 -85.593)"
    />
    <path
      d="M275.249 196.063a6.823 6.823 0 0 0-1.182-.323l-.808 1.226c.164.355.3.7.48 1.021a.954.954 0 0 0 1.43.286c.147-.113.283-.24.407-.379a1.083 1.083 0 0 0-.327-1.83"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.515 -88.124)"
    />
    <path
      d="M291.907 313.244a.67.67 0 0 0 .5.172c.528-.14 1.1-.249 1.227-.941.06-.253.136-.502.228-.745a1.7 1.7 0 0 0-1.252-.929c-.5.492-1.583.35-1.627 1.532.27.272.578.611.922.911"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.366 -139.088)"
    />
    <path
      d="M281.235 204.29a.602.602 0 0 0 .575-.515c.06-.553.063-1.111.008-1.665a.958.958 0 0 0-1.368-.7c-.28.148-.551.309-.814.485.088.249.163.502.225.759.041.228.013.466.056.692a1.138 1.138 0 0 0 1.318.944"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.339 -90.594)"
    />
    <path
      d="M225.887 246.694c.925-.055 1.067-.892 1.5-1.41-.216-.425-.121-.978-.577-1.131a2.339 2.339 0 0 0-1.222-.077c-.323.075-.59.39-.916.623.279.9.484 1.613 1.218 1.995"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-140.992 -109.512)"
    />
    <path
      d="M290.987 336.4c.088.263.209.514.361.746l1.645-2.012c-.225-.04-.451-.064-.655-.092a3.142 3.142 0 0 0-1.351 1.358"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.367 -149.826)"
    />
    <path
      d="M178.45 282.978c.046-.289.119-.573.218-.849.138-.228.299-.442.482-.636-.571-.418-.575-1.245-1.309-1.409a4.79 4.79 0 0 1-.65.53 4.062 4.062 0 0 1-.725.258c-.183 1.517-.023 1.692 1.984 2.106"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-119.617 -125.483)"
    />
    <path
      d="m148.532 263.566-.367-.959a7.645 7.645 0 0 0-.911-.357.677.677 0 0 0-.525.079c-.463.332-.712.89-.65 1.457.015.094.038.186.07.275.651.219 1.3.426 1.936.637.091-.122.163-.257.214-.4.094-.256.167-.519.233-.728"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-106.175 -117.575)"
    />
    <path
      d="M195.683 274.766a1.459 1.459 0 0 0-.687 1.643l1.231.978a4.134 4.134 0 0 0 1.51-2.262 1.557 1.557 0 0 0-2.054-.359"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-127.832 -123.022)"
    />
    <path
      d="M244.009 361.553a.966.966 0 0 0 1.347-.88l-.001-.032c.018-.409-.032-.82-.049-1.179a2.409 2.409 0 0 0-.314-.184c-1.174-.417-1.319-.36-1.964.775-.046.08-.076.169-.152.343.5.971.5.971 1.133 1.156"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.057 -160.456)"
    />
    <path
      d="M281.4 298.785a1.645 1.645 0 0 0-.535.084c-.395.122-.786.266-1.385.474-.09.241-.316.555-.285.84.042.369.21.713.475.973.633.547 1.211.067 1.714-.172.192-.553.366-1.026.516-1.5a.543.543 0 0 0-.5-.694"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.142 -133.767)"
    />
    <path
      d="M159.672 228.583a1.513 1.513 0 0 0 1.164-.891c-.076-.249-.154-.512-.229-.761l-1.444-1.018a1.079 1.079 0 0 0-.876.655c-.116.301-.15.628-.1.947a1.616 1.616 0 0 0 1.486 1.068"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-111.536 -101.49)"
    />
    <path
      d="M341.227 240.61c.578-.088 1.073-.3 1.195-1.01-.4-.4-.5-1.07-1.059-1.32-1.019 0-1.519.676-2.08 1.336a1.717 1.717 0 0 0 1.944.994"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-191.759 -106.966)"
    />
    <path
      d="m212.943 246.156 1.671.65c.257-.384.5-.757.784-1.172-.306-.69-.435-1.56-1.556-1.353a.742.742 0 0 0-.982.253 1.009 1.009 0 0 0-.176 1.163c.077.158.163.311.258.458"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.636 -109.581)"
    />
    <path
      d="M394.5 113.015c.042.131.093.258.152.382a.528.528 0 0 0 .969-.014.66.66 0 0 1 .512-.354c.534-.089.7-.273.606-.676a4.639 4.639 0 0 0-.459-1.14.702.702 0 0 0-.878-.2c-.489.456-1.263.6-1.51 1.463.137.057.267.129.388.214.092.095.167.206.221.328"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-215.946 -50.556)"
    />
    <path
      d="M304.514 132.083c.489-.1.97-.242 1.468-.369a1.666 1.666 0 0 0-.145-1.462c-.245-.44-.719-.374-1.071-.568a.567.567 0 0 0-.733.24 5.081 5.081 0 0 0-.752 1.642c.287.382.759.581 1.233.518"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-175.812 -58.84)"
    />
    <path
      d="M229.367 138.322c0-.011 0-.021.008-.032-.091.118-.184.235-.28.352l.023-.009a.529.529 0 0 0 .249-.311"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-142.953 -62.678)"
    />
    <path
      d="M444.386 135.688a.766.766 0 0 1-.492-.13.444.444 0 0 0-.6-.146c-.275.155-.533.338-.775.494.085.438.162.8.219 1.158.037.385.36.684.746.691.361.047.719.124 1.1.193.378-.274.616-.701.65-1.167.072-.653.03-1.072-.844-1.092"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-237.484 -61.373)"
    />
    <path
      d="M238.217 146.835a.689.689 0 0 0-.866.49c-.027.442-.03.885-.01 1.327.302.19.594.396.874.617.75.666.747.676 1.284-.048.134-.179.24-.379.323-.512a2.277 2.277 0 0 0-1.6-1.875"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.599 -66.45)"
    />
    <path
      d="M291.333 154.746c.007.085 0 .137.018.169.208.3.422.6.631.9l.7.139c.67.133.852.072 1.147-.44.183-.316.329-.655.532-1.066-.689-.39-1.087-.988-1.908-.967l-1.12 1.265Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.52 -69.407)"
    />
    <path
      d="M352.332 95.547a3.08 3.08 0 0 0 1.964-.462c.239-.6-.1-1.059-.407-1.514a.65.65 0 0 0-.346-.212 1.948 1.948 0 0 0-1.86.634.877.877 0 0 0-.157.9.928.928 0 0 0 .806.649"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-197.151 -42.755)"
    />
    <path
      d="M286.684 173.964c.163-.213.295-.4.446-.576.151-.176.33-.329.484-.5.56-.631.51-.911-.237-1.418-.192-.129-.367-.287-.568-.4a.777.777 0 0 0-.9.021c-.213.624-.421 1.227-.656 1.912.523.213.789.841 1.428.962"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-167.829 -77.137)"
    />
    <path
      d="M232.925 168.11a.709.709 0 0 0 .594-.8 1.748 1.748 0 0 0-.563-1.218c-.306-.285-.677-.178-1.044-.077-.179.014-.36.012-.539-.006h-.016a.612.612 0 0 0-.61.6c-.031.318-.006.64-.006 1.025.641.43 1.422.599 2.184.474"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.677 -74.911)"
    />
    <path
      d="M413.718 177.458c-.571.571-1.343.688-1.885 1.25.148.326.267.623.414.907.147.284.315.539.48.818.956-.393 2.088-.5 2.042-1.916a2.19 2.19 0 0 1-.607-.314 3.865 3.865 0 0 1-.443-.745"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-223.894 -80.026)"
    />
    <path
      d="M220.133 167.8c.379-.153.731-.373 1.095-.561-.092-.474-.157-.878-.254-1.275a.732.732 0 0 0-.708-.559 2.816 2.816 0 0 0-1.526.62c-.2.124-.292.424-.418.618.176.275.294.472.422.661.423.624.748.754 1.389.495"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.181 -74.69)"
    />
    <path
      d="M342.543 178.26c-.545-.695-1.243-.411-1.952-.245-.745.919-.257 1.6.138 2.311l1.742-.47c.391-.45.421-1.116.072-1.6"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-192.166 -80.184)"
    />
    <path
      d="M358.648 160.227a1.192 1.192 0 0 0-.407.678c-.127.7.048.921.811 1.2.175.064.346.135.59.229.964-.653 1.138-.86 1.284-1.458.1-.427-.167-.688-.4-.96a2.085 2.085 0 0 0-1.878.311"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-200.139 -72.215)"
    />
    <path
      d="M316.235 298.073c-.155.655-1.156.773-1.2 1.694.358.253 1.06.094 1.108.8.388.247.789.548 1.268.363.479-.185.177-.647.228-.984.015-.091.062-.177.078-.267a1.554 1.554 0 0 0-.433-1.144.883.883 0 0 0-1.05-.458"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-181.019 -133.434)"
    />
    <path
      d="m249.686 183.286-.765 1.522c.519 1.071.656 1.163 1.379 1.134.954-.038 1.2-.684 1.348-1.573-.429-.31-.787-.6-1.18-.836a3.574 3.574 0 0 0-.782-.247"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.735 -82.608)"
    />
    <path
      d="M251.406 196.026a2.797 2.797 0 0 0-1.576.168c-.137.405-.251.817-.34 1.235a.838.838 0 0 0 .164.617c.313.349 1.421.426 1.624.023.219-.437.64-.3.9-.606a2.83 2.83 0 0 0 .037-.495c-.054-.639-.187-.769-.807-.943"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.983 -88.224)"
    />
    <path
      d="M279.386 312.41a.902.902 0 0 0 1.444.431 2.366 2.366 0 0 0 1.019-1.548 1.433 1.433 0 0 0-1.4-.781 1.455 1.455 0 0 0-1.37.95c.108.339.193.649.3.948"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.093 -138.96)"
    />
    <path
      d="M389.333 95.6a2.547 2.547 0 0 0 1.164-.393.826.826 0 0 0-.129-1.462c-.309-.147-.544-.247-.659-.614-.09-.285-.443-.311-.7-.148-.435.288-.71.765-.74 1.286a1.145 1.145 0 0 0 1.06 1.332"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-213.455 -42.566)"
    />
    <path
      d="m376.975 93.28-1 1.17c.175.3.344.616.548.91a.824.824 0 0 0 1.38.152c.387-.384.724-.817 1.108-1.257-.509-1.157-1.378-.958-2.032-.975"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-208.009 -42.74)"
    />
    <path
      d="M190.95 184.653c-.143-.33-.253-.674-.408-1.092-.714.378-1.578.071-2.027.834.095.218.257.422.247.617-.01.195-.2.372-.3.539.726.72 1.549.5 2.319.48.229-.007.45-.227.673-.349-.2-.4-.369-.706-.508-1.028"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-124.957 -82.73)"
    />
    <path
      d="M208.876 299.011c-1.609-.2-1.995-.112-2.36 1.382.303.451.709.823 1.186 1.083l1.8-1.4-.626-1.065Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-132.952 -133.832)"
    />
    <path
      d="M292.159 189.7c-.488.326-.87.787-1.1 1.327.627.3.711 1.078 1.49 1.2.4.065.746.114 1-.2s.513-.651.152-1.109a2.952 2.952 0 0 1-.315-.877l-1.227-.341Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.397 -85.447)"
    />
    <path
      d="M219.777 300.865a1.188 1.188 0 0 0 1.614-.641l-.334-1.315c-.936-.834-1.642-.314-2.37.362a1.303 1.303 0 0 0 1.091 1.594"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.333 -133.63)"
    />
    <path
      d="M292.111 141.639v1.919c.147.137.304.263.469.376a.85.85 0 0 0 .4.081.837.837 0 0 0 .408-.055 2.084 2.084 0 0 0 1.158-1.94l-1.5-.749-.935.368Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.865 -63.998)"
    />
    <path
      d="m286.708 147.382-.995.729a3.251 3.251 0 0 0 .27 1.95c.447-.18.904-.333 1.368-.461a1.105 1.105 0 0 0 .983-1.155 2.716 2.716 0 0 0-1.626-1.063"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-168.021 -66.704)"
    />
    <path
      d="M245.111 155.745a2.837 2.837 0 0 0 0-1.993c-.2-.1-.4-.208-.61-.308a1.064 1.064 0 0 0-.254-.105.854.854 0 0 0-1.119.715c-.06.4-.166.8-.251 1.2.6.737 1.378.588 2.239.492"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.055 -69.331)"
    />
    <path
      d="M209.081 214.6a1.214 1.214 0 0 0-1.886.242c-.153.224-.289.459-.453.722.086.212.184.418.295.618.283.502.882.742 1.433.574.301-.093.568-.271.77-.513a.75.75 0 0 0 .012-.625c-.13-.33.253-.694-.17-1.018"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-133.052 -96.326)"
    />
    <path
      d="M341.254 155.943c.184-.026.362-.082.528-.165a.952.952 0 0 0 .379-.54 4.087 4.087 0 0 0-.1-1.82 2.479 2.479 0 0 0-2.268.843c.131.297.237.606.315.921.17 1.037.206 1.072 1.147.762"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-191.984 -69.37)"
    />
    <path
      d="M255.359 153.875a4.643 4.643 0 0 0-.373.708.875.875 0 0 0 .544 1.229c.9.457 1.42.365 2.321-.411l-.945-1.526h-1.547Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.387 -69.58)"
    />
    <path
      d="M378.369 214.286c-.45 0-.866-.013-1.281 0a.787.787 0 0 0-.792.66c-.085.357-.241.7.107 1.038.151.146.178.42.3.735.624.127 1.27.095 1.879-.091-.291-.738.061-1.5-.215-2.345"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-208.105 -96.337)"
    />
    <path
      d="m298.285 113.6 1.343-.375-.053-.694c.313-.037.578-.066 1.06-.124-.457-.709-.459-1.524-1.37-1.686-.31.233-.65.466-.964.731-.36.3-.563.75-.55 1.219a.828.828 0 0 0 .535.929"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.363 -50.468)"
    />
    <path
      d="M231.157 275.367c-.51.553-.546.665-.092 1.291.228.315.282.839.808.853.393.011.466-.392.682-.611a.617.617 0 0 1 .241-.128c.715-.261.755-.828.694-1.583a3.34 3.34 0 0 1-.893-.2c-.628-.361-1.033-.064-1.439.375"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.685 -123.156)"
    />
    <path
      d="M322.227 113.833a.832.832 0 0 0 .382.138c.407.018.814.007 1.188.007a5.1 5.1 0 0 0 .37-.528c.4-.774.3-1.154-.416-1.6a3.339 3.339 0 0 0-.494-.245.937.937 0 0 0-1.22.279c-.255.5-.14 1.749.189 1.953"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-184.059 -50.812)"
    />
    <path
      d="M351.9 118.961c.058.075.125.142.2.2 1.077.831 1.6.662 1.97-.633.013-.046.036-.088.047-.133a1.07 1.07 0 0 0-.307-1.149c-.206-.177-.417-.349-.593-.5a2.303 2.303 0 0 0-1.6 1.773c.135.208.206.329.288.438"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-197.222 -53.137)"
    />
    <path
      d="M292.161 119.957c.045.015.089.029.134.04a1.405 1.405 0 0 0 1.583-1.2 1.306 1.306 0 0 0-1.217-1.267 1.524 1.524 0 0 0-1.455 1.252c-.1.536.232.941.954 1.178"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.457 -53.48)"
    />
    <path
      d="M322.835 125.565c.731.07.972-.115 1.125-.855.053-.262.094-.528.138-.785-.372-.264-.708-.5-1.045-.736a.63.63 0 0 0-.125-.061.857.857 0 0 0-1.155.449c-.145.38-.236.782-.344 1.15.289.503.82.821 1.4.837"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-183.851 -55.928)"
    />
    <path
      d="M219.164 262.862a4.208 4.208 0 0 0-.022.832c.041.523.44.952.959 1.031.276.031.554.034.831.009l.028.001a.685.685 0 0 0 .682-.682v-.019a1.609 1.609 0 0 0-.457-1.406c-.438-.094-.882-.2-1.331-.284a.583.583 0 0 0-.69.521"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.539 -117.62)"
    />
    <path
      d="M262.472 126.33c.207-.079.41-.17.608-.271.389-.169.674-.515.763-.93.087-.528-.3-.727-.625-.973a.298.298 0 0 1-.091-.105c-.29-.608-.785-.539-1.346-.394l-.617.894c.3.695.494 1.427 1.308 1.78"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.157 -56.153)"
    />
    <path
      d="m245.027 201.931-2.062.55c-.035.5-.076.945-.095 1.391a.627.627 0 0 0 .569.673c.273.037.552-.007.8-.128.408-.223.76-.535 1.03-.914.476-.768.515-.743-.24-1.572"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.054 -90.866)"
    />
    <path
      d="m273.55 257.068-.177 1.459c.214.378.568.657.985.777a.699.699 0 0 0 1.039-.347c.143-.282.236-.586.274-.9.027-.425.031-.852.011-1.278a2.674 2.674 0 0 0-2.132.29"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.565 -115.102)"
    />
    <path
      d="M291.752 131.221a.948.948 0 0 0 1.5-.33c.273-.456.333-1.01.163-1.514-.3-.211-.635-.445-.923-.645-1.051.149-1.32.969-1.764 1.658.206.152.359.255.5.373.177.147.334.321.522.458"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.253 -58.445)"
    />
    <path
      d="M183.253 334.773a4.6 4.6 0 0 0-1.419 1.379l.741 1.056c1.485.135 2.032.21 2.27-1.17-.345-.644-1.044-.834-1.593-1.264"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-122.019 -149.707)"
    />
    <path
      d="M239.134 343.137a5.76 5.76 0 0 0 .5-.393 5.447 5.447 0 0 0-.3-1.265 6.665 6.665 0 0 0-.875-1.078c-.889.1-1.154.675-1.438 1.2.216.33.411.673.584 1.028.331.818.832.936 1.526.5"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.465 -152.2)"
    />
    <path
      d="M231.539 359.529c-.116.3-.193.618-.3.982.129.344.275.728.407 1.082.655.272 1.124-.069 1.6-.333.112-.353.224-.7.4-1.249a5.862 5.862 0 0 0-.646-.834.865.865 0 0 0-1.46.352"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.901 -160.397)"
    />
    <path
      d="M395.158 89.715c.18-.06 1.038-.347 1.259-.424l.645-1.377c-1.5-1.041-1.7-1-2.951.507a2.002 2.002 0 0 0 1.047 1.293"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-216.044 -40.049)"
    />
    <path
      d="M249.894 343.409c.69.182.962.01 1.23-.723.094-.258.186-.519.275-.77-.236-.541-.385-1.092-.96-1.372l-1.589 1 .414 1.544c.197.13.408.239.629.324"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.703 -152.262)"
    />
    <path
      d="M408.26 135.534a5.816 5.816 0 0 0-.88-.232 1.265 1.265 0 0 0-.759 1.536c.172.767.859.9 1.6 1.1.183-.173.328-.288.449-.425.708-.81.558-1.571-.409-1.981"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-221.566 -61.355)"
    />
    <path
      d="M256.43 192.564c.146-.226.414-.491.364-.585-.367-.674.241-1.1.445-1.676-.231-.246-.4-.554-.66-.673-.368-.168-.7.058-.982.334-.36.348-.74.673-1.164 1.057.55.653.994 1.405 2 1.543"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.176 -85.392)"
    />
    <path
      d="m413.209 166.1-1.023 1.246c.311 1.032 1.213.984 1.861 1.4.247-.244.451-.427.631-.632.117-.151.224-.31.319-.476l-.445-1.286-1.341-.249"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-224.05 -74.996)"
    />
    <path
      d="M213.508 292.477c-.162-.14-.455-.033-.592.206-.094.16-.173.329-.292.56.259.583.483 1.085.71 1.6.515.135 1.059.11 1.559-.071.312-.45.541-.952.675-1.483-.149-.108-.252-.242-.372-.258a2.952 2.952 0 0 1-1.687-.553"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.657 -130.945)"
    />
    <path
      d="m168.181 172.126-.4-.116a19.765 19.765 0 0 0-.115 2.189c.228-.011.454-.051.672-.118l-.157-1.955Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-115.745 -77.613)"
    />
    <path
      d="M256.285 141.225a1.73 1.73 0 0 0-.51.133l-.422 1.782c.488.541 1.26.732 1.944.481.076-.067.146-.139.21-.217.832-1.209.382-2.073-1.222-2.179"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.583 -63.977)"
    />
    <path
      d="M347.428 111.394c-.36 0-.721-.048-1.1-.075a2.174 2.174 0 0 0-.11 1.9c.135.316.622.484.941.714.282-.114.556-.248.82-.4.366-.348.537-.856.457-1.355a1.004 1.004 0 0 0-1.01-.786"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.756 -50.732)"
    />
    <path
      d="M196.979 226.434c-.43-.15-.9-.136-1.32.039-.185.249-.352.468-.511.692a.947.947 0 0 0-.037 1.156c.263.458.822.665 1.32.489a7.55 7.55 0 0 0 1-.534c-.036-.432-.289-.807-.066-1.224.159-.3-.116-.536-.384-.618"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-127.82 -101.674)"
    />
    <path
      d="M268.623 225.026 267 226.868l.6 1.165c.519-.521 1.021-.114 1.556-.058.077.008.164-.064.274-.111l.372-1.065-1.179-1.773Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.741 -101.096)"
    />
    <path
      d="M249.429 125.254c.282.173.573.333.871.477.443-.162.982-.173 1.325-.626.225-.562-.336-.818-.5-1.232-.341-.883-.647-.959-1.438-.481a2.794 2.794 0 0 0-.34.242.939.939 0 0 0 .079 1.619"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.735 -55.95)"
    />
    <path
      d="M341.95 262.868c.26-.442-.045-.7-.322-.937-.412-.357-1.07-.255-1.11.232-.051.649-.481.941-.833 1.343-.077.089-.064.26-.112.487l1.427.807c.34-.176.66-.389.954-.634.31-.325.037-.69-.07-1.035a.393.393 0 0 1 .069-.262"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-191.888 -117.349)"
    />
    <path
      d="M177.861 198.143c.447.16.8-.114 1.163-.4a2.718 2.718 0 0 1-.146-.642c0-.264.187-.553-.125-.75a2.988 2.988 0 0 0-.72-.369 11.452 11.452 0 0 0-1.177-.221 3.827 3.827 0 0 0-.2.331c-.14.287-.271.579-.406.873.325.87 1.015.965 1.609 1.177"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-119.547 -88.134)"
    />
    <path
      d="M371.609 198.367c.445-.169.734-.607.715-1.083-.013-.5.075-1.018-.491-1.334-.855-.476-1.2-.407-2.052.6.34.558.631 1.077.969 1.568.179.29.552.4.859.253"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-205.268 -88.091)"
    />
    <path
      d="M330.264 106.182a1.228 1.228 0 0 0-1.441-.778.863.863 0 0 0-.876.54c-.26.45-.208 1.02.13 1.415.127.128.29.216.468.25a2.756 2.756 0 0 0 2.181-.7 4.36 4.36 0 0 1-.463-.724"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.667 -48.108)"
    />
    <path
      d="M394.033 209.159c.048.1.094.192.138.29l1.137-1.417a2.333 2.333 0 0 0-.548-.236l-.727 1.363Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-216.009 -93.465)"
    />
    <path
      d="M329.915 238.413c-.685-.256-1.333-.6-1.984.088l-.284.884c.335.422.609.782.9 1.128a.7.7 0 0 0 1.094.061c.598-.4.789-1.203.437-1.83-.062-.117-.118-.239-.162-.33"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.605 -106.886)"
    />
    <path
      d="M372.591 159.984a1.22 1.22 0 0 0-1.919.247 2.679 2.679 0 0 0-.292.631 1.126 1.126 0 0 0 1.263 1.382 1.775 1.775 0 0 0 1.22-1.578 1.58 1.58 0 0 0-.272-.682"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-205.513 -72.127)"
    />
    <path
      d="M306.048 299.831c-.286-.708-1.032-.861-1.581-1.325-.54.473-1.115.868-1.157 1.684l.708.975c1.4.13 1.419.116 2.03-1.334"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-175.825 -133.642)"
    />
    <path
      d="M207.512 165.47a.694.694 0 0 0-.464.632 9.517 9.517 0 0 0 0 1.241c.057.266.146.525.264.77l2.182-.635c0-.248.088-.53-.016-.678a9.818 9.818 0 0 0-1.057-1.22.802.802 0 0 0-.907-.109"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-133.177 -74.677)"
    />
    <path
      d="M250.211 305.007c-.402.092-.78.267-1.111.512-.406.3-.329 1.175.133 1.294a7.344 7.344 0 0 1 1.678 1.016c.185-.262.386-.426.416-.617a6.73 6.73 0 0 0 .043-1.245c-.028-.768-.414-1.115-1.159-.96"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.697 -136.506)"
    />
    <path
      d="M359.95 86.863c-.157-.217-.328-.423-.51-.655a2.412 2.412 0 0 0-1.586 1.731l1.6 1.367c1.174-.9 1.262-1.383.5-2.443"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-199.985 -39.608)"
    />
    <path
      d="M322.6 150.371c.126.061.261.1.4.115.333-.255.659-.5.969-.737a6.99 6.99 0 0 0-.08-1.894c-.482-.155-.955-.258-1.327.109-.436.431-1.1.735-1.067 1.484.395.334.741.642 1.107.923"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-183.877 -66.852)"
    />
    <path
      d="M347.139 159.854c-.4.375-1.108.109-1.37.779l.292 1.285c.45.442 1.133.554 1.7.279.572-.262.953-.821.989-1.449a3.585 3.585 0 0 1-.8-.228 6.408 6.408 0 0 1-.813-.665"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.632 -72.23)"
    />
    <path
      d="M414.514 154.12a2.02 2.02 0 0 0-1.326-.312.774.774 0 0 0-.643.467 3.938 3.938 0 0 0-.309 1.057c-.017.177.181.521.315.539.489.097.992.097 1.481 0 .41-.182.789-.426 1.125-.723a1.316 1.316 0 0 0-.643-1.032"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-224.072 -69.541)"
    />
    <path
      d="M323.5 159.922a1.003 1.003 0 0 0-.55-.165 1.006 1.006 0 0 0-.997 1.073c.003.267.02.534.051.8.308.376.749.619 1.232.678a.772.772 0 0 0 .852-.347c.194-.359.549-.734.285-1.156a2.698 2.698 0 0 0-.873-.883"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-184.081 -72.186)"
    />
    <path
      d="M353.1 192.207c.267-.08.523-.19.765-.328a.686.686 0 0 0 .376-.7 3.321 3.321 0 0 0-.87-1.85c-.048-.055-.215-.086-.25-.052a.892.892 0 0 0-.177.324 1.49 1.49 0 0 0-.039.305l-.788.07c-.194.263-.433.49-.245.873.14.288.2.614.331.911a.695.695 0 0 0 .9.445"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-197.306 -85.256)"
    />
    <path
      d="M310.528 234.581c.631.243 1.031-.039 1.178-.726.056-.264.1-.533.164-.912a4.614 4.614 0 0 0-.807-.42c-.96-.26-1.814-.239-2.07.906-.005.101.002.201.023.3.425.405.945.697 1.512.85"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.34 -104.356)"
    />
    <path
      d="M221.257 214.563a2.273 2.273 0 0 0-1.285-.756 1.441 1.441 0 0 0-1.248.485l.1 1.389a1.855 1.855 0 0 1 1.812 1.091c.341-.643.565-1.034.758-1.441a.684.684 0 0 0-.139-.768"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.359 -96.116)"
    />
    <path
      d="M244.907 216.054c.27-.3.338-.562.038-.844a.69.69 0 0 1-.207-.955.392.392 0 0 0-.133-.589.59.59 0 0 0-.507.033c-.592.411-1.156.86-1.723 1.288.211.34.382.592.525.858a1.164 1.164 0 0 0 2.007.209"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-148.835 -96.045)"
    />
    <path
      d="M213.364 234.072a1.453 1.453 0 0 0 1.471.07 3.275 3.275 0 0 1 .012-.779c.101-.299.227-.589.377-.866a2.372 2.372 0 0 0-.294-.354c-.758-.6-.906-.6-1.7-.048-.257.181-.494.388-.827.652.324.479.357 1.05.958 1.325"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.561 -104.046)"
    />
    <path
      d="M353.857 226.435h-1.765l-.446 1.379c.111.168.236.325.375.47a1.353 1.353 0 0 0 1.764.072c.756-.611.8-.859.309-1.755-.039-.069-.143-.1-.237-.165"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-197.235 -101.72)"
    />
    <path
      d="M196.2 156.235c.076-.112.229-.349.4-.573.082-.112.2-.2.286-.306.283-.323.329-.794.114-1.166a.701.701 0 0 0-.981-.214 1.747 1.747 0 0 1-.771.121c-.575 0-1.048.473-1.048 1.048v.009a2.24 2.24 0 0 0 2 1.081"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-127.497 -69.578)"
    />
    <path
      d="M214.064 222.7a1.828 1.828 0 0 0 1.5-1.062.375.375 0 0 0 0-.272 2.657 2.657 0 0 0-1.009-1.274.648.648 0 0 0-.759.1c-.473.335-.486.89-.649 1.355-.249.719.139 1.157.914 1.151"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.857 -98.873)"
    />
    <path
      d="m158.28 251.44.713 1.072a2.553 2.553 0 0 0 1.7-.149l.095-1.712-1.316-.611a1.953 1.953 0 0 0-1.193 1.4"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-111.587 -112.175)"
    />
    <path
      d="M304.458 310.623c-.113.065-.221.14-.322.223a6.767 6.767 0 0 0-.035.955c.01.225.09.44.229.617a1.258 1.258 0 0 0 1.839.016c.2-.184.362-.408.6-.68-.34-.548-.672-1.084-1.067-1.718-.5.236-.877.4-1.245.587"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-176.172 -138.75)"
    />
    <path
      d="M269.52 240.632v-1.582c-.579-.333-.989-.885-1.774-.945-.1.14-.382.385-.338.48.267.578-.217.962-.312 1.466.682 1.1 1.6.447 2.425.582"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.784 -106.89)"
    />
    <path
      d="M365.7 165.6c-.047-.258-.415-.213-.6-.075a3.84 3.84 0 0 0-1.134 1.168c.232.259.45.53.652.813.174.274.274.6.72.635l1.6-.976c-.023-.292-.039-.519-.059-.758-.442-.219-1.054-.124-1.18-.807"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-202.692 -74.691)"
    />
    <path
      d="M376.7 141.474c-.16.092-.33.168-.487.267a.695.695 0 0 0-.332.819c.186.532.618.942 1.159 1.1.61.14 1.209.07 1.463-.551l-.305-1.55c-.774-.5-.773-.5-1.5-.085"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-207.951 -63.954)"
    />
    <path
      d="M401.488 177.549a.8.8 0 0 0-.525.393c-.184.6-.6 1.185-.252 1.975a2.375 2.375 0 0 0 2.049-.3.66.66 0 0 0 .242-.859c-.216-.4-.463-.784-.729-1.229a3.041 3.041 0 0 0-.786.016"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-218.908 -80.051)"
    />
    <path
      d="m288.137 112.921-.5-1.927a5.818 5.818 0 0 1-.927.489 7.157 7.157 0 0 1-1.079.175l-.2 1.347 1.579.79 1.127-.874Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-167.906 -50.587)"
    />
    <path
      d="m346.532 244.13-.855.887.374 1.419c.483.051.89.1 1.3.131a.756.756 0 0 0 .786-.45c.114-.242.2-.5.3-.739a2.67 2.67 0 0 0-1.9-1.248"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.591 -109.557)"
    />
    <path
      d="M336.168 210.214a7.7 7.7 0 0 1 .2-1.116c.193-.355.198-.784.011-1.142-.459.053-.868.109-1.278.148-.922.091-1.238.471-1.045 1.364.103.289.244.562.421.813a3.495 3.495 0 0 0 1.7-.067"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-189.418 -93.535)"
    />
    <path
      d="M189.27 198.309c1.359.362 2.068-.372 1.688-1.724a3.29 3.29 0 0 0-.193-.423c-.81-.007-1.68-.3-2.268.749.06.329.152.651.273.963a.894.894 0 0 0 .5.434"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-124.971 -88.292)"
    />
    <path
      d="M257.517 348.778a.643.643 0 0 0-.184-1.05 6.004 6.004 0 0 0-.909-.326.92.92 0 0 0-1.24.559c-.26.344-.321.801-.158 1.2l1.06.7c.493-.369.995-.692 1.431-1.087"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.394 -155.27)"
    />
    <path
      d="m244.741 129.184-1.39.674c0 .569-.032 1.031.012 1.485a.544.544 0 0 0 .543.535 15.04 15.04 0 0 0 1.571-.324l.287-.922c-.553-.287-.292-1.12-1.022-1.448"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.262 -58.645)"
    />
    <path
      d="M238.378 365.291c-.573.345-1.272.559-1.632 1.323l1.424 1.22a2.926 2.926 0 0 0 1.691-1.354 6.625 6.625 0 0 0-.6-.943.719.719 0 0 0-.88-.247"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.342 -163.19)"
    />
    <path
      d="M358.84 150.124c.419.023.869.07 1.1-.4.218-.443.4-.9.625-1.412l-1.375-.858-1.346.85c.087.445.145.808.229 1.164a.717.717 0 0 0 .767.657"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-199.98 -66.736)"
    />
    <path
      d="m221.19 288.561.155-1.783c-1.229-.821-1.229-.821-2.112-.014.096.243.159.499.186.759a4.174 4.174 0 0 1-.18.871c1.209.836 1.209.836 1.951.168"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.585 -128.174)"
    />
    <path
      d="M396.107 123.593a10.81 10.81 0 0 0-1.35.217c-.353.618-.889 1.163-.164 1.944h1.652c.626-.889.626-.889.66-1.253a.764.764 0 0 0-.8-.908"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-216.098 -56.168)"
    />
    <path
      d="M218.668 323.951c.228.736.365 1.566 1.494 1.632.386-.36.759-.688 1.106-1.041a.599.599 0 0 0-.04-.867 2.627 2.627 0 0 0-2.078-.47.686.686 0 0 0-.482.746"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.327 -144.552)"
    />
    <path
      d="M263.139 220.157c-.561.149-1.132.287-1.692.456-.315.095-.575.527-.443.76.194.465.512.868.919 1.165a.899.899 0 0 0 1.33-.222c.656-.8.64-1.148-.113-2.159"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.071 -98.94)"
    />
    <path
      d="M140.877 246.482h1.262a8.15 8.15 0 0 0 .4-1.034 1.18 1.18 0 0 0-1.126-1.436 1.248 1.248 0 0 0-1.308.834c.246.544.211 1.2.772 1.636"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-103.536 -109.503)"
    />
    <path
      d="M309.6 112.724c.238.281.442.528.655.765.386.436.43.431.887.128.177-.135.37-.248.575-.336a.77.77 0 0 0 .536-.861c-.011-.222-.05-.444-.079-.68l-1.108-.546c-1.1.495-1.1.495-1.466 1.529"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.611 -50.677)"
    />
    <path
      d="M251.668 293.539a2.012 2.012 0 0 0-1.338-1.008 1.905 1.905 0 0 0-1.382 1.049c.059.155.113.281.156.411.319.937.75 1.183 1.639.9a.883.883 0 0 0 .723-.748c.045-.206.112-.408.2-.6"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.746 -130.996)"
    />
    <path
      d="M225.931 234.545a.685.685 0 0 0 .861-.241c.329-.432.621-.889.941-1.355a5.224 5.224 0 0 0-.352-.473c-.251-.264-.519-.511-.731-.72-.123-.026-.174-.055-.212-.043-.343.094-.737.06-.958.441a1.039 1.039 0 0 0-.114 1.158c.077.13-.008.351-.006.53.023.329.252.61.57.7"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-141.242 -104.056)"
    />
    <path
      d="M286.64 329.357c-.377.264-.743.544-1.1.826-.449.35-.435.743-.04 1.177a1.54 1.54 0 0 0 1.282.368 1.27 1.27 0 0 0 1.215-.9c-.146-.452-.25-.853-.408-1.233a.638.638 0 0 0-.945-.241"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-167.803 -147.256)"
    />
    <path
      d="M249.323 149.714a1.301 1.301 0 0 0 1.428-.246c.313-.329.621-.662.995-1.062l-1.046-1.065a1.75 1.75 0 0 0-1.274.412 1.25 1.25 0 0 0-.529 1.466c.061.22.217.402.426.495"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.701 -66.68)"
    />
    <path
      d="M226.184 271.195c.6.189.948-.115 1.038-.754.05-.36.085-.722.149-1.267l-1.9-.433-.729 1.7c.305.637.941.6 1.441.755"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-141.025 -120.459)"
    />
    <path
      d="M395.613 101.861c.547-.051 1.044-.593.883-1.041a5.914 5.914 0 0 1-.059-1.375c-.358-.026-.678-.066-1-.067-.213.01-.426.034-.636.072l-.74 2.237a2.353 2.353 0 0 0 1.549.175"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-216.023 -45.443)"
    />
    <path
      d="M200.945 282.678c.54.15.978.3 1.426.388.4.08.823-.313.634-.647-.315-.558.332-.691.329-1.15-.184-.2-.4-.437-.624-.661a1.37 1.37 0 0 0-.339-.235c-.357-.18-.908-.017-.889.323.036.6-.512.721-.74 1.121l.203.861Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.394 -125.578)"
    />
    <path
      d="M388.095 178.694c-.319.471.139.848.319 1.247.018.039.069.061.159.135a9.8 9.8 0 0 0 1.158.135c.631.009 1.257-1.073.977-1.663a.613.613 0 0 0-.28-.276 2.534 2.534 0 0 0-.77-.262 1.622 1.622 0 0 0-1.561.685"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-213.331 -80.266)"
    />
    <path
      d="M396.893 160.59a1.097 1.097 0 0 0-1.349-.667c-.4.179-.958.071-1.21.553.167 1.2.167 1.2.867 1.869a9.056 9.056 0 0 0 1.1-.359c.524-.245.781-.854.59-1.4"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-216.143 -72.23)"
    />
    <path
      d="M213.136 305.873a.913.913 0 0 0 .244 1.027c.267.241.551.462.838.7.18-.115.348-.249.5-.4.262-.326.504-.669.723-1.026a1.038 1.038 0 0 0-.256-1.379c-.427-.383-.638-.38-1.341.019l.391.731c-.61-.178-.96-.11-1.1.322"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.857 -136.305)"
    />
    <path
      d="M316.092 274.786a1.24 1.24 0 0 0-.295.113c-.074.127-.176.279-.256.441a1.497 1.497 0 0 0-.135.389.784.784 0 0 0 .575.957c.17.066.351.109.521.176a1.168 1.168 0 0 0 1.618-.578c-.318-1.407-.18-1.627-2.029-1.5"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-181.175 -123.122)"
    />
    <path
      d="M334.238 147.386c-.685.318-.413 1.043-.894 1.474l.721 1.185a5.13 5.13 0 0 0 2.206-.984 3.235 3.235 0 0 0-2.033-1.675"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-189.128 -66.706)"
    />
    <path
      d="M165.843 223c1.435-1.035.872-1.29.687-2.708a3.199 3.199 0 0 1-2.172.076c.247 1.236.183 2.339 1.485 2.632"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-114.279 -99)"
    />
    <path
      d="m310.56 268.312-.783 1.141.821 1.226h1.3c.131-.313.24-.562.343-.815.103-.253.192-.489.315-.8-.706-.241-1.076-1.019-2-.747"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.69 -120.243)"
    />
    <path
      d="M358.579 125.329c.22.067.436.15.645.247.296-.262.577-.541.842-.834a.921.921 0 0 0 .049-1.056 1.312 1.312 0 0 0-1.819-.456 1.291 1.291 0 0 0-.571 1.334c.059.526.413.675.855.764"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-199.921 -55.92)"
    />
    <path
      d="M237.355 318.938c.5.423 1.048.713 1.636.3.523-.365 1.067-.8.7-1.646-.389-.175-1.019-.087-1.1-.772-.009-.066-.228-.158-.342-.148-.442.039-.873.43-.744.8.191.546-.285.969-.146 1.467"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.6 -141.69)"
    />
    <path
      d="M339.755 274.748a3.842 3.842 0 0 0 .43 1.789l1.495-1.872a5.622 5.622 0 0 0-.652-.245l-1.273.328Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-191.968 -122.974)"
    />
    <path
      d="M353.036 214.267a1.524 1.524 0 0 0-1.175.474l-.077 1.273c.342.341.845.69 1.211.433.46-.323 1.058-.183 1.521-.6a55.836 55.836 0 0 1-.218-.647c-.289-.855-.43-.946-1.261-.93"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-197.296 -96.33)"
    />
    <path
      d="M342.3 167.75c.057-.055.063-.165.114-.308a2.455 2.455 0 0 0-1.534-1.431.675.675 0 0 0-.913.392c-.123.3-.192.617-.306 1 1.173 1.254 1.627 1.308 2.639.347"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-191.926 -74.93)"
    />
    <path
      d="M267.608 119.79c.222.061.433.162.657.213a.892.892 0 0 0 1.014-.527c.091-.45.133-.91.124-1.369a.64.64 0 0 0-.835-.507 8.198 8.198 0 0 0-1.4.6 1.04 1.04 0 0 0-.344.69c.016.27.076.536.178.787.247.046.428.067.6.115"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.663 -53.5)"
    />
    <path
      d="M275 123.208c-1.667.974-1.667.974-.922 2.669.172.005.344-.014.511-.057.338-.139.656-.326.984-.489a.814.814 0 0 0 .5-1.011 1.288 1.288 0 0 0-1.073-1.112"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.664 -55.998)"
    />
    <path
      d="M370.325 88.415c.03.855.5 1.22 1.268.92a2.885 2.885 0 0 0 1.028-.683.855.855 0 0 0-.551-1.452 9.86 9.86 0 0 0-1.045-.124.691.691 0 0 0-.687.64 4.163 4.163 0 0 0 0 .418h-.012v.281"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-205.507 -39.995)"
    />
    <path
      d="m281.353 347.4-1.044-.271.037-.355c-.076-.028-.161-.094-.231-.081-.926.174-.867 1.077-1.2 1.681-.13.241.287.806.585.84.277.032.523.057.741.071l1.257-1.52c0-.032-.007-.061-.012-.095a1.066 1.066 0 0 0-.138-.271"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.011 -154.987)"
    />
    <path
      d="M304.539 288.934c.184.016.369.019.554.01.619-.019.762-.115.941-.616.2-.566.152-1.143-.229-1.376a2.98 2.98 0 0 0-1.147-.413c-.231-.031-.495.189-.843.34 0 .529-.023.945.005 1.356 0 .384.316.7.7.7h.018"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-176.045 -128.34)"
    />
    <path
      d="M268.516 346.846a2.102 2.102 0 0 0-1.46 1.354.822.822 0 0 0 .487 1.091 2.024 2.024 0 0 1 1.876.059c0-.706-.017-1.2 0-1.7.025-.61-.365-1.008-.907-.809"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.744 -155.031)"
    />
    <path
      d="M457.391 113.053c.139-.47.029-.98-.291-1.351a1.053 1.053 0 0 0-.97-.647c-.478 0-.899.327-1.018.79a5.614 5.614 0 0 0-.223.75l.983 1.08 1.519-.622Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-242.965 -50.616)"
    />
    <path
      d="M280.839 178.016a5.35 5.35 0 0 0-1.359.126.832.832 0 0 0-.16 1.3c.615.438 1.2 1.067 2.217.656l.21-1.471a1.076 1.076 0 0 0-.907-.61"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.091 -80.273)"
    />
    <path
      d="M371.653 210.531c.304-.107.589-.263.844-.461.121-.1.24-.391.183-.481-.362-.556-.207-1.4-.992-1.735a2.49 2.49 0 0 0-1.65 1.176c.3.426.58.841.874 1.248a.638.638 0 0 0 .74.253"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-205.382 -93.49)"
    />
    <path
      d="m285.692 222.076 1.175.055h.014l1.085-.89a2.545 2.545 0 0 0-1.113-1.894c-.282.331-.292.892-.935.761-.247-.051-.487.268-.451.592.053.452.142.9.222 1.378"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-167.922 -98.58)"
    />
    <path
      d="M251.226 135.981a4.249 4.249 0 0 1-.339-.746c-.312 0-.584-.011-.853.007-.08 0-.155.087-.274.16-.03.686-.569 1.326-.222 2.256.455.015.866.031 1.276.041.377-.007.726-.204.926-.524a.535.535 0 0 0 0-.749c-.155-.165-.389-.265-.516-.445"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-151.956 -61.324)"
    />
    <path
      d="M407.824 159.7a2.409 2.409 0 0 1-.4-.107c-.448-.089-.739.091-.792.51a1.31 1.31 0 0 0 .251 1.173c.187.2.339.426.506.641h1.271a1.235 1.235 0 0 0 .25-1.52 1.146 1.146 0 0 0-1.089-.7"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-221.577 -72.102)"
    />
    <path
      d="M291.28 215.258a3.376 3.376 0 0 0 1.78 1.224c1.3-1.127 1.3-1.2.138-2.723-.717.49-1.645.555-1.918 1.5"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.497 -96.105)"
    />
    <path
      d="M202.165 150.054c.273-.461.5-.8.682-1.16a1.086 1.086 0 0 0-.663-1.556 2.183 2.183 0 0 0-1.207.041c-.232.085-.35.482-.569.813.772.469.593 1.707 1.757 1.861"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.246 -66.656)"
    />
    <path
      d="M312.013 125.691c.116-.033.2-.173.319-.277l-.31-1.745a3.478 3.478 0 0 0-1.711-.026c-.443 1.3-.443 1.3-.195 2.081a4.54 4.54 0 0 0 1.9-.033"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.766 -56.148)"
    />
    <path
      d="M347.792 231.971a1.633 1.633 0 0 0-1.5.561 1.286 1.286 0 0 0 .053 1.431c.149.231.336.439.56.727.221-.125.447-.261.679-.379.232-.118.486-.226.745-.345 0-.5.021-.965-.007-1.426a.625.625 0 0 0-.531-.569"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.778 -104.157)"
    />
    <path
      d="M321.413 269.524c.461.607.067 1.507.935 1.973.475-.293 1.146-.208 1.621-.822a3.518 3.518 0 0 1-.357-.788 5.602 5.602 0 0 1-.014-1.052c-.912-.234-1.467.291-2.185.69"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-183.844 -120.474)"
    />
    <path
      d="M190.159 282.831c.396-.161.718-.465.9-.852-.331-.354-.669-.715-1.076-1.147a6.24 6.24 0 0 0-1.023.009.973.973 0 0 0-.657 1.4 1.236 1.236 0 0 0 1.851.589"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-124.838 -125.806)"
    />
    <path
      d="M311.926 294.52c.12-.239.285-.455.4-.642-.568-1.346-.81-1.432-1.933-.8a.963.963 0 0 0-.6.848c0 .225-.039.45-.061.706.136.12.281.228.435.323.874.447 1.227.622 1.761-.438"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.668 -131.07)"
    />
    <path
      d="M154.454 256.977c-.988-.635-1.661-.448-2.214.618a.746.746 0 0 0-.035.154c.155.411.247.911.765 1.049a1.886 1.886 0 0 0 1.47-.232.913.913 0 0 0 .013-1.589"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-108.896 -115.087)"
    />
    <path
      d="M190.2 256.644c-.32-.009-.641.01-.958.056-.074.008-.137.11-.221.184v.736l-.606.35c.075.205.162.405.26.6a.94.94 0 0 0 1.495.341c.211-.18.411-.371.6-.574.271-.265.549-.553.359-.959a1.12 1.12 0 0 0-.929-.736"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-124.935 -115.099)"
    />
    <path
      d="M237.741 271.059c.168-.115.318-.221.47-.321.076-.052.156-.099.239-.138a1.35 1.35 0 0 0 .844-1.308c0-.616-.433-.864-.9-1.119l-1.439.516.326.656-.489.619c.346.4.647.745.948 1.095"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.363 -120.207)"
    />
    <path
      d="M257.317 107.047c.115-.311.2-.522.271-.74.187-.608.078-.873-.474-1.081-.338-.126-.692-.209-1.023-.307-.487.264-.898.65-1.192 1.12l1.214 1.408 1.204-.4Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.382 -47.897)"
    />
    <path
      d="M168.526 184.354c.067.371.135.735.206 1.092l.137-.152c-.1-.277-.207-.567-.343-.94"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-116.125 -83.081)"
    />
    <path
      d="M370.567 174.3a1.135 1.135 0 0 0 1.62-.443c.172-.368.347-.733.519-1.094-.589-.741-.772-.866-1.179-.811a1.727 1.727 0 0 0-1.394 1.524c.034.32.189.616.434.825"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-205.423 -77.584)"
    />
    <path
      d="M257 264.748a.608.608 0 0 0 .313-.263 1.72 1.72 0 0 0-.215-1.7c-.266-.348-.631-.413-1.263-.186-.341.121-.717.218-.886.628.234.57.473 1.152.732 1.778.431-.08.88-.148 1.32-.253"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.405 -117.678)"
    />
    <path
      d="M164.779 294.813a2.373 2.373 0 0 0 2.069-.295 1.46 1.46 0 0 0-1.021-1.915c-.585-.245-1.067-.032-1.117.564a4.667 4.667 0 0 0 .07 1.645"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-114.417 -130.984)"
    />
    <path
      d="M225.309 378.106c-.227.138-.307.522-.447.783.507.575 1.171.99 1.91 1.193l1.087-1.349-.793-1.1a2.646 2.646 0 0 0-1.758.476"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-141.078 -168.681)"
    />
    <path
      d="M166.648 234.377a1.33 1.33 0 0 0 .35-1.408c-.067-.341-.358-.522-.865-.547-.367-.017-.734 0-1.141 0l-.684 1.341c.375.287.767.549 1.175.786.384.199.854.13 1.165-.17"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-114.256 -104.368)"
    />
    <path
      d="M214.67 353.094c-.348-.07-.687-.187-1.224-.338.057.255.078.517.062.778a3.493 3.493 0 0 1-.333.59.548.548 0 0 0 .183.738c.306.203.644.355 1 .448a1.112 1.112 0 0 0 1.2-.729c.072-.143.109-.301.109-.46 0-.553-.446-1.011-.998-1.027"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.87 -157.672)"
    />
    <path
      d="M262.28 101.777c.246-.173.455-.442.673-.448.814-.025 1.118-.514 1.31-1.39-.571-.25-1.085-.512-1.629-.691a.913.913 0 0 0-.652.165 1.224 1.224 0 0 0-.318 1.6c.191.267.397.524.616.769"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.304 -45.377)"
    />
    <path
      d="M153.98 231.912c-.729-.035-1.21.389-1.371 1.251-.108.569.13.809 1.234 1.219l1.213-.987c.132-.372.04-.789-.237-1.071a1.234 1.234 0 0 0-.839-.412"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-109.064 -104.145)"
    />
    <path
      d="M208.342 180.687a1.184 1.184 0 0 0-.027-2.055 1.962 1.962 0 0 0-2.1.154c.227.928 1.019 1.41 1.448 2.2a3.27 3.27 0 0 0 .676-.3"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-132.819 -80.444)"
    />
    <path
      d="M347.017 222.535a2.527 2.527 0 0 0 1.112-1.27 1.18 1.18 0 0 0-1.5-.975c-.056.828-.861.866-1.415 1.361.041.146.091.288.152.427.441.826.9.95 1.651.457"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.386 -98.986)"
    />
    <path
      d="M141.089 222.269c.52.365 1.247.242 1.618-.273v-1.521l-.353-.233a10.16 10.16 0 0 0-1.844 1.164c.001.377.23.719.579.863"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-103.716 -98.977)"
    />
    <path
      d="M391.191 166.191a9.069 9.069 0 0 0-.975-.7.834.834 0 0 0-1.249.656 2.125 2.125 0 0 0 .744 2.161c1.035-.427 1.076-1.374 1.481-2.116"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-213.753 -74.677)"
    />
    <path
      d="m329.354 165.443-1.346 1.089c.017.5-.183 1.051.489 1.337a6.414 6.414 0 0 1 1.737-.311c.437.059.721-.561.442-.922a3.26 3.26 0 0 0-1.323-1.193"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.758 -74.704)"
    />
    <path
      d="m269.746 252.6-.785-.525c.768-.978.768-.978.152-1.973a1.967 1.967 0 0 0-1.9.428v1.07c.549.385 1.069.773 1.615 1.121a.646.646 0 0 0 .913-.115"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.839 -112.177)"
    />
    <path
      d="M225.539 280.493c-.4.2-.691.452-.494.967.13.338.245.681.379 1.058 1.371.135 1.371.135 2.346-.5-.208-1.282-1.221-1.4-2.232-1.526"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-141.131 -125.664)"
    />
    <path
      d="M239.993 305.113c-.392-.309-.73-.686-1.161-1.1-1.166.595-1.226.755-.9 2.389.85.335 1.557-.174 2.3-.334.063-.418.077-.706-.239-.955"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.792 -136.082)"
    />
    <path
      d="M297.769 161.766c.278.298.579.575.9.827.729-.442.822-1.149 1.107-1.659a1.021 1.021 0 0 0-.077-.228c-.266-.313-.538-.625-.887-1.028l-1.029.178a1.265 1.265 0 0 0-.01 1.91"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.178 -72.151)"
    />
    <path
      d="M256.422 180.048c.268-.052.532-.123.79-.213.784-.261.941-.525.832-1.307a1.156 1.156 0 0 0-.761-.894l-1.6.368.233 1.97c.163.048.331.073.5.076"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.73 -80.105)"
    />
    <path
      d="M165.187 282.448c.277.681.686.722 1.211.141.082-.089.246-.1.331-.194.185-.19.345-.4.468-.552-.138-1.147-.964-1.326-1.943-1.527-.4.53-.784.99-.2 1.611.108.114.067.355.135.521"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-114.454 -125.587)"
    />
    <path
      d="M335.352 101.308a.936.936 0 0 0 .253-1.5 1.242 1.242 0 0 0-1.83-.124 6.735 6.735 0 0 0-.655.92c1.043 1.1 1.372 1.188 2.231.709"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-189.03 -45.422)"
    />
    <path
      d="M189.55 149.671c.55.289 1.24.1 1.564-.431.098-.181.183-.368.255-.561-.554-1.074-.554-1.074-1.757-1.385l-.764.821c.13.45.209.811.344 1.151.072.169.199.31.359.4"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-125.126 -66.666)"
    />
    <path
      d="M311.5 162.456c.499-.365.86-.888 1.025-1.484l-.408-1.1a5.727 5.727 0 0 0-.818.007 1.08 1.08 0 0 0-.9.783 1.97 1.97 0 0 0 .172 1.619.579.579 0 0 0 .934.172"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.922 -72.233)"
    />
    <path
      d="m298.748 150.424 1.177-.88-.248-1.43-1.069-.416c-.296.249-.579.514-.848.792-.143.161-.277.376-.058.611.09.1.223.256.2.343-.228.754.315.834.85.98"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.29 -66.844)"
    />
    <path
      d="M269.065 287.023c-.3-.141-.5-.492-.8-.8-.252.216-.594.367-.619.559a4.655 4.655 0 0 0 .03 1.631c.051.229.468.409.749.545.5.238.845-.207 1.254-.243.211-.777.14-1.331-.613-1.689"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-160.002 -128.202)"
    />
    <path
      d="M214.452 377.767a5.26 5.26 0 0 0-1.158.151c-.373.7.323 1.2.11 1.818.372.133.544.213.723.256a1.42 1.42 0 0 0 1.379-.423 1.167 1.167 0 0 0-.213-1.412 1.473 1.473 0 0 0-.841-.39"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.905 -168.749)"
    />
    <path
      d="M297.259 294.723c.58.046.349.548.58.828.4-.159.8-.349 1.227-.479.743-.23.747-.215.676-1.2a3.28 3.28 0 0 0-2.251-.468.732.732 0 0 0-.7.58.57.57 0 0 0 .464.736"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-172.932 -131.371)"
    />
    <path
      d="M195.054 299.675c-.209.788-.066 1.077.69 1.407a.983.983 0 0 0 1.429-.936 5.501 5.501 0 0 0-.219-1.031l-1.554-.674c-.151.535-.255.883-.346 1.234"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-127.836 -133.614)"
    />
    <path
      d="M334.6 258.754c.479.262.785.249 1.1-.149.251-.324.452-.685.595-1.069.1-.272-.057-.67-.327-.663-.524.014-.784-.3-1.119-.581a.559.559 0 0 0-.506-.042.6.6 0 0 0-.208.449c.017.169.064.333.137.486-.253.326-.475.608-.751.961.45.251.765.433 1.084.608"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-189.205 -114.907)"
    />
    <path
      d="M146.349 228.676a7 7 0 0 0 2.049.016c.095-.114.181-.235.256-.363.3-.653.236-.989-.351-1.232a6.74 6.74 0 0 0-1.582-.4l-.03-.001a.625.625 0 0 0-.613.725c.06.4.17.793.272 1.252"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-106.179 -101.834)"
    />
    <path
      d="m378.3 133.487.028-.014-.028.014"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-209.04 -60.544)"
    />
    <path
      d="M377.141 131.587v.016l.578-.288.575-.725-.934-1.339c-1.262.4-1.262.4-1.739 2.286.828.323.828.323 1.519.05"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-207.855 -58.675)"
    />
    <path
      d="m232.938 132.188.873-.511a6.824 6.824 0 0 1 .061-1 1.157 1.157 0 0 0-.793-1.458c-.169.898-.45 1.771-.836 2.6.181.2.428.328.695.363"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-144.347 -58.662)"
    />
    <path
      d="M353.809 107.812a3.143 3.143 0 0 0 .228-2.29c-1.319-.463-1.646.737-2.368 1.326.57.806 1.145 1.369 2.14.963"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-197.245 -48.117)"
    />
    <path
      d="M329.441 274.366c-.489-.219-.854.206-1.258.391-.109.064-.21.141-.3.229l.107 1.34c.61.405 1.393.451 2.047.121.313-.146.534-.54.367-.787-.3-.447-.43-1.053-.965-1.293"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-186.71 -122.923)"
    />
    <path
      d="M370.787 125.482a.933.933 0 0 0 1.123.241c.44-.984.627-1.916-.557-2.736-.459.3-.894.559-1.3.859a.586.586 0 0 0-.044.867c.25.264.536.495.773.769"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-205.295 -55.9)"
    />
    <path
      d="M243.48 225.774a2.335 2.335 0 0 0-.973 1.06c.189.275.392.564.544.784a4.56 4.56 0 0 1 1.2-.082 1.47 1.47 0 0 0 1.179-.01c-.08-.314-.162-.58-.212-.852-.05-.272-.062-.538-.091-.819-.589-.161-1.087-.468-1.643-.08"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-148.894 -101.344)"
    />
    <path
      d="M250.511 377.4c-.217.064-.427.145-.68.231l-.131 1.522c.489.18.971.08 1.33.437a.56.56 0 0 0 .761-.076 1.387 1.387 0 0 0 .227-1.524 1.231 1.231 0 0 0-1.507-.589"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-152.08 -168.558)"
    />
    <path
      d="M201.766 270.933a7.195 7.195 0 0 0 1.348-.17.637.637 0 0 0 .253-.564 2.17 2.17 0 0 0-.957-1.278 1.389 1.389 0 0 0-1.411-.058c.138.503.06 1.042-.216 1.485.181.373.569.604.983.586"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.413 -120.44)"
    />
    <path
      d="M318.423 142.51c-.273-.43-.529-.747-1-.8a1.677 1.677 0 0 0-1.4.471c-.185 1.668-.068 1.8 1.549 1.749.154-.016.308-.04.459-.074a2.746 2.746 0 0 1 .044-.659c.094-.24.21-.471.346-.689"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-181.428 -64.189)"
    />
    <path
      d="m184.575 202.647-1.48-.13c-.073.2-.208.375-.185.526.076.455.182.904.316 1.345a.645.645 0 0 0 .788.417 4.246 4.246 0 0 0 1.608-.888l-1.047-1.27Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-122.495 -91.126)"
    />
    <path
      d="M311.669 101.267c-.144-.251-.26-.449-.4-.694.212-.159.423-.319.634-.476-.118-1-.988-.94-1.648-1.157-.187-.062-.42.232-.377.476.056.235.126.466.211.692a4.329 4.329 0 0 0-.448.313c-.15.149-.293.305-.427.468a3.453 3.453 0 0 0 2.021.657c.131.013.284-.178.434-.28"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.44 -45.245)"
    />
    <path
      d="M346.717 172.422c-.292.052-.56.313-.507.571.123.593.114 1.317.977 1.517.634-.33 1.444-.487 1.772-1.387l-.811-.87c-.524.058-.982.086-1.431.169"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.824 -77.721)"
    />
    <path
      d="M281.272 131.817c.112-.021.222-.055.327-.1.148-.211.281-.431.4-.66a.803.803 0 0 0-.221-1.111.971.971 0 0 0-1.019-.28c-.349.11-.685.263-1 .389-.219 1.7-.068 1.872 1.515 1.766"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.358 -58.834)"
    />
    <path
      d="M388.924 214.367c-.734.1-.85.6-.958 1.205.194.09.383.183.57.273l1.426-1.785c-.072-.047-.148-.091-.226-.141a2.672 2.672 0 0 1-.813.448"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-213.322 -96.176)"
    />
    <path
      d="M347.912 198.4a.77.77 0 0 0 .512-.925c-.055-.438-.135-.875-.213-1.36a9.122 9.122 0 0 0-.829-.411.56.56 0 0 0-.72.256 3.592 3.592 0 0 0-.385 1.42c.009.46.577.535.925.734.229.112.466.208.709.286"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.857 -88.09)"
    />
    <path
      d="M177.685 270.615a3.31 3.31 0 0 0 .808-.512 1.03 1.03 0 0 0-.045-1.57.965.965 0 0 0-1.53.168c-.176.314-.37.617-.58.909.084.228.185.449.3.663a.726.726 0 0 0 1.048.343"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-119.585 -120.234)"
    />
    <path
      d="M304.7 264.657c.319.018.64 0 .974 0 .123-.562.223-.955.294-1.355a1.047 1.047 0 0 0-.494-1.074.803.803 0 0 0-1.008.224 3.767 3.767 0 0 0-.567.61.998.998 0 0 0 .8 1.593"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-176.003 -117.53)"
    />
    <path
      d="M219.44 179.962c.41.05.824.055 1.235.014a.652.652 0 0 0 .4-.339 1.764 1.764 0 0 0-.4-1.88 1.569 1.569 0 0 0-.409-.2l-.695.714c-.324.052-.594-.271-.856.085a1.128 1.128 0 0 0-.01.362c.078.31.187.611.324.9.08.163.226.286.4.338"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-138.348 -80.072)"
    />
    <path
      d="M184.784 154.572a.535.535 0 0 0-.155-.743 3.409 3.409 0 0 0-.716-.409.637.637 0 0 0-.847.237c-.257.435-.47.9-.71 1.362l.534.98c1.1-.047 1.431-.831 1.9-1.428"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-122.25 -69.349)"
    />
    <path
      d="M365.975 228.2c.312-.591.886-1.089.855-1.843v-.001a.474.474 0 0 0-.567-.462c-.409.075-.813.18-1.216.285a2.107 2.107 0 0 0-.348.16c-.113.339-.217.647-.318.953l.33.982c.5.142 1.078.275 1.263-.074"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-202.876 -101.477)"
    />
    <path
      d="M185.194 264.078c.281-.6.241-.833-.262-1.181-.263-.183-.55-.331-.795-.477-1.126.241-1.074 1.124-1.241 1.9l1.478.653c.631-.488.627-.489.819-.9"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-122.49 -117.66)"
    />
    <path
      d="M182.485 275.042c.191.657.255 1.408.993 1.592a1.52 1.52 0 0 0 1.892-.548 4.01 4.01 0 0 0-.241-.873c-.087-.168-.408-.393-.51-.347-.488.222-.9-.081-1.339-.1-.312-.017-.67-.081-.794.281"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-122.308 -123.118)"
    />
    <path
      d="M131.076 246.232v-1.612a3.574 3.574 0 0 0-1.61-.039c.065.729.185 1.453.359 2.164.475.04.941-.151 1.251-.513"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-98.824 -109.724)"
    />
    <path
      d="M280.623 107.676a.72.72 0 0 0 .794-.88 1.136 1.136 0 0 0-1-1.126 13.175 13.175 0 0 0-1.085-.155c-.08-.009-.169.057-.305.108l-.367 1.019a2.34 2.34 0 0 0 1.963 1.035"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-164.907 -48.16)"
    />
    <path
      d="M237.763 198.473c.119.069.246.125.377.167a.721.721 0 0 0 1.02-.731l.055-1.1a3.958 3.958 0 0 0-.591-.521.579.579 0 0 0-.511-.036c-.563.436-1.242.8-1.324 1.683.338.186.655.365.974.537"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.361 -88.331)"
    />
    <path
      d="M255.388 131.21a3.24 3.24 0 0 0 2.009-.065c.291-1.071-.319-1.615-1.12-2.038-1.49.617-1.49.617-.983 2.013.013.037.062.061.094.09"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.422 -58.61)"
    />
    <path
      d="M225.038 222.03a.92.92 0 0 0 1.248.158c.316-.253.594-.558.887-.838-.137-.391-.258-.735-.389-1.1-.345-.12-.691-.241-1.04-.358a1.067 1.067 0 0 0-.246.013c-.233.319-.451.6-.65.9a.858.858 0 0 0 .189 1.229"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-140.992 -98.82)"
    />
    <path
      d="M335.889 160.273c-.97-.541-1.423-.365-1.769.672a.731.731 0 0 0 .2.763c.393.362.838.663 1.321.893.9-.557.651-1.316.6-2.014a2.38 2.38 0 0 0-.351-.314"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-189.455 -72.282)"
    />
    <path
      d="M170.832 286.948v1.49c.328.14.613.289.916.386a.702.702 0 0 0 .945-.357 3.08 3.08 0 0 0 .295-.632.958.958 0 0 0-.512-1.164 1.149 1.149 0 0 0-1.644.276"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-117.146 -128.3)"
    />
    <path
      d="M267.511 324.631c.15.107.306.203.468.29 1.059.7 1.059.7 1.8-.391.1-.151.206-.3.394-.574a2.665 2.665 0 0 0-2.377-.685.77.77 0 0 0-.731.555c-.073.37.209.592.45.8"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.764 -144.596)"
    />
    <path
      d="M317.245 262.8a.69.69 0 0 0-.769.083c-.376.312-.738.641-1.17 1.019l1.444 1.286c.295-.129.5-.216.707-.316a.566.566 0 0 0 .213-.172c.728-1.026.632-1.451-.425-1.9"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-181.139 -117.792)"
    />
    <path
      d="M274.554 208.337c-.372.33-.697.708-.967 1.126a.769.769 0 0 0 .214.969l1.8-.151c.149-.432.29-.818.413-1.211a1.393 1.393 0 0 0-.038-.391c-.29-.16-.591-.3-.9-.42a.623.623 0 0 0-.526.077"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.62 -93.658)"
    />
    <path
      d="M268.192 153.941a1.194 1.194 0 0 0-.543.608c-.069.358-.064.727.016 1.083.023.138.243.243.372.362.437-.152.822-.3 1.217-.415.209-.061.5.014.638-.107.278-.242.009-.48-.11-.726a4.728 4.728 0 0 1-.233-.8 1.502 1.502 0 0 0-1.357 0"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-160.008 -69.54)"
    />
    <path
      d="M226.192 173.412c.2.049.37.1.543.134.175.048.355.079.536.091a.629.629 0 0 0 .563-.662 2.137 2.137 0 0 0-.736-1.291 1.302 1.302 0 0 0-1.419-.079c-.052.792-.108 1.519-.144 2.248 0 .058.109.122.176.194.154-.2.306-.406.481-.635"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-141.376 -77.36)"
    />
    <path
      d="M189.311 220.08c-.236.321-.455.655-.656 1 .428.964 1.252 1.044 2.141 1.139.043-.272.099-.542.169-.808.082-.257.211-.5.318-.75-.438-.263-.823-.5-1.216-.723a.569.569 0 0 0-.756.14"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-125.041 -98.804)"
    />
    <path
      d="M279.892 215.94a1.44 1.44 0 0 0 1.6.53c.491-.155.531-.567.389-1.017a.461.461 0 0 1 .054-.27c.113-.274-.063-.43-.23-.594a.967.967 0 0 0-1.024-.26c-.347.112-.683.265-1.154.451.097.393.218.781.362 1.159"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.292 -96.334)"
    />
    <path
      d="M297.97 126.211c.433.279.755-.187 1.108-.379a.43.43 0 0 1 .13-.051c.569-.1.723-.5.745-1.034a1.892 1.892 0 0 0-1.992-.593l-.779 1.093c.211.36.477.685.788.964"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.111 -56.378)"
    />
    <path
      d="M288.192 184.588a2.91 2.91 0 0 0-.7-.549l-1.37.412c.162 1.238.263 1.49.829 2.07.193-.065.409-.135.624-.21a1.08 1.08 0 0 0 .613-1.723"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-168.213 -82.941)"
    />
    <path
      d="M257.178 276.873c.179-.147.333-.322.456-.519a.792.792 0 0 0 .012-.643 3.932 3.932 0 0 0-.87-.867.758.758 0 0 0-.912.045 1.912 1.912 0 0 0-.856 1.019c.434 1.005 1.4.7 2.17.965"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.431 -123.103)"
    />
    <path
      d="M358.608 244.133c-.114.065-.26.189-.271.3-.057.545-.074 1.1-.105 1.636l1.124.386.987-.8c-.054-.3-.095-.568-.153-.836a1.011 1.011 0 0 0-1.582-.684"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-200.152 -109.481)"
    />
    <path
      d="m315.46 106.43.582 1.071c1.156.01 1.691-.534 1.72-1.592a2.5 2.5 0 0 1 .174-.533l-.793-.385c-.722.314-1.474.5-1.683 1.438"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-181.207 -47.928)"
    />
    <path
      d="m262.672 232.306-1.414 1.37c.073.406.222.809.712.892a1.483 1.483 0 0 0 1.609-.519c.031-1.416.031-1.416-.907-1.743"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.199 -104.32)"
    />
    <path
      d="M274.853 269.222c-1.232.583-1.232.583-1.162 1.836.304.154.622.277.95.369a.991.991 0 0 0 1.089-.434c.185-.379.509-.79.291-1.355l-1.168-.416Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-162.695 -120.672)"
    />
    <path
      d="M171.131 190.927a1.321 1.321 0 0 0-.285 1.522c.214.391.6.364.977.374.379.017.739-.171.943-.491.183-.297.35-.603.5-.918-.365-.277-.607-.492-.88-.658a.945.945 0 0 0-1.256.172"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-117.095 -85.843)"
    />
    <path
      d="M359.959 233.154c-.417-.269-.358-.673-.535-1.006a3.803 3.803 0 0 0-1.584 1.639c.274.647.881.767 1.531.944.216-.163.367-.268.509-.384.534-.438.611-.85.079-1.193"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-199.978 -104.25)"
    />
    <path
      d="M298.794 235.446c.455-.491 1.228-.79.925-1.742-.276-.182-.543-.352-.8-.529-.449-.308-.7-.335-1.111-.017a3.431 3.431 0 0 0-.5.583c.383 1.041.383 1.041 1.491 1.7"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.164 -104.597)"
    />
    <path
      d="M171.377 240.412c.367.05.731.118 1.091.206a.624.624 0 0 0 .789-.707c.055-.536-.35-.826-.7-1.126a1.094 1.094 0 0 0-1.807.7.764.764 0 0 0 .622.932"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-117.105 -107.067)"
    />
    <path
      d="m309.939 173.011.683.353-.146.762c.787.385 1.037-.437 1.546-.533.183-1.156-.639-1.556-1.3-2.032-.115-.084-.461.028-.621.152a1.17 1.17 0 0 0-.167 1.3"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.709 -77.401)"
    />
    <path
      d="m280.623 274.392-1.047.895-.193 1.409 1.181.15c.369-.3.674-.5.937-.758a.9.9 0 0 0-.084-1.342 3.92 3.92 0 0 0-.794-.354"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.227 -122.962)"
    />
    <path
      d="M233.075 180.026v-1.286c-.091-.074-.24-.189-.384-.308-.071-.059-.13-.135-.206-.189-.38-.288-.694-.309-.989.027-.277.351-.533.718-.765 1.1.793 1.01.941 1.048 2.344.659"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.678 -80.276)"
    />
    <path
      d="M300.189 257.546c.056-.06-.042-.4-.152-.463-.391-.226-.817-.389-1.217-.571l-1.047.592c.095.568.32 1.106.656 1.573l2.039.127c-.517-.537-.636-.885-.279-1.258"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.373 -115.042)"
    />
    <path
      d="M280.585 143.834c.135.039.183.068.224.062.267-.042.642 0 .773-.16.241-.33.417-.704.518-1.1a.538.538 0 0 0-.258-.451c-.67-.282-1.334-.71-2.111-.325-.427 1.036.667 1.326.855 1.978"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.338 -64.19)"
    />
    <path
      d="M280.71 238.966a3.294 3.294 0 0 0-.559.075.585.585 0 0 0-.475.6c.08.445.228.875.439 1.275.15.164.365.252.586.242.224-.037.44-.114.636-.228.573-.237.612-.733.486-1.416-.069-.371-.4-.533-1.114-.547"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-165.354 -107.27)"
    />
    <path
      d="M189.762 316.547c-.258.206-.491.44-.695.7a.701.701 0 0 0-.015.631 3.201 3.201 0 0 0 1.792 1.195c.212-.548.4-1.031.571-1.468a2.484 2.484 0 0 0-1.653-1.056"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-125.186 -141.633)"
    />
    <path
      d="M268.356 107.534c.41-.062.802-.216 1.144-.45.388-.279.262-.755.2-1.266-.378-.206-.7-.394-1.028-.552a.56.56 0 0 0-.734.227c-.192.37-.327.767-.4 1.177a.711.711 0 0 0 .819.863"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.973 -48.025)"
    />
    <path
      d="M292.825 107.882a.556.556 0 0 0 .893.285c.447-.313.82-.721 1.092-1.194a5.375 5.375 0 0 0-.487-.578c-.617.015-1.235-.02-1.846-.107.043.334.081.638.137.94.044.225.114.444.21.653"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-171.027 -48.504)"
    />
    <path
      d="M305.564 275.1a5.486 5.486 0 0 0-.785.223c-.157.067-.4.25-.383.347.084.543-.5.864-.48 1.395.941.74 1.645.23 2.416-.392-.055-.829-.055-.829-.769-1.573"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-176.094 -123.273)"
    />
    <path
      d="M232.231 300.866c.382-.362.736-.662 1.044-1a.661.661 0 0 0-.012-.893 1.073 1.073 0 0 0-1.211-.474c-.45.153-.775.549-.838 1.02-.1.745.256 1.14 1.017 1.35"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-143.884 -133.612)"
    />
    <path
      d="M239.173 137.318a1.305 1.305 0 0 0-.343-.877 2.47 2.47 0 0 0-1.631-.711l-.6 1.19a6.341 6.341 0 0 0 1.852 1.259c.37-.237.735-.452.722-.861"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.277 -61.544)"
    />
    <path
      d="M257.5 300.719v-1.532c-1.365-.931-1.715-.773-2.281 1 .583.883 1.428.513 2.281.529"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.525 -133.696)"
    />
    <path
      d="m359.755 220.006-1.5 1.027c.17.33.282.586.428.82.315.48.911.7 1.462.54a.702.702 0 0 0 .606-.652 1.633 1.633 0 0 0-1-1.735"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-200.162 -98.872)"
    />
    <path
      d="M201.281 366.063c-.672 1.333-.672 1.334.666 1.8.073.01.147.003.216-.021l1.508-.994c-.262-.872-.548-.966-2.39-.788"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.437 -163.532)"
    />
    <path
      d="m201.467 160.016-.168 1.247c.438.346.825.665 1.225.964a.493.493 0 0 0 .768-.271c.094-.523.134-1.056.2-1.642-.7-.107-1.224-.6-2.028-.3"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.641 -72.258)"
    />
    <path
      d="M184.484 311.123h-1.63l-.158 1.714 2.2.155a1.658 1.658 0 0 0-.408-1.869"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-122.401 -139.231)"
    />
    <path
      d="M287.757 207.917a.973.973 0 0 0-.768-.041 5.114 5.114 0 0 0-.909.612c-.076.224-.149.439-.219.656-.23.709-.191.809.487 1.184.157.078.318.146.482.206.407-.214.868-.437.722-.906a.885.885 0 0 1 .2-.988c.2-.222.285-.55.007-.722"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-168.04 -93.474)"
    />
    <path
      d="m304.042 107.633 1.314-.264c.612-1.733.612-1.733-.73-2.384-.7.412-1.022.875-.7 1.647.082.327.12.663.113 1"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-176.043 -47.925)"
    />
    <path
      d="M304.878 168.121c.13-.009.259-.031.385-.066.579-.6 1.038-1.211.618-2.039a1.623 1.623 0 0 0-1.642.259 1.14 1.14 0 0 0-.426.661c-.153.832.164 1.162 1.065 1.185"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-176.032 -74.904)"
    />
    <path
      d="m213.485 172.048-1 .868c.334.349.694.674 1.076.97.331.221.715.485 1.159.221.408-.377.554-.964.369-1.488a1.882 1.882 0 0 0-.251-.408l-1.352-.162"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-135.595 -77.63)"
    />
    <path
      d="M291.73 238.332c-.078.2-.165.409-.231.622-.078.253-.137.511-.215.8l1.41.882a2.174 2.174 0 0 0 1.065-1.79 5.2 5.2 0 0 0-2.029-.514"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-170.499 -106.986)"
    />
    <path
      d="M227.133 257.6a1.689 1.689 0 0 0-1.822-.516l-.614.807c.225.5.408.784.89.93 1.325.406 1.625.207 1.547-1.22"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-141.004 -115.27)"
    />
    <path
      d="M262.345 138.066a.844.844 0 0 0 1.01-.263c.181-.26.4-.523.231-.891-.181-.4-.4-.731-.9-.749a1.94 1.94 0 0 0-1.71.806c.267.555.772.96 1.372 1.1"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.073 -61.735)"
    />
    <path
      d="m310.226 137.84 2.023-.565a1.604 1.604 0 0 0-.749-1.521c-.505.35-1.212.06-1.78.433l.506 1.653Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.664 -61.555)"
    />
    <path
      d="M214.1 210.047c.248.067.492.15.729.248l.819-1.245a1.83 1.83 0 0 0-.99-1.232 4.337 4.337 0 0 0-.822.4 1.346 1.346 0 0 0-.417 1.253c.023.38.333.511.681.577"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-136.006 -93.474)"
    />
    <path
      d="M306.115 226.547a2.49 2.49 0 0 0-2.092 1.056.8.8 0 0 0-.1.636.969.969 0 0 0 1.338.586c.236-.112.464-.241.682-.386a4.549 4.549 0 0 1-.09-.871 7.2 7.2 0 0 1 .258-1.021"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-176.085 -101.77)"
    />
    <path
      d="M227.611 149.571a.64.64 0 0 0-.224-.857 6.2 6.2 0 0 0-1.315-.427c-.506-.089-.895.325-.874.971.035.14.088.274.158.4a1.374 1.374 0 0 0 2.255-.084"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-141.227 -67.1)"
    />
    <path
      d="M372.268 112.2c-.272-.588-.214-1.449-1.2-1.645-.475.511-1.145.831-1.311 1.8.652.14 1.183.285 1.725.362a.682.682 0 0 0 .786-.522"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-205.257 -50.393)"
    />
    <path
      d="M225.664 317.264c-.076.048-.175.126-.184.2-.037.264-.025.534-.057.8a4.111 4.111 0 0 1-.14.534c.364.094.672.17.98.253.874.234.933.2 1.43-.8l-1.294-1.414c-.269.155-.506.287-.736.432"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-141.265 -141.76)"
    />
    <path
      d="M297.435 246.726c.295.201.601.385.916.552.41-.252.772-.451 1.108-.688a1.32 1.32 0 0 0 .47-.5.935.935 0 0 0 0-.651.583.583 0 0 0-.681-.352 5.41 5.41 0 0 0-1.9 1.024c-.185.118-.116.462.085.613"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-173.138 -109.977)"
    />
    <path
      d="M226.392 389.81c-.953 1.308-.94 1.424.2 2.04.097.034.198.056.3.065a2.087 2.087 0 0 0 1.288-1.033c-.373-.886-1.117-.928-1.793-1.072"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-141.451 -174.084)"
    />
    <path
      d="M348.246 100.413a2.536 2.536 0 0 0-.3-.286.955.955 0 0 0-1.265-.017.878.878 0 0 0-.292 1.308c.139.295.263.6.745.637.357-.487 1.305-.324 1.455-1.239-.108-.127-.219-.27-.341-.4"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.831 -45.663)"
    />
    <path
      d="M341.268 105.426a1.147 1.147 0 0 0-.919.663c.155.307.294.621.416.943a.77.77 0 0 0 .675.6.84.84 0 0 0 .951-.412c.123-.24.219-.495.331-.748-.2-.223-.342-.4-.5-.568a1.15 1.15 0 0 0-.958-.482"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-192.231 -48.12)"
    />
    <path
      d="M178.311 185.679c.288-.492.34-1.089.141-1.624a1.245 1.245 0 0 0-1.727-.094.673.673 0 0 0-.007 1.229 3 3 0 0 0 1.593.489"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-119.577 -82.771)"
    />
    <path
      d="M257.849 166.809a.649.649 0 0 0-.281-.3 1.207 1.207 0 0 0-1.319.079 9.067 9.067 0 0 0-.836.884c.676.711.9.842 1.434.852a1.23 1.23 0 0 0 1-1.516"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-154.61 -75.108)"
    />
    <path
      d="M239.2 113.417c.059.025.162-.05.316-.1-.006-.317-.243-.646-.034-1.019.251-.45-.062-.783-.391-1.076a.78.78 0 0 0-1.023-.018l-.03.018c-.228.413-.434.828-.621 1.242l-.012.1c.622.3 1.2.583 1.794.852"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.632 -50.598)"
    />
    <path
      d="M354.088 240.774c-.022-.61.318-1.138-.021-1.781-.844.013-1.817-.348-2.287.849 1.481 1.013 1.481 1.013 2.308.932"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-197.294 -107.264)"
    />
    <path
      d="M245.171 384.4c-.279-.167-.554-.338-.851-.519a1.233 1.233 0 0 0-.968 1.039.775.775 0 0 0 .729 1.041l.059-.002c.28.018.561.009.839-.027a.67.67 0 0 0 .569-.687.843.843 0 0 0-.377-.846"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-149.25 -171.457)"
    />
    <path
      d="M237.519 125.941c.41.09.827.14 1.247.149.27-.243.516-.511.735-.8.095-.239.152-.491.17-.748l-1.438-.328a9.685 9.685 0 0 0-.906.609.652.652 0 0 0 .192 1.117"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-146.494 -56.444)"
    />
    <path
      d="M202.8 378.31a3.382 3.382 0 0 1-1.344-.146.421.421 0 0 0-.355.152.947.947 0 0 0 .5 1.438c.447.087.9.134 1.519.222.14-.241.253-.496.338-.761.084-.393-.288-.936-.655-.9"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-130.48 -168.923)"
    />
    <path
      d="M310.122 257.461c-.121.092-.247.348-.2.461.174.535.607.948 1.151 1.094.388.091.7-.158 1.037-.489l-.06-1.273a1.959 1.959 0 0 0-1.928.208"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-178.749 -115.302)"
    />
    <path
      d="m261.6 112.237.323 1.439c.557 0 1.071.21 1.39-.252a1.36 1.36 0 0 0-.014-1.448 3.473 3.473 0 0 0-.283-.417 1.16 1.16 0 0 0-1.417.677"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-157.35 -50.813)"
    />
    <path
      d="M267.26 216.029c.211-.021.42-.054.626-.1.049.11.091.223.126.339.08.375.284.531.492.358.176-.146.326-.325.5-.475.59-.514.592-.518.09-1.381H267.5a9.35 9.35 0 0 0-.558.769.424.424 0 0 0-.025.369c.092.087.217.13.343.119"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-159.692 -96.554)"
    />
    <path
      d="M287.688 282.926c.42-.386.569-.99.377-1.527a1.524 1.524 0 0 0-1.785.026l.075 1.389c.226.138.472.243.728.312a.754.754 0 0 0 .606-.2"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-168.282 -125.942)"
    />
    <path
      d="M366.585 118.384a1.731 1.731 0 0 0-1.324-.845.473.473 0 0 0-.44.572c.083.263.211.51.377.73.164.144.351.261.553.344.589.016.987-.452.834-.8"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-203.061 -53.486)"
    />
    <path
      d="m222.086 144.4.078-.223c-.159.079-.32.153-.485.223.135.025.272.025.407 0"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-139.668 -65.287)"
    />
    <path
      d="M379.395 132.949h-.008.008Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-209.522 -60.313)"
    />
    <path
      d="M378.349 133.244v.545c.437.168.925.141 1.34-.075-.282-.7-.282-.7-.791-.722l.029-.036-.578.288M378.349 133.244l.578-.288-.578.288Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-209.063 -60.316)"
    />
    <path
      d="m379.336 132.989.036-.04h-.007l-.028.036"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-209.5 -60.313)"
    />
    <path
      d="m378.326 133.445-.028.03.028-.014v-.016Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-209.04 -60.532)"
    />
    <path
      d="M346.057 213.423a2.861 2.861 0 0 0-.071-.626.373.373 0 0 0-.333-.136.35.35 0 0 0-.19.286c.028.285.121.556.594.476"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-194.496 -95.617)"
    />
    <path
      d="M365.358 122.054h.961c-.576-.5-.576-.5-.961 0"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-203.308 -55.32)"
    />
    <path
      d="M221.009 146.217c-.047-.07-.08-.167-.145-.2-.065-.033-.148.023-.224.039.047.093.104.181.172.26.016.016.128-.064.2-.1"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-139.208 -66.097)"
    />
    <path
      d="M370 110.293c.005-.017-.061-.055-.092-.084-.036.025-.1.061-.1.075.026.069.056.136.091.2.04-.061.073-.126.1-.194"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-205.278 -50.24)"
    />
    <path
      d="m287.962 224.345-.024.033.024-.033Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-169.016 -100.794)"
    />
    <path
      d="m288.19 224.263-.125.05a.228.228 0 0 0 .073.012c.018 0 .035-.039.052-.062"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-169.073 -100.758)"
    />
    <path
      d="m288.009 224.348-.029-.006-.042.035.071-.028"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-169.016 -100.793)"
    />
    <path
      d="m287.962 224.341-.024.033.042-.035h-.015l-.003.002Z"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-169.016 -100.79)"
    />
    <path
      d="M626.244 275.906c11.472 6.235 33.667 15.961 38.655 27.931 4.988 11.97 3.242 28.43-.748 36.161l-67.334-19.951-45.887-19.7 11.721-34.665s24.938-.748 38.9 2.494a171.502 171.502 0 0 1 24.689 7.731"
      style={{
        fill: "#eb8b8b",
        fillRule: "nonzero",
      }}
      transform="translate(-285.505 -119.07)"
    />
    <path
      d="M584.505 205.373c.665 7.481 1.164 13.3 5.487 18.454s13.134 8.812 13.134 8.812a35.21 35.21 0 0 1 .832 9.975c-.5 4.322-.665 9.975-.665 9.975s8.978 9.144 24.938 5.985c15.96-3.159 19.452-12.469 19.452-12.469s-3.325-10.141-3.658-14.8c-.333-4.659-.333-9.144-.333-9.144a59.207 59.207 0 0 0 5.32-11.472c1.359-4.003 3.99 1 9.311-5.653s3.491-15.628-1.164-17.623a9.538 9.538 0 0 0-7.98-.166l-17.956-13.134s-21.78.831-34.415 9.975c-12.635 9.144-12.3 21.281-12.3 21.281"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-300.376 -78.546)"
    />
    <path
      d="M601.678 220.494h-.001c-.576 0-1.05-.474-1.05-1.05 0-.328.154-.638.415-.836 3.486-2.644 11.342-4.859 13.725-5.085a1.055 1.055 0 0 1 1.172 1.043c0 .546-.427 1.006-.972 1.047-2.217.211-9.638 2.38-12.654 4.668a1.05 1.05 0 0 1-.634.213"
      style={{
        fill: "#3b598b",
        fillRule: "nonzero",
      }}
      transform="translate(-307.517 -95.998)"
    />
    <path
      d="M622.543 160.112c-.332 2.161.831 6.983 4.323 8.978 3.492 1.995 7.481 3.99 7.481 8.479 0 0 .5-8.812.665-16.293 0 0 4.821-7.149 2.66-13.466-2.161-6.317-6.484-5.653-10.142-7.482-3.658-1.829-.664-6.151-3.325-9.144-2.661-2.993-6.484-2-8.978-.831a8.43 8.43 0 0 1-7.98-1c-1.829-1.5-5.653-3.99-9.643-3.658-3.99.332-6.484 3.99-10.142 4.655-3.658.665-6.152-.167-10.973-3.325-4.821-3.158-10.307-2.661-13.8.166-3.493 2.827-5.487 9.31-10.474 9.643-4.987.333 1 39.07 2.494 41.232 1.494 2.162 14.963 19.119 19.618 22.278A141.573 141.573 0 0 1 572 185.882c-.5-5.986 5.154.5 10.142-5.321 4.988-5.821.665-6.65 5.487-10.474s7.482.333 11.638.665 6.65-3.492 7.98-7.315c1.33-3.823 4.322-3.99 7.149-2.993 2.827.997 8.146-.333 8.146-.333"
      style={{
        fill: "#4b6694",
        fillRule: "nonzero",
      }}
      transform="translate(-285.211 -56.729)"
    />
    <path
      d="M636.825 221.172c-1.109 2.106-4.545 5.874-4.1 8.2.445 2.326 5.653 1.884 5.653 1.884"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-321.716 -99.39)"
    />
    <path
      d="M671.547 241.8c-2.623-.481-3.6-2.679-2.9-6.52.7-3.841 2.4-5.551 5.019-5.07 2.619.481 3.6 2.679 2.9 6.52-.7 3.841-2.4 5.551-5.019 5.07"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-337.549 -103.355)"
    />
    <path
      d="M613.884 255.975c-2.171 1.547-4.43.716-6.7-2.464-2.27-3.18-2.314-5.586-.141-7.133 2.173-1.547 4.429-.716 6.695 2.464 2.266 3.18 2.313 5.586.142 7.133"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-309.654 -110.218)"
    />
    <path
      d="M664.373 224.933c-.863.115-1.387-.47-1.555-1.734-.168-1.264.185-1.964 1.048-2.079.863-.115 1.387.47 1.555 1.734.168 1.264-.186 1.964-1.048 2.079"
      style={{
        fill: "#100c27",
        fillRule: "nonzero",
      }}
      transform="translate(-335.046 -99.36)"
    />
    <path
      d="M614.035 237.468c-.863.115-1.388-.47-1.555-1.734-.167-1.264.185-1.964 1.048-2.079.863-.115 1.388.47 1.555 1.734.167 1.264-.186 1.964-1.048 2.079"
      style={{
        fill: "#100c27",
        fillRule: "nonzero",
      }}
      transform="translate(-312.749 -104.912)"
    />
    <path
      d="M631.485 247.725c.548-2.057 4.684-2.131 7.442-2.423 2.758-.292 5.327-2.64 8-1.485 2.094.905-1.876 4.377-6.694 5.357-4.646.944-9.211.306-8.744-1.448"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-321.17 -109.278)"
    />
    <path
      d="M631.485 247.725c.548-2.057 4.684-2.131 7.442-2.423 2.758-.292 5.327-2.64 8-1.485 2.094.905-1.876 4.377-6.694 5.357-4.65.944-9.215.306-8.748-1.449Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-321.17 -109.278)"
    />
    <path
      d="M617.933 270.842c4.489 1.94 14.242 3.6 23 .831s15.628-8.645 17.568-11.305"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-315.182 -116.75)"
    />
    <path
      d="M649.232 207.167a1.05 1.05 0 0 1-.427-2.009c3.27-1.453 11.338-3.419 16.544-3.193a1.055 1.055 0 0 1 1.005 1.05c0 .577-.474 1.051-1.05 1.051l-.046-.001c-4.83-.21-12.587 1.675-15.6 3.014-.134.06-.279.091-.426.091"
      style={{
        fill: "#3b598b",
        fillRule: "nonzero",
      }}
      transform="translate(-328.58 -90.873)"
    />
    <path
      d="M700.8 219.48c-.333 4.379-.5 11.306-.5 11.306"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-351.668 -98.64)"
    />
    <path
      d="M708.24 207.244a59.632 59.632 0 0 0-3.658 10.641"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-353.562 -93.22)"
    />
    <path
      d="M597.284 265.356c-2.106-1.441-2.771-3.214-3.824-4.988"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-304.342 -116.75)"
    />
    <path
      d="M638.561 167.053c-1.33 2.771-5.763 4.766-10.086 3.769-4.323-.997-8.534.443-10.2 3.1-1.666 2.657-2.217 5.874-6.1 6.207-3.883.333-8.312-2.217-13.19 1.441-4.878 3.658-5.321 8.756-8.2 9.532a9.677 9.677 0 0 1-5.985-.776"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#9497ce",
        strokeWidth: "1.2px",
      }}
      transform="translate(-300.509 -75.417)"
    />
    <path
      d="M680.01 182.855c2.937-.887 6.705-2.771 8.368-6.65"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#9497ce",
        strokeWidth: "1.2px",
      }}
      transform="translate(-342.678 -79.471)"
    />
    <path
      d="M685.88 192.72a8.36 8.36 0 0 0 4.212 5.542c.916.7 1.769 1.479 2.549 2.328"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#9497ce",
        strokeWidth: "1.2px",
      }}
      transform="translate(-345.278 -86.786)"
    />
    <path
      d="M689.461 187.944a10.025 10.025 0 0 0 3.879 5.209c1.662 1 1.884 3.436 1.884 3.436"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#9497ce",
        strokeWidth: "1.2px",
      }}
      transform="translate(-346.865 -84.671)"
    />
    <path
      d="M694.435 183.567c.333 1.219 2.438 3.325 2.549 4.877"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#9497ce",
        strokeWidth: "1.2px",
      }}
      transform="translate(-349.068 -82.733)"
    />
    <path
      d="M629.455 158.7c-2.771 1.441-7.98-1-12.081 1.995-4.101 2.995-4.655 6.539-7.648 7.426-2.993.887-11.195-1.551-14.631 2.106s-6.429 9.311-12.081 8.978"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#9497ce",
        strokeWidth: "1.2px",
      }}
      transform="translate(-299.716 -71.716)"
    />
    <path
      d="M607.689 149.3c-4.545-.443-8.091 1.662-10.751 5.542s-8.756 2.439-12.414 1.219c-3.658-1.22-6.983-.665-9.089 1.995-2.106 2.66-6.429 7.759-9.532 7.87"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#9497ce",
        strokeWidth: "1.2px",
      }}
      transform="translate(-292.137 -67.528)"
    />
    <path
      d="M561.327 149.273c2.66-.222 7.426-3.547 10.086-7.094s7.648-2.217 11.084.222 10.307 2.327 13.19-.222c2.883-2.549 6.872-5.32 11.527-3.325"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#9497ce",
        strokeWidth: "1.2px",
      }}
      transform="translate(-290.11 -62.626)"
    />
    <path
      d="M663.51 149c-2.327-5.1-7.315-4.434-10.086-2.549-2.771 1.885-7.537 1.995-7.537 1.995"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#9497ce",
        strokeWidth: "1.2px",
      }}
      transform="translate(-327.564 -65.673)"
    />
    <g className="hand">
      <path
        d="M497.593 29.3c4.861-.773 5.874 3.658 6.318 11.195.444 7.537.887 18.62 1.219 18.842.332.222 3.547.222 3.99-.333.443-.555 4.544-12.746 6.761-18.067 2.217-5.321 5.432-11.637 9.419-10.308 3.987 1.329 2.993 7.537.886 13.3-2.107 5.763-7.315 20.061-7.315 20.061s5.653-1.884 8.091 1.109c2.438 2.993 1.33 7.87.111 9.421 0 0 5.1 1 3.99 6.872-1.11 5.872-11.749 8.424-14.077 8.757-2.328.333-22.5 1-22.5 1s-11.417-5.874-11.97-13.19c-.553-7.316 9.643-11.416 9.643-11.416s.333-10.641.222-19.286c-.111-8.645.333-17.18 5.209-17.956"
        style={{
          fill: "#f7e5dd",
          fillRule: "nonzero",
        }}
        transform="translate(-255.194 -14.364)"
      />
      <path
        d="M499.846 93.489c2.66-1.109 11.527-5.1 14.52-2.66 2.993 2.44 2.549 5.874-.887 8.978s-10.862 5.431-13.079 5.653"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#f3a8a3",
          strokeWidth: "1.2px",
        }}
        transform="translate(-262.878 -41.313)"
      />
      <path
        d="M537.546 94.629a36.647 36.647 0 0 0-8.978 7.87c-2.439 3.1-5.542 6.207-4.434 8.867 1.108 2.66 4.434 3.547 6.539 2.993 2.105-.554 11.195-6.539 14.742-10.862"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#f3a8a3",
          strokeWidth: "1.2px",
        }}
        transform="translate(-273.534 -43.339)"
      />
      <path
        d="M506.014 93.236c.443 3.325 4.545 4.877 7.537 4.434a11.478 11.478 0 0 0 5.431-2.771"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#f3a8a3",
          strokeWidth: "1.2px",
        }}
        transform="translate(-265.61 -42.721)"
      />
      <path
        d="M526.508 115.333c2.882-2.438 6.872-1.552 7.87.333.998 1.885 0 5.1-1.219 6.429"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#f3a8a3",
          strokeWidth: "1.2px",
        }}
        transform="translate(-274.687 -51.852)"
      />
    </g>
    <path
      d="M468.006 447.525s5.137 17.861 7.65 27.347c-24.943 6.3-51.653 10.672-82.735 12.979-2.2-13.589-6.652-37.072-13.364-53.628-9.974-24.605-31.255-39.569-54.53-52.537-23.275-12.968-45.683-10.927-63.971-10.927 0 0 18.418-23.32 50.005-36.621a230.432 230.432 0 0 1 117.46-15.712c13.259 1.71 38.9 7.232 56.86 19.451 17.96 12.219 32.67 23.693 40.9 42.4 8.23 18.707 10.219 31.669 10.219 31.669l2.667 12.146c-4.24 2.425-15.276 8.359-33.258 15.451-23.608 9.311-37.907 7.982-37.907 7.982"
      style={{
        fill: "#3b598b",
        fillRule: "nonzero",
      }}
      transform="translate(-157.109 -141.64)"
    />
    <path
      d="M604.2 413.92c.25 7.232 5.154 48.465 15.794 76.728"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.2px",
      }}
      transform="translate(-309.101 -184.763)"
    />
    <path
      d="M577.605 356.618c-2.244 5.736-1.995 18.7-4.738 22.2"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.2px",
      }}
      transform="translate(-295.221 -159.382)"
    />
    <path
      d="M585.4 421.979c0 3.99 4.239 12.968 7.232 19.7"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.2px",
      }}
      transform="translate(-300.773 -188.333)"
    />
    <path
      d="M623.907 558.967c.5 7.232-1.746 17.207-2.244 24.938"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.2px",
      }}
      transform="translate(-316.835 -249.01)"
    />
    <path
      d="M741.363 467.641c-1.247 6.235-1.995 13.217-8.23 20.2"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.2px",
      }}
      transform="translate(-366.209 -208.558)"
    />
    <path
      d="M310.27 529.941c-29.315 2.175-62.522 2.515-101.811.9-81.133-3.325-134-19.618-134-19.618s5.659-38.56 27.643-62.771c22.943-25.272 47.914-34.814 77.8-36.506 15.86-.9 39.2-1.141 62.475 11.825s44.556 27.932 54.53 52.538c6.712 16.556 11.167 40.039 13.364 53.628"
      style={{
        fill: "#bdebf1",
        fillRule: "nonzero",
      }}
      transform="translate(-74.459 -183.73)"
    />
    <path
      d="M707.041 524.71s-17.956 23.275-66.5 35.579l-.33.082c-2.512-9.486-7.65-27.347-7.65-27.347s14.3 1.33 37.907-7.982c17.981-7.092 29.018-13.026 33.257-15.451l3.316 15.119Z"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-321.66 -227.14)"
    />
    <path
      d="M542.711 355.872s20.283 3.99 30.924 11.638a47.152 47.152 0 0 1 11.555 12.469c3.064 4.822-15.821 28.468-46.136 48.381-33.916 22.278-83.793 29.261-104.741 31.921a262.444 262.444 0 0 1-37.075 2.161c-12.394-.156-6.484 5.32-10.474 8.147s-6.65.167-5.819-2.494c.831-2.661 12.136-13.8 12.136-13.8s-8.978 4.988-12.635 6.318c-3.657 1.33-5.154-1.33-3.658-4.323 1.496-2.993 13.3-9.809 13.3-9.809s-8.978 3.325-12.968 3.325-6.151-1.829-2.826-5.985c3.325-4.156 19.119-9.31 24.938-11.306 5.819-1.996 44.723-13.3 54.033-16.126 9.31-2.826 48.214-15.961 48.214-15.961l41.232-44.556Z"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-206.543 -159.052)"
    />
    <path
      d="M398.483 275.887c-8.645 8.645-23.276 30.924-22.611 54.2.665 23.276 7.648 57.857 11.97 71.157 4.322 13.3 12.469 26.851 16.46 29.344 3.991 2.493 7.731-.748 7.731-.748a11.45 11.45 0 0 0 7.481 2.993c4.489 0 5.237-3.242 5.237-3.242a8.004 8.004 0 0 0 6.484 1.745 7.771 7.771 0 0 0 5.237-4.239 72.612 72.612 0 0 1-7.481-19.951 91.823 91.823 0 0 1-1.746-18.2l8.23-2.744s-9.227-9.476-10.225-12.968c-.998-3.492-5.736-25.686-5.985-32.42-.249-6.734-3.242-50.126-3.741-53.618-.499-3.492-7.232-9.477-7.232-9.477l-9.809-1.832Z"
      style={{
        fill: "#e6d0c5",
        fillRule: "nonzero",
      }}
      transform="translate(-207.956 -123.624)"
    />
    <path
      d="M427.929 196.359c-.867 4.957-1.33 11.8-1.33 11.8s-7.98-5.487-12.635-2.66c-4.655 2.827-8.645 9.476-6.151 17.789 2.494 8.313 11.305 13.3 17.955 13.3 0 0 2.327 2.66 3.325 16.126.998 13.466 4.988 48.713 4.988 48.713s5.486 13.467 32.586 10.308c27.1-3.159 28.928-23.435 28.928-23.435s-1.5-28.929.5-38.073c2-9.144 5.986-13.966 7.149-20.283a10.9 10.9 0 0 0 11.472-5.487c4.156-7.149 3.824-17.291.5-20.283-3.324-2.992-10.973-2.161-10.973-2.161s-2.161-26.767-23.109-31.921-33.916.333-40.567 5.819c-6.651 5.486-11.472 13.8-12.635 20.449"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-221.773 -75.85)"
    />
    <path
      d="M478.988 247.051c-1.112 0-1.678-.829-1.678-2.457s.566-2.457 1.678-2.457 1.678.829 1.678 2.457-.566 2.457-1.678 2.457"
      style={{
        fill: "#100c27",
        fillRule: "nonzero",
      }}
      transform="translate(-252.896 -108.675)"
    />
    <path
      d="M544.249 249.041c-1.112 0-1.678-.829-1.678-2.457s.566-2.457 1.678-2.457 1.678.829 1.678 2.457-.566 2.457-1.678 2.457"
      style={{
        fill: "#100c27",
        fillRule: "nonzero",
      }}
      transform="translate(-281.802 -109.556)"
    />
    <path
      d="M489.7 273.588c1.658-4.527 8.377-2.448 12.946-1.632 4.569.816 9.605-3.291 13.489.87 3.046 3.264-4.678 9.247-12.837 8.92-7.869-.315-15.012-4.3-13.6-8.159"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-258.303 -121.355)"
    />
    <path
      d="M489.7 273.588c1.658-4.527 8.377-2.448 12.946-1.632 4.569.816 9.605-3.291 13.489.87 3.046 3.264-4.678 9.247-12.837 8.92-7.87-.314-15.014-4.296-13.598-8.158Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-258.303 -121.355)"
    />
    <path
      d="M509.058 236.094c-1.773 2.217-7.648 10.2-7.426 12.3.222 2.1 9.31 2.328 9.31 2.328"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-263.666 -105.998)"
    />
    <path
      d="M560.061 277.678a103.05 103.05 0 0 0-6.1 8.645"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-286.849 -124.417)"
    />
    <path
      d="M460.849 278.673c2.882 2.217 5.542 8.313 5.542 8.313"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-245.605 -124.858)"
    />
    <path
      d="M549.054 273.842c-2.785-.51-3.822-2.845-3.074-6.924.748-4.079 2.544-5.894 5.329-5.384 2.785.51 3.822 2.845 3.074 6.924-.748 4.079-2.545 5.894-5.329 5.384"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-283.208 -117.23)"
    />
    <path
      d="M466.513 271.111c-2.7.842-4.706-.744-5.939-4.7-1.233-3.956-.485-6.4 2.218-7.244 2.703-.844 4.706.744 5.939 4.7 1.233 3.956.485 6.4-2.218 7.244"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-245.227 -116.12)"
    />
    <path
      d="M460.451 301.54c2.327 4.545 10.741 13.489 27.377 14.3 15.961.775 20.948-2.106 25.16-5.653a45 45 0 0 0 9.31-11.416"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-245.428 -133.76)"
    />
    <path
      d="M420.658 250.619c1.773 2.327 7.426 13.411 7.426 13.411"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-227.803 -112.432)"
    />
    <path
      d="M526.821 228.095s.744-4.393 3.031-5.348c2.287-.955 10.176-.972 12.85.544 2.674 1.516 7.436 6.617 7.147 7.6a45.57 45.57 0 0 0-10.967-2.082c-6.3-.43-12.061-.712-12.061-.712"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-274.826 -99.792)"
    />
    <path
      d="M482.81 225.509s-.744-4.393-3.031-5.348c-2.287-.955-10.176-.972-12.85.544-2.674 1.516-7.436 6.617-7.147 7.6a45.578 45.578 0 0 1 10.968-2.082c6.3-.43 12.061-.712 12.061-.712"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-245.126 -98.646)"
    />
    <path
      d="M476.874 147.566s7.426-13.633-.887-20.727c-8.313-7.094-14.409-6.429-20.837-3.214-6.428 3.215-7.2 7.094-15.407 5.874-8.207-1.22-15.184-4.045-25.16 2.439s-3.824 13.8-10.142 19.618c-6.318 5.818-12.8 1.829-14.132 12.137-1.332 10.308.333 13.965-4.489 17.623-4.822 3.658-6.705 18.843-3.713 23s.217 10.993 4.655 12.635c11.084 4.1 3.547-25.492 16.071-31.256 5.021-2.31 9.588 3.769 9.588 3.769s-.333 16.46 1.5 20.616l3.658 1.5s-1.829-14.8-.831-21.78 1.5-9.144 1.5-9.144 6.816-4.489 9.31-11.8c2.494-7.311 7.981-5.487 10.807-3.99 2.826 1.497 10.142 3.325 13.467-1.5 3.325-4.825 3.99-7.981 7.647-8.646 3.657-.665 7.149 1 9.31 0 0 0 .832 7.648 6.318 12.636 5.486 4.988 11.97 6.65 13.965 9.809 1.995 3.159 3.159 8.147 3.99 12.8.831 4.653-1 13.3-1 13.3l1.663-1.663a142.306 142.306 0 0 0 2.327-16.127c.5-7.315 2.494-18.121-4.821-27.266-7.315-9.145-14.353-10.641-14.353-10.641"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-210.09 -55.156)"
    />
    <path
      d="M510.318 144.62c-2.66-3.491-7.481-5.985-13.966-3.325-6.485 2.66-8.978 6.152-15.129 5.32-6.151-.832-10.973-2.826-17.29.167"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "2.4px",
      }}
      transform="translate(-246.971 -63.515)"
    />
    <path
      d="M498.733 157.663c-1.995-1.662-6.816-2.827-10.807-1-3.991 1.827-4.156 5.154-9.975 4.489s-5.819-3.657-12.968-2.993c-7.149.664-7.648 5.986-12.3 7.815a33.352 33.352 0 0 1-8.147 2.161"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "2.4px",
      }}
      transform="translate(-238.378 -70.405)"
    />
    <path
      d="M478.2 169.869c-3.824-.333-8.645.166-12.3 3.658-3.655 3.492-7.814 5.487-13.3 3.658-5.486-1.829-10.142-.665-13.633 2.494-3.491 3.159-4.489 8.147-10.474 10.474s-10.807 0-15.3 5.154"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "2.4px",
      }}
      transform="translate(-224.497 -76.626)"
    />
    <path
      d="M546.6 184.562a24.196 24.196 0 0 0 8.812 9.477c4.323 2.66 7.814 5.486 8.812 7.814"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "2.4px",
      }}
      transform="translate(-283.588 -83.173)"
    />
    <path
      d="M551.08 178.891c3.658 2.161 9.31 7.481 11.472 11.638"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "2.4px",
      }}
      transform="translate(-285.571 -80.661)"
    />
    <path
      d="M563.615 178.295c3.159 2.494 8.812 7.814 10.141 15.794"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "2.4px",
      }}
      transform="translate(-291.123 -80.397)"
    />
    <path
      d="M189.658 491.816c3.99 11.721 15.462 58.855 17.208 70.326"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.2px",
      }}
      transform="translate(-125.485 -219.267)"
    />
    <path
      d="M372.323 485.548c-4.239 7.731-7.981 34.914-5.237 48.63 2.744 13.716 7.481 29.926 7.481 29.926"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.2px",
      }}
      transform="translate(-203.659 -216.49)"
    />
    <path
      d="M460.461 547.067a14.832 14.832 0 0 1-3.99-5.957"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-243.665 -241.1)"
    />
    <path
      d="M437.873 547.758a26.013 26.013 0 0 1-3.686-5.653"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-233.795 -241.541)"
    />
    <path
      d="M379.185 510.867c1.773.665 3.214 2.882 2.66 4.766a4.708 4.708 0 0 1-2.771 2.66"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-209.384 -227.704)"
    />
    <path
      d="M381.66 533.77a2.961 2.961 0 0 1 3.879 1.773c.776 1.885-1.33 3.436-2.106 3.99"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-210.529 -237.75)"
    />
    <path
      d="M389.221 553.838a3.066 3.066 0 0 1 4.1.665c1.108 1.551.111 2.882-.665 4.1"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-213.878 -246.513)"
    />
    <path
      d="m366.422 199.166-.8 4.389 11.37 14.563a28.841 28.841 0 0 0 6.384-5.586 42.479 42.479 0 0 0 4.987-8.378l-21.941-4.988Z"
      style={{
        fill: "#e6d0c5",
        fillRule: "nonzero",
      }}
      transform="translate(-203.426 -89.641)"
    />
    <path
      d="M407.317 118.53c-.832-8.526-6.947-9.167-9.576-9.041a9.281 9.281 0 0 0-1.6 6.115c.199 2.162-3.724-7.182-5.719-9.975-1.995-2.793-6.915-3.723-12.9-5.319s-6.118-7.979-6.118-7.979a20.023 20.023 0 0 1-2.527 8.645c-2.394 3.99-5.453 5.319-10.906 7.846s-7.049 8.778-7.182 14.1c-.133 5.322-6.251 9.842-9.309 11.171-3.058 1.329-4.389 6.65-4.389 6.65s-3.724-8.332-5.7-14.224c-2.917 2.057-7.8 6.766-5.6 14.224 3.059 10.374 15.694 7.448 15.694 7.448s16.757 16.89 37.371 7.979c20.614-8.911 19.284-25.67 19.284-25.67s10.241-1.063 9.177-11.969"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-185.538 -42.322)"
    />
    <path
      d="M334.379 118.9c-1.345-4.528 2.423-26.1 18.6-34.318 16.225-8.246 31.652-1.995 36.174 1.6 4.522 3.595 9.575 9.974 10.906 15.826l.664-.13a31.677 31.677 0 0 0-3.989-13.568c-3.723-6.118-12.634-13.3-26.333-13.166-13.699.134-21.146 4.122-27.53 9.442s-8.511 10.773-10.373 18.353c-1.862 7.58 1.877 15.965 1.877 15.965"
      style={{
        fill: "#686cba",
        fillRule: "nonzero",
      }}
      transform="translate(-188.525 -34.706)"
    />
    <path
      d="M405.97 140.556c.8 1.2 4.921 1.729 5.852 2.261s1.995 1.6.531 4.122a11.323 11.323 0 0 1-2.793 3.458"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-221.297 -63.681)"
    />
    <path
      d="M332.916 173.5c1.6 1.064 6.251 4.788 6.251 4.788"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-188.939 -78.274)"
    />
    <path
      d="M454.625 148.477a50.448 50.448 0 0 0-1.862-15.56"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-242.023 -60.297)"
    />
    <path
      d="M460.218 129.574c-.532 1.463-.133 8.91-.532 10.374"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-245.089 -58.818)"
    />
    <path
      d="M350.913 186.679c-2.261-2.394-4.389-7.447-4.389-7.447"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-194.966 -80.813)"
    />
    <path
      d="M394.268 169c-.186-2.42 4.389-2.926 8.113-3.591 3.724-.665 5.985-2.527 8.246-2.926s3.325 1.729 1.463 4.787-5.852 4.921-9.974 5.054-7.714-1.6-7.847-3.325"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-216.111 -73.371)"
    />
    <path
      d="M394.268 169c-.186-2.42 4.389-2.926 8.113-3.591 3.724-.665 5.985-2.527 8.246-2.926s3.325 1.729 1.463 4.787-5.852 4.921-9.974 5.054-7.716-1.597-7.848-3.324Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-216.111 -73.371)"
    />
    <path
      d="M429.255 141.378c-.8.228-1.38-.255-1.714-1.429-.334-1.174-.094-1.888.708-2.116.802-.228 1.38.255 1.713 1.429.333 1.174.095 1.889-.707 2.116"
      style={{
        fill: "#100c27",
        fillRule: "nonzero",
      }}
      transform="translate(-230.787 -62.45)"
    />
    <path
      d="M380.911 157.672c-.8.227-1.38-.255-1.714-1.429-.334-1.174-.094-1.888.708-2.116.802-.228 1.38.255 1.714 1.429.334 1.174.094 1.888-.708 2.116"
      style={{
        fill: "#100c27",
        fillRule: "nonzero",
      }}
      transform="translate(-209.374 -69.668)"
    />
    <path
      d="M435.8 158.851c-2.629.282-4.177-1.534-4.59-5.384-.413-3.85.716-5.953 3.345-6.235 2.629-.282 4.177 1.534 4.589 5.384.412 3.85-.715 5.953-3.344 6.235"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-232.441 -66.626)"
    />
    <path
      d="M381.564 177.869c-1.993 1.737-4.3 1.136-6.846-1.783-2.546-2.919-2.824-5.289-.831-7.026 1.993-1.737 4.3-1.136 6.846 1.783 2.546 2.919 2.824 5.289.831 7.026"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-206.505 -75.877)"
    />
    <path
      d="M370.249 139a.714.714 0 0 1-.625-1.05 7.578 7.578 0 0 1 5.35-4.11 11.806 11.806 0 0 1 5.437.583c.273.106.454.37.454.663a.714.714 0 0 1-.968.663 10.528 10.528 0 0 0-4.715-.5 6.195 6.195 0 0 0-4.308 3.38.711.711 0 0 1-.626.373"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-205.16 -60.663)"
    />
    <path
      d="M417.285 125.038a.712.712 0 0 1-.537-1.177l.06-.07c1.346-1.555 4.147-4.79 8.71-3.449a.713.713 0 0 1 .511.682.715.715 0 0 1-.911.683c-3.684-1.084-5.993 1.583-7.233 3.015l-.061.07a.712.712 0 0 1-.538.245"
      style={{
        fill: "#f0c16a",
        fillRule: "nonzero",
      }}
      transform="translate(-225.994 -54.587)"
    />
    <path
      d="M208.219 181.94c5.586-2.926 12.767.066 14.363 3.857 1.596 3.791 3.723 5.453 7.314 5.453s3.192-3.458 3.059-7.315 3.192-8.511 7.315-8.778c4.212-.363 8.13 2.324 9.31 6.384a29.329 29.329 0 0 1 .4 13.565c-.931 4.123-17.555 38.3-17.555 38.3s-9.575 7.447-17.821 7.049c-8.246-.398-17.289-3.458-20.347-11.3a15.47 15.47 0 0 1 2.926-16.225c3.249-3.79 8.167-3.6 8.511-7.049.532-5.32-3.059-5.586-3.724-12.369-.574-5.861 3.582-10.172 6.251-11.57"
      style={{
        fill: "#3b598b",
        fillRule: "nonzero",
      }}
      transform="translate(-127.014 -78.998)"
    />
    <path
      d="M283.867 193.7c10.29-3.381 21.614-2.161 28.762 1 7.094 3.134 16.792 9.476 16.459 24.606-.373 16.954-65.837 38.572-65.837 38.572s-9.476-12.469-6.816-31.589c2.66-19.12 15.795-28.762 27.432-32.586"
      style={{
        fill: "#4b6694",
        fillRule: "nonzero",
      }}
      transform="translate(-154.854 -86.323)"
    />
    <path
      d="M267.154 223.563c1.044-1.74 6.152 1.33 11.472 1 5.32-.33 7.981-3.99 9.809-7.149a25.115 25.115 0 0 0 2.493-7.481c.432-2.609 6.817 3.492 10.641 5.487s8.978 5.32 10.807 10.308c1.829 4.988 4.821 18.953 3.325 29.594-1.496 10.641-4.156 16.958-9.643 21.613-5.487 4.655-13.466 7.481-15.96 7.648-2.494.167-5.651 17.1-8.644 29.972-2.54 10.922-5.084 25.418-5.685 30.223-.956 7.65-6.941 18.891-10.6 25.193a6.82 6.82 0 0 1-8.181 2.981c-6.508-2.341-17.6-7.005-25.2-13.811-11.389-10.191-13.173-19.693-13.173-19.693s12.3-32.752 16.626-43.559c4.326-10.807 8.645-23.775 8.645-23.775-5.708-1.167-9.993-5.994-10.474-11.8-1.164-10.308 11.97-17.457 16.626-15.628l4.489 10.973s2.327-1.829 3.159-7.981c.832-6.152-.166-8.978 2.827-10.141 2.993-1.163 6.484-3.658 6.983-7.149.499-3.491-.831-5.985-.333-6.816"
      style={{
        fill: "#f7e5dd",
        fillRule: "nonzero",
      }}
      transform="translate(-138.307 -94.41)"
    />
    <path
      d="M337.45 297.2c.665-1.5 6.041-.554 10.474-1.33a31.713 31.713 0 0 0 7.232-2.078c2.32-.977.487 5.051-3.658 7.814-3.492 2.327-7.343 1.5-9.892.25-2.408-1.178-4.821-3.159-4.156-4.655"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-190.897 -131.556)"
    />
    <path
      d="M337.45 297.2c.665-1.5 6.041-.554 10.474-1.33a31.713 31.713 0 0 0 7.232-2.078c2.32-.977.487 5.051-3.658 7.814-3.492 2.327-7.343 1.5-9.892.25-2.406-1.176-4.821-3.156-4.156-4.656Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#e4a2a0",
        strokeWidth: "1.2px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
      }}
      transform="translate(-190.897 -131.556)"
    />
    <path
      d="M354.1 260.667a3.018 3.018 0 0 0 1.164 2.91c1.247.831 6.318 4.406 6.235 5.653-.105 1.578-4.738 4.655-4.738 4.655"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-198.322 -116.882)"
    />
    <path
      d="M321.546 275.851c-.8.2-1.352-.293-1.644-1.461-.292-1.168-.036-1.863.761-2.063.797-.2 1.352.293 1.645 1.461.293 1.168.035 1.863-.762 2.063"
      style={{
        fill: "#100c27",
        fillRule: "nonzero",
      }}
      transform="translate(-183.123 -122.028)"
    />
    <path
      d="M370.492 265.555c-.8.2-1.352-.293-1.644-1.461-.292-1.168-.036-1.863.762-2.063.798-.2 1.352.293 1.644 1.461.292 1.168.035 1.863-.762 2.063"
      style={{
        fill: "#100c27",
        fillRule: "nonzero",
      }}
      transform="translate(-204.802 -117.468)"
    />
    <path
      d="M305.993 258.146c-.658 0-1.2-.542-1.2-1.2 0-.199.05-.396.145-.571 1.231-2.272 4.04-6.195 9.026-6.933a19.95 19.95 0 0 1 9.172.983c.461.179.766.625.766 1.119a1.206 1.206 0 0 1-1.634 1.119 17.776 17.776 0 0 0-7.952-.847c-3.953.586-6.25 3.824-7.267 5.7-.21.387-.616.628-1.056.628"
      style={{
        fill: "#3b598b",
        fillRule: "nonzero",
      }}
      transform="translate(-176.482 -111.832)"
    />
    <path
      d="M355.536 253.111a1.2 1.2 0 0 1-.906-1.986l.1-.117c2.271-2.623 7-8.081 14.693-5.817.535.132.913.615.913 1.165a1.205 1.205 0 0 1-1.59 1.135c-6.216-1.83-10.11 2.669-12.2 5.086l-.1.118a1.2 1.2 0 0 1-.907.414"
      style={{
        fill: "#3b598b",
        fillRule: "nonzero",
      }}
      transform="translate(-198.427 -109.79)"
    />
    <path
      d="M262.927 288.124c1.746 1.081 2.993 8.978 7.066 11.97"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-157.938 -129.044)"
    />
    <path
      d="M263.972 320.625c2.993 1.081 10.141 1.5 14.215-1.164"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-158.401 -142.924)"
    />
    <path
      d="M329.09 339.655c-8.4 1.081-16.21-2.66-20.948-5.57"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#f3a8a3",
        strokeWidth: "1.2px",
      }}
      transform="translate(-177.966 -149.401)"
    />
    <path
      d="M319.753 298.734c-2.495.92-4.45-.471-5.8-4.123-1.35-3.652-.764-5.981 1.731-6.9 2.495-.919 4.449.471 5.8 4.124 1.351 3.653.764 5.98-1.731 6.9"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-180.228 -128.738)"
    />
    <path
      d="M377.642 284.221c-2.641-.3-3.758-2.428-3.312-6.3.446-3.872 2.018-5.681 4.659-5.377 2.641.304 3.758 2.428 3.312 6.3-.446 3.872-2.018 5.682-4.659 5.377"
      style={{
        fill: "#f3a8a3",
        fillRule: "nonzero",
      }}
      transform="translate(-207.24 -122.13)"
    />
    <path
      d="M277.729 200.718c-4.389 2.527-14.23 9.842-17.688 20.481-3.458 10.639-3.325 15.826-2.793 21.545"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.2px",
      }}
      transform="translate(-155.296 -90.33)"
    />
    <path
      d="M208.711 252.047c-2.793 2.128-4.788 7.182-2.128 10.906"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.2px",
      }}
      transform="translate(-132.427 -113.064)"
    />
    <path
      d="M222.172 225.547c1.463 2.527 1.2 6.915-1.463 8.379"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.2px",
      }}
      transform="translate(-139.238 -101.326)"
    />
    <path
      d="M222.1 203.243c-.4-3.458 1.6-5.852 4.123-6.118s4.123 3.059 4.123 3.059"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.2px",
      }}
      transform="translate(-139.832 -88.731)"
    />
    <path
      d="M247.686 211.7a7.955 7.955 0 0 0 6.915 3.059 3.798 3.798 0 0 0 3.325-2.926"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.2px",
      }}
      transform="translate(-151.187 -95.194)"
    />
    <path
      d="M271.56 187.611c.266-2.261 1.6-4.788 4.123-5.054a3.757 3.757 0 0 1 4.123 3.99"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.2px",
      }}
      transform="translate(-161.762 -82.275)"
    />
    <path
      d="M233.839 219.578c2.394 1.33 3.99 3.99 2.527 7.049"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.2px",
      }}
      transform="translate(-145.054 -98.683)"
    />
    <path
      d="M229.96 246.317c-.931 2.793-.266 6.783 3.591 7.714"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.2px",
      }}
      transform="translate(-143.17 -110.526)"
    />
    <path
      d="M250.5 235.1c-2.394.8-5.852 4.788-3.458 8.91"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.2px",
      }}
      transform="translate(-150.545 -105.556)"
    />
    <path
      d="M220 267.565a5.01 5.01 0 0 0-.624 2.42 5.023 5.023 0 0 0 3.949 4.895"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.2px",
      }}
      transform="translate(-138.666 -119.938)"
    />
    <path
      d="M235.988 271.862c.4 3.458 5.054 4.389 7.447 2.394"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#100c27",
        strokeWidth: "1.2px",
      }}
      transform="translate(-146.006 -121.841)"
    />
    <path
      d="M677.921 347.371c5.171-4.906 12.153-9.893 14.547-10.692"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.2px",
      }}
      transform="translate(-341.753 -150.552)"
    />
    <path
      d="M612.832 306.957c-2.394 2.793-3.79 7.78-3.391 9.575"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.2px",
      }}
      transform="translate(-311.391 -137.386)"
    />
    <path
      d="M607.805 286.9a13.756 13.756 0 0 0-7.979 5.785"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.2px",
      }}
      transform="translate(-307.162 -128.504)"
    />
    <path
      d="M216.292 552.26c.2 4.987 1.8 23.141 1.4 29.724"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#2f3045",
        strokeWidth: "1.2px",
      }}
      transform="translate(-137.282 -246.04)"
    />
    <path
      d="M622.211 88.6c0 2.408-1.981 4.389-4.389 4.389-2.408 0-4.389-1.981-4.389-4.389 0-2.408 1.981-4.389 4.389-4.389h.001c2.407 0 4.388 1.981 4.388 4.388v.001Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#98deea",
        strokeWidth: "2.4px",
      }}
      transform="translate(-313.189 -38.726)"
    />
    <path
      d="M296.333 48.495c0 2.408-1.981 4.389-4.389 4.389-2.408 0-4.389-1.981-4.389-4.389 0-2.408 1.981-4.389 4.389-4.389h.001c2.407 0 4.388 1.981 4.388 4.388v.001Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#aaadd8",
        strokeWidth: "2.4px",
      }}
      transform="translate(-168.847 -20.96)"
    />
    <path
      d="M84.607 244.986c5.314-.1 8.772 4.407 7.689 8.523-1.083 4.116-1.2 9.777 4.867 11.575s8.481 5.663 6.761 10.822c-1.72 5.159.839 8.4 5.447 9.063"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#ffd585",
        strokeWidth: "2.4px",
      }}
      transform="translate(-78.954 -109.936)"
    />
    <path
      d="m0 0 7.581 11.57"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#4ebdcc",
        strokeWidth: "2.4px",
      }}
      transform="translate(200.499 7.985)"
    />
    <path
      d="m0 0 1.197 12.767"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#ffd585",
        strokeWidth: "2.4px",
      }}
      transform="translate(222.842 1.201)"
    />
    <path
      d="M7.581 0 0 11.57"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#4ebdcc",
        strokeWidth: "2.4px",
      }}
      transform="translate(339.743 43.892)"
    />
    <path
      d="M884.346 225.4c0 2.408-1.981 4.389-4.389 4.389-2.408 0-4.389-1.981-4.389-4.389 0-2.408 1.981-4.389 4.389-4.389h.001c2.407 0 4.388 1.981 4.388 4.388v.001Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#aaadd8",
        strokeWidth: "2.4px",
      }}
      transform="translate(-489.298 -79.317)"
    />
  </svg>
);

export default WantChildSvg;
