import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import { classNames } from "../functions";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { UPDATE_MAIN_REDUCER } from "../reducers/MainReducer";

const useStyles = makeStyles((theme) => ({
  defaultIcon: {
    height: 25,
    [theme.breakpoints.down("sm")]: {
      height: 18,
    },
  },
  smallIcon: {
    height: 18,
    [theme.breakpoints.down("sm")]: {
      height: 13,
    },
  },
  btn: {
    margin: `${theme.spacing(1)} ${theme.spacing(3)}`,
    "&:last-of-type": {
      marginRight: 0,
    },
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0.5),
    },
  },
}));

const IconBtnComponent = ({
  small,
  id,
  value,
  to,
  title,
  Icon = () => {},
  onClick = () => {},
}) => {
  const classes = useStyles();
  const { data } = useSelector((state) => state.MainReducer);
  const dispatch = useDispatch();

  const handleToggleAccess = useCallback(() => {
    onClick();
    if (to) {
      window.open(to, "_blank");
    } else {
      let newValue = "";
      if (value) {
        newValue = data[id] === value ? "" : value;
      } else {
        const active = data[id];
        newValue = active ? "" : true;
      }
      localStorage.setItem(id, newValue);
      dispatch(UPDATE_MAIN_REDUCER({ id, newValue }));
    }
  }, [dispatch, data]);

  return (
    <IconButton
      className={classes.btn}
      onClick={handleToggleAccess}
      title={title}
    >
      {
        <Icon
          className={classNames([
            classes.icon,
            small ? classes.smallIcon : classes.defaultIcon,
          ])}
        />
      }
    </IconButton>
  );
};

IconBtnComponent.propTypes = {
  Icon: PropTypes.func,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  type: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  title: PropTypes.string,
  to: PropTypes.string,
};
export default React.memo(IconBtnComponent);
