import React, { useMemo } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import MainContainerComponent from "../../components/MainContainerComponent";
import { Container, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import LinkComponent from "../../components/LinkComponent";
import {
  CONTACT_ROUTE,
  INFORMATIONS_ROUTE,
  KNOWLEDGES_ROUTE,
  MAIN_ROUTE,
  NEWS_ROUTE,
  STORIES_ROUTE,
} from "../../constants/routes";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  cont: {
    paddingBottom: theme.spacing(16),
  },
}));

const MapPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const routes = useMemo(
    () => [
      {
        title: t("main_page.title"),
        to: MAIN_ROUTE,
      },
      {
        title: t("news_page.title"),
        to: NEWS_ROUTE,
      },
      {
        title: t("knowledge_page.title"),
        to: KNOWLEDGES_ROUTE,
      },
      {
        title: t("information_page.title"),
        to: INFORMATIONS_ROUTE,
      },
      {
        title: t("stories_page.title"),
        to: STORIES_ROUTE,
      },
      {
        title: t("contact_page.title"),
        to: CONTACT_ROUTE,
      },
    ],
    []
  );
  return (
    <MainContainerComponent>
      <Container className={classes.cont}>
        <Typography className={classes.title} variant="h1">
          {t("maps_page.title")}
        </Typography>
        <Box>
          {routes?.length
            ? routes.map((route, key) => (
                <LinkComponent key={key} to={route?.to}>
                  <Typography variant="h3" component={"p"}>
                    {route?.title}
                  </Typography>
                </LinkComponent>
              ))
            : null}
        </Box>
      </Container>
    </MainContainerComponent>
  );
};

MapPage.propTypes = {
  name: PropTypes.string,
};
export default React.memo(MapPage);
