import React, { useMemo } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import MainContainerComponent from "./MainContainerComponent";
import { Box, Container, Grid, Skeleton, Typography } from "@mui/material";
import TopicItemComponent from "./TopicItemComponent";
import { ARTICLE_CSS } from "../constants/csses";
import ImageComponent from "./ImageComponent";
import TopicComponent from "./TopicComponent";
import ImageSkelComponent from "./skeletons/ImageSkelComponent";
import TopicItemSkelComponent from "./skeletons/TopicItemSkelComponent";
import TextSkelComponent from "./skeletons/TextSkelComponent";
import BreadcrumbsComponent from "./BreadcrumbsComponent";

const useStyles = (color) =>
  makeStyles((theme) => ({
    subtitle: {
      color: theme.palette.primary.main,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    otherPostsBox: {
      marginRight: theme.spacing(8),
      [theme.breakpoints.down("md")]: {
        marginRight: theme.spacing(4),
      },
      [theme.breakpoints.down("sm")]: {
        marginRight: 0,
        marginBottom: theme.spacing(8),
      },
    },
    postsBox: {
      marginTop: theme.spacing(4),
    },
    desc: {
      ...ARTICLE_CSS(theme, color),
    },
    imgBox: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }));

const PostContainerComponent = ({
  color,
  background,
  data,
  to,
  topic,
  firstActive,
  loading,
  id,
  hiddenOtherPosts,
}) => {
  const classes = useStyles(color)();
  const topicData = useMemo(
    () => ({
      id,
      title: topic,
      to,
      background: background,
    }),
    [topic, to, color]
  );
  return (
    <MainContainerComponent>
      <Container>
        {hiddenOtherPosts ? (
          <BreadcrumbsComponent links={[topicData, { title: data?.title }]} />
        ) : null}
        <Grid container>
          {!hiddenOtherPosts ? (
            <Grid item md={4} sm={6} xs={12}>
              <Box className={classes.otherPostsBox}>
                <TopicComponent data={topicData} hiddenIcon md={12} />
                <Box className={classes.postsBox}>
                  {loading ? (
                    <>
                      <TopicItemSkelComponent />
                      <TopicItemSkelComponent />
                      <TopicItemSkelComponent />
                      <TopicItemSkelComponent />
                      <TopicItemSkelComponent />
                    </>
                  ) : data?.other_posts?.length ? (
                    data.other_posts.map((post, key) => (
                      <TopicItemComponent
                        key={key}
                        active={firstActive ? key === 0 : false}
                        color={color}
                        title={post.title}
                        to={`${to}/${post.slug}`}
                        slug={post.slug}
                      />
                    ))
                  ) : null}
                </Box>
              </Box>
            </Grid>
          ) : null}

          <Grid
            item
            md={hiddenOtherPosts ? 12 : 8}
            sm={hiddenOtherPosts ? 12 : 6}
            xs={12}
          >
            {loading ? (
              <>
                <Skeleton className={classes.title} variant="rect" />
                <Skeleton className={classes.subtitle} variant="rect" />
              </>
            ) : (
              <>
                <Typography variant="h1">{data?.title}</Typography>
                <Typography style={{ color }} className={classes.subtitle}>
                  {data?.subtitle}
                </Typography>
              </>
            )}
            <Box className={classes.imgBox}>
              {loading ? (
                <ImageSkelComponent />
              ) : data?.src ? (
                <ImageComponent src={data?.src} title={data?.title} />
              ) : null}
            </Box>
            {loading ? (
              <>
                <TextSkelComponent />
                <TextSkelComponent />
                <TextSkelComponent />
                <TextSkelComponent />
              </>
            ) : (
              <Typography
                className={classes.desc}
                dangerouslySetInnerHTML={{ __html: data?.desc }}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </MainContainerComponent>
  );
};

PostContainerComponent.propTypes = {
  topic: PropTypes.string,
  id: PropTypes.string,
  color: PropTypes.string,
  to: PropTypes.string,
  background: PropTypes.string,
  data: PropTypes.object,
  loading: PropTypes.bool,
  hiddenOtherPosts: PropTypes.bool,
  firstActive: PropTypes.bool,
};
export default React.memo(PostContainerComponent);
