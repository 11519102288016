import { createTheme } from "@mui/material/styles";
import { MOBILE_DEVICE_WIDTH } from "../constants";

export const newTheme = () => {
  const theme = createTheme({
    typography: {
      fontSize: 20,
      fontFamily: '"sofia-pro", "sans-serif"',
      button: {
        fontSize: 20,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 14,
        },
      },
      body1: {
        fontSize: 18,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 14,
        },
      },
      logoTxt: {
        fontSize: 35,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 25,
        },
      },
      body2: {
        fontSize: 20,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 14,
        },
      },
      h6: {},
      h5: {},
      h4: {},
      h3: {
        fontWeight: 500,
        fontSize: 30,
        marginBottom: 16,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 25,
        },
      },
      h2: {
        fontWeight: 500,
        fontSize: 30,
        marginBottom: 16,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 25,
        },
      },
      h1: {
        fontWeight: 500,
        fontSize: 50,
        marginBottom: 16,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 35,
        },
      },
    },
  });
  return theme.typography;
};

const TextTheme = newTheme();
export default TextTheme;
