import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import PropTypes from "prop-types";
import MainContainerComponent from "../../components/MainContainerComponent";
import { Container, Typography, Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import MainSvg from "../../svg/MainSvg";
import MainTopicComponent from "./components/MainTopicComponent";
import PostItemComponent from "./components/PostItemComponent";
import GrayContainerComponent from "../../components/GrayContainerComponent";
import { NEWS_ROUTE, STORIES_ROUTE } from "../../constants/routes";
import MoreBtnComponent from "../../components/MoreBtnComponent";
import {
  DARK_PRIMARY_COLOR_ID,
  INFORMATIONS_DATA,
  KNWOLEDGES_DATA,
  LIGHT_PRIMARY_COLOR_ID,
  PRIMARY_COLOR_ID,
  STORIES_DATA,
} from "../../constants";
import { useGetMainQuery } from "../../services";
import LinkComponent from "../../components/LinkComponent";

const useStyles = makeStyles((theme) => ({
  bannerGrid: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    alignItems: "center",
  },
  title: {
    fontSize: 75,
    [theme.breakpoints.down("md")]: {
      ...theme.typography.h1,
    },
  },
  subtitle: {
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(4),
  },
  mainIcon: {
    width: "100%",
    "& .dream": {
      transform: "matrix(1, 0, 0, 1, 50, 0)",
      animation: "$dream 5.5s ease-in-out infinite",
    },
    "& .hand": {
      transform: "translateX(0px)",
      animation: "$hand 2s ease-in-out infinite",
    },
    "& .bird": {
      transform: "matrix(1, 0, 0, 1, -50, 0)",
      animation: "$bird 8.5s ease-in-out infinite",
    },
    "& .air_balloon": {
      transform: "matrix(1, 0, 0, 1, 0, 200)",
      animation: "$air_balloon 5.5s ease-in-out infinite",
    },
    "& .big_air_balloon": {
      transform: "matrix(1, 0, 0, 1, 0, 200)",
      animation: "$big_air_balloon 7.5s ease-in-out infinite",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(8),
    },
  },
  knowledge: {
    right: 0,
    width: "40%",
    top: 0,
    bottom: 0,
    [theme.breakpoints.down("md")]: {
      width: "30%",
    },
  },
  info: {
    width: "40%",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: "30%",
    },
  },
  stories: {
    width: "30%",
    top: theme.spacing(4),
    right: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: "20%",
    },
  },
  gridCont: {
    marginBottom: theme.spacing(8),
  },
  "@keyframes hand": {
    "0%": {
      transform: "translateX(0px)",
    },
    "50%": {
      transform: "translateX(3px)",
    },
    "100%": {
      transform: "translateX(0px)",
    },
  },
  "@keyframes air_balloon": {
    "0%": {
      transform: "matrix(1, 0, 0, 1, 0, 200)",
    },
    "50%": {
      transform: "matrix(1, 0, 0, 1, 0, 1)",
    },
    "100%": {
      transform: "matrix(1, 0, 0, 1, 0, 200)",
    },
  },
  "@keyframes big_air_balloon": {
    "0%": {
      transform: "matrix(1, 0, 0, 1, 0, 200)",
    },
    "50%": {
      transform: "matrix(1, 0, 0, 1, 0, 1)",
    },
    "100%": {
      transform: "matrix(1, 0, 0, 1, 0, 200)",
    },
  },
  "@keyframes dream": {
    "0%": {
      transform: "matrix(1, 0, 0, 1, 50, 0)",
    },
    "50%": {
      transform: "matrix(1, 0, 0, 1, 0, 0)",
    },
    "100%": {
      transform: "matrix(1, 0, 0, 1, 50, 0)",
    },
  },
  "@keyframes bird": {
    "0%": {
      transform: "matrix(1, 0, 0, 1, -50, 0)",
    },
    "50%": {
      transform: "matrix(1, 0, 0, 1, 0, 0)",
    },
    "100%": {
      transform: "matrix(1, 0, 0, 1, -50, 0)",
    },
  },
}));

const MainPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data } = useGetMainQuery();
  const theme = useTheme();

  return (
    <MainContainerComponent hiddenSpacing>
      <Container>
        <Grid container className={classes.bannerGrid}>
          <Grid item md={6} xs={12}>
            <Typography variant="h1" className={classes.title}>
              {t("main_page.title")}
            </Typography>
            <Typography
              variant="h1"
              component={"h2"}
              className={classes.subtitle}
            >
              {t("main_page.subtitle")}
            </Typography>
            <LinkComponent to={STORIES_ROUTE} title={t("btns.read_more")}>
              <Button variant="outlined" color="primary">
                {t("btns.read_more")}
              </Button>
            </LinkComponent>
          </Grid>
          <Grid item md={6} xs={12}>
            <MainSvg className={classes.mainIcon} />
          </Grid>
        </Grid>
        <Grid container className={classes.gridCont}>
          <MainTopicComponent
            data={{
              ...KNWOLEDGES_DATA,
              background: theme.palette.background[PRIMARY_COLOR_ID],
            }}
            boxClassName={classes.boxKnowledge}
            iconClassName={classes.knowledge}
          />
          <MainTopicComponent
            data={{
              ...INFORMATIONS_DATA,
              background: theme.palette.background[LIGHT_PRIMARY_COLOR_ID],
            }}
            iconClassName={classes.info}
          />
          <MainTopicComponent
            data={{
              ...STORIES_DATA,
              background: theme.palette.background[DARK_PRIMARY_COLOR_ID],
            }}
            iconClassName={classes.stories}
          />
        </Grid>
      </Container>
      <GrayContainerComponent>
        <Typography variant="h3">{t("main_page.news_title")}</Typography>
        <Grid container>
          {data?.news?.length
            ? data.news.map((post, key) => (
                <PostItemComponent
                  key={key}
                  title={post?.title}
                  date={post?.date}
                  slug={post?.slug}
                  src={post?.src}
                />
              ))
            : null}
        </Grid>
        <MoreBtnComponent to={NEWS_ROUTE} title={t("btns.more_news")} />
      </GrayContainerComponent>
    </MainContainerComponent>
  );
};

MainPage.propTypes = {
  name: PropTypes.string,
};
export default React.memo(MainPage);
