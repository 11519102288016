import React, { useMemo } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { classNames } from "../functions";
import { ANIM_BOX, MAIN_BLOCK } from "../constants/csses";

const useStyles = makeStyles((theme) => ({
  box: {
    ...MAIN_BLOCK(theme),
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    background: theme.palette.primary.main,
    color: theme.palette.text.white,
    ...ANIM_BOX(),
  },
  icon: {
    margin: "auto",
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      position: "static",
      marginRight: 0,
    },
  },
  title: {
    position: "relative",
    wordBreak: "break-word",
  },
  subtitle: {
    zIndex: 1,
    position: "relative",
    marginTop: theme.spacing(4),
  },
}));

const TopicComponent = ({
  data = {},
  hiddenIcon,
  titleClassName = "",
  boxClassName = "",
  iconClassName = "",
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    subtitle,
    title,
    color,
    background,
    id,
    Icon = () => {},
  } = useMemo(() => data, [data]);
  const theme = useTheme();
  return (
    <Box
      className={classNames([classes.box, boxClassName])}
      style={{
        background: background || theme.palette.background[id],
        color: color,
      }}
    >
      <Typography
        variant="h1"
        component="h3"
        className={classNames([classes.title, titleClassName])}
      >
        {t(title)}
      </Typography>
      {subtitle ? (
        <Typography
          color="textPrimary"
          className={classes.subtitle}
          dangerouslySetInnerHTML={{ __html: t(subtitle) }}
        />
      ) : null}
      {!hiddenIcon ? (
        <Icon className={classNames([classes.icon, iconClassName, "icon"])} />
      ) : null}
    </Box>
  );
};

TopicComponent.propTypes = {
  data: PropTypes.object,
  hiddenIcon: PropTypes.bool,
  iconClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  boxClassName: PropTypes.string,
};
export default React.memo(TopicComponent);
