export const MAIN_BLOCK = (theme, withoutPadding, height) => ({
  padding: withoutPadding ? 0 : `${theme.spacing(6)} ${theme.spacing(4)}`,
  paddingBottom: withoutPadding ? 0 : theme.spacing(8),
  borderBottomLeftRadius: 85,
  borderTopRightRadius: 85,
  margin: 0,
  height: height || `100%`,
});

export const IMAGE_BLOCK = (theme, height = 400) => ({
  ...MAIN_BLOCK(theme, true, height),
  width: "100%",
  objectFit: "cover",
  [theme.breakpoints.down("sm")]: {
    height: 300,
  },
});

export const ANIM_BOX = () => ({
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: "''",
    position: "absolute",
    bottom: -35,
    right: -35,
    background: "#00000020",
    height: 32,
    width: 32,
    borderRadius: 32,
    transform: "scale(1)",
    transformOrigin: "50% 50%",
    transition: "transform 0.5s ease-out",
  },
  "&:hover button": {
    transition: "all 0.5s ease-out",
  },
  "&:hover::before": {
    transform: "scale(42)",
  },
});

export const BLUR_EFFECT = {
  backgroundColor: "#50535799 !important",
  backdropFilter: "blur(10px)",
};

export const ARTICLE_CSS = (theme, color) => ({
  marginBottom: 24,
  fontFamily: theme.typography.fontFamily,
  fontDisplay: "swap",
  overflowWrap: "break-word",
  "& *": {
    fontSize: theme.typography.body1.fontSize,
    maxWidth: "100%",
    height: "auto",
    wordBreak: "break-word",
  },
  "& [data-oembed-url]": {
    "& > div": {
      maxWidth: "100% !important",
      "& > div": {
        paddingBottom: "0 !important",
        height: "auto !important",
        "& iframe": {
          position: "relative !important",
          minHeight: 450,
          [theme.breakpoints.down("sm")]: {
            minHeight: 350,
          },
        },
      },
    },
  },
  // '& iframe': {
  //     minHeight: 600,
  // },
  "& img": {
    ...MAIN_BLOCK(theme, true, "auto !important"),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100% !important",
    },
  },
  "& p": {
    marginTop: theme.spacing(2),
    ...theme.typography.body1,
  },
  "& hr": {
    background: theme.palette.background.gray,
    border: `0.75px solid ${theme.palette.background.gray}`,
  },
  "& h2": {
    ...theme.typography.h2,
    color: color || theme.palette.primary.main,
  },
  "& h3": {
    ...theme.typography.h3,
    color: color || theme.palette.primary.main,
  },
  "& h4": {
    ...theme.typography.h4,
    color: color || theme.palette.primary.main,
  },
  "& ol ol, ol ul": {
    fontSize: theme.typography.body1.fontSize,
    listStyle: "none",
    padding: 0,
    "& li::before": {
      color: color || theme.palette.primary.main,
      content: "'•'",
      fontSize: `calc(${theme.typography.body1.fontSize} + 2px)`,
      lineHeight: 1,
      margin: "0 10px 0 -20px",
    },
  },
  "& ul": {
    "& li": {
      marginLeft: theme.spacing(2),
    },
    listStyle: "none",
    padding: 0,
    "& li::before": {
      color: color || theme.palette.primary.main,
      content: "'•'",
      fontSize: `calc(${theme.typography.body1.fontSize} + 2px)`,
      lineHeight: 1,
      margin: "0 10px 0 -20px",
    },
  },
  "& ul ol": {
    ...theme.typography.body1,
    marginLeft: theme.spacing(2),
    listStyle: "none !important",
    counterReset: "countMe2",
    "& li": {
      counterIncrement: "countMe2",
      paddingLeft: theme.spacing(3),
    },
    "& li::before": {
      color: color || theme.palette.primary.main,
      content: 'counter(countMe2) ". "',
      fontSize: `calc(${theme.typography.body1.fontSize} + 2px)`,
    },
  },
  "& ul, ol ul": {
    "& li::before": {
      fontWeight: 550,
      lineHeight: 24,
      marginLeft: "-10px",
    },
  },
  "& ol li::marker": {
    width: "1em",
    fontWeight: 900,
    color: color || theme.palette.primary.main,
    margin: "0 6px 0 -25px",
  },
  "& .border": {
    color: color || theme.palette.primary.main,
    border: `1px solid ${color || theme.palette.primary.main}`,
    padding: "15px 40px",
    fontSize: theme.typography.h1.fontSize,
    borderRadius: 50,
    marginTop: 5,
    marginBottom: 5,
    textDecoration: "none",
    textAlign: "center",
    maxWidth: "100%",
    width: "fit-content",
  },
  "& .border *": {
    fontSize: theme.typography.h1.fontSize,
  },
  "& a": {
    textDecoration: "none",
    color: color || theme.palette.primary.main,
    transition: "all 0.2s ease-in",
    "&:hover": {
      color: color || theme.palette.primary.main,
    },
  },
  "& table": {
    border: "none",
    width: "100% !important",
    margin: "20px auto",
    borderSpacing: 0,
  },
  "& td, th": {
    border: `1px solid ${theme.palette.background.lightGray}`,
    padding: "0 5px",
    // borderColor: theme.palette.gray.light,
    // textAlign: 'center',
    // [theme.breakpoints.down('sm')]: {
    //   display: 'block',
    //   width: '100% !important',
    // },
  },
  "& caption": {
    ...theme.typography.body1,
    fontStyle: "italic",
    marginBottom: 10,
  },
  "& blockquote": {
    position: "relative",
    padding: "10px 20px",
    margin: 0,
    background: theme.palette.background.gray,
    "&>p": {
      fontSize: "18px !important",
      fontWeight: "400 !important",
      fontStyle: "italic",
    },
    "&::after": {
      color: theme.palette.background.gray,
      fontSize: "12em",
      lineHeight: "0.65em",
      right: "20px",
      position: "absolute",
      zIndex: 0,
      top: "-10px",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      width: 4,
      height: "100%",
      display: "block",
      top: 0,
      left: 0,
      background: color || theme.palette.background.primary,
    },
  },
});
