import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import LogoSvg from "../svg/LogoSvg";
import menuItems from "../constants/menuItems";
import MenuItemComponent from "./MenuItemComponent";
import AppBarComponent from "./AppBarComponent";
import LinkComponent from "./LinkComponent";
import { MAIN_ROUTE } from "../constants/routes";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { useCallback } from "react";
import FacebookBtnComponent from "./FacebookBtnComponent";

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.background.default,
    position: "sticky",
    top: 0,
    zIndex: 100,
  },
  logo: {
    height: 70,
    [theme.breakpoints.down("sm")]: {
      height: 35,
    },
  },
  logoCont: {
    display: "flex",
    alignItems: "center",
  },
  logoName: {
    marginLeft: theme.spacing(2),
    ...theme.typography.logoTxt,
  },
  cont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  menuBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  drawerBox: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  facebookDeskCont: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(0.5),
  },
  facebookDesc: {
    width: 30,
    height: 30,
  },
  facebook: {
    marginLeft: theme.spacing(1),
    width: 45,
    height: 45,
  },
}));

const HeaderComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = useCallback(() => {
    setShowDrawer(!showDrawer);
  }, [showDrawer]);
  return (
    <header className={classes.header}>
      <AppBarComponent />
      <Container className={classes.cont}>
        <LinkComponent to={MAIN_ROUTE}>
          <div className={classes.logoCont}>
            <LogoSvg className={classes.logo} />
            <Typography
              className={classes.logoName}
              dangerouslySetInnerHTML={{ __html: t("header.logo_name") }}
            />
          </div>
        </LinkComponent>
        <Box className={classes.menuBox} component="nav">
          {menuItems.map((item, key) => (
            <MenuItemComponent
              key={key}
              name={item?.name}
              to={item.to}
              background={item.background}
            />
          ))}
          <Box className={classes.facebookDeskCont}>
            <FacebookBtnComponent className={classes.facebookDesc} />
          </Box>
        </Box>
        <Box className={classes.drawerBox}>
          <IconButton onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <Drawer anchor="right" open={showDrawer} onClose={toggleDrawer}>
            <Box
              role="presentation"
              onClick={toggleDrawer}
              onKeyDown={toggleDrawer}
            >
              <List>
                {menuItems.map((item, key) => (
                  <ListItem button key={key}>
                    <MenuItemComponent
                      name={item?.name}
                      to={item.to}
                      background={item.background}
                    />
                  </ListItem>
                ))}
                <ListItem button>
                  <FacebookBtnComponent className={classes.facebook} />
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </Box>
      </Container>
    </header>
  );
};

export default React.memo(HeaderComponent);
