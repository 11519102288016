import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import LinkComponent from "../../../components/LinkComponent";

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(2),
    "&:first-of-type": {
      paddingLeft: 0,
    },
    "&:last-of-type": {
      paddingRight: 0,
    },
  },
}));

const GridItemComponent = ({ children, to, external_link, md = 4 }) => {
  const classes = useStyles();

  return (
    <Grid item md={md} xs={12} className={classes.grid} data-aos="fade-up">
      <LinkComponent to={to} external_link={external_link}>
        {children}
      </LinkComponent>
    </Grid>
  );
};

GridItemComponent.propTypes = {
  children: PropTypes.any,
  to: PropTypes.string,
  external_link: PropTypes.bool,
  md: PropTypes.number,
};
export default React.memo(GridItemComponent);
