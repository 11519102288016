import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SEARCH_API } from "../constants/api";
import { GET } from "../functions/superagentSending";

export const FETCH_SEARCH_DATA_REDUCER = createAsyncThunk(
  "SearchReducer/fetchSearchData",
  async ({ name }) => {
    const response = await GET(`${SEARCH_API}/${name}`);
    return response.body;
  }
);

export const SearchReducer = createSlice({
  name: "SearchReducer",
  initialState: {
    data: [],
    loading: false,
    error: {},
  },
  reducers: {},
  extraReducers: {
    [FETCH_SEARCH_DATA_REDUCER.pending]: (state) => {
      state.loading = true;
    },
    [FETCH_SEARCH_DATA_REDUCER.fulfilled]: (state, { payload }) => {
      state.data = payload?.data;
      state.loading = false;
    },
    [FETCH_SEARCH_DATA_REDUCER.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.error = {};
    },
  },
});

export default SearchReducer.reducer;
