import { useTheme } from "@mui/material";
import * as React from "react";

const BigTxtSvg = (props) => {
  const theme = useTheme();
  return (
    <svg
      viewBox="0 0 33 20"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
      {...props}
    >
      <path
        d="M3.164-.476 4.9-5.264h8.456l1.736 4.788A.595.595 0 0 0 15.68 0h2.02c.364 0 .588-.28.42-.7L11.2-19.1a.758.758 0 0 0-.672-.5H7.9a.73.73 0 0 0-.672.5L.308-.672C.168-.28.364 0 .7 0h1.9a.594.594 0 0 0 .564-.476Zm9.352-7.14H5.74L9.072-16.8h.056l3.388 9.184Zm12.32-8.932h-.017a.517.517 0 0 0-.515.515v4.805h-4.676a.554.554 0 0 0-.532.532v1.6c0 .292.24.532.532.532H24.3v4.816c0 .292.24.532.532.532h1.708a.554.554 0 0 0 .532-.532v-4.82h4.7c.292 0 .532-.24.532-.532v-1.6a.554.554 0 0 0-.532-.532h-4.7v-4.788a.534.534 0 0 0-.532-.532l-1.704.004Z"
        style={{
          fill: theme.palette.text.primary,
          fillRule: "nonzero",
        }}
        transform="translate(.217 19.8)"
      />
    </svg>
  );
};

export default BigTxtSvg;
