import { useTheme } from "@mui/styles";
import * as React from "react";

const StoriesSvg = (props) => {
  const theme = useTheme();
  return (
    <svg
      viewBox="0 0 108 135"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      {...props}
    >
      <path
        d="M829.527 137.436v-.011c0-23.432 19.281-42.713 42.713-42.713 23.432 0 42.713 19.281 42.713 42.713a42.729 42.729 0 0 1-13.119 30.799c-6.238 5.992-18.067 17.089-17.648 23.325.044.654.053 1.311.028 1.966-.282 7.462-1.427 14.3-11.753 14.3-10.845 0-12.21-6.462-12.21-15.013 0-.437.016-.87.046-1.3.432-6.187-11.3-17.228-17.52-23.151a42.576 42.576 0 0 1-13.25-30.915Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: "2.68px",
        }}
        transform="rotate(-9 -123.684 5312.613)"
      />
      <path
        d="M837.091 137.436c0-24.266 14.364-43.806 31.766-42.666 15.608 1.022 28.234 18.946 28.848 40.939.357 12.785-3.277 24.379-9.289 32.515-4.427 5.992-12.824 17.089-12.528 23.325.031.644.039 1.3.021 1.966-.2 7.462-1.014 14.3-8.343 14.3-7.7 0-8.667-6.462-8.667-15.013 0-.437.011-.871.033-1.3.306-6.187-8.023-17.228-12.436-23.151-5.796-7.777-9.405-18.751-9.405-30.915Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: "2.68px",
        }}
        transform="rotate(-9 -126.098 5281.927)"
      />
      <path
        d="M849.112 137.436c0-24.266 5.033-43.806 11.13-42.666 5.468 1.022 9.891 18.946 10.108 40.939.125 12.785-1.148 24.379-3.254 32.515-1.552 5.992-4.494 17.089-4.389 23.325.01.644.013 1.3.007 1.966-.071 7.462-.356 14.3-2.923 14.3-2.7 0-3.036-6.462-3.036-15.013 0-.437.004-.871.011-1.3.106-6.187-2.812-17.228-4.358-23.151-2.031-7.777-3.296-18.751-3.296-30.915Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: "2.68px",
        }}
        transform="rotate(-9 -129.936 5233.167)"
      />
      <path
        d="M852.759 168.255v5.638c.006 2.284 1.883 4.161 4.167 4.167h2.289c2.284-.006 4.161-1.883 4.167-4.167v-5.638h-10.623Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: "2.68px",
        }}
        transform="rotate(-9 167.18 5194.903)"
      />
    </svg>
  );
};
export default StoriesSvg;
