import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ANIM_BOX, MAIN_BLOCK } from "../../../constants/csses";
import GridItemComponent from "./GridItemComponent";
import { NEWS_ROUTE } from "../../../constants/routes";

const useStyles = makeStyles((theme) => ({
  box: {
    ...MAIN_BLOCK(theme),
    background: theme.palette.background.default,
    paddingLeft: theme.spacing(6),
    boxShadow: "0 4px 30px #00000010",
    "& span": {
      width: "100%",
    },
    ...ANIM_BOX(),
  },
  img: {
    marginTop: theme.spacing(2),
    width: "100%",
    height: 150,
    objectFit: "cover",
  },
  day: {
    fontWeight: "bold",
    marginBottom: theme.spacing(0.5),
  },
  date: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
}));

const PostItemComponent = ({ src = "", title = "", date, slug = "" }) => {
  const classes = useStyles();

  return (
    <GridItemComponent to={`${NEWS_ROUTE}/${slug}`}>
      <Box className={classes.box}>
        <Typography variant="h1" component="p" className={classes.day}>
          {moment(date).format("DD")}
        </Typography>
        <Typography className={classes.date}>
          {moment(date).format("MM.YYYY")}
        </Typography>
        <Typography variant="body2">{title}</Typography>
        <LazyLoadImage
          className={classes.img}
          effect="blur"
          src={src}
          alt={title}
        />
      </Box>
    </GridItemComponent>
  );
};

PostItemComponent.propTypes = {
  src: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
};
export default React.memo(PostItemComponent);
