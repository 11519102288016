import React from "react";
import { makeStyles } from "@mui/styles";
import LinkComponent from "./LinkComponent";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

const useStyles = (background) =>
  makeStyles((theme) => ({
    section: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      transition: "all 0.2s ease-in",
      borderRadius: 40,
      "&:hover": {
        background: background || theme.palette.background.primary,
      },

      "&:last-of-type": {
        marginRight: `-${theme.spacing(2)}`,
      },
      [theme.breakpoints.down("md")]: {
        padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
        "&:last-of-type": {
          marginRight: `-${theme.spacing(1)}`,
        },
      },
    },
    link: {
      "&:hover": {
        background: background || theme.palette.background.primary,
      },
    },
  }));

const MenuItemComponent = ({ name = "", to, background }) => {
  const classes = useStyles(background)();
  const { t } = useTranslation();
  return (
    <section className={classes.section}>
      <LinkComponent title={t(name)} to={to} className={classes.link}>
        <Typography>{t(name)}</Typography>
      </LinkComponent>
    </section>
  );
};

MenuItemComponent.propTypes = {
  name: PropTypes.string,
  background: PropTypes.string,
  to: PropTypes.string,
};
export default React.memo(MenuItemComponent);
