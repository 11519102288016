import { createTheme } from "@mui/material/styles";
import {
  DARK_PRIMARY_COLOR,
  DARK_PRIMARY_COLOR_ID,
  LIGHT_PRIMARY_COLOR,
  LIGHT_PRIMARY_COLOR_ID,
  MOBILE_DEVICE_WIDTH,
  PRIMARY_COLOR_ID,
} from "../constants";

// const tablet = "@media (max-width: 920px)";
export const crTheme = () => {
  const theme = createTheme({
    "*": {
      fontFamily: '"sofia-pro", "sans-serif"',
    },
    typography: {
      fontSize: 18,
      fontFamily: '"sofia-pro", "sans-serif"',
      button: {
        fontSize: 16,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 12,
        },
      },
      body1: {
        fontSize: 16,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 12,
        },
      },
      logoTxt: {
        fontSize: 30,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 20,
        },
      },
      body2: {
        fontSize: 18,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 12,
        },
      },
      h6: {},
      h5: {},
      h4: {
        fontWeight: 500,
        fontSize: 20,
        marginBottom: 8,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 18,
        },
      },
      h3: {
        fontWeight: 500,
        fontSize: 25,
        marginBottom: 16,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 20,
        },
      },
      contact: {
        fontWeight: 900,
        fontSize: 26,
        marginBottom: 16,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 20,
        },
      },
      h2: {
        fontWeight: 500,
        fontSize: 25,
        marginBottom: 16,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 20,
        },
      },
      h1: {
        fontWeight: 500,
        fontSize: 45,
        marginBottom: 16,
        [MOBILE_DEVICE_WIDTH]: {
          fontSize: 30,
        },
      },
    },
    palette: {
      text: {
        primary: "#363634",
        secondary: "#616261",
        white: "#FFFFFF",
        [PRIMARY_COLOR_ID]: "#BBEDF5",
        [LIGHT_PRIMARY_COLOR_ID]: "#A9D8EF",
        [DARK_PRIMARY_COLOR_ID]: "#99B9F8",
      },
      primary: {
        main: "#4881F2",
        dark: DARK_PRIMARY_COLOR,
        light: LIGHT_PRIMARY_COLOR,
      },
      background: {
        primary: "#4881F2",
        default: "#FFFFFF",
        gray: "#F7F7F7",
        moreIcon: "#4b4b4b",
        darkGray: "#E8E8E8",
        [PRIMARY_COLOR_ID]: "#BBEDF5",
        [LIGHT_PRIMARY_COLOR_ID]: "#A9D8EF",
        [DARK_PRIMARY_COLOR_ID]: "#99B9F8",
      },
    },
    overrides: {},
    props: {
      MuiContainer: {
        // maxWidth: "md",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 50,
            padding: "8px 24px",
          },
          outlinedPrimary: {
            color: "#363634",
          },
        },
      },
    },
  });
  return {
    ...theme,
  };
};

const MainTheme = crTheme();
export default MainTheme;
