import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textFieldGray: {
    "&>div": {
      background: theme.palette.background.default,
    },
  },
  textfield: {
    [theme.breakpoints.down("xs")]: {
      "& fieldset": {
        border: `1px solid ${theme.palette.background.gray}`,
      },
    },
  },
}));

const ArticlesTextfieldComponent = ({
  title,
  value = "",
  onChange = () => {},
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <section>
      <TextField
        fullWidth
        placeholder={title || t("form.shops")}
        variant="outlined"
        classes={{ root: classes.textFieldGray }}
        className={classes.textfield}
        onChange={onChange}
        value={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon color="primary" />
            </InputAdornment>
          ),
        }}
      />
    </section>
  );
};

ArticlesTextfieldComponent.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  title: PropTypes.string,
};
export default React.memo(ArticlesTextfieldComponent);
