import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { IMAGE_BLOCK } from "../constants/csses";

const useStyles = makeStyles((theme) => ({
  image: {
    ...IMAGE_BLOCK(theme),
  },
}));

const ImageComponent = ({ src, alt }) => {
  const classes = useStyles();

  return <LazyLoadImage src={src} alt={alt} className={classes.image} />;
};

ImageComponent.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};
export default React.memo(ImageComponent);
