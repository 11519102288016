import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import pl from "./pl.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  pl: {
    translation: pl,
  },
};

i18n.use(initReactI18next).init({
  resources,
  // lng: localStorage.getItem('lang') || PL_LNG,
  lng: "pl",
  fallbackLng: ["pl"],

  // keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
