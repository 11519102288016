import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import MainContainerComponent from "../../components/MainContainerComponent";
import WavesSvg from "../../svg/WavesSvg";
import { Container, Grid, Typography } from "@mui/material";
import ContactSvg from "../../svg/ContactSvg";
import { useTranslation } from "react-i18next";
import ContactGroupComponent from "../../components/ContactGroupComponent";
import FacebookBtnComponent from "../../components/FacebookBtnComponent";

const useStyles = makeStyles((theme) => ({
  wave: {
    position: "absolute",
    width: 300,
    top: 100,
    right: 0,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  gridCont: {
    minHeight: "70vh",
    marginBottom: theme.spacing(12),
  },
  tel: {
    display: "block",
    ...theme.typography.contact,

    marginBottom: theme.spacing(1),
  },
  address: {
    ...theme.typography.contact,
    fontWeight: 500,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  h1: {
    ...theme.typography.contact,
  },
  icon: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(4),
    },
  },
  facebook: {
    width: 50,
    height: 50,
  },
}));

const ContactPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <MainContainerComponent>
      <WavesSvg className={classes.wave} />
      <Container>
        <Grid container className={classes.gridCont} alignItems="center">
          <Grid item md={6} xs={12}>
            <Typography variant="h2" component="h1" className={classes.h1}>
              {t("contact_page.title")}
            </Typography>
            <Typography
              className={classes.address}
              dangerouslySetInnerHTML={{ __html: t("contact_page.address") }}
            />
            <ContactGroupComponent className={classes.tel} />
            <Typography
              className={classes.address}
              dangerouslySetInnerHTML={{
                __html: t("contact_page.project_desc"),
              }}
            />
            <FacebookBtnComponent className={classes.facebook} />
          </Grid>
          <Grid item md={6} xs={12}>
            <ContactSvg className={classes.icon} />
          </Grid>
        </Grid>
      </Container>
    </MainContainerComponent>
  );
};

ContactPage.propTypes = {
  name: PropTypes.string,
};
export default React.memo(ContactPage);
