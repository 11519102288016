import { useTheme } from "@mui/styles";
import * as React from "react";

const InfoSvg = (props) => {
  const theme = useTheme();
  return (
    <svg
      viewBox="0 0 187 138"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeMiterlimit: 10,
      }}
      {...props}
    >
      <path
        d="M192.945 54.631c.293 1.575 1.03 3.047 1.1 4.676a6.238 6.238 0 0 0-2.5 2.933c-1.354 2.711-3.75 4.477-6.349 5.945-.774.437-1.592.79-2.376 1.209-.9.481-1.634 1.111-1.116 2.242.508 1.107 1.473 1.086 2.418.661a21.344 21.344 0 0 0 6.985-4.439c.69-.723 1.425-1.4 2.081-2.159.309-.358.551-.88 1.188-.71.341 1.674-.247 3.307-.214 4.968a3.66 3.66 0 0 0-3.02 2.224 11.225 11.225 0 0 1-4.688 4.407c-.3.156-.6.3-.89.462-.888.482-1.7 1.091-1.19 2.225.543 1.216 1.58.988 2.587.619a17.487 17.487 0 0 0 3.168-1.85c.491-.307.878-.913 1.605-.674-.741 2.4-2.47 4.234-3.684 6.363a14.59 14.59 0 0 1-3.213 4.2 133.52 133.52 0 0 1-21.4 17.736 6.028 6.028 0 0 0-.794.53 184.327 184.327 0 0 1-28.137 16.188 142.858 142.858 0 0 1-19.68 7.285c-.652.189-1.2.557-1.817.8a31.716 31.716 0 0 0-5.7 1.223 98.073 98.073 0 0 1-28.138 2.635c-3.689-.2-7.447-.42-10.879-2.114a52.12 52.12 0 0 1-12.454-5.5c-7.772-5.081-12.754-12.243-15.757-20.894-.818-2.354.09-3.824 2.741-4.752.821-.632 1.613-.184 2.337.15 5.038 2.327 10.405 3.339 15.853 4.047a6.82 6.82 0 0 0 .99-.05 89.003 89.003 0 0 0 45.507-8.85c1.507-.723 2.924-1.613 4.361-2.464a63.379 63.379 0 0 0 23.569-24.723c4.917-9.067 7.4-18.954 9.686-28.9.662-2.877 1.223-5.778 1.959-8.638 1.024-3.98 3.713-5.806 7.487-5.12 3.338.606 5.79 4.168 5.353 7.789a37.007 37.007 0 0 0 .6 12.121c.193.985.501 1.945.918 2.858 1.469 3.111 3.466 4.058 6.771 3.126 4.5-1.268 8.49-3.612 12.5-5.919a15.484 15.484 0 0 1 4-2.052.801.801 0 0 1 .916.192c2.453.372 4.5 1.318 5.665 3.706.462.949.331 2.107 1.106 2.924a4.172 4.172 0 0 0-3.064 2.249 14.966 14.966 0 0 1-6.094 5.288c-1.477.774-3.026 1.414-4.442 2.3-.823.514-1.345 1.267-.793 2.246a1.549 1.549 0 0 0 2.2.5c4.414-2.012 8.819-4.046 11.632-8.309.27-.4.5-.898 1.106-.91Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: 2,
        }}
        transform="translate(-19.121 -17.677)"
      />
      <path
        d="M37.538 25.67A41.592 41.592 0 0 1 55.544 5.308a37.975 37.975 0 0 1 22.2-4.65 42.516 42.516 0 0 1 24.392 10.128 43.553 43.553 0 0 1 5.182 5.179c2.305 2.727 4.7 2.72 7-.031A41.972 41.972 0 0 1 140.6 1.073c11.505-1.835 22.079.7 31.266 8.2a44.784 44.784 0 0 1 11.867 15.507c.115.307.2.624.253.947"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: 2,
        }}
        transform="translate(-17.477 1.227)"
      />
      <path
        d="M34.911 105.969a139.317 139.317 0 0 1-20.337-17.006 15.68 15.68 0 0 1-3.2-4.218c-1.206-2.135-2.953-3.957-3.687-6.365.792-.237 1.2.429 1.736.765 1.106.84 2.338 1.5 3.651 1.955a1.525 1.525 0 0 0 1.969-.79 1.468 1.468 0 0 0-.731-1.979c-.841-.54-1.783-.906-2.6-1.489a10.358 10.358 0 0 1-3.223-3.324 5.609 5.609 0 0 0-3.079-2.827A11.441 11.441 0 0 1 5.043 66c.793-.155 1.03.539 1.4.968a22.52 22.52 0 0 0 8.242 6.08 5.66 5.66 0 0 0 1.246.463 1.5 1.5 0 0 0 1.794-.86 1.446 1.446 0 0 0-.562-1.9 14.785 14.785 0 0 0-2.054-1.1c-3.367-1.756-6.352-3.909-7.767-7.638-.361-.95-1.313-1.131-1.965-1.7.054-1.632.833-3.095 1.089-4.679.685.042.852.661 1.155 1.09 2.929 4.151 7.341 6.19 11.736 8.24a1.48 1.48 0 0 0 2.118-.737 1.583 1.583 0 0 0-.84-2.109c-1.848-1.14-3.9-1.9-5.735-3.074a14.327 14.327 0 0 1-4.725-4.388 4.393 4.393 0 0 0-3.162-2.389c.332-.37.55-.829.625-1.321.739-2.875 2.354-4.8 5.5-5.068.2-.017.386-.194.579-.3 3.546.994 6.265 3.551 9.548 5.052a44.623 44.623 0 0 0 8.5 3.395 5.1 5.1 0 0 0 6.249-2.825c1.568-2.925 1.875-6.158 2.086-9.367A31.36 31.36 0 0 0 39.9 35a6.699 6.699 0 0 1 3.921-6.767 5.785 5.785 0 0 1 7.037 1.867c1.11 1.539 1.247 3.431 1.647 5.208 2.206 9.814 4.275 19.66 8.179 29a65.288 65.288 0 0 0 24.494 29.7c.507.339 1 .7 1.5 1.044"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: 2,
        }}
        transform="translate(6.339 -18.686)"
      />
      <path
        d="M233.863 419.16a111.315 111.315 0 0 1-16.256 11.294 96.09 96.09 0 0 1-16.6 7.779c-3.985 1.37-7.977 2.95-12.292 2.637-4.019-.292-7.809-1.683-11.541-3.1a112.687 112.687 0 0 1-31.615-18.611"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: 2,
        }}
        transform="translate(-96.391 -304.618)"
      />
      <path
        d="M364.4 306.772a51.035 51.035 0 0 0 14.867-3.862c.769-.35 1.6-.873 2.491-.217 2.869.986 3.58 2.519 2.536 5.386-4.7 12.9-13.523 21.432-26.749 25.319-.424.125-.834.3-1.25.451a76.074 76.074 0 0 1-17.186 1.739 108.114 108.114 0 0 1-25.956-3.793"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: 2,
        }}
        transform="translate(-218.823 -219.307)"
      />
      <path
        d="M34.8 256.573a92.008 92.008 0 0 1-5.749-15.873c-.119-.477-.159-.974-.236-1.461"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: 2,
        }}
        transform="translate(-11.104 -173.182)"
      />
      <path
        d="M573.746 239.228a59.548 59.548 0 0 1-3.642 11.845c-.705 1.859-1.563 3.659-2.352 5.485"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: 2,
        }}
        transform="translate(-404.816 -173.173)"
      />
      <path
        d="M8.788 122.083c-.871-1.293-1.1-2.482.547-3.367"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: 2,
        }}
        transform="translate(4.018 -85.134)"
      />
      <path
        d="M606.148 118.738c1.619.89 1.45 2.069.543 3.366"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: 2,
        }}
        transform="translate(-432.867 -85.15)"
      />
      <path
        d="m1.8 154.259-1.04-2.577a2.085 2.085 0 0 1-.291-1.061c0-.985.7-1.842 1.665-2.039"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: 2,
        }}
        transform="translate(9.58 -106.949)"
      />
      <path
        d="M612.23 148.582c1.708.892 1.964 1.488 1.328 3.261-.294.82-.66 1.614-.993 2.42"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: 2,
        }}
        transform="translate(-437.31 -106.952)"
      />
      <path
        d="M5.649 194.774a33.217 33.217 0 0 1-2.994-4.139c-.81-1.445-.694-2.6.72-3.551"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: 2,
        }}
        transform="translate(8.374 -135.08)"
      />
      <path
        d="M606.1 188.1c1.366.709 1.277 1.778.714 2.947a24.687 24.687 0 0 1-3.142 4.467"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: 2,
        }}
        transform="translate(-431.057 -135.825)"
      />
      <path
        d="m496.353 319.859 1.369.025c-.606.516-1.326.243-2 .288a.624.624 0 0 1 .631-.313Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: theme.palette.text.white,
          strokeWidth: 2,
        }}
        transform="translate(-352.197 -232.076)"
      />
    </svg>
  );
};

export default InfoSvg;
