import React, { useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Container, Typography } from "@mui/material";
import IconBtnComponent from "./IconBtnComponent";
import SearchSvg from "../svg/SearchSvg";
import { useCallback } from "react";
import { DISABLED_PEOPLE_FUNC } from "../constants";
import { useTranslation } from "react-i18next";
import SearchComponent from "./SearchComponent";

const useStyles = makeStyles((theme) => ({
  box: {
    background: theme.palette.background.default,
    boxShadow: `0 3px 28px #00000010`,
  },
  cont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

const AppBarComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showSearch, setShowSearch] = useState(false);
  const handleToggleSearch = useCallback(() => {
    setShowSearch(!showSearch);
  }, [showSearch]);
  const data = useMemo(() => DISABLED_PEOPLE_FUNC(t) || [], [t]);

  return (
    <Box className={classes.box}>
      <Container className={classes.cont}>
        <IconBtnComponent Icon={SearchSvg} onClick={handleToggleSearch} />
        <Typography>|</Typography>
        {data?.length
          ? data.map((item, key) => (
              <IconBtnComponent
                Icon={item.Icon}
                small={item.small}
                id={item.id}
                to={item.to}
                value={item.value}
                // title={item.title}
                key={key}
              />
            ))
          : null}
      </Container>
      <SearchComponent
        open={showSearch}
        handleToggleSearch={handleToggleSearch}
      />
    </Box>
  );
};

AppBarComponent.propTypes = {
  name: PropTypes.string,
};
export default React.memo(AppBarComponent);
