import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// MAP_ROUTE
import {
  STORIES_ROUTE,
  INFORMATIONS_ROUTE,
  KNOWLEDGES_ROUTE,
  MAIN_ROUTE,
  NEWS_ROUTE,
  CONTACT_ROUTE,
  MAP_ROUTE,
} from "./constants/routes";
import StoriesPage from "./pages/storiesPage/StoriesPage";
import StoryPostPage from "./pages/storyPostPage/StoryPostPage";
import InformationPostPage from "./pages/informationPostPage/InformationPostPage";
import InformationsPage from "./pages/informationsPage/InformationsPage";
import KnowledgePostPage from "./pages/knowledgePostPage/KnowledgePostPage";
import MainPage from "./pages/mainPage/MainPage";
import NewsPage from "./pages/newsPage/NewsPage";
import NewsPostPage from "./pages/newsPostPage/NewsPostPage";
import KnowledgesPage from "./pages/knowledgesPage/KnowledgesPage";
import ContactPage from "./pages/contactPage/ContactPage";
import MapPage from "./pages/mapPage/MapPage";

const Root = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={MAIN_ROUTE} element={<MainPage />} />
        <Route exact path={INFORMATIONS_ROUTE} element={<InformationsPage />} />
        <Route
          exact
          path={`${INFORMATIONS_ROUTE}/:slug`}
          element={<InformationPostPage />}
        />
        <Route
          exact
          path={`${KNOWLEDGES_ROUTE}/:slug`}
          element={<KnowledgePostPage />}
        />
        <Route exact path={KNOWLEDGES_ROUTE} element={<KnowledgesPage />} />
        <Route exact path={NEWS_ROUTE} element={<NewsPage />} />
        <Route exact path={`${NEWS_ROUTE}/:slug`} element={<NewsPostPage />} />
        <Route exact path={STORIES_ROUTE} element={<StoriesPage />} />
        <Route exact path={CONTACT_ROUTE} element={<ContactPage />} />
        <Route exact path={MAP_ROUTE} element={<MapPage />} />
        <Route
          exact
          path={`${STORIES_ROUTE}/:slug`}
          element={<StoryPostPage />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Root;
