import React from "react";
import { useGetKnowledgePostQuery } from "../../services";
import PostContainerComponent from "../../components/PostContainerComponent";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { KNOWLEDGES_ROUTE } from "../../constants/routes";
import { useTheme } from "@mui/styles";
import { KNWOLEDGES_DATA } from "../../constants";

const KnowledgePostPage = () => {
  const { slug } = useParams();
  const { data, loading } = useGetKnowledgePostQuery(slug);
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <PostContainerComponent
      data={data?.data}
      to={KNOWLEDGES_ROUTE}
      color={theme.palette.text[KNWOLEDGES_DATA.id]}
      id={KNWOLEDGES_DATA.id}
      background={theme.palette.background[KNWOLEDGES_DATA.id]}
      loading={loading}
      topic={t("knowledge_page.title")}
    />
  );
};

export default React.memo(KnowledgePostPage);
